import React, { useContext, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
//import fs from 'fs';
//import { API } from 'netlify';
import { generateLogo, getAboutBlock, getBannerBlock, getMenus, getServicesBlock, getTestimonialsBlock, getWebsiteColors } from '../helpers/prompts';
import WebsitePreview from './WebsitePreview';
import SitePreview from '../pages/SiteCreated';
import TemplateStylesOne from './StylesOne';
//import styles from '../App.css';
import { Button, CircularProgress, Skeleton } from '@mui/material';
import { MessageContext } from '../NEW/contexts/MessageContext';
import { supabase } from '../supabase';
import HtmlViewer from './HtmlViewer';
import { getFontCombos } from '../helpers/fontChoices';
import { prepareColorCssFromPallette, rgbToHex } from '../helpers/colors';
import PlaceholderList from '../NEW/components/PlaceholderList';
import { getStylesOnly } from '../helpers/style';
import { addScripts } from '../helpers/scripts';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWebsites, setCurrentEditedWebsitePageSectionIndex } from '../NEW/redux/slices/websiteSlice';

const WebsitePreviewerLive = ({ websiteId, theFontCombo, theColorPallete, iframeRef, onHTMLChange, children, toRefresh=1 }) => {
  
  const { addMessage } = useContext(MessageContext);
  const websiteData = useSelector(state => state.websites?.websites?.find(website => website.id === parseInt(websiteId))); 
  const {currentEditedWebsitePageId, isLoading } = useSelector(state => state.websites);

  const dispatch = useDispatch();
  console.log('in preview website data',websiteData,'pageId:',currentEditedWebsitePageId)
  // Generate the HTML content
  //const htmlContent = ReactDOMServer.renderToString(<WebsitePreview website={website} />);

    const fetchHomePageData = async (name = 'Somistry', industry='Florist', websiteType='business') => {
        let pageData = {
            "_id": "644759ffb29fcec827b43354",
            "Website": "644759ffb29fcec827b43350",
            "slug": null,
            "label": "Home",
            "showOnHeader": true,
            "showOnFooter": true,
            "default": true,
            "blocks": [],
            "order": 0,
            "createdAt": "2023-04-25T04:41:35.809Z",
            "seo": {
                "title": "Home Page",
                "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
                "keywords": "keyword",
                "headCode": "",
                "footerCode": "",
                "Image": null
            },
            "__v": 3
        }
        try {
            const header = {
                "WebsiteBlock": {
                    "_id": "629f98a1eb0b4972268051aa",
                    "description": "Website header with logo and links",
                    "type": "header",
                    "active": true,
                    "name": "Header"
                },
                "siteName": name,
                "showLogo": false,
                "source": "branding",
                "customLogo": null,
                "logoDesktop": {
                    "height": 80
                },
                "logoMobile": {
                    "height": 40
                },
                "showSocial": true,
                "style": "left",
                "fullWidth": true,
                "sticky": false,
                "background": "#3D9970",
                "button": {
                    "label": "Call Us",
                    "link": "#",
                    "type": "url"
                }
            };

            const footer = {
                "WebsiteBlock": {
                    "_id": "629f98a1eb0b4972268051ab",
                    "description": "Website footer with logos and links",
                    "type": "footer",
                    "active": true,
                    "name": "Footer"
                },
                "businessName": "ZenoCorp",
                "showLogo": true,
                "style": "simple-right",
                "additionalDetails": "",
                "showSocial": true,
                "madeWithContentSimi": true,
                "background": "#83dfb6",
                "button": {
                    "label": "Home",
                    "link": "/",
                    "type": "page",
                    "page": "644759ffb29fcec827b43354"
                }
            };
            //let banner = [];
            let testimonial = [];
            //let service = [];
            //let about = [];
            let theWebsiteSettings = {"websiteName":"okni","pages":["new","effective"],"backgroundColor":"#e10e0e","navigationColor":"#67d58e","headingColor":"#ae4242","bodyTextColor":"","googleFont":"Roboto"};
            
            try{
                setGeneratedProgress(5);
                setGeneratedProgressLabel('Understanding what colors would suit ..')
                addMessage('Understanding what colors would suit ..')
                let theColorsTaken = await getWebsiteColors(name,industry);
                const theColors = JSON.parse(theColorsTaken);
                theWebsiteSettings['navigationColor'] = theColors['navigationBarColor'];
                header['websiteSettings'] = theWebsiteSettings;
                console.log(header)
                console.log(theColorsTaken)
            } catch (error) {
                // Handle errors
                console.log('error in website Settings')
                console.log(error.message)
            }

            
            setGeneratedProgressLabel('Creating Menus ..')
            addMessage('Creating Menus ..')
            const theMenus = await getMenus(name,industry);
            const menus = theMenus.map((menu) => {
              if(Array.isArray(menu)) {
                //return undefined;
              }
              menu['label'] = menu.menu;
              menu['link'] = "#"+menu.menu.replace(' ','+');
              menu['type'] = 'section';
              menu['section'] = '1234';
              return menu;
            }).filter((element) => element !== undefined);

            header['menus'] = menus;


            setGeneratedProgress(10);
            setGeneratedProgressLabel('Generating Logo ..')
            addMessage('Generating Logo ..')
            const logo = await generateLogo(name,industry);
            //console.log('error in logo')
            header['customLogo'] = (logo && logo.data && logo.data.length>0) ? logo.data[0] : null;
            setGeneratedProgress(20);
        //try{
            setGeneratedProgressLabel('Generating Blocks ..')
            addMessage('Generating Blocks ..')
            const banner = await getBannerBlock(name,industry,websiteType,120);
            setGeneratedProgress(30)
        //} catch (error) {
            // Handle errors
        //    console.log('error in banner')
        //}
        //try{
            const about = await getAboutBlock(name,industry,websiteType);
            setGeneratedProgress(40)
        //} catch (error) {
        //    // Handle errors
        //    console.log('error in about')
       // }
        //try{
            setGeneratedProgressLabel('Generating Content for services ..')
            addMessage('Generating Content for services ..')
            const service = await getServicesBlock(name,industry);
            setGeneratedProgress(60)
        //} catch (error) {
            // Handle errors
        //    console.log('error in service')
        //}
        try{
            setGeneratedProgressLabel('Generating Testimonials ..')
            addMessage('Generating Testimonials ..')
            testimonial = await getTestimonialsBlock(name,industry)
            setGeneratedProgress(70)
        } catch (error) {
            // Handle errors
            console.log('error in testimonial')
            console.log(error.message)
        }
            const homePageBlocks = [header,banner,testimonial,service,about,footer];

            console.log(homePageBlocks)
            let newPageData = pageData;
            newPageData['blocks'] = homePageBlocks;
            //savePage('Home',newPageData);
            setGeneratedProgressLabel('')
            addMessage('Preparing View ..')
            setGeneratedProgress(100)
            return newPageData;

        } catch (error) {
            // Handle errors
            console.log('error in fetchHomePageData')
            console.log(error.message)
        }
    }
    const savePage = (pageName, {blocks}) => {
        let thePage = {
            "_id": "644759ffb29fcec827b43354",
            "Website": "644759ffb29fcec827b43350",
            "slug": null,
            "label": "Home",
            "showOnHeader": true,
            "showOnFooter": true,
            "default": true,
            "blocks": [],
            "order": 0,
            "createdAt": "2023-04-25T04:41:35.809Z",
            "seo": {
                "title": "Home Page",
                "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
                "keywords": "keyword",
                "headCode": "",
                "footerCode": "",
                "Image": null
            },
            "__v": 3
        }
        if(blocks && blocks.length>0){
            thePage['blocks'] = blocks;
        }
        if(pageName && pageName!==''){
            thePage['label'] = pageName;
        }
        //localStorage.setItem('homePageData', JSON.stringify(thePage));
    }


    const generateWebsite = async ({thePageData=false,brandGuide=false,theWebsiteData=false,theSiteSettings=false,seoConfig=false, cssVariables=[]}) => {
        //const pageData = (thePageData) ? thePageData : await fetchHomePageData(website.name,website.industry,website.websiteType);
        const pageData = thePageData;
        const htmlContent = ReactDOMServer.renderToString(<WebsitePreview pageData={pageData} websiteData={theWebsiteData} pageId={currentEditedWebsitePageId} />);
        //const inlineStyle = require('../App.css');
        //console.log(inlineStyle);

        const inlineStyle = TemplateStylesOne();

        console.log('Font In preview',theFontCombo);
        let fontCombo = (brandGuide && brandGuide.fontCombo && brandGuide.fontCombo.url) ? brandGuide.fontCombo : getFontCombos();
        fontCombo = (theFontCombo && theFontCombo.url) ? theFontCombo : fontCombo;
        console.log('Font going in',fontCombo);

        
        console.log('Color In preview',theColorPallete);
        let colorPalette = (brandGuide && brandGuide.colorCombo && Object.values(brandGuide.colorCombo).length>0) ? Object.values(brandGuide.colorCombo) : [];
        colorPalette = (theColorPallete && theColorPallete.length>0) ? theColorPallete : colorPalette;
        console.log('Color going in',colorPalette);

        let analyticsCodeHead = false;
        if(theSiteSettings?.analytics?.google){
            analyticsCodeHead = `<!-- Google tag (gtag.js) -->
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-501LYYTH1T"></script>
            <script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', '${theSiteSettings.analytics.google}');
            </script>`;
        }
        
        if(theSiteSettings?.analytics?.facebook){
            let analyticsCodeHeadOld = "\n"+`\n<!-- Facebook Pixel Code -->
            <script>
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${theSiteSettings.analytics.facebook}');
              fbq('track', 'PageView');
            </script>
            <!-- End Facebook Pixel Code -->`;

            analyticsCodeHead += `<!-- Meta Pixel Code -->
            <script>
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${theSiteSettings.analytics.facebook}');
            fbq('track', 'PageView');
            </script>
            <!-- End Meta Pixel Code -->`;
        }


        let seoCodeHead = '';
        
        // Handle meta tags
        if (seoConfig.metas) {
            Object.keys(seoConfig.metas).forEach((seokey) => {
                if(seokey!=='title'){
                    const propertyPrefixes = ['og:', 'fb:', 'twitter:'];
                    const isProperty = propertyPrefixes.some(prefix => seokey.startsWith(prefix));
                    
                    if (isProperty) {
                        seoCodeHead += "\n"+`<meta property="${seokey}" content="${seoConfig.metas[seokey]}" />`;
                    } else {
                        seoCodeHead += "\n"+`<meta content="${seoConfig.metas[seokey]}" name="${seokey}"  />`;
                    }
                }
            });
        }
        
        // Handle JSON-LD objects
        if (seoConfig.jsonLds) {
            seoConfig.jsonLds.forEach(jsonLd => {
                seoCodeHead += `<script type="application/ld+json">${JSON.stringify(jsonLd)}</script>`;
            });
        }
        let cssVariablesCSS = '';
        if(cssVariables){        
            cssVariables && cssVariables.map((cssVariable,index) => {
                cssVariablesCSS += `${cssVariable.name} : ${cssVariable.value};`;
            })
        }
        

        // Generate the final HTML file
        const finalHtml = `<!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>${website.name}</title>
            <meta content="ContentSimi" name="generator" />
            ${seoCodeHead}
            <!-- Include necessary stylesheets or scripts -->
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap" rel="stylesheet">            
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
            <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/js/all.min.js"></script>
            ${fontCombo.url}
            <style>   
            ${(colorPalette && colorPalette.length>0) ? prepareColorCssFromPallette(colorPalette) : prepareColorCssFromPallette()}         
            :root {
                ${cssVariablesCSS?.replace("\n",' ')}
                font-size: 18px;
            }

            ${inlineStyle?.replace("\n",' ')}
            ${fontCombo?.style?.replace("\n",' ')}
            ${getStylesOnly?.replace("\n",' ')}
            body{
                background-color: ${(colorPalette && colorPalette.length>0 && colorPalette[0]) ? rgbToHex(...colorPalette[0]) : '#fcfcff'}
                font-size: 1.2 rem !important;
            }
            /*
            img{
                opacity: 0.7;
            }
            */
            /*a{pointer-events: none;}*/
            a{
                color: inherit;
                font-weight: 600;
            }
            .card{
                z-Index: 1;
            }
            h1,h2,h3,.display-1,.display-2,.display-3{
                letter-spacing: -0.02em;
                line-height: 1.01;
                margin-bottom: 0.4em;
            }
            body{                
                line-height: 1.89em;
                overflow-x: hidden;
            }
            blockquote, ol, p, ul {
                margin-bottom: 1rem;
            }
            .highlight {
                background-color: yellow;
            }            
            .stretched-word {
                display: inline-block;
                white-space: nowrap; /* Prevent word wrapping */
                overflow: hidden; /* Hide any overflow */
                text-overflow: ellipsis; /* Show ellipsis (...) if the word is too long */
            }
            .card {
                position: inherit;
            }   
            .stretch-company-name {
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
            }
           .top-div-full-blown div:nth-child(1):not(:has(.brandtransparentbg)) {
                padding-top: 50px !important;
            }
            
            /* Style when the first child DOES have .include-class */
            .top-div-full-blown div:nth-child(1).brandtransparentbg {
                padding-top: 50px !important;
            }
            
            .accordion-respective-image-container .image-div{
                width: 100% !important;
                display: none !important;
            }
            .accordion-respective-image-container .image-div.active{
                display: block !important;
            }  
            h1,h2,h3,.h3,h4{                
                animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
                animation-duration: 2s; /* don't forget to set a duration! */
            }
            .calendly-inline-widget2 {
                position: relative;
                height: 100%;
                min-height: 100vh;
                iframe {
                  height: 100%;
                  width: 100%;    
                  left: 0;
                  top: 0;
                  position: absolute;
                  body,html {
                    height: 100%;
                    overflow: hidden;
                    background: transparent;
                  }
                }
              }
            iframe{
                height: 120% !important;
            }
            ${pageData?.pageCSS?.replace("\n",'')}      
            </style>
            ${analyticsCodeHead ? analyticsCodeHead : ''}
          </head>
          <body>
            <div id="root">
            ${htmlContent}            
            <!-- Modal Structure -->
            <div class="modal" id="dynamicModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-body p-0" id="modalBody">
                    <!-- Content will be loaded here -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
            </div>
            <!-- Include necessary scripts -->
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>
          
            <script>
                ${addScripts()}
                class Typewriter {
                constructor(element, speed) {
                    this.elements = document.getElementsByClassName(element);

                    Array.from(this.elements).forEach(element => {
                    const text = element.innerHTML;
                    element.innerHTML = '';

                    const ariaSpeed = parseInt(element.getAttribute('aria-speed'));
                    const actualSpeed = isNaN(ariaSpeed) ? speed : ariaSpeed;

                    let index = 0;
                    const interval = setInterval(() => {
                        element.innerHTML += text[index];
                        index++;

                        if (index >= text.length) {
                        clearInterval(interval);
                        }
                    }, actualSpeed);
                    });
                }
                }

                const typewriter = new Typewriter('typewriter-text', 20);
                function highlightMovingVerbs() {
                    const divElement = document.querySelector('.highlight-this');
                    const text = divElement.textContent;
                    
                    // Regular expression to match verbs (you can customize this as needed)
                    const verbRegex = /\b(\w+ing\b|\w+ed\b|\bwill \w+\b|\bshall \w+\b|\bcan \w+\b|\bcould \w+\b|\bshould \w+\b|\bwould \w+\b|\bmust \w+\b|\bought \w+\b)\b/gi;
                  
                    // Wrap matching verbs in a span with a class for highlighting
                    const highlightedText = text.replace(verbRegex, '<span class="highlight">$&</span>');
                  
                    divElement.innerHTML = highlightedText;
                }   
                /*
                function stretchWordsToParentWidth() {
                    const parentDivs = document.getElementsByClassName("parent");

                    for (const parentDiv of parentDivs) {
                        const wordSpan = parentDiv.querySelector(".stretch-word");
                        

                        const scale = parentDiv.offsetWidth / wordSpan.offsetWidth;
                        /*wordSpan.style.transform = "scale(" + scale + ")";*1/
                        wordSpan.style.fontSize = scale+"rem";
                    }
                }

                // Call the function when the page loads
                stretchWordsToParentWidth();
                */               
                function stretchWordsToParentWidth() {
                    const stretchCompanyName = document.getElementById('stretch-company-name');
                    let fontSize = 100;

                    while (stretchCompanyName.scrollWidth > stretchCompanyName.offsetWidth) {
                    stretchCompanyName.style.fontSize = fontSize-- + '%';
                    }
                }
                stretchWordsToParentWidth();
                
                function popupSID(modalId) {
                    var content = $('[data-modal-id="' + modalId + '"]').removeClass('d-none').parent().html();
                    $('#modalBody').html(content);
                    $('#dynamicModal').modal('show');
                }
                
               /*
                function popupSID(modalId) {
                    var $parent = $('[data-modal-id="' + modalId + '"]').parent();
                    console.log($parent.attr('class')); // Check classes before removal
                    $parent.removeClass('d-none');
                    console.log($parent.attr('class')); // Check classes after removal
                
                    var content = $parent.html();
                    $('#modalBody').html(content);
                    $('#dynamicModal').modal('show');
                }
                */
                
                $(document).ready(function() {
                    $('[data-click]').on('click', function(event) {
                        const elementType = $(this).prop("tagName").toLowerCase();
                        const functionName = $(this).data('click');
                        
                        // If the element is a link, prevent default, execute function, then navigate
                        if (elementType === 'a') {
                            event.preventDefault();
                            const href = $(this).attr('href');
                            
                            eval(functionName);
                            
                            window.location.href = href;
                        }
                        // If the element is a submit button, prevent default, execute function, then submit
                        else if (elementType === 'input' && $(this).attr('type') === 'submit') {
                            event.preventDefault();
                            const form = $(this).closest('form');
                            
                            eval(functionName);
                            
                            form.submit();
                        }
                        // For other elements, just execute the function
                        else {
                            executeFunctionByName(functionName);
                        }
                    });
                });
                
                function executeFunctionByName(functionName) {
                    if (typeof window[functionName] === 'function') {
                        window[functionName]();
                    } else {
                        console.error("Function does not exist.");
                    }
                }
                
                
                
            </script>
          </body>
        </html>`;
       return finalHtml;
    }

  const downloadHTMLFile = async (websiteId,updatedData=false) => {
    let seoConfig = {};
    // the website Id given will be taken.
    // will utilize the parts in the way prescribed
    // will output html that will be shown in different viewer.
    // on theme changes
    // on website changes too
    let thewebsite = [];
    //let websiteOrPageData = (currentEditedWebsitePageId===0) ? websiteData : websiteData.webpages.find(webpage => webpage.id=currentEditedWebsitePageId);
    console.log('in download html',currentEditedWebsitePageId)
    thewebsite[0] = websiteData || {data: {}};
    seoConfig = websiteData?.seoConfig;
    if(currentEditedWebsitePageId && currentEditedWebsitePageId>0){
        //console.log('website data: ', currentEditedWebsitePageId, websiteData);
        let websiteOrPageData = websiteData?.webpages?.find(webpage => webpage.id===parseInt(currentEditedWebsitePageId));
        seoConfig = websiteOrPageData?.seoConfig;
        updatedData = (websiteOrPageData?.data) ? {... websiteOrPageData.data} : {blocks: []};
        //console.log('website data: ', currentEditedWebsitePageId, websiteOrPageData);
        // insert the two blocks of header and footer for menu
        let theHeaderBlock = (websiteData.data.blocks && websiteData.data.blocks.length>0) ? websiteData.data.blocks.find(block => block.WebsiteBlock.type == 'header') : false;
        let theFooterBlock = (websiteData.data.blocks && websiteData.data.blocks.length>0) ? websiteData.data.blocks.find(block => block.WebsiteBlock.type == 'footer') : false;
        if(theHeaderBlock!==undefined && theHeaderBlock){
            updatedData = {
                ...updatedData,
                blocks: [theHeaderBlock, ...updatedData.blocks],
            };
        }
        if(theFooterBlock!==undefined && theFooterBlock){
            updatedData = {
                ...updatedData,
                blocks: [...updatedData.blocks, theFooterBlock],
            };
        }
        console.log('in updated preview',updatedData.blocks,theHeaderBlock,theFooterBlock,websiteData);

    }
    console.log('website or page data',websiteData,currentEditedWebsitePageId);
    console.log('New Website Data',websiteData);
    //return null;
    /*
    console.log('updated data suscription',updatedData)
    let { data: thewebsite, error } = await supabase
    .from('website')
    .select('*')
    .eq('id',websiteId);
    if(error){
        //console.error(error);
        return null;
    }
    if(updatedData){        
        //let thewebsite = updatedData;
    }
    */
    //console.log(thewebsite)
    setWebsite(thewebsite[0])
    setGenerated(false);
    setGeneratedProgress(0)
    console.log('website issue',thewebsite[0]);
    let theData = (thewebsite) ? thewebsite[0].data : {};
    if(updatedData){
        theData = updatedData;
    }
    const brandGuide = thewebsite[0].brandGuide;
    const theSiteSettings = thewebsite[0].settings;
    const cssVariables = thewebsite[0].cssVariables;
    const theWebsiteData = thewebsite[0].data;
    console.log(theData)
    //console.log(theColorPallete[1])
    //    theData['blocks'][0]['websiteSettings']['navigationColor'] = (theColorPallete && theColorPallete.length>0 && theColorPallete[1]) ? rgbToHex(...theColorPallete[1]) : '#fcfcff';
    //theData['blocks'][0]['websiteSettings']['backgroundColor'] = (theColorPallete && theColorPallete.length>0 && theColorPallete[0]) ? rgbToHex(...theColorPallete[0]) : '#fcfcff';
    console.log(theData)
    console.log('Brand guide',brandGuide)

    const htmlContent = await generateWebsite({thePageData: theData,brandGuide,theWebsiteData,theSiteSettings,seoConfig,cssVariables});
    const replacedHtmlContent = htmlContent.replace('a{pointer-events: none;}','')
    localStorage.setItem('htmlContent-'+websiteId, replacedHtmlContent);
    //setGenerated(true);
    setWebsiteHtml(htmlContent)
    onHTMLChange(replacedHtmlContent)
    addMessage('Preview Created. Next please Save & Publish ..')
    setGeneratedProgress(100)
    setTimeout(() => {
        addMessage('')
    },2000)
    return htmlContent;
  }

  const [generated, setGenerated] = useState(true);
  const [generatedProgress, setGeneratedProgress] = useState(0);
  const [generatedProgressLabel, setGeneratedProgressLabel] = useState('');
  const [websiteHtml, setWebsiteHtml] = useState(false);
  const [website, setWebsite] = useState({});
  const [refreshId, setRefreshId] = useState(1);
  
  //console.log(websiteId,websiteHtml);

  useEffect(() => {
    if(websiteId && websiteId>0 && websiteData===undefined ){
        //getPageData(websiteId);
        dispatch(getAllWebsites({startFromId: websiteId}));
    }
    // Subscribe to Supabase Realtime events
    const realtimeSubscription = supabase
    .channel('any')
    .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'countries' }, payload => {
        console.log('Change received!', payload)
        //downloadHTMLFile(websiteId)
    })
    .subscribe();

    // Fetch initial data
    setRefreshId(toRefresh);

    console.log('in use effect',websiteData,currentEditedWebsitePageId,website)
    //downloadHTMLFile(websiteId);

    // Clean up the subscription when the component unmounts
    return () => {
      realtimeSubscription.unsubscribe();
    };
    //downloadHTMLFile(websiteId,theFontCombo,theColorPallete)
  },[])

  useEffect(() => {
      if (websiteData) {
          //const gotPageData = websiteData.data;
          console.log('in use effect 2',websiteData,currentEditedWebsitePageId)
          setWebsite(websiteData);
          downloadHTMLFile(websiteId);
      }
  }, [websiteData]);
  useEffect(() => {
      //if (websiteData) {
          //const gotPageData = websiteData.data;
          console.log('in use effect 2',websiteData,currentEditedWebsitePageId)
          //setWebsite(websiteData);
          downloadHTMLFile(websiteId);
      //}
  }, [currentEditedWebsitePageId]);



  console.log('outside',websiteData,currentEditedWebsitePageId,website)

  
  useEffect(() => {
    const handleIframeLoad = () => {
        console.log('Iframe has loaded.');

        const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

        // General click handler
        iframeDoc.addEventListener('click', (e) => {
            console.log('Click detected inside iframe:', e.target);

            // Prevent all link navigation
            if (e.target.closest('a')) {
                console.log('Click on a link detected, preventing navigation.');
                e.preventDefault();
            }

            if (!e.isTrusted) {
                return;
            }

            // Track clicks on sections
            const clickedElement = e.target.closest('.editable-section');
            if (clickedElement) {
                const sectionEditIntentSectionId = clickedElement.getAttribute('data-section-id');
                const sectionEditIntentPageId = clickedElement.getAttribute('data-pg-id');
                console.log(`Click detected on editable section with id: ${sectionEditIntentPageId}-${sectionEditIntentSectionId}`);
                //onSectionClick(Number(sectionId));
                //if(onSectionClick){
                    onSectionClick({sectionEditIntentPageId,sectionEditIntentSectionId})
                //}
            }
        });
        // Add CSS for hover effects
        const styleEl = iframeDoc.createElement('style');
        styleEl.innerHTML = `
            .editable-section:hover {
                border: 2px solid #4CAF50;
                cursor: pointer;
            }
            h1:hover, p:hover, img:hover, button:hover {
                border: 2px solid #FF5733; /* Different color for individual elements */
                cursor: pointer;
            }
        `;
        iframeDoc.head.appendChild(styleEl);

        // Add event listeners for dynamic interactions
        iframeDoc.querySelectorAll('.editable-section').forEach(section => {
            section.addEventListener('mouseover', () => {
                section.style.border = '2px solid #4CAF50';
            });
            section.addEventListener('mouseout', () => {
                section.style.border = 'none';
            });
        });
        
        ['h1', 'p', 'img', 'button'].forEach(selector => {
            iframeDoc.querySelectorAll(selector).forEach(element => {
                element.addEventListener('mouseover', (e) => {
                    e.stopPropagation(); // Prevent triggering hover on the parent section
                    element.style.border = '2px solid #FF5733';
                });
                element.addEventListener('mouseout', () => {
                    element.style.border = 'none';
                });
                /*
                element.addEventListener('click', function(e) {
                    this.setAttribute('contenteditable', 'true');
                    e.stopPropagation(); // Prevent triggering hover on the parent section
                });
                // Remove the `contenteditable` attribute when clicking outside
                iframeDoc.addEventListener('click', function(event) {
                    if (event.target !== element) {
                        element.removeAttribute('contenteditable');
                    }
                });
                         
                // The element to be dragged
                var dragElement = element;

                // Initial positions
                var posX = 0, posY = 0, mouseX = 0, mouseY = 0;

                // Call function on mouse down
                dragElement.onmousedown = dragMouseDown;
                
                function dragMouseDown(e) {
                    console.log('getting dragged');
                    e = e || window.event;
                    e.preventDefault();

                    // Get initial mouse cursor position
                    mouseX = e.clientX;
                    mouseY = e.clientY;

                    // Call functions on mouse movement and release
                    document.onmouseup = closeDragElement;
                    document.onmousemove = elementDrag;
                }

                function elementDrag(e) {
                    console.log('getting elementDrag');
                    e = e || window.event;
                    e.preventDefault();

                    // Calculate new cursor position
                    posX = mouseX - e.clientX;
                    posY = mouseY - e.clientY;
                    mouseX = e.clientX;
                    mouseY = e.clientY;

                    // Set the element's new position
                    dragElement.style.top = (dragElement.offsetTop - posY) + "px";
                    dragElement.style.left = (dragElement.offsetLeft - posX) + "px";
                }

                function closeDragElement() {
                    console.log('getting drag closed');
                    // Stop moving when mouse button is released
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
                */
            });
        });
        
        /*
        ['h1', 'p', 'img', 'button'].forEach(selector => {
            iframeDoc.querySelectorAll(selector).forEach(element => {
                // ... your existing event listeners for mouseover and mouseout ...
        
                element.addEventListener('mouseover', (e) => {
                    e.stopPropagation(); // Prevent triggering hover on the parent section
                    element.style.border = '2px solid #FF5733';
                });
                element.addEventListener('mouseout', () => {
                    element.style.border = 'none';
                });

                element.addEventListener('click', function(e) {
                    this.setAttribute('contenteditable', 'true');
                    e.stopPropagation(); // Prevent triggering click on the iframe document
                });
        
                iframeDoc.addEventListener('click', function(event) {
                    if (event.target !== element) {
                        element.removeAttribute('contenteditable');
                    }
                });
        
                // Dragging logic
                var posX = 0, posY = 0, mouseX = 0, mouseY = 0;
        
                element.onmousedown = function(e) {
                    e.preventDefault();
                    mouseX = e.clientX;
                    mouseY = e.clientY;
                    iframeDoc.onmouseup = closeDragElement;
                    iframeDoc.onmousemove = elementDrag;
                };
        
                function elementDrag(e) {
                    e.preventDefault();
                    // Calculate new cursor position
                    posX = mouseX - e.clientX;
                    posY = mouseY - e.clientY;
                    mouseX = e.clientX;
                    mouseY = e.clientY;
                    // Set the element's new position
                    element.style.position = 'absolute'; // Ensure the element is positioned absolutely
                    element.style.top = (element.offsetTop - posY) + "px";
                    element.style.left = (element.offsetLeft - posX) + "px";
                }
        
                function closeDragElement() {
                    // Stop moving when mouse button is released
                    iframeDoc.onmouseup = null;
                    iframeDoc.onmousemove = null;
                }
            });
        });
        */
        /*
        ['h1', 'p', 'img', 'button'].forEach(selector => {
            iframeDoc.querySelectorAll(selector).forEach(element => {
                // Add a draggable handle to each element
                var handle = document.createElement('div');
                handle.style.width = '20px';
                handle.style.height = '20px';
                handle.style.backgroundColor = 'blue';
                handle.style.position = 'absolute';
                handle.style.right = '0';
                handle.style.bottom = '0';
                handle.style.cursor = 'move';
                element.appendChild(handle);
        
                // Styling on mouseover and mouseout
                element.addEventListener('mouseover', (e) => {
                    e.stopPropagation();
                    element.style.border = '2px solid #FF5733';
                });
        
                element.addEventListener('mouseout', () => {
                    element.style.border = 'none';
                });
        
                // Enable contenteditable on double click
                let clickCount = 0;
                let singleClickTimer;
                element.addEventListener('click', function(e) {
                    clickCount++;
                    if (clickCount === 1) {
                        singleClickTimer = setTimeout(function() {
                            clickCount = 0;
                        }, 400);
                    } else if (clickCount === 2) {
                        clearTimeout(singleClickTimer);
                        clickCount = 0;
                        this.setAttribute('contenteditable', 'true');
                        e.stopPropagation();
                    }
                });
        
                // Remove contenteditable on click outside
                iframeDoc.addEventListener('click', function(event) {
                    if (event.target !== element) {
                        element.removeAttribute('contenteditable');
                    }
                });
        
                // Dragging logic
                let isDragging = false;
                var posX = 0, posY = 0, mouseX = 0, mouseY = 0;
        
                handle.onmousedown = function(e) {
                    e.preventDefault();
                    mouseX = e.clientX;
                    mouseY = e.clientY;
                    iframeDoc.onmouseup = closeDragElement;
                    iframeDoc.onmousemove = elementDrag;
                    isDragging = true;
                };
        
                function elementDrag(e) {
                    if (isDragging) {
                        e.preventDefault();
                        // Calculate new cursor position
                        posX = mouseX - e.clientX;
                        posY = mouseY - e.clientY;
                        mouseX = e.clientX;
                        mouseY = e.clientY;
                        // Set the element's new position
                        element.style.position = 'absolute';
                        element.style.top = (element.offsetTop - posY) + "px";
                        element.style.left = (element.offsetLeft - posX) + "px";
                    }
                }
        
                function closeDragElement() {
                    // Stop moving when mouse button is released
                    iframeDoc.onmouseup = null;
                    iframeDoc.onmousemove = null;
                    isDragging = false;
                }
            });
        });
        */
                
        
    };



    // Check if iframeRef and its current property exist
    if (!iframeRef || !iframeRef.current) {
        console.warn('iframeRef or iframeRef.current is not available.');
        return;
    }

    console.log('Adding load event listener to iframe.');
    iframeRef.current.addEventListener('load', handleIframeLoad);

    // Cleanup
    return () => {
        console.log('Cleanup: Removing load event listener from iframe.');
        if (iframeRef.current) {
            iframeRef.current.removeEventListener('load', handleIframeLoad);
        }
    };
}, [websiteHtml, iframeRef]);
  
  const onSectionClick = ({sectionEditIntentPageId,sectionEditIntentSectionId}) => {
    dispatch(setCurrentEditedWebsitePageSectionIndex((sectionEditIntentPageId!==null) ? sectionEditIntentSectionId-1 : sectionEditIntentSectionId));
  }

  //useEffect()

   // State to store the scroll position
   const [scrollPosition, setScrollPosition] = useState(0);
 
   // Function to call when the iframe content scrolls
   const handleIframeScroll = () => {
     // Get the scroll position from the iframe's contentWindow
     const currentScrollPosition = iframeRef.current.contentWindow.pageYOffset || iframeRef.current.contentDocument.pageYOffset;
     console.log('scrolled to',currentScrollPosition)
     setScrollPosition(currentScrollPosition);
   };
 
   // Effect hook to add the event listener after the iframe loads
   useEffect(() => {
     const iframe = iframeRef.current;
 
     if (iframe) {
       // Make sure the iframe's content has been loaded before attaching the event listener
       iframe.onload = () => {
         // Add scroll event listener to the iframe's content window
         console.log('iframe loaded','position',scrollPosition)
         iframe.contentWindow.addEventListener('scroll', handleIframeScroll, true);
       };
     }
 
     // Cleanup function to remove the event listener
     return () => {
       if (iframe) {
         iframe.contentWindow.removeEventListener('scroll', handleIframeScroll, true);
       }
     };
   }, []);

   useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const onLoad = () => {
      // Ensure the content window and document are available
      if (iframe.contentWindow && iframe.contentDocument) {
        // Scroll to the saved position
        iframe.contentWindow.scrollTo(0, scrollPosition);
      }
    };

    // Add the load event listener
    iframe.addEventListener('load', onLoad);

    // Cleanup: Remove the load event listener
    return () => {
      iframe.removeEventListener('load', onLoad);
    };
  }, [scrollPosition]);

  // Return a success message or redirect the user to the generated website
  return (
    <div style={{height: '100vh'}}>
      {websiteHtml ? 
      <>
      {/*<HtmlViewer theHtml={websiteHtml} iframeRef={iframeRef} />*/}
          <div className=''>
            {/* We can have header children here */}
            <div className='bg-light border' style={{height: '50pt', lineHeight: '50pt'}}>
                {children}
            </div>
            <iframe className={toRefresh} ref={iframeRef}
                title="HTML Viewer" 
                id='htmlViewer' 
                srcDoc={websiteHtml}
                style={{ width: '100%', minHeight: '100vh', background: '#eee', opacity: (isLoading) ? 0.5: 1 }}  
                //sandbox="allow-scripts"       
                //onLoad={handleIframeLoad}
                //className={`iframe${loading ? ' hide' : ' show'}`}
            />
          </div>
        </>
      : 
      <>      
        <Skeleton height='50pt' />
        <PlaceholderList type={'iframe'} height='100vh' />
      </>
      }
      {/*websiteHtml ? <div dangerouslySetInnerHTML={{ __html: websiteHtml }} /> : ''*/}
    </div>
  );
};

export default React.memo(WebsitePreviewerLive);
