import React, { useState } from 'react';
import MessageComponent from '../components/MessageComponent';

export const MessageContext = React.createContext();
/*
export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('');

  const showMessage = (newMessage) => {
    setMessage(newMessage);
  };

  const clearMessage = () => {
    setMessage('');
  };

  return (
    <MessageContext.Provider value={{ showMessage, clearMessage }}>
      {children}
      <MessageComponent message={message} onClose={clearMessage} />
    </MessageContext.Provider>
  );
};
*/

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState('');
  
    const showMessage = (newMessage) => {
      setMessage(newMessage);
    };
  
    const clearMessage = () => {
      setMessage('');
    };
  
    const contextValue = {
      showMessage,
      clearMessage
    };
  
    return (
      <MessageContext.Provider value={contextValue}>
        {children}
        <MessageComponent message={message} onClose={clearMessage} />
      </MessageContext.Provider>
    );
  };
  