import React, { useState } from 'react';
import axios from 'axios';

const DigitalOceanUploadFileForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      await axios.post('https://webbuilder23.nyc3.digitaloceanspaces.com', formData, {
        withCredentials: true,
        crossDomain: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer dop_v1_9f452ef0c26f59cb64de0430a3ae5c8725e4a48b1b446e88098f1335cef08d4e',
        },
      });

      alert('File uploaded successfully');
    } catch (error) {
      console.error(error);
      alert('An error occurred while uploading the file');
    }
  };

  return (
    <div>
      <h2>Upload to DigitalOcean Spaces</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default DigitalOceanUploadFileForm;
