import React, { useEffect, useState } from 'react';
import { compress, decompress } from 'lz-string';
import DynamicElement from './parts/DynamicElement';
import { Button, TextareaAutosize } from '@mui/material';
import { LayersList } from './parts/LayerList';
import { htmlToJson } from '../../../helpers/randoms';

// Utility function to parse style string
const parseStyleStringOld = (styleString) => {
    return styleString.split(';').reduce((acc, style) => {
        const [key, value] = style.split(':').map(str => str.trim());
        if (key && value) {
            acc[key] = value;
        }
        return acc;
    }, {});
};

const parseStyleStringOldNew = (styleString) => {
    return styleString.split(';').reduce((acc, style) => {
        let [key, value] = style.split(':').map(str => str.trim());
        if (key && value) {
            // Convert to camelCase
            key = key.replace(/-\w/g, match => match[1].toUpperCase());
            acc[key] = value;
        }
        return acc;
    }, {});
};

const styleObjectToString = (styleObject) => {
    return Object.entries(styleObject).map(([key, value]) => {
        // Convert camelCase to kebab-case
        const kebabCaseKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        return `${kebabCaseKey}: ${value}`;
    }).join('; ');
};


/*
const parseStyleString = (styleString) => {
    const styleObject = {};
    if (!styleString) return styleObject;
  
    // Split the string by semicolon and filter out empty entries
    const styles = styleString.split(';').filter(style => style.trim() !== '');
  
    styles.forEach(style => {
      const [property, value] = style.split(':');
      if (!property || !value) {
        console.error(`Invalid style format: "${style}". Correct format is "propertyName: value;"`);
        return;
      }
  
      // Convert property name to camelCase
      const camelCaseProperty = property.trim().replace(/(-\w)/g, match => match[1].toUpperCase());
      styleObject[camelCaseProperty] = value.trim();
    });
  
    return styleObject;
  };

*/

const parseStyleString = (styleString) => {
    const styleObject = {};
  
    // Return an empty object if there's no style string
    if (!styleString) {
      return styleObject;
    }
  
    // Split the string by semicolons and iterate over the resulting array
    styleString.split(';').forEach(style => {
      if (style.includes(':')) {
        const [property, value] = style.split(':').map(item => item.trim());
        // Only add the property to the object if both a property and a value exist
        if (property && value) {
          const camelCaseProperty = property.replace(/(-\w)/g, m => m[1].toUpperCase());
          styleObject[camelCaseProperty] = value;
        }
      }
    });
  
    return styleObject;
  };
  
  


const ElementForm = ({ onElementSubmit, editingElement, isOpen=false }) => {
    const [element, setElement] = useState({
      tag: '',
      text: '',
      classes: '',
      styles: '',
      dataTags: '',
      prompt: '',
      fieldName: '',
      children: []
    });
    const [open, setOpen] = useState(isOpen || false)
    
    useEffect(() => {
        if (editingElement) {
            setElement({
                ...editingElement,
                styles: styleObjectToString(editingElement.styles) // Convert object to string for input field
            });
            setOpen(true);
        }
    }, [editingElement]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setElement(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Pass the element up to the parent component's state
      onElementSubmit({
        ...element,
        styles: parseStyleString(element.styles)
      });
      // Reset the form
      setElement({
        tag: '',
        text: '',
        classes: '',
        styles: '',
        dataTags: '',
        prompt: '',
        fieldName: '',
        children: []
      });
      setOpen(false)
    };
  
    const handleAddChild = () => {
      // Add a default new child to the children array
      setElement(prev => ({
        ...prev,
        children: [...prev.children, {
          tag: 'div',
          text: 'New Child',
          classes: '',
          styles: '',
          dataTags: '',
          prompt: '',
          fieldName: '',
          children: []
        }]
      }));
    };
  
    return (
        <>
            {open && 
                <form onSubmit={handleSubmit} style={{position: 'absolute', width: '450px', left: '25%', background: 'black', padding: '20px 30px',}}>
                    <input type="text" name="tag" value={element.tag} onChange={handleChange} placeholder="Tag (e.g., div)" />
                    <input type="text" name="text" value={element.text} onChange={handleChange} placeholder="Text" />
                    <input type="text" name="classes" value={element.classes} onChange={handleChange} placeholder="Classes" />
                    <input type="text" name="styles" value={element.styles} onChange={handleChange} placeholder="Styles (e.g., color:red; font-size:14px)" />
                    <input type="text" name="dataTags" value={element.dataTags} onChange={handleChange} placeholder="Data Tags" />
                    <input type="text" name="prompt" value={element.prompt} onChange={handleChange} placeholder="Prompt" />
                    <input type="text" name="fieldName" value={element.fieldName} onChange={handleChange} placeholder="Field Name" />
                    <button type="button" onClick={handleAddChild}>Add Child</button>
                    <button type="submit">Submit</button>
                </form>
            }
        </>
    );
  };
/*
const ElementFormCover = () => {
    const [elements, setElements] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);

    const editElement = (element) => {
        setSelectedElement(element);
    };

    return (
        <div>
            <ElementForm 
                elements={elements} 
                setElements={setElements} 
                selectedElement={selectedElement} 
                setSelectedElement={setSelectedElement} 
            />
            {elements.map(element => (
                <div key={element.id}>
                    <DynamicElement element={element} />
                    <button onClick={() => editElement(element)}>Edit</button>
                </div>
            ))}
        </div>
    );
};

export default ElementFormCover;
*/

const ElementFormCover = ({ data, onUpdate}) => {
    const [elements, setElements] = useState( data?.elements || []);
    const [selectedElementId, setSelectedElementId] = useState(null);
    const [editingElement, setEditingElement] = useState(null);
    const [elementsInput, setElementsInput] = useState([]);
    const [elementsInputBox, setElementsInputBox] = useState('');



    const handleEditElement = (element) => {
        console.log('selecting',element)
        setEditingElement(element);
    };
    const handleSelectLayer = (element) => {
        console.log('selecting',element)
        setEditingElement(element);
    };
    
    const handleSave = () => {
        onUpdate({...data, elements: elements});
    };

    const handleInputSave = () => {
        onUpdate({...data, elements: elementsInput});
    };

    const handleElementUpdate = (updatedElement) => {
        // Function to recursively update the element
        const updateElementRecursive = (elements, updatedElement) => {
            return elements.map(el => {
                if (el.id === updatedElement.id) {
                    return updatedElement;
                } else if (el.children) {
                    return {
                        ...el,
                        children: updateElementRecursive(el.children, updatedElement)
                    };
                }
                return el;
            });
        };

        setElements(prevElements => updateElementRecursive(prevElements, updatedElement));
        setEditingElement(null); // Reset the editing element
    };

    // Function to create a new element or update an existing one
    const handleElementCreateOrUpdate = (newElement, parentId) => {
        if (parentId == null) {
            // Add a new top-level element
            setElements(prevElements => [...prevElements, { ...newElement, id: Date.now(), children: [] }]);
        } else {
            // Add a new child to the specified parent element
            setElements(prevElements =>
                prevElements.map(el =>
                    el.id === parentId
                        ? { ...el, children: [...el.children, { ...newElement, id: Date.now(), children: [] }] }
                        : el
                )
            );
        }
    };
      // Function to add a child to an element
      /*
  const handleAddChild = (parentId) => {
    setElements(prevElements =>
      prevElements.map(el =>
        el.id === parentId ? {
          ...el,
          children: [...el.children, {
            // Provide a new ID for the child and default properties
            id: Date.now(),
            tag: 'div',
            text: 'New Child',
            classes: '',
            styles: {},
            dataTags: '',
            prompt: '',
            fieldName: '',
            children: []
          }]
        } : el
      )
    );
  };
  */

  const handleAddChild = (parentId) => {
    const addChildRecursive = (elements, parentId) => {
        return elements.map(element => {
            if (element.id === parentId) {
                // Add a child to this element
                return {
                    ...element,
                    children: [
                        ...element.children,
                        {
                            id: Date.now(),
                            tag: 'div',
                            text: 'New Child',
                            classes: '',
                            styles: {},
                            dataTags: '',
                            prompt: '',
                            fieldName: '',
                            children: []
                        }
                    ]
                };
            } else if (element.children) {
                // Recursively check this element's children
                return {
                    ...element,
                    children: addChildRecursive(element.children, parentId)
                };
            }
            return element;
        });
    };

    // Update the state with the new elements array
    setElements(prevElements => addChildRecursive(prevElements, parentId));
};

    const handleDeleteElement = (elementId) => {
        const deleteElementRecursive = (elements, elementId) => {
            return elements.filter(el => el.id !== elementId)
                .map(el => ({
                    ...el,
                    children: el.children ? deleteElementRecursive(el.children, elementId) : []
                }));
        };

        setElements(prevElements => deleteElementRecursive(prevElements, elementId));
    };

  // App.js or your parent component
/*
const handleAddChild = (parentId, newChild) => {
    setElements(prevElements =>
        prevElements.map(el => {
            if (el.id === parentId) {
                return { ...el, children: [...el.children, newChild] };
            } else {
                return {
                    ...el,
                    children: addChildToSubElement(el.children, parentId, newChild)
                };
            }
        })
    );
};

// Recursive function to add a child to a sub-element
const addChildToSubElement = (children, parentId, newChild) => {
    return children.map(child => {
        if (child.id === parentId) {
            return { ...child, children: [...child.children, newChild] };
        } else {
            return {
                ...child,
                children: addChildToSubElement(child.children, parentId, newChild)
            };
        }
    });
};
*/

    // Function to select an element for editing
    const editElement = (id) => {
        setSelectedElementId(id);
    };

    // Find the selected element based on selectedElementId
    const selectedElement = selectedElementId != null
        ? elements.find(el => el.id === selectedElementId)
        : null;


        
        const handleAddSibling = (parentId, referenceId) => {
            const newSibling = {
                id: Date.now(),
                tag: 'div',
                text: 'New Child',
                classes: '',
                styles: {},
                dataTags: '',
                prompt: '',
                fieldName: '',
                children: []
            };
            
        
            setElements(prevElements =>
                addElementNextTo(prevElements, newSibling, referenceId)
            );
        };
        
        // Helper function to add an element next to a reference element
        const addElementNextTo = (elements, newElement, referenceId) => {
            let newElements = [];
            elements.forEach(el => {
                newElements.push(el);
                if (el.id === referenceId) {
                    newElements.push(newElement);
                }
                if (el.children) {
                    el.children = addElementNextTo(el.children, newElement, referenceId);
                }
            });
            return newElements;
        };
        const handleCopyElement = (element, asChild = false) => {
            const copiedElement = {
                ...element,
                id: Date.now() // Assign a new ID
            };
        
            if (asChild) {
                // Add as a child to the selected element
                handleAddChild(selectedElement.id, copiedElement);
            } else {
                // Add as a sibling
                handleAddSibling(selectedElement.parentId, selectedElement.id, copiedElement);
            }
        };
        
    // Example usage
    const htmlString = ``;
    const htmlStringNew = `<div class="container px-4 py-5" id="custom-cards">
    <h2 class="pb-2 border-bottom">Custom cards</h2>

    <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
      <div class="col">
        <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style="background-image: url('unsplash-photo-1.jpg');">
          <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
            <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Short title, long jacket</h3>
            <ul class="d-flex list-unstyled mt-auto">
              <li class="me-auto">
                <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" class="rounded-circle border border-white">
              </li>
              <li class="d-flex align-items-center me-3">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#geo-fill"></use></svg>
                <small>Earth</small>
              </li>
              <li class="d-flex align-items-center">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#calendar3"></use></svg>
                <small>3d</small>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style="background-image: url('unsplash-photo-2.jpg');">
          <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
            <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Much longer title that wraps to multiple lines</h3>
            <ul class="d-flex list-unstyled mt-auto">
              <li class="me-auto">
                <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" class="rounded-circle border border-white">
              </li>
              <li class="d-flex align-items-center me-3">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#geo-fill"></use></svg>
                <small>Pakistan</small>
              </li>
              <li class="d-flex align-items-center">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#calendar3"></use></svg>
                <small>4d</small>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style="background-image: url('unsplash-photo-3.jpg');">
          <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
            <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Another longer title belongs here</h3>
            <ul class="d-flex list-unstyled mt-auto">
              <li class="me-auto">
                <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" class="rounded-circle border border-white">
              </li>
              <li class="d-flex align-items-center me-3">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#geo-fill"></use></svg>
                <small>California</small>
              </li>
              <li class="d-flex align-items-center">
                <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#calendar3"></use></svg>
                <small>5d</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`;
    const json = htmlToJson(htmlString.replace("\n",''));
    console.log('html to json',json);


    const elementsInputSubmit = (val) => {
        setElementsInputBox(val);
        const jsonInput = htmlToJson(val.replace("\n",''));
        setElementsInput(jsonInput?.children);
    }

    console.log("input elements", elementsInput)

    return (
        <div>
            {/*<ElementForm
                onElementCreate={newElement => handleElementCreateOrUpdate(newElement, null)}
            />
            {elements.map(element => (
                <div key={element.id}>
                    <DynamicElement
                        element={element}
                        onAddChild={newChild => handleElementCreateOrUpdate(newChild, element.id)}
                    />
                </div>
            ))}*/}

            {/*<ElementForm
                onElementCreate={handleElementCreateOrUpdate}
                selectedElement={selectedElement}
            />*/}
            <ElementForm 
                onElementSubmit={editingElement ? handleElementUpdate : handleElementCreateOrUpdate}
                //onElementCreate={handleElementCreateOrUpdate}
                editingElement={editingElement}
            />

            {/* Render elements and add edit functionality */}
            {/*elements.map(element => (
                <div key={element.id}>
                    <DynamicElement key={element.id} element={element} onAddChild={handleAddChild} onEditElement={handleEditElement} onDeleteElement={handleDeleteElement} />
                    <button onClick={() => editElement(element.id)}>Edit</button>
                </div>
            ))*/}
            {/*decompress(compress(JSON.stringify(elements)))*/}
            <LayersList elements={elements} onSelectLayer={handleEditElement} onAddSibling={handleAddSibling} onAddChild={handleAddChild} onCopyElement={handleCopyElement} onDeleteElement={handleDeleteElement} />
            {/*elements.map(element => (
                <div key={element.id}>
                    <DynamicElement key={element.id} element={element} editMode={false} onDeleteElement={handleDeleteElement} />
                </div>
            ))*/}
            {json?.children?.map(element => {
                element = {...element, text: element.text.replace("n",'')}
                return(
                <div key={element.id}>
                    <DynamicElement key={element.id} element={element} editMode={false} onDeleteElement={handleDeleteElement} />
                </div>
                );
            })}

            <label>
                design:
                <TextareaAutosize
                    minRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    value={elementsInputBox}
                    onChange={(e) => elementsInputSubmit(e.target.value)}
                    style={{width: '100%'}}
                    />
            </label>

            
            {elementsInput?.children?.map(element => {
                element = {...element, text: element.text.replace("n",'')}
                return(
                <div key={`${element.id}-${Math.random()}`}>
                    <DynamicElement key={element.id} element={element} editMode={false} onDeleteElement={handleDeleteElement} />
                </div>
                );
            })}
            <Button variant='outlined' color='primary' className='my-5' fullWidth onClick={handleInputSave}>Input Save</Button>


            <Button variant='outlined' color='primary' className='my-5' fullWidth onClick={handleSave}>Save</Button>
        </div>
    );
};

export default ElementFormCover;

