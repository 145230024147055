import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { getRandomIpsumLogo } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const TableBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';

    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className="row text-center">
            <div className='pt-5 col display-3'>{theblock.headline}</div>
            <div className='col-md-12 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-center d-flex justify-content-center">
            <table className='table'>
              <tr>
                <td>

                </td>
              </tr>
            </table>
            {/*(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-${Math.floor(12/theblock.items.length)} col-sm-12`}>
                <h1>{column.stat}{(column.type==='percentage') ? '%': ''}</h1>
                <p>{column.title}</p>
              </div>
            ))*/}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default TableBlock;