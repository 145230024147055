// Template for Homepage Etc
const InternalFeatureTemplate = ({ headerContent, footerContent, children, leftSideBarContent, rightSideBarContent }) => {
  const LeftSideBar = ({children}) => {
    return(
      <div className="col-md-2 py-5" style={{backgroundColor: '#111927'}}>
        <div className="vh-100" style={{position: 'sticky'}}>
          {children}
        </div>
      </div>
    )
  }
  const CenterContent = ({children}) => {
    return(
      <div className="col-md-10 mx-auto">{children}</div>
    )
  }
    return (
        <div className="row text-center">
          <CenterContent>
            <header className="text-center fw-bold my-5 col-md-5 mx-auto">{headerContent}</header>
            {children}
            <footer>{footerContent}</footer>
          </CenterContent>
        </div>
    );
  };

  export default InternalFeatureTemplate;