import { Component, useContext, useEffect, useState } from "react";
import BannerForm from "./BannnerForm";
import { supabase } from "../../../supabase";
import QuoteBlockForm from "./QuoteBlockForm";
import ListForm from "./ListForm";
import HeroForm from "./HeroForm";
import FooterForm from "./FooterForm";
import HeaderForm from "./HeaderForm";
import { Button, Checkbox, Chip, FormControlLabel, IconButton, Link, MenuItem, Radio, Select, Skeleton, Table, TableBody, TableCell, TableRow, TextField, TextareaAutosize, styled } from "@mui/material";
import PageForm from "./PageForm";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getAllComponents } from "../../../helpers/templateComponents";
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Col, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllBlockTypes, getBlankBlockData, getBlankBlockDataWithAIContent, getBlankPageData, getBlankPageDataInfo } from "../../../helpers/sections";
import FormForm from "./FormForm";
import { GriderLayout } from "./GriderLayout";
import { useDispatch, useSelector } from "react-redux";
import { createWebsitePageDefault, deleteWebsitePageDefault, getAllWebsitePages, getAllWebsites, setCurrentEditedWebsitePageId, updateWebsite, updateWebsitePage, updateWebsitePageDefault } from "../../redux/slices/websiteSlice";
import { addPropToMenu, generateBreadcrumbs, getJsonObject, getStringSkeletonWidth } from "../../../helpers/randoms";
import ViewListIcon from '@mui/icons-material/ViewList';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ConfirmationDialog from "../ConfirmationDialog";
import StatsForm from "./StatsForm";
import AwardsForm from "./AwardsForm";
import FeatureForm from "./FeatureForm";
import TeamForm from "./TeamForm";
import CircularProgress from '@mui/material/CircularProgress';
import { Save, SaveAltTwoTone, TaskAlt } from "@mui/icons-material";
import { addTemplate } from "../../redux/slices/templateSlice";
import CodeWriterForm from "./CodeWriterForm";
import { MessageContext } from "../../contexts/MessageContext";
import HtmlForm from "./HtmlForm";
import CalendlyForm from "./CalendlyForm";
import GlobalAdvancedForm from "./GlobalAdvancedForm";
import ElementFormCover from "./ElementForm";



const EditorForm = ({thePageData, websiteId, onUpdate, sectionClickPageId, sectionClickSectionId}) => {
    const [currentWebsite,setCurrentWebsite] = useState({});
    const [pageData, setPageData] = useState((thePageData) ? thePageData : {});
    const [pages, setPages] = useState(['Home']);
    const [allPages, setAllPages] = useState([]);
    const [sections, setSections] = useState([]);
    const [sectionTemplates, setSectionTemplates] = useState([]);
    const [pageTemplates, setPageTemplates] = useState([]);
    const [view, setView] = useState('pages');
    const [sectionId, setSectionId] = useState(0);
    const [backTo, setBackTo] = useState(null);
    const [pageId, setPageId] = useState(0);
    const [allBlockTypes, setAllBlockTypes] = useState(getAllBlockTypes() || []);
    const [pageName, setPageName] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [potentialPagesByMenu,setPotentialPagesByMenu] = useState([]);
    const [isNewPageCreating, setIsNewPageCreating] = useState(false);
    const [toCreatePageName, setToCreatePageName] = useState('');
    const [pageNeedInfoFullfilled, setPageNeedInfoFullfilled] = useState(false);
    const [pageCreateQuestions,setPageCreateQuestions] = useState({});
    const [createPageFormData,setCreatePageFormData] = useState({});
    const [blockTypeAdd,setBlockTypeAdd] = useState(false);
    const [blockAddInstruction,setBlockAddInstruction] = useState("");
    const [isSectionAdding,setIsSectionAdding] = useState(false);
    const [isAddingTemplate,setIsAddingTemplate] = useState(false);
    const [isAddedTemplate,setIsAddedTemplate] = useState(false);
    const [pageAdd,setPageAdd] = useState(false);
    const [pageAddInstruction,setPageAddInstruction] = useState("");
    const [userProvidedSections,setUserProvidedSections] = useState([]);
    //const {websites, isLoading, lastCreatedWebsiteId, currentEditedWebsite, currentEditedWebsiteId} = useSelector(state => state.websites);    
    const dispatch = useDispatch();
    const { addMessage } = useContext(MessageContext);
    
    useEffect(() => {
        const getPageData = async(websiteId) => {
            let { data: thewebsite, error } = await supabase
            .from('website')
            .select('*')
            .eq('id',websiteId);
            if(error){
                //console.error(error);
                return null;
            }
            const theNewData = thewebsite[0].data;
            setPageData(theNewData);
            setSections(theNewData.blocks)
            console.error('website editor data',thewebsite)
        }
        if(websiteId && websiteId>0 ){
            //getPageData(websiteId);
            dispatch(getAllWebsites({startFromId: websiteId}));
            //dispatch(getAllWebsitePages(websiteId));
        }
        
        
    },[])
    

    const currentEditedWebsitePageId = useSelector(state => state.websites.currentEditedWebsitePageId);
    const currentEditedWebsitePageSectionIndex = useSelector(state => state.websites.currentEditedWebsitePageSectionIndex);
    const websiteData = useSelector(state => state.websites?.websites?.find(website => website.id === parseInt(websiteId)));
    if(currentEditedWebsitePageId!==null){ 
        if(websiteData && websiteData.webpages){
            if(websiteData.webpages.find(webpage => webpage.id === currentEditedWebsitePageId)){
                console.log('aiiiyoooommmmma')
            } else{       
                    console.log('aiiiyoooommmmma not found')
                    dispatch(setCurrentEditedWebsitePageId(null))
            }
        }
    }
    const templatesData = useSelector(state => state.templates.templates);
    const sectionTemplatesData = useSelector(state => state.templates.sectionTemplates);
    const pageTemplatesData = useSelector(state => state.templates.pageTemplates);
    useEffect(() => {
        if (websiteData) {
            setCurrentWebsite(websiteData);
            setAllPages(websiteData.webpages);
            let theHeaderBlock = (websiteData.data && websiteData.data.blocks && websiteData.data.blocks.length>0) ? websiteData.data.blocks.find(block => block.WebsiteBlock.type=='header') : {};
            if(theHeaderBlock?.menus){
                let theMenuBreadcrumbs = generateBreadcrumbs(theHeaderBlock.menus);
                setPotentialPagesByMenu(theMenuBreadcrumbs);
            }
            if(currentEditedWebsitePageId && currentEditedWebsitePageId>0){
                let websiteOrPageData = websiteData.webpages.find(webpage => webpage.id===currentEditedWebsitePageId);
                if(websiteOrPageData){
                    const gotPageData = websiteOrPageData.data;
                    setPageData(gotPageData);
                    setSections(gotPageData.blocks);
                    setPageId(currentEditedWebsitePageId);
                }
            } else{
                const gotPageData = websiteData.data;
                setPageData(gotPageData);
                setSections(gotPageData && gotPageData.blocks ? gotPageData.blocks : []);
                setPageId(currentEditedWebsitePageId);
            }
        }
    }, [websiteData]);
    
    const websitePagesData = useSelector(state => state.websites?.websitePages?.find(websitePage => websitePage.website_id === parseInt(websiteId)));
    useEffect(() => {
        if (websitePagesData) {
            const gotPageData = websitePagesData.data;
            //setPages(['Home'])
            setAllPages(gotPageData);
            setPageId(currentEditedWebsitePageId)
            //setPageData(gotPageData);
            //setSections(gotPageData.blocks);
        }
    }, [websitePagesData]);

    useEffect(() => {
        if (sectionTemplatesData) {
            setSectionTemplates(sectionTemplatesData)
        }
    }, [sectionTemplatesData]);

    useEffect(() => {
        if (pageTemplatesData) {
            setPageTemplates(pageTemplatesData)
        }
    }, [pageTemplatesData]);
    
    useEffect(() => {
        //if (sectionClickPageId && sectionClickSectionId) {
            //dispatch(setCurrentEditedWebsitePageId(sectionClickPageId))
        //    onSectionSelect(sectionClickSectionId)
        //}
        //console.log('effect section',currentEditedWebsitePageSectionIndex,pageId,view);
        //console.log('currentEditedWebsitePageSectionIndex',currentEditedWebsitePageSectionIndex,'sectionId',sectionId)
        //if(currentEditedWebsitePageSectionIndex && currentEditedWebsitePageSectionIndex!==sectionId){
            //console.log('inside section')
            //setView('sections')
            //onSectionSelect(null,parseInt(currentEditedWebsitePageSectionIndex))
        //}
        onSectionSelect(null,parseInt(currentEditedWebsitePageSectionIndex))
    }, [currentEditedWebsitePageSectionIndex]);


    //if(!isLoading){
        //const theWebsiteData = websites.filter((mapwebsite) => mapwebsite.id === websiteId);
        //console.log('my website:', websiteId, websites, theWebsiteData);
    //}

    /*
    useEffect(() => {
        //console.log('updated',pageData)
    },[pageData])
    */
   /*
    useEffect(() => {
        //updateSupabase();
        if (pageData && pageData.sections && pageData.sections.length > 0) {
            //updateSupabase();
        }
    }, [pageData]);
    */
    const updatePageData = async (toSubmitData,toSubmitWebsiteId,toSubmitPageId=0) => {
        // will update homePage if pageId ==0

        //const { data: { user } } = await supabase.auth.getUser();
        if(toSubmitPageId>0){
            dispatch(updateWebsitePageDefault({id: toSubmitPageId, websiteId: toSubmitWebsiteId, data: {data: toSubmitData}}))
            //onUpdate()
        } else{
            dispatch(updateWebsite({id: toSubmitWebsiteId, data: {data: toSubmitData}}))
        }
        /*
        try {
            const { data, error } = await supabase
            .from('website')
            .update({ data:  JSON.stringify(pageData)})
            .eq('id', websiteId)
            .select()

            if (error) {
            throw new Error(error.message);
            }
            
            console.log('Supabase update success:', data);
        } catch (error) {
            console.error('Supabase update error:', error);
        }
        */
    };


    const alterBlock  = ({blockId,direction,newPosition}) => {
        // get all the blocks, put in position, suffle rest
        // once updated, Update PageData & if Needed save
    }

    const alterPositionInArray = ({initialArray,currentPosition,direction,newPosition}) => {
        let newArray = [];
        initialArray.map((item,index) => {
            // remove the current position element
            // iterate from new position 
        })
    }

    const SpaceBetweenDiv = styled('div')({
        justifyContent: 'space-between',
        alignItems: 'center',
    });

    const StyledIconButton = styled(IconButton)({
        //marginRight: 'auto',
        cursor: 'grab !important',
    });
    
    const handleDragStart = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = async (event, targetIndex) => {
        event.preventDefault();
        const draggedIndex = event.dataTransfer.getData('text/plain');
        const draggedItem = sections[draggedIndex];

        const newSections = [...sections];
        newSections.splice(draggedIndex, 1);
        newSections.splice(targetIndex, 0, draggedItem);

        setSections(newSections);
        //delete pageData.sections;
        let newPageData = {...pageData, blocks: newSections};
        //newPageData['blocks'] = newSections;
        //setPageData(newPageData)
        setPageData(newPageData);

        //console.log('The section changes',newSections,pageData,pageId)
        await updatePageData(newPageData,websiteId,pageId);
        /*
        const { data: websiteData, error } = await supabase
        .from('website')
        .update({ data:  JSON.stringify(newPageData)})
        .eq('id', websiteId)
        .select()
        console.log('returned data:',JSON.parse(websiteData[0].data))
        if(error){
            console.error('error saving data',error)
        } else{
            onUpdate()
        }   
        */     
    };
    
    const handleSectionDelete = async (index,event) => {
        setIsAddedTemplate(false)
        event.stopPropagation();
        let newPage = {
            ...pageData,
            blocks: pageData.blocks.filter((_, itemIndex) => itemIndex !== index)
        };



        setPageData(newPage);
        
        await updatePageData(newPage,websiteId,pageId);
        /*
        const { websiteData, error } = await supabase
        .from('website')
        .update({ data:  JSON.stringify(newPage)})
        .eq('id', websiteId)
        .select()

        console.log(websiteData,newPage)
        onUpdate()
        */
    };
    const handleSectionAdd = async (type,pageId=0,positionSeeking,extraInstruction,staticBlockData=false) => {
        // if pageId false, it's homepage, else proper page Id desired.
        setIsSectionAdding(true);
        setIsAddedTemplate(false)
        console.log('section add check',type,pageId,positionSeeking)
        console.log(pageData)
        // Create a shallow copy of pageData
        let newPage = { ...pageData };

        // Ensure blocks property exists and is an array
        newPage.blocks = newPage.blocks || [];
        
        const menuBlock = websiteData.data.blocks.find(block => block.WebsiteBlock.type=='header');
        const allMenus = (menuBlock) ? menuBlock.menus : [];
        let newBlockData = {}
        if (type==='sectionTemplateStatic'){
            if(!staticBlockData)  return false;
            newBlockData = {...staticBlockData}
        } else if (type==='sectionTemplateDynamic'){
            if(!staticBlockData)  return false;
            newBlockData = {...staticBlockData}
        } else{
            newBlockData = (type === 'formOld')
            ? await getBlankBlockData('form', 'some extra ordinary form')
            : await getBlankBlockDataWithAIContent(type,[],websiteData.formData,allMenus,extraInstruction);
        }

        // Insert newBlockData at the desired position without mutating the original array
        newPage.blocks = [
            ...newPage.blocks.slice(0, positionSeeking),
            newBlockData,
            ...newPage.blocks.slice(positionSeeking)
        ];

        setPageData(newPage);
        setBlockTypeAdd(false);
        setBlockAddInstruction('');
        setIsSectionAdding(false);
        /*
        const { websiteData, error } = await supabase
        .from('website')
        .update({ data:  JSON.stringify(newPage)})
        .eq('id', websiteId)
        .select()
        */
        await updatePageData(newPage,websiteId,pageId);

        console.log(websiteData,newPage)
        //onUpdate()
    };

    const onUpdateBlock = async (data,theKey) => {
        setIsAddedTemplate(false);
        if(!data && !theKey){
            onUpdate();
            return null;
        }
        const blocktype = data.WebsiteBlock.type;
        //alert(blocktype +':'+ JSON.stringify(data))
        //console.log(theKey,data);
        //let newPage = {...pageData, blocks: [...pageData.blocks, [theKey] : data];
        //let newPageBlock = [...pageData.blocks];
        //newPageBlock[theKey] = data;
        //newPage.blocks = newPageBlock;
        let newPage = {
            ...pageData,
            blocks: pageData.blocks.map((block, index) => 
              index === theKey ? data : block
            )
        };
        setPageData(newPage);
        
        /*
        const { websiteData, error } = await supabase
        .from('website')
        .update({ data:  JSON.stringify(newPage)})
        .eq('id', websiteId)
        .select()

        console.log(websiteData,newPage)
        onUpdate()
        */
        await updatePageData(newPage,websiteId,pageId);
        //onUpdate()

    }
    const onUpdatePage = async (data,toSubmitPageId,toSubmitWebsiteId) => {
        setIsAddedTemplate(false);
        if(!data || !(toSubmitPageId>0) || !(toSubmitWebsiteId>0)){
            onUpdate();
            return null;
        }
        dispatch(updateWebsitePageDefault({id: toSubmitPageId, websiteId: toSubmitWebsiteId, data: {...data}}))
    }

    const changeView = (viewType) => {
        setView(viewType)
    }

    const onPageCreate = async (breadcrumb=false,toSubmitWebsiteId=false,extraInstruction,staticPageData=false,ignoreCompanyData=false) => {
        const { data: { user } } = await supabase.auth.getUser();
        const menuBlock = websiteData.data.blocks.find(block => block.WebsiteBlock.type=='header');
        const allMenus = (menuBlock) ? menuBlock.menus : [];


        // if static stuff, remove id, created_at. Replace website_id, owner
        let staticPageDataCleaned = {...staticPageData}
        if(breadcrumb==='pageTemplateStatic'){
            if(!staticPageDataCleaned) return false;
            ['id','created_at','website_id','owner'].map(remProp => {
                delete staticPageDataCleaned[remProp];
            })
            breadcrumb =  {breadcrumb: staticPageDataCleaned.name.split(' > ')};
        }

        /*if(!pageNeedInfoFullfilled){
            const blankData = await getBlankPageDataInfo(breadcrumb, websiteData.formData, allMenus);
            setPageCreateQuestions(blankData);
            console.log(blankData);
        } else{*/
            // create a page with the desired details.
            if(breadcrumb && breadcrumb.breadcrumb){
                setToCreatePageName(breadcrumb.breadcrumb.join(' > '));
            } else{
                setToCreatePageName('Blank Page');
            }
            setIsNewPageCreating(true);
            if(!toSubmitWebsiteId){
                setIsNewPageCreating(false);
                return null;
            }
            let blankData = (staticPageData) ? staticPageDataCleaned : await getBlankPageData({
                breadcrumb, 
                companyData: websiteData.companySummary, 
                allMenus, 
                extraInstruction: pageAddInstruction, 
                addMessage: addMessage, 
                ignoreCompanyData, 
                userProvidedSections: (userProvidedSections && userProvidedSections.length>0) ? {sections : userProvidedSections} : false
            });
            if(blankData){
                let blankDataModified = {...blankData, 'owner' : user.id};
                //dispatch(createWebsitePageDefault({websiteId: toSubmitWebsiteId, data: {...blankDataModified},postDo: 'SETEDIT'})).then((res) =>{
                dispatch(createWebsitePageDefault({websiteId: toSubmitWebsiteId, data: {...blankDataModified}})).then((res) =>{
                    console.log('something happened',res);
                    if(res && res.payload && res.payload.id){
                            //dispatch(setCurrentEditedWebsitePageId(res.payload.id)).then(() => {
                                //setTimeout(() => { setView('sections') },5000)
                            //});
                            // update the menu.
                            // websiteData.data =
                            const updatedBlocks = websiteData.data.blocks.map(block => {
                                if (block.WebsiteBlock.type == 'header') {
                                    return {
                                        ...block,
                                        menus: addPropToMenu(block.menus,breadcrumb,'page_id',res.payload.id)  // Add or update the property you want here
                                    };
                                }
                                return block;
                            });
                            
                            let newWebsiteData = {
                                data: {
                                    ...websiteData.data,
                                    blocks: updatedBlocks,
                                }
                            }
                        //dispatch(updateWebsite({id: toSubmitWebsiteId, data: {...newWebsiteData}}))
                        setIsNewPageCreating(false);
                        setPageAdd(false);
                        setPageAddInstruction(null);
                    }
                })
            } else{            
                setIsNewPageCreating(false);
            }
        /*}*/
    }
    const onPageDelete = async (id=false) => {
        setIsAddedTemplate(false);
        // create a page with the desired details.
        if(!id){
            return null;
        }
        console.log('Delete:',id,websiteId)
        //const isConfirmed = window.confirm("Are you sure you want to delete this item?");

        setSelectedPage({ websiteId: websiteId, pageId: id });
        setDialogOpen(true);

        //if (isConfirmed) {
        //    dispatch(deleteWebsitePageDefault({id:id,websiteId:websiteId}));
        //}
    }
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);


    const handleConfirmDeletePage = () => {
        // Dispatch your delete action
        console.log('selected page',selectedPage);
        dispatch(deleteWebsitePageDefault({websiteId: selectedPage.websiteId, id: selectedPage.pageId}));
    };

    const chooseBlock = (block,theKey) =>  {
        console.log('block form view:',block,theKey);
        //return null;
        const blockType = block?.WebsiteBlock?.type;

        if (!blockType) return; // Exit early if there's no block type
        let updatedBlock = { ...block };
        if (block.pageFill?.section_style === 'logo_list') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'logos' };
        } else if (block.pageFill?.section_style === 'customer_list') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'customers' };
        } else if (block.pageFill?.section_style === 'stats') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'stats' };
        } else if (block.pageFill?.section_style === 'faq') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'faqs' };
        } else if (block.pageFill?.section_style === 'awards') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'awards' };
        } else if (block.pageFill?.section_style === 'team') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'team' };
        } else if (block.pageFill?.section_style === 'feature_list') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'features' };
        } else if (block.pageFill?.section_style === 'methods_process_list') {
            updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'process' };
        }
        //console.log('block editors',updatedBlock.WebsiteBlock.type,block.version);
        let theForm = <>hh 1{updatedBlock.WebsiteBlock.type}</>;
        switch (updatedBlock.WebsiteBlock.type) {
            case 'banner':
            theForm = <BannerForm blockData={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'header':
            theForm = <HeaderForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'footer':
            theForm = <FooterForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'list':
            theForm = <ListForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'awards':
            theForm = <AwardsForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'stats':
            theForm = <StatsForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'team':
            theForm = <TeamForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'hero':
            theForm = <HeroForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'quote':
            theForm = <QuoteBlockForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'formblock':
            theForm = <FormForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'features':
            theForm = <FeatureForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'how_it_works':
            theForm = <FeatureForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'logos':
            theForm = <FeatureForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            //case 'cta':
            //theForm = <GlobalAdvancedForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'cta':
            theForm = <FeatureForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'html':
            theForm = <HtmlForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'calendly':
            theForm = <CalendlyForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            case 'freeform':
            theForm = <ElementFormCover data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey} />;
            break;
            default:
            theForm = <>hh2 {updatedBlock.WebsiteBlock.type}</>;
        }
        if(block.version==='block'){
            return <GlobalAdvancedForm data={block} onUpdate={(e) => onUpdateBlock(e, theKey)} theKey={theKey}>{theForm}</GlobalAdvancedForm>;
        } else{
            //alert(updatedBlock.WebsiteBlock.type)
            return theForm;
        }
        return null;
    }
    
    const onAddTemplate = async (data={},type='section') => {
        if(type==='section') {
            setIsAddingTemplate(true); 
            dispatch(addTemplate({type: type, data: {...data}, name: (data.headline) ? data.headline : `A ${type} template`}))
            //.then((res) => {
                setTimeout(() => {
                    setIsAddedTemplate(true);
                    setIsAddingTemplate(false);
                }, 1000)
            //})
        } else if(type==='page') {
            setIsAddingTemplate(true);
            dispatch(addTemplate({type: type, data: {...data}, name: (data.name) ? data.name : `A ${type} template`}))
            //.then((res) => {
                setTimeout(() => {
                    setIsAddedTemplate(true);
                    setIsAddingTemplate(false);
                }, 1000)
            //})
        }
    }
    
    const onPageSelect = (page,pageId=0) => {
        // set page
        setPageId(pageId);
        setIsAddedTemplate(false);
        dispatch(setCurrentEditedWebsitePageId(pageId));
        if(pageId===0){
            setPageName('Home');
            setPageData(websiteData.data);
            setSections(websiteData.data.blocks || []);
        } else{
            const thePage = allPages.find(page => page.id===pageId);
            if(thePage && thePage!==undefined){
                /*
                let thePageDataJson = getJsonObject(thePage.data);
                thePageDataJson['blocks'] = (thePageDataJson['blocks']) ? thePageDataJson['blocks'] : [];

                setPageData(JSON.stringify(thePageDataJson));
                console.log(thePage.data);
                console.log(thePageDataJson);
                setSections(thePageDataJson['blocks'] || []);
                */
                let thePageDataJson = getJsonObject(thePage.data);

                // Ensure thePageDataJson is an object
                if (typeof thePageDataJson !== 'object' || thePageDataJson === null) {
                    console.error('Expected an object but got:', thePageDataJson);
                    return;
                }
            
                // Ensure blocks property exists
                thePageDataJson.blocks = thePageDataJson.blocks || [];
            
                setPageData(thePageDataJson);
                console.log(thePage.data);
                console.log(thePageDataJson);
                setSections(thePageDataJson.blocks);
                setPageName(thePage.name);
                setPageInfo(thePage)
            }
        }
        // get the sections from us
        // change view to section

        setView('sections')
        setBackTo('pages')
    }
    const onSectionSelect = (section, sectionIndex) => {
        //console.log('in onSectionSelect',sectionIndex)
        setSectionId(sectionIndex)
        setView('section')
        setBackTo('sections')
    }
    const goBack = () => {
        let goToView = null;
        let goBackView = null;
        setIsAddedTemplate(false)
        switch (view){
            case 'section':
                goToView = 'sections';
                //goBackView = 'pages';
                setBackTo('pages')
                break;
            case 'sections':
                goToView = 'pages';
                dispatch(setCurrentEditedWebsitePageId(null))
                setBackTo(null)
                break;
            case 'pages':
                goToView = 'pages';
                dispatch(setCurrentEditedWebsitePageId(null))
                setBackTo(null)
                break;
            default:
                goToView = null
                dispatch(setCurrentEditedWebsitePageId(null))
                setBackTo(null)
        }
        console.log(view,goToView)
        setView(goToView)
        //setBackTo('pages')
    }

    //console.log('website data',pageData)


    const TemplateComponent = ({type, data, onUpdate}) => {
        const components = getAllComponents(type)
        /*
        const setComponentDesign = (siteId,pageId,blockTypeId,componentDesign) => {
            let componentDesigns = localStorage.getItem('componentDesigns-'+siteId+'-'+pageId);
            if(componentDesigns===null || componentDesigns===undefined){
                componentDesigns = {}
            } else{
                componentDesigns = componentDesigns
            }
            componentDesigns[blockTypeId] = componentDesign.identifier;
            localStorage.setItem('componentDesigns-'+siteId+'-'+pageId,JSON.stringify(componentDesigns))
            data['componentDesign'] = componentDesign;
            onUpdate(data)
        }
        */

        const setComponentDesign = (siteId, pageId, blockTypeId, componentDesign) => {
            const storageKey = 'componentDesigns-' + siteId + '-' + pageId;
            
            // Retrieve componentDesigns from localStorage and parse it
            let componentDesigns = JSON.parse(localStorage.getItem(storageKey) || '{}');
            
            // Update componentDesigns
            componentDesigns[blockTypeId] = componentDesign.identifier;
            
            // Store the updated componentDesigns back into localStorage
            localStorage.setItem(storageKey, JSON.stringify(componentDesigns));
        
            // Create a new data object with updated componentDesign
            const updatedData = {
                ...data,
                componentDesign: componentDesign
            };
        
            // Call the onUpdate function with the updated data
            onUpdate(updatedData);
        }
        

        console.log('editor component design',type,data)

        return(
            <>
            {components && <h2><hr className="my-5" />Designs</h2>}
            {components && components.map((component,index) => (
                <div className={`mt-2 mb-3 ${component.identifier}`} style={(data.componentDesign && data.componentDesign.identifier===component.identifier) ? {border: '5px solid #333 !important'} : {border: 'none'}} 
                    key={index}
                >
                    <div className="fw-bold mb-2">{component.name}</div>
                    <div role="button" onClick={() => setComponentDesign(websiteId,pageId,type,{name: component.name, identifier: component.identifier, text: component.text})}>
                    {component.design}
                    </div>
                </div>
            ))}
            </>
        )
    }

    const BlockTypeToIcon = ({blocktype}) => {
        const icons = {
            banner: <ArtTrackIcon />,
            list: <ViewListIcon />,
        }
        return (
            <span>{icons[blocktype]}</span>
        )
    }

    const PageAddSectionSelection = () => {
        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                //value={age}
                                //label="Age"
                                //onChange={handleChange}
                            >       
                                <MenuItem key={-1} value={''}>Block Style</MenuItem>                         
                                {allBlockTypes.map((blockTypeSingle,blockTypeIndex) => (
                                    <MenuItem key={blockTypeIndex} value={blockTypeSingle}>{blockTypeSingle}</MenuItem>
                                ))}
                            </Select>                     
                        </TableCell>
                    </TableRow>    
                </TableBody>      
            </Table>
        );
    }

    return (
        <div className="left-sidebar-editor">
        {/* If No view */}
        {(backTo!==null) ? <span role="button" variant="outlined" onClick={goBack}><KeyboardBackspaceIcon /></span> : ''}
        {(view==="pages") ? 
            <>
                <div>
                    <GriderLayout
                        left={<h5 className="fw-bold">Pages</h5>}
                        right={<Button onClick={() =>  { setPageAdd('new'); alert('Purchase our Package to Get this feature. We add Rs. 1000 per page')}} variant="outlined">+</Button>}
                    />                
                    {pageAdd && pageAdd === 'new' && 
                        <div>
                            <br />
                            {!isNewPageCreating && 
                            <>
                            <b>Any specific instruction you want to give?</b>
                            <TextareaAutosize
                                autoFocus                                    
                                minRows={4}
                                aria-label="Any specific instruction you want to give?"
                                placeholder="Any specific instruction you want to give?"
                                style={{width: '100%'}}
                                variant="outlined"
                                label={'Any specific instruction you want to give?'}
                                value={pageAddInstruction}
                                onChange={(e) => setPageAddInstruction(e.target.value)}
                            />
                            <input type="file" />
                            <PageAddSectionSelection />
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className="mx-1 mb-2"
                                onClick={() => onPageCreate(false,websiteId,pageAddInstruction,false,false)}
                            >
                                Create This Page
                            </Button> 
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className="mx-1 mb-2"
                                onClick={() => onPageCreate(false,websiteId,pageAddInstruction,false,true)}
                            >
                                Ignore Company Data And Create
                            </Button>                                
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className="mx-1 mb-2"
                                onClick={() => { setPageAdd(false); setPageAddInstruction('') }}
                            >
                                Cancel
                            </Button>
                            </>
                            }
                            {isNewPageCreating && <>Creating a page <CircularProgress /></>}
                        </div>
                    }
                </div>
                {pages && pages.map((page,pageIndex) => (
                    <span key={pageIndex} className="h5 d-block" role='button' onClick={() => onPageSelect(page)}>{page}</span>
                ))}
                {allPages && allPages.map((page,pageIndex) => (
                    <span key={pageIndex} className={`row py-2 d-flex border-top ${(pageIndex%2===0) ? 'bg-light': ''}`} role='button'><span className="col" onClick={() => onPageSelect(page.name,page.id)}>{page.name}</span><span className="col text-end text-light-comfort"><DeleteIcon onClick={() => onPageDelete(page.id)} /></span></span>
                ))}
                {isNewPageCreating && 
                    <span className={`row py-2 d-flex border-top ${((allPages.length)%2===0) ? 'bg-light': ''}`}>
                        <span className="col">
                            <Skeleton width={getStringSkeletonWidth(toCreatePageName)} height={'30px'} />
                        </span>
                        <span className="col text-end text-light-comfort">
                            <DeleteIcon />
                        </span>
                    </span>       
                }
                <h4 className="fw-bold mt-5">Create Additional Pages</h4>
                {potentialPagesByMenu && potentialPagesByMenu.map((page,pageIndex) => (
                    <div 
                        key={pageIndex} 
                        className={`py-2 d-block border-top ${(pageIndex%2===0) ? 'bg-light': ''}`} 
                    >
                    <span
                        role='button' 
                        onClick={() => setPageAdd(page.breadcrumb.join(' > '))}>
                            + {page.breadcrumb.join(' > ')}
                    </span>
                    {pageAdd && pageAdd === page.breadcrumb.join(' > ') && 
                        <div>
                            <br />
                            {!isNewPageCreating && 
                            <>
                            <b>Any specific instruction you want to give?</b>
                            <TextareaAutosize
                                autoFocus                                    
                                minRows={4}
                                aria-label="Any specific instruction you want to give?"
                                placeholder="Any specific instruction you want to give?"
                                style={{width: '100%'}}
                                variant="outlined"
                                label={'Any specific instruction you want to give?'}
                                value={pageAddInstruction}
                                onChange={(e) => setPageAddInstruction(e.target.value)}
                            />
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className="mx-1 mb-2"
                                onClick={() => onPageCreate(page,websiteId,pageAddInstruction)}
                            >
                                Create This Page
                            </Button>                                
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className="mx-1 mb-2"
                                onClick={() => { setPageAdd(false); setPageAddInstruction('') }}
                            >
                                Cancel
                            </Button>
                            </>
                            }
                            {isNewPageCreating && <>Creating a page <CircularProgress /></>}
                        </div>
                    }
                    </div>
                ))}          
                {pageTemplates &&
                <>
                    <h5 className="fw-bold mt-5">Add Saved pages</h5>
                    {pageTemplates.map((pageTemplate,pageTemplateIndex) => (
                        <div 
                            className="mx-1 mb-2 pointer"
                            onClick={() => onPageCreate('pageTemplateStatic',websiteId,false,pageTemplate.data)}
                            key={pageTemplateIndex}
                        >
                            + {pageTemplate.name}
                        </div>
                    ))}
                </>
                }
                <>
                    <div
                        className="mx-1 mb-2 pointer"
                        onClick={() => onPageCreate('pageTemplateStatic',websiteId,false,{data: {blocks: []},name: "A blank Page",title:"A Blank Page"})}
                    >
                        + Add a blank page without AI.
                    </div>
                </>
            </>
            : 
            ''
        }
        {/* If a page chosen */}
        {(view==="sections") ? 
            <>
                {pageName && 
                    <h3>
                        {pageName}                        
                        {isAddingTemplate && <span className="ms-3" ><CircularProgress /></span>}
                        {isAddedTemplate && <span className="ms-3" ><TaskAlt /></span>}
                        {!isAddingTemplate && !isAddedTemplate &&
                            <span className="pointer ms-3" 
                                onClick={() => onAddTemplate(pageInfo,'page')}
                                alt='Save this page as a template' 
                                title='Save this page as a template'
                            >
                                <SaveAltTwoTone />
                            </span>
                        }
                    </h3>
                }
                
                {(pageId>0) && <PageForm data={pageInfo} onUpdate={(e) => onUpdatePage(e,pageId,websiteId)} isHomei={(pageId<=0)} />}
                <hr />
                <h5 className="fw-bold">Sections</h5>
                <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                <>
                    {sections && sections.map((section,sectionIndex) => (
                        <Row 
                            key={sectionIndex}                      
                            draggable
                            onDragStart={(event) => handleDragStart(event, sectionIndex)}
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, sectionIndex)}
                            onTouchStart={(event) => handleDragStart(event, sectionIndex)}
                            onTouchMove={handleDragOver}
                            onTouchEnd={(event) => handleDrop(event, sectionIndex)}
                            className={`border-bottom pb-2 d-flex align-items-center py-2 d-block ${(sectionIndex%2===0) ? 'bg-light-comfort': ''}`}
                            role='button' 
                            onClick={() => onSectionSelect(section,sectionIndex)}
                        >
                            <Col md={1} xs={2}>
                                <StyledIconButton 
                                        className="text-light-comfort">
                                    <DragIndicatorIcon />
                                </StyledIconButton>
                            </Col>
                            <Col>
                                {section.WebsiteBlock && section.WebsiteBlock.type && <span className="text-light-comfort">{section.WebsiteBlock.type} {/*<BlockTypeToIcon blocktype={section.WebsiteBlock.type} /> */}{/*<Chip icon={} className="bg-light-comfort-deeper" label={section.WebsiteBlock.type} size="small" />*/}</span>}                      
                                {section.headline && <div>{section.headline}</div>}                
                                {section.header && !section.headline && <div>{section.header}</div>}
                            </Col>
                            <Col md={2} xs={3}>
                                <StyledIconButton>
                                    <IconButton 
                                        style={{marginLeft: 'auto'}} 
                                        className="text-light-comfort"
                                        onClick={(event) => handleSectionDelete(sectionIndex,event)}>
                                            <DeleteIcon />
                                    </IconButton>
                                </StyledIconButton>
                            </Col>
                        </Row>
                    ))}
                </>
                </DndProvider>
                {/*<PageForm data={pageData} />*/}
                <div className="my-3">
                {allBlockTypes.map((blockTypeSingle,blockTypeIndex) => (
                    <span key={blockTypeIndex}>
                        {!blockTypeAdd && <Button 
                            variant="outlined" 
                            color="secondary"
                            className="mx-1 mb-2"
                            onClick={() => setBlockTypeAdd(blockTypeSingle)}
                        >
                            + {blockTypeSingle}
                        </Button>}
                        {blockTypeAdd && blockTypeAdd === blockTypeSingle && 
                            <>
                                Creating a {blockTypeAdd} section
                                <br />
                                {!isSectionAdding && 
                                <>
                                Any specific instruction you want to give?
                                <TextareaAutosize
                                    autoFocus                                    
                                    minRows={4}
                                    aria-label="Any specific instruction you want to give?"
                                    placeholder="Any specific instruction you want to give?"
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    label={'Any specific instruction you want to give?'}
                                    value={blockAddInstruction}
                                    fullwidth="true"
                                    onChange={(e) => setBlockAddInstruction(e.target.value)}
                                />
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    className="mx-1 mb-2"
                                    onClick={() => handleSectionAdd(blockTypeSingle,pageId,(pageId>0) ? sections.length : sections.length-1,blockAddInstruction)}
                                >
                                    Create This Block
                                </Button>                                
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    className="mx-1 mb-2"
                                    onClick={() => { setBlockTypeAdd(false); setBlockAddInstruction('') }}
                                >
                                    Cancel
                                </Button>
                                </>
                                }
                                {isSectionAdding && <CircularProgress />}
                            </>
                        }
                    </span>
                ))}
                {sectionTemplates &&
                <>
                    <h5>Add Saved sections</h5>
                    {sectionTemplates.map((sectionTemplate,sectionTemplateIndex) => (
                        <>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            className="mx-1 mb-2"
                            onClick={() => handleSectionAdd('sectionTemplateStatic',pageId,(pageId>0) ? sections.length : sections.length-1,false,sectionTemplate.data)}
                            key={sectionTemplateIndex}
                        >
                            + {sectionTemplate.name}
                        </Button>
                        </>
                    ))}
                </>
                }    
                </div>
            </>
            : 
            ''
        }
        {/* If a section is chosen */}
        {(view==="section") ? 
        <>
        {(pageData && pageData.blocks && pageData.blocks.length>0) && pageData.blocks.map((theBlockData, key) => (
          <>
          <div key={key} className={(key===sectionId) ? `` : `d-none`}>
            <div className="text-end"><span className="badge bg-secondary">{theBlockData && theBlockData.WebsiteBlock && theBlockData.WebsiteBlock.type}</span></div>
            {chooseBlock(theBlockData,key)}
            {isAddingTemplate && <span className="ms-3" ><CircularProgress /></span>}
            {isAddedTemplate && <span className="ms-3" ><TaskAlt /></span>}
            {!isAddingTemplate && !isAddedTemplate &&
                <span 
                    className="pointer" 
                    onClick={() => onAddTemplate(theBlockData,'section')}
                    alt='Save this block as a template' 
                    title='Save this block as a template'>
                        <SaveAltTwoTone />
                </span>
            }
            <CodeWriterForm jsonData={theBlockData} />
            {(theBlockData && theBlockData.WebsiteBlock && theBlockData.WebsiteBlock.type) ? <TemplateComponent data={theBlockData} type={theBlockData.WebsiteBlock.type} onUpdate={(e) => onUpdateBlock(e, key)} /> : ''}
          </div>
          </>
        ))}
        </>
        : ''}
        <ConfirmationDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onConfirm={handleConfirmDeletePage}
        />
        {pageCreateQuestions && pageCreateQuestions.sections && pageCreateQuestions.sections.length>0 &&
            <div className="scroll-y col-md-3 p-3" style={{position: 'absolute', top:0, left:0, widthu: '350px', background: '#fcfcfc', bottom: 0, overflow: 'scroll'}}>                
                {pageCreateQuestions && pageCreateQuestions.sections.map((nextQuestion,index) => (
                    <div className="pb-3" key={index}>
                        <b>{nextQuestion.question}</b>
                        <span className="d-block small">{nextQuestion.example}</span>
                        {(nextQuestion.type==='text' || nextQuestion.type==='' || !nextQuestion.type) ? 
                            <TextField
                            label={nextQuestion.question} 
                            fullwidth="true"
                            placeholder={nextQuestion.question}
                            //name={`${group.name}.value`}
                            //value={createPageFormData[group.name]?.value || ''}

                            name={`questions['${nextQuestion.question}']`}
                            value={createPageFormData[`questions['${nextQuestion.question}']`] || ''}
                            //onChange={(e) => newData(e)}
                            />
                        : ''}
                        {nextQuestion.type === 'checkbox' && nextQuestion.choices.map((choice) => (
                            <FormControlLabel
                            key={choice.choiceText}
                            control={
                                <Checkbox
                                checked={createPageFormData[`questions['${nextQuestion.question}']`]?.[choice.choiceText] || false}
                                //onChange={(e) => newData(e)}
                                name={`questions['${nextQuestion.question}'].${choice.choiceText}`}
                                />
                            }
                            label={choice.choiceText}
                            />
                        ))}                                    
                        {nextQuestion.type === 'radio' && nextQuestion.choices.map((choice) => (
                            <FormControlLabel
                            key={choice.choiceText}
                            control={
                                <Radio
                                checked={createPageFormData[`questions['${nextQuestion.question}']`]?.value === choice.choiceText}
                                //onChange={(e) => newData(e)}
                                name={`questions['${nextQuestion.question}'].value`}
                                value={choice.choiceText}
                                />
                            }
                            label={choice.choiceText}
                            />
                        ))}


                    </div>
                ))}
            </div>
        }
        </div>
    );
}
export default EditorForm;