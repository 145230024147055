import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CssEditor = ({type="p", initialProperties={"font-size":"25px"}, onPropertyChange=false}) => {
  const [cssProperties, setCssProperties] = useState({});
  const [selectedProperties, setSelectedProperties] = useState(initialProperties || {});
  const [defaultProperties, setDefaultProperties] = useState({});
  useEffect(() => {
    axios.get('https://gist.githubusercontent.com/cblanquera/9e890e2fef9d7f5b819e0550c26566b4/raw/f066fbb7cf3603888c427754150f913c642670ed/css-properties.json')
      .then(response => {
        if (response.data) { // Checking if response.data is truthy
          setCssProperties(response.data);

          const initialValues = {};
          const initialValuesFromInitProps = {};
          Object.entries(response.data).forEach(([key, value]) => {
            initialValues[key] = {
              value: value.initial,
              isCustom: (!value.values.includes(value.initial) || value.initial.includes('[')), // !value.values.includes(value.initial),
            };
            if(initialProperties && initialProperties[key]){
                initialValuesFromInitProps[key] = {
                    value: initialProperties[key],
                    isCustom: (!value.values.includes(initialProperties[key]) || initialProperties[key].includes('[')), // !value.values.includes(value.initial),
                };
            }
          });
          setDefaultProperties(initialValues);
          setSelectedProperties(initialValuesFromInitProps);
        }
      })
      .catch(error => {
        console.error('Error fetching CSS properties:', error);
      });
  }, []);

  const handleSelectChange = (property, newValue) => {
    setSelectedProperties(prev => {
      const isCustomValue = (!cssProperties[property].values.includes(newValue) || newValue.includes('['));
      const isValueBracket = newValue.includes('[');
      return {
        ...prev,
        [property]: {
          value: (isValueBracket) ? null: newValue,
          isCustom: isCustomValue,
        }
      };
    });
  };
  let counter = 0;
  return (
        <table className='table table-responsive'>
            <tbody>               
                {Object.entries(cssProperties).map(([key, value]) => (
                    <tr key={key}>
                        <td>
                            {counter++}  =  
                            {key}
                        </td>
                        <td>
                            <select 
                                onChange={(e) => handleSelectChange(key, e.target.value)} 
                                value={selectedProperties[key]?.value}
                                style={{width: "100%"}}
                            >
                                <option></option>
                                {value.values.map((val, index) => (
                                <option key={index} value={val}>
                                    {val}
                                </option>
                                ))}
                            </select>
                            <br />
                            {selectedProperties[key] && selectedProperties[key]?.isCustom && (
                                <input 
                                type="text"
                                placeholder="Enter custom value"
                                value={selectedProperties[key]?.value}
                                onChange={(e) => handleSelectChange(key, e.target.value)}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
  );
};

export default CssEditor;
