import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import CardBlock from './CardBlock';
import ThreeColumnBlock from './Blocks/ThreeColumnBlock';
import YouTubeVideoBlock from './YouTubeVideoBlock';
import TestimonialsBlock from './TestimonialBlock';
import { Paper, Button } from '@mui/material';

const BlockContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 20px;
  padding: 20px;
`;


const Block = ({ title, content, type, industry }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      let randomkey = Math.floor(Math.random() * 20) + 1;
      try {
        const response = await fetch(`https://api.unsplash.com/search/photos?query='+industry+'&per_page=20&page=10&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`);
        const data = await response.json();
        const imageSrc = (data.results.length>0) ?  data.results[randomkey].urls.small : '';
        setImageUrl(imageSrc);
      } catch (error) {
        console.error('Failed to fetch image:', error);
      }
    };

    fetchImage();
  }, []);


  if(type='3-block'){
    return (
      <BlockContainer className="col-md-3">
        <h2>{title}</h2>
        {imageUrl && <img src={imageUrl} alt={title} style={{ width: '100%' }} />}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </BlockContainer>
    );
  } else{
    return (
      <BlockContainer>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </BlockContainer>
    );
  }
};



const EditorBlock = ({ block }) => {

  
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const chooseBlock = () =>  {        
    switch (block.type) {
      case 'threeColumn':
        return <ThreeColumnBlock block={block} />;
        case 'card':
          return <CardBlock block={block} />;
        case 'youtubeVideo':
          return <YouTubeVideoBlock block={block} />;        
        case 'testimonial':
          return <TestimonialsBlock block={block} />;
        default:
          return null;
      }
  }

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative' }}>
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="contained" color="primary" onClick={() => console.log('Button clicked')}>
            Click Me
          </Button>
        </div>
      )}
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        {chooseBlock(block)}
      </Paper>
      </div>
  )
};

export {Block, EditorBlock};