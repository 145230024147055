import { useParams } from "react-router-dom";
import WebsitePreviewerLive from "../../../components/WebsitePreviwerLive";
import DashboardSideBarMenu from "./SidebarMenu";
import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { supabase } from "../../../supabase";
import EditorForm from "../../components/Forms/EditorForm";
import InternalTemplate from "../../templates/Internal";

const SingleWebsiteContentEditor = () => {
    const { theSiteId } = useParams();
    const leftSideBarContent = <DashboardSideBarMenu />
    const [websiteHtml, setWebsiteHtml] = useState('');
    const [updateCount, setUpdateCount] = useState(0);

    
    const iframeRef = useRef(null);
    
    function downloadHTMLFile(htmlContent) {
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.html';
      link.click();
      URL.revokeObjectURL(url);
    }


    const htmlChanged = (html) => {
        setWebsiteHtml(html)
      
    }
    
    const dataUpdated = () => {
      setUpdateCount(updateCount+1)    
    }
    return(
        <InternalTemplate leftSideBarContent={leftSideBarContent}>
            <Row className="overflow-y-auto vh-100" style={{height: 'calc(100vh-80px)'}}>
                <Col className="col-md-2 overflow-y-auto" style={{height: 'calc(100vh-80px)'}}>
                  <EditorForm websiteId={theSiteId} onUpdate={dataUpdated} />
                </Col>
                <Col>
                    {/*<button onClick={() => downloadHTMLFile(websiteHtml)}>Download</button>*/}
                    <WebsitePreviewerLive websiteId={theSiteId} iframeRefu={iframeRef} onHTMLChangeu={(e) => htmlChanged(e)} />
                </Col>
            </Row>
        </InternalTemplate>
    )
}
export default React.memo(SingleWebsiteContentEditor);
