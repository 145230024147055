import { parse } from "best-effort-json-parser";
import DOMPurify from "dompurify";
import { jsonrepair } from "jsonrepair";
import { BASE_URL } from "../lib/config";
import { marked } from "marked";


export const sanitizeHtml = (html) => {
    const sanitizedHTML = DOMPurify.sanitize(html);
    return sanitizedHTML;
}
export const markedToHtml = (input) => {
    return marked(input)
}

export const getJsonObject = (input) => {
    // If the input is already an object (but not null), return a fresh copy of it
    if (typeof input === 'object' && input !== null) {
      return Object.assign({}, input);
    }
  
    // If the input is a string, try to parse it as JSON
    if (typeof input === 'string') {
      try {
        const parsed = JSON.parse(input);
        // If the parsed result is a string, return an empty object
        if (typeof parsed === 'string') {
          return {};
        }
        return Object.assign({}, parsed);
      } catch (e) {
        // If parsing fails, it's not a valid JSON string
      }
    }
  
    // For all other cases, return an empty object
    return {};
}

function extractJSONContent(str) {
    // First, try to extract content between markdown code block indicators
    const codeBlockMatch = str.match(/```json([\s\S]*?)```/);
    if (codeBlockMatch) {
        return codeBlockMatch[1].trim();
    }

    // If not found, try to extract content between outermost curly braces
    const jsonMatch = str.match(/{[\s\S]*}/);
    if (jsonMatch) {
        return jsonMatch[0].trim();
    }

    // If neither is found, return null or an empty string
    return null;
}

export const cleanAndExtractJSON = (response) => {
    // Remove known prose patterns
    //const withoutKnownProse = response.replace(/^Here's how you can.*\n/gm, '');

    // Remove markdown code block indicators
    /*const withoutCodeBlocks = response.replace(/```json/g, '').replace(/```/g, '').trim();

    // Extract JSON using outermost curly braces
    const match = withoutCodeBlocks.match(/{[^]*?}/);
    if (match) {
        try {
            return JSON.parse(match[0]); //match[0]);
        } catch (error) {
            console.error("Failed to parse extracted JSON:", error);
            return null;
        }
    } else {
        console.error("No valid JSON found in the string.");
        return null;
    }*/
    return parse(extractJSONContent(response));
}
export const generateSlug = (pageName) => {
    return pageName
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^\w\s-]/g, '')            // Remove special characters and punctuation
        .replace(/[\s_-]+/g, '-')            // Replace spaces, underscores, and hyphens with a single hyphen
        .replace(/^-+|-+$/g, '');           // Remove leading and trailing hyphens
}
/*
export const generateBreadcrumbs = (menu, path = []) => {
    let breadcrumbs = [];
  
    for (let item of menu) {
      const newPath = [...path, item.menu];
      breadcrumbs.push(newPath);
  
      if (item.submenus && item.submenus.length) {
        breadcrumbs = breadcrumbs.concat(generateBreadcrumbs(item.submenus, newPath));
      }
    }
  
    return breadcrumbs;
}
*/
export const generateBreadcrumbs = (menu, path = []) => {
    let breadcrumbs = [];
    if (Array.isArray(menu)) {
      for (let item of menu) {
        const newPath = [...path, item.menu];
        breadcrumbs.push({
          breadcrumb: newPath,
          pageDesc: item.pageDescription || ''  // Assuming 'pageDescription' is the property name
        });
    
        if (item.submenus && item.submenus.length) {
          breadcrumbs = breadcrumbs.concat(generateBreadcrumbs(item.submenus, newPath));
        }
      }
    }
  
    return breadcrumbs;
}

export const addPropToMenu = (menu, breadcrumb, prop, value) => {
    if (!breadcrumb.length) return menu;

    return menu.map(item => {
        if (item.menu === breadcrumb[0]) {
            if (breadcrumb.length === 1) {
                return { ...item, [prop]: value };
            } else {
                return { ...item, submenus: addPropToMenu(item.submenus, breadcrumb.slice(1), prop, value) };
            }
        }
        return item;
    });
}
/*
export const cleanSubmenus = (menu) => {
    if (menu.submenus && menu.submenus.length === 0) {
        delete menu.submenus;
    } else if (menu.submenus && menu.submenus.length > 0) {
        menu.submenus.forEach(submenu => cleanSubmenus(submenu));
    }
    return menu;
}
*/

export const cleanSubmenus = (menuItem) => {
    if (menuItem.submenus && menuItem.submenus.length === 0) {
        const { submenus, ...rest } = menuItem;
        return rest;
    } else if (menuItem.submenus && menuItem.submenus.length > 0) {
        return {
            ...menuItem,
            submenus: menuItem.submenus.map(submenu => cleanSubmenus(submenu))
        };
    }
    return menuItem;
}

export const getRandomIpsumLogo = () => {
    const logos = [213,214,218,221,235,253,254,255,256,257,259,260,261,263,264,265];
    const n=1;
    // Shuffle the array
    const shuffled = logos.sort(() => 0.5 - Math.random());

    // Get sub-array of first n elements after shuffled
    const res = shuffled.slice(0, n);
    const resi = res.map(logo => `${BASE_URL}/brands/logoipsum/logoipsum-${logo}.svg`);

    //console.error('logos ipsum',res,resi)
    return resi[0];
}

export const getRandomRadius = () => {
    const randomValues = [];  
    for (let i = 0; i < 8; i++) {
      let randomPercentage = Math.floor(Math.random() * 50) + 1;
      randomPercentage = 0;
      randomValues.push(randomPercentage + "%");
      if(i===3){
        randomValues.push("/");
      }
    }  
    return randomValues.join(" ");
}

export const generateRandomPath = () => {
    const randomRadius = Math.random() * 100;
    const randomX = Math.random() * 100;
    const randomY = Math.random() * 100;
    const randomPath = `circle(${randomRadius}% at ${randomX}% ${randomY}%)`;
    return randomPath;
}

export const getStringSkeletonWidth = (str='',fw='normal',fontPerWordSpace=false) => {
    const width = (str && str.length>0) ? (200/26)*str.length : 0;
    return width+'px';
}

export const removePropsFromMenu = (menu, propsToRemove) => {
    return menu.map(item => {
      // Create a copy of the item
      let updatedItem = { ...item };
  
      // Remove each property in propsToRemove from the updatedItem
      propsToRemove.forEach(prop => {
        delete updatedItem[prop];
      });
  
      // If there's a submenus property, recursively call the function
      if (updatedItem.submenus) {
        updatedItem.submenus = removePropsFromMenu(updatedItem.submenus, propsToRemove);
      }
  
      return updatedItem;
    });
};

export const pickProps = (obj, propsToKeep) => {
    return propsToKeep.reduce((newObj, prop) => {
      if (obj.hasOwnProperty(prop)) {
        newObj[prop] = obj[prop];
      }
      return newObj;
    }, {});
}
export const pickPropsFromNested = (obj, propsToKeep) => {
    let newObj = pickProps(obj, propsToKeep);
  
    if (obj.submenus && Array.isArray(obj.submenus)) {
      newObj.submenus = obj.submenus.map(submenu => pickPropsFromNested(submenu, propsToKeep));
    }
  
    return newObj;
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const pickRandomArray = (...arrays) => {
  const randomIndex = Math.floor(Math.random() * arrays.length);
  console.log(randomIndex)
  return arrays[randomIndex];
}
export const pickRandomValue = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};


export const isValidDomain = (domain) => {
  // Regular expression for validating a basic domain
  const domainRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,}\.){1,}[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
};

export const cleanDomain = (domain) => {
  // Removing protocol and 'www.' if present
  return domain.replace(/(https?:\/\/)?(www\.)?/, '');
};

export const setDotNotationObjectValue = (obj, path, value) => {
  if (path.length === 1) {
    obj[path[0]] = value;
    return;
  }
  if (!obj[path[0]]) obj[path[0]] = {};
  setDotNotationObjectValue(obj[path[0]], path.slice(1), value);
};

export const deepClone = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    const arrCopy = [];
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepClone(obj[i]);
    }
    return arrCopy;
  }

  const objCopy = {};
  for (let key in obj) {
    objCopy[key] = deepClone(obj[key]);
  }
  return objCopy;
};

// Function to convert style string to style object
export function convertStyleStringToObjectOld(styleString) {

  if (!styleString) { // Check if styleString is not null or undefined
    return {};
  } else if(typeof styleString === 'object'){
    return styleString;
  }
  const styleArray = styleString.split(';');
  const styleObject = {};

  styleArray.forEach((styleRule) => {
    const [property, value] = styleRule.split(':').map((item) => item.trim());

    if (property && value) {
      // Convert CSS property to camelCase
      const camelCaseProperty = property.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
      
      styleObject[camelCaseProperty] = value;
    }
  });

  return styleObject;
}

// Function to convert style string to style object new
export function convertStyleStringToObjectNew(styleString) {
  if (!styleString) {
    return {};
  } else if (typeof styleString === 'object') {
    return styleString;
  }

  // Splitting by ';' but not inside parentheses (to handle URLs)
  const styleArray = styleString.split(/;(?![^()]*\))/g);
  const styleObject = {};

  styleArray.forEach((styleRule) => {
    // Use indexOf to find the first colon, to avoid splitting URLs
    const colonIndex = styleRule.indexOf(':');
    if (colonIndex === -1) return;

    const property = styleRule.substring(0, colonIndex).trim();
    const value = styleRule.substring(colonIndex + 1).trim();

    if (property && value) {
      // Convert CSS property to camelCase
      const camelCaseProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      styleObject[camelCaseProperty] = value;
    }
  });

  return styleObject;
}

export function convertStyleStringToObject(styleString) {
  if (!styleString) {
    return {};
  } else if (typeof styleString === 'object') {
    return styleString;
  }

  const styleArray = styleString.split(/;(?![^()]*\))/g);
  const styleObject = {};

  styleArray.forEach((styleRule) => {
    const colonIndex = styleRule.indexOf(':');
    if (colonIndex === -1) return;

    let property = styleRule.substring(0, colonIndex).trim();
    const value = styleRule.substring(colonIndex + 1).trim();

    if (property && value) {
      // Special case for properties like z-index
      if (property === 'z-index' || property === 'z-Index') {
        styleObject['zIndex'] = value;
      } else {
        // Convert CSS property to camelCase for other properties
        property = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        styleObject[property] = value;
      }
    }
  });

  return styleObject;
}

export function replaceOrAddClass(classString, classesToReplace, newClass) {
  let classArray = classString ? classString.split(' ') : [''];
  let classAdded = false;

  // Iterate through each class to replace
  classesToReplace && classesToReplace.forEach(classToReplace => {
      const index = classArray.indexOf(classToReplace);
      
      // Replace the first occurrence only
      if (index !== -1) {
          classArray[index] = newClass;
          classAdded = true;
      }
  });

  // If none of the classes to replace were found, add newClass
  if (!classAdded) {
      classArray.push(newClass);
  }

  return classArray.join(' ');
}

export function isClassPresentInString(classString, classToCheck) {
  console.log(classString,classToCheck)
  return classString ? classString.split(' ').includes(classToCheck) : false;
}

export function updateThemeVariables(themeColors, savedColors) {
  if (!Array.isArray(themeColors)) {
    console.error('themeColors is not an array');
    return;
  }
  // Convert savedColors to a map for faster lookups
  const savedColorsMap = new Map(savedColors.map(sc => [sc.name, sc.value]));

  // Iterate over each color in themeColors
  themeColors.forEach(themeColor => {
    // Check if the color exists in savedColorsMap
    if (savedColorsMap.has(themeColor.name)) {
      themeColor.value = savedColorsMap.get(themeColor.name);
    }
  });
  return themeColors; // Ensure you're returning the updated array
}

export function generateId() {
  return `block_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;
}

export const htmlToJson = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return elementToJson(doc.body);
};

export const elementToJsonOld = (element) => {
  const obj = {
      id: Date.now(),
      tag: element.tagName.toLowerCase(),
      text: element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE 
            ? element.textContent.trim()
            : '',
      classes: element.className,
      styles: element.style.cssText
             ? convertStyleStringToObject(element.style.cssText)
             : {},
      dataTags: '', // This needs custom logic based on your requirements
      prompt: '',   // Same as above
      fieldName: '', // Same as above
      children: []
  };

  // Recursively add child elements
  Array.from(element.children).forEach(child => {
      obj.children.push(elementToJson(child));
  });

  return obj;
};

export const voidElements = new Set(['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);

export const elementToJsonNN = (element) => {
    const isVoidElement = voidElements.has(element.tagName.toLowerCase());
    // Check if the current node is a text node
    if (element.nodeType === Node.TEXT_NODE) {
      const content = element.textContent.trim();
      return content ? { id: Date.now(), type: 'text', content: content } : null;
    }
    const obj = {
        id: Date.now(),
        type: 'element',
        tag: element.tagName.toLowerCase(),
        text: isVoidElement ? '' : getTextContent(element), //element.textContent.trim().replace("\n",''),
        classes: element.className,
        styles: element.style.cssText
               ? styleStringToObject(element.style.cssText)
               : {},
        dataTags: extractDataAndAriaAttributes(element),
        prompt: '',   // Custom logic needed
        fieldName: '', // Custom logic needed
        children: isVoidElement ? [] : Array.from(element.children).map(child => elementToJson(child))
    };

    return obj;
};



export const elementToJson = (element) => {
  if (element.nodeType === Node.TEXT_NODE) {
      const content = element.textContent.trim();
      return content ? {id: Date.now(), type: 'text', content } : null;
  }

  const isVoidElement = voidElements.has(element.tagName.toLowerCase());

  const obj = {
      type: 'element',
      id: Date.now(),
      tag: element.tagName.toLowerCase(),
      classes: element.className,
      styles: element.style.cssText
             ? styleStringToObject(element.style.cssText)
             : {},
      dataTags: extractDataAndAriaAttributes(element),
      prompt: '',   // Custom logic needed
      fieldName: '', // Custom logic needed
      children: []
  };

  if (!isVoidElement) {
      Array.from(element.childNodes).forEach(childNode => {
          const childJson = elementToJson(childNode);
          if (childJson) {
              obj.children.push(childJson);
          }
      });
  }

  return obj;
};



export const parseCssToObject = (cssString) => {
  const cssObject = {};
  if (!cssString) return cssObject;

  // Splitting by semi-colon outside of parentheses (to avoid splitting URLs)
  const styles = cssString.split(/;(?![^()]*\))/g);

  styles.forEach(style => {
    if (style.trim() !== '') {
      // Finding first colon to split property and value (to handle colons in URLs)
      const colonIndex = style.indexOf(':');
      if (colonIndex !== -1) {
        let property = style.substring(0, colonIndex).trim();
        let value = style.substring(colonIndex + 1).trim().replace('!important', '');
        cssObject[property] = value;
      }
    }
  });

  return cssObject;
};

export const styleStringToObject = (styleString) => {
  return styleString.split(';').reduce((styleObj, styleProperty) => {
      let [key, value] = styleProperty.split(':');
      if (key && value) {
          key = key.trim().replace(/(-\w)/g, m => m[1].toUpperCase());
          styleObj[key] = value.trim();
      }
      return styleObj;
  }, {});
};

const getTextContentNN = (element) => {
  let textContent = '';
  Array.from(element.childNodes).forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
          textContent += node.textContent.trim();
      }
  });
  return textContent;
};

const getTextContent = (element) => {
  // Collects only the direct text content of the element, ignoring nested elements
  return Array.from(element.childNodes)
              .filter(node => node.nodeType === Node.TEXT_NODE)
              .map(node => node.textContent.trim())
              .join(" ").trim();
};

const extractDataAndAriaAttributes = (element) => {
  const attributes = {};
  Array.from(element.attributes).forEach(attr => {
      if (attr.name.startsWith('data-') || attr.name.startsWith('aria-')) {
          attributes[attr.name] = attr.value;
      }
  });
  return attributes;
};