import { NavDropdown } from "react-bootstrap";
import { supabase } from "../../../supabase";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";

  const DashboardSideBarMenu = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
      try {
        await supabase.auth.signOut();
        // Perform any additional actions after successful logout
        setIsLoggedIn(false);
        navigate('/');
      } catch (error) {
        // Handle error during logout
        console.error('Error logging out:', error);
      }
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserLogIn = async() => {
      const res = await supabase.auth.getUser();
      //console.log(session)
      const session = res.data.user;
      if (session) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }

    useEffect(() => {
      checkUserLogIn();
    }, []);
    //useEffect(() => {
    //  console.log('new', isLoggedIn);
    //}, [isLoggedIn]);
    
    const menusList = [
        {name: 'Dashboard', path: '/dashboard'},
        {name: 'Websites', submenus: [
          {name: 'All Websites', path: '/dashboard/websites'},
          {name: 'Add a new site', path: '/dashboard/websites/create'},
          {name: 'Park a Domain', path: '/dashboard/domain'}
        ]},
        {name: 'Content Writer', submenus: [
          {name: 'For Website', path: '/dashboard/content/websites'},
          {name: 'For Blog Post', path: '/dashboard/content/blogs'},
          {name: 'For Landing Page', path: '/dashboard/content/landingpages'},
          {name: 'For Testimonials', path: '/dashboard/content/testimonials'},
        ]},
        {name: 'Settings', path: '/dashboard/settings'},
        {name: 'Payments & Subscriptions', path: '/dashboard/payments'},
        //{name: 'Sessions', submenus: [{name: 'Login', path: '/dashboard/login'},{name: 'Signup', path: '/dashboard/signup'},{name: 'Forgot Password', path: '/dashboard/forgot-password'}]},
    ];
    const LeftSideBarContent = ({menus, isSubmenu=false}) => {
        return(
            <>
            <ul className="nav flex-column">
                {menus.map((menu, index) => {
                    if(menu.submenus) return <NavDropdown title={<span className="text-lightu my-auto">{menu.name}</span>} id="nav-dropdown" key={index}><LeftSideBarContent menus={menu.submenus} isSubmenu={true} /></NavDropdown>
                    return <li className="nav-item" key={index}><Link className={`nav-link ${isSubmenu ? "text-secondary" : "text-lightu"}`} to={menu.path}>{menu.name}</Link></li>
                })}
                {(!isSubmenu && isLoggedIn) ? <li className="nav-item"><a href="#" className="nav-link text-lightu" onClick={handleLogout}>Logout</a></li> : <><li className="nav-item"><a href="#" className="nav-link text-light" onClick={handleLogout}>Logout if not</a></li></>}
            </ul>
            </>
        )
    }

    return (<LeftSideBarContent menus={menusList} />);
  };
  
  export default React.memo(DashboardSideBarMenu);