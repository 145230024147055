import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../../supabase';

const PlatformThemeContext = createContext();

export const usePlatformTheme = () => {
  return useContext(PlatformThemeContext);
};
const getTenantConfig = async (subdomain) => {
    try {
      const { data, error } = await supabase
        .from('partner')
        .select('brand_properties,landing_sections_ours')
        .filter('brand_properties->hosted_domain', 'eq', `"${subdomain}"`);  
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching tenant config:', error);
      return null;
    }
};

export const PlatformThemeProvider = ({ children }) => {
  const [platformTheme, setPlatformTheme] = useState({});
  const [platformContent, setPlatformContent] = useState({
    "headline": "Design a new Website.",
    "sections": [
      {
        "content": "At ContentSimi we design 500+ websites per week. Ultimately by going through this process, we were able to achieve a much more objective confidence that Series would be the easiest, most flexible and, hopefully, most pleasant way for businesses to orchestrate their outbound communications. Give it a try.",
        "headline": "Beautiful Templates auto-selected for you"
      },
      {
        "content": "At ContentSimi we design 500+ websites per week. Ultimately by going through this process, we were able to achieve a much more objective confidence that Series would be the easiest, most flexible and, hopefully, most pleasant way for businesses to orchestrate their outbound communications. Give it a try.",
        "headline": "Auto-edited images to suit you."
      },
      {
        "content": "At ContentSimi we design 500+ websites per week. Ultimately by going through this process, we were able to achieve a much more objective confidence that Series would be the easiest, most flexible and, hopefully, most pleasant way for businesses to orchestrate their outbound communications. Give it a try.",
        "headline": "Carefully crafted content to increase conversion rate."
      },
    ],
    "subHeadline": "At ContentSimi we design 500+ websites per week. Ultimately by going through this process, we were able to achieve a much more objective confidence that Series would be the easiest, most flexible and, hopefully, most pleasant way for businesses to orchestrate their outbound communications. Give it a try.",
    "primaryHeadline": "Welcome! You are at your AI designer"
  });
  const subdomain = window.location.hostname;

  useEffect(() => {
    const fetchConfig = async () => {
      const config = await getTenantConfig(subdomain);

      if(config && config[0] && config[0].brand_properties){
        setPlatformTheme(config[0].brand_properties);
      }
      if(config && config[0] && config[0].landing_sections_ours && config[0].landing_sections_ours!==null){
        setPlatformContent(config[0].landing_sections_ours);
      }
    };
    fetchConfig();
  }, [subdomain]);

  return (
    <PlatformThemeContext.Provider value={{platformTheme,platformContent}}>
      {children}
    </PlatformThemeContext.Provider>
  );
};
