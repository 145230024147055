export const addScripts = (type=['compulsory'],identifiers=false,position='footer') => {
    const scripts = {
        'compulsory' : [
            {
                name: 'Accordion Feature list',
                position: 'footer',
                identifier: 'accordionFeatureList',
                script: `
                $(document).ready(function() {
                    let currentIndex = 0;
                    const accordionItems = $('.accordion .accordion-button');
                    const progressBarDuration = 0.5 * 60 * 1000; // 2 minutes

                    function resetProgressBars() {
                        $('.progress-bar').css('width', '0%').attr('aria-valuenow', 0);
                    }

                    function showNextItem() {
                        // Reset if we've reached the end
                        if (currentIndex >= accordionItems.length) {
                            currentIndex = 0;
                        }

                        resetProgressBars();

                        // Start progress bar for the current item
                        let progressBar = accordionItems.eq(currentIndex).closest('.accordion-item').find('.progress-bar');
                        progressBar.animate({
                            width: "100%"
                        }, progressBarDuration, function() {
                            // Animation complete
                        });

                        // Trigger the accordion item
                        accordionItems.eq(currentIndex).trigger('click');

                        currentIndex++;
                    }
                    showNextItem()

                    // Set the interval to trigger every X minutes (e.g., 2 minutes in this example)
                    setInterval(showNextItem, progressBarDuration);
                    $('.accordion .accordion-button').on('click', function() {
                        let index = $(this).closest('.accordion-item').index();
                
                        // Update active image
                        $('.accordion-respective-image-container .image-div').removeClass('active');
                        $('.accordion-respective-image-container .image-div').eq(index).addClass('active');
                    });
                });
                `
            },
            {
                name: 'Carousal slider',
                position: 'footer',
                identifier: 'carousalSlider',
                script: `
                //$(document).ready(function() {
                        //$('.carousel').carousel({
                        //    interval: 2000  // Changes the slide every 2 seconds
                        //})
                //});
                `
            },
        ],
        "extra_unused": [
            
            {
                name: 'Form submit',
                position: 'footer',
                identifier: 'formSubmit',
                script: `
                window.onload = function() {
                    var forms = document.getElementsByTagName('form');
                    for (let i = 0; i < forms.length; i++) {
                        forms[i].addEventListener('submit', function(e) {
                            e.preventDefault(); // Prevent the form from submitting normally
                
                            var formData = new FormData(this); // Create a FormData object from the form                
                            formData["pageTitle"] = document.title;
                            formData["pageUrl"] = window.location.href;
                            
                            var fetchOptions = {
                                method: this.method,
                            };
                            var url = this.action;

                            if (this.method.toUpperCase() === 'GET' || this.method.toUpperCase() === 'HEAD') {
                                // Convert FormData to URLSearchParams
                                var urlParams = new URLSearchParams(Array.from(formData.entries()));
                                // Append the parameters to the URL
                                url += '?' + urlParams.toString();
                            } else {
                                fetchOptions.body = formData;
                            }
                
                            fetch(url, fetchOptions)
                            .then(response => response.json())
                            .then(data => {
                                alert(this.dataset.successMsg)
                                console.log(data);
                            })
                            .catch(error => {
                                alert(this.dataset.failureMsg)
                                console.log('Error: ' + error);
                            });
                        });
                    }
                };
                `,
            },
        ]
    }
    if(type && type.length>0){
        let selectedScripts = [];
    
        type.forEach(t => {
            if (scripts[t]) {
                selectedScripts = selectedScripts.concat(scripts[t]);
            }
        });
    
        if (identifiers !== false) {
            console.log(identifiers)
            selectedScripts = selectedScripts.filter(script => identifiers.includes(script.identifier));
        }
    
        selectedScripts = selectedScripts.filter(script => script.position === position);
    
        return selectedScripts.map(script => script.script).join('\n');
    }
    return '';
}