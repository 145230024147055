import React, {useEffect, useState} from 'react';
import { Block } from '../components/Block';
import DOMPurify from 'dompurify';
import HeroBlock from '../components/Blocks/HeroBlock';
import { Container } from 'react-bootstrap';
import BannerBlock from '../components/Blocks/BannerBlock';
import TestimonialBlock from '../components/Blocks/TestimonialBlock';
import ListBlock from '../components/Blocks/ListBlock';
import GoogleMapsBlock from '../components/Blocks/GoogleMapsBlock';
import CalendlyBlock from '../components/Blocks/CalendlyBlock';
import loadGoogleFonts from '../helpers/fonts';
import { getAboutBlock, getBannerBlock, getServicesBlock, getTestimonialsBlock } from '../helpers/prompts';

const SitePreview = ({websiteSettings}) => {

  // 
  
  // get the website if id or something is passed.
  // each sections push to the stuffs below.

  // create a timestamp
  // access the websites object from the localStorage and add the website name to it and timestamp as the id of the website with basic property like businessName, location, SiteSettings.
  // siteSettings to include the navBarColor, fontColor, headingColor, get this from chatGPT
  // generate a bannerBlock with chatGPT with backgroundImage from xxx
  // generate a services block with first calling few services and then generating content for it.
  // get the images in meanwhile for the sections
  // generate one testimonial
  // generate a contact form block
  // generate a map block
  // generate an hero for about block.

  // save all these.

  const [bannerData, setBannerData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);

  const [pageData, setPageData] = useState({
      "_id": "644759ffb29fcec827b43354",
      "Website": "644759ffb29fcec827b43350",
      "slug": null,
      "label": "Home",
      "showOnHeader": true,
      "showOnFooter": true,
      "default": true,
      "blocks": [],
      "order": 0,
      "createdAt": "2023-04-25T04:41:35.809Z",
      "seo": {
          "title": "Home Page",
          "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
          "keywords": "keyword",
          "headCode": "",
          "footerCode": "",
          "Image": null
      },
      "__v": 3
  })



  useEffect(() => {
    const fetchData = async () => {
      try {
        const name = 'Somistry';
        const industry = 'Florist';
        const banner = await getBannerBlock(name,industry);
        const about = await getAboutBlock(name,industry);
        const service = await getServicesBlock(name,industry);
        const testimonial = await getTestimonialsBlock(name,industry)

        const homePageBlocks = [banner,testimonial,service,about];

        let newPageData = pageData;
        newPageData['blocks'] = homePageBlocks;

        setPageData(newPageData);

        setBannerData(banner);
        setAboutData(about);
        setServicesData(service);
        setTestimonialData(testimonial);
        console.log(banner)
        console.log(about)
        console.log(service)
      } catch (error) {
        // Handle errors
      }
    };
    fetchData();
  },[])

  const chooseBlock = (block) =>  {        
    switch (block.WebsiteBlock.type) {
      case 'banner':
        return <BannerBlock block={block} />;
        case 'list':
          return <ListBlock block={block} />;
        case 'hero':
          return <HeroBlock block={block} />;        
        case 'quote':
          return <TestimonialBlock block={block} />;
        case 'calendly':
          return <CalendlyBlock block={block} />;
        default:
          return null;
      }
  }

  
  return (
    <>    
    {(pageData && pageData.blocks && pageData.blocks.length>0) && pageData.blocks.map((theBlockData) => (
      <>{chooseBlock(theBlockData)}</>
    ))}      
    </>
  );
};

export default SitePreview;
