import React, { useState } from 'react';

const GoogleCloudStorageForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN = process.env.REACT_APP_GCP_S_KEY;
    const YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN_FORMATTED = YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN.replace(/\\n/g, '\n');


    if (!file) {
      alert('Please select a file');
      return;
    }

    try {
      // Step 1: Create a Google Cloud Storage bucket
      const createBucketResponse = await fetch('https://www.googleapis.com/storage/v1/b', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN}`,
        },
        body: JSON.stringify({
          name: 'my-web-build-sa-bucket-'+Math.random(),
          location: 'us',
          storageClass: 'STANDARD',
        }),
      });

      if (!createBucketResponse.ok) {
        throw new Error('Failed to create Google Cloud Storage bucket');
      }

      // Step 2: Upload HTML file to the Google Cloud Storage bucket
      const { name: bucketName } = await createBucketResponse.json();
      const uploadFileResponse = await fetch(`https://www.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=my-file.html`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/html',
          Authorization: 'Bearer '+YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN,
        },
        body: file,
      });

      if (!uploadFileResponse.ok) {
        throw new Error('Failed to upload HTML file');
      }

      // Step 3: Configure static website hosting for the Google Cloud Storage bucket
      const configureWebsiteResponse = await fetch(`https://www.googleapis.com/storage/v1/b/${bucketName}/patch`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN,
        },
        body: JSON.stringify({
          website: {
            mainPageSuffix: 'index.html',
            notFoundPage: '404.html',
          },
        }),
      });

      if (!configureWebsiteResponse.ok) {
        throw new Error('Failed to configure static website hosting');
      }

      console.log('HTML file uploaded and static website hosting configured successfully');
    } catch (error) {
      console.error(error);
      console.log('An error occurred while interacting with Google Cloud Storage API');
      console.log(YOUR_GOOGLE_CLOUD_STORAGE_API_TOKEN)
    }
  };

  return (
    <div>
      <h2>Interact with Google Cloud Storage API</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload and Configure</button>
      </form>
    </div>
  );
};

export default GoogleCloudStorageForm;
