import React, { useState, useEffect } from 'react';

const DnsManagerForm = () => {
  const [domain, setDomain] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('A');
  const [value, setValue] = useState('');
  const [records, setRecords] = useState([]);
  const [provider, setProvider] = useState('cloudflare'); // or 'dnsimple' or 'godaddy'

  useEffect(() => {
    // Fetch existing DNS records when the component mounts or provider/domain changes
    const fetchRecords = async () => {
      try {
        const res = await fetch(`/api/${provider}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();
        setRecords(data);
      } catch (error) {
        console.error('Error fetching DNS records:', error);
      }
    };

    fetchRecords();
  }, [provider, domain]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const res = await fetch(`/api/${provider}`, {
        method: 'POST', // or 'PUT' for updating
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain, name, type, value }),
      });
      const data = await res.json();
      
      if (res.ok) {
        setRecords((prevRecords) => [...prevRecords, data]);
      } else {
        console.error('Error updating DNS records:', data);
      }
    } catch (error) {
      console.error('Error updating DNS records:', error);
    }
  };

  return (
    <div>
      <h4>DNS Manager</h4>      
      <form onSubmit={handleSubmit}>
        <div>
          <label>Provider: </label>
          <select value={provider} onChange={(e) => setProvider(e.target.value)}>
            <option value="cloudflare">Cloudflare</option>
            <option value="dnsimple">DNSimple</option>
            <option value="godaddy">GoDaddy</option>
          </select>
        </div>
        <div>
          <label>Domain: </label>
          <input className='form-control' type="text" value={domain} onChange={(e) => setDomain(e.target.value)} required />
        </div>
        <div>
          <label>Name (Subdomain): </label>
          <input className='form-control' type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label>Type: </label>
          <input className='form-control' type="text" value={type} onChange={(e) => setType(e.target.value)} required />
        </div>
        <div>
          <label>Value: </label>
          <input className='form-control' type="text" value={value} onChange={(e) => setValue(e.target.value)} required />
        </div>
        <button type="submit" className='form-control btn btn-primary'>Submit</button>
      </form>
      
      <h4>Existing Records</h4>
      <ul>
        {records.map((record, index) => (
          <li key={index}>
            {record.name} - {record.type} - {record.content}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DnsManagerForm;
