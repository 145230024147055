/*
import React, { useEffect } from 'react';
import WebFont from 'webfontloader';

export const DynamicFontComponent = ({ font }) => {
    /*
  useEffect(() => {
    //const link = document.createElement('link');
    //link.href = font.url;
    //link.rel = 'stylesheet';
    //document.head.appendChild(link);

    const url = font.url;

    // Extract the font families and weights from the URL
    const fontFamilies = [];
    const fontWeights = [];

    const matches = url.match(/family=([^|&]+)/g);

    if (matches) {
    matches.forEach((match) => {
        const fontFamilyMatch = match.match(/family=([^|&]+)/);
        const fontWeightMatch = match.match(/:(\d+)/);

        if (fontFamilyMatch && fontFamilyMatch[1]) {
        const fontFamily = fontFamilyMatch[1].replace(/\+/g, ' ');
        fontFamilies.push(fontFamily);
        }

        if (fontWeightMatch && fontWeightMatch[1]) {
        const fontWeight = parseInt(fontWeightMatch[1], 10);
        fontWeights.push(fontWeight);
        }
    });
    }

    // Load the extracted font families and weights with webfontloader
    WebFont.load({
    google: {
        families: fontFamilies,
        weights: fontWeights,
    },
    });
  }, [font]);
  *1/

  return (
    <div>
      <style>
        @import url(`${font.url}&display=swap`);
        {font.style}
        
      </style>
        <h1>Heading</h1>
        <body>Design Text</body>
    </div>
  );
};
*/

import React, { useEffect } from 'react';
import WebFont from 'webfontloader';

export const DynamicFontComponent = ({ font }) => {
    const url = font.url;
    const style = font.style;
  useEffect(() => {
    const fontFamilies = [];
    const fontWeights = [];

    const matches = url.match(/family=([^|&]+)/g);

    if (matches) {
      matches.forEach((match) => {
        const fontFamilyMatch = match.match(/family=([^|&]+)/);
        const fontWeightMatch = match.match(/:(\d+)/);

        if (fontFamilyMatch && fontFamilyMatch[1]) {
          const fontFamily = fontFamilyMatch[1].replace(/\+/g, ' ');
          fontFamilies.push(`${fontFamily}:${fontWeights.join(',')}`);
        }

        if (fontWeightMatch && fontWeightMatch[1]) {
          const fontWeight = parseInt(fontWeightMatch[1], 10);
          fontWeights.push(fontWeight);
        }
      });
    }
    WebFont.load({
        google: {
          families: fontFamilies,
          weights: fontWeights,
        },
        /*
        active: () => {
          const styleElement = document.createElement('style');
          styleElement.innerHTML = style;
          document.head.appendChild(styleElement);
        },
        */
      });
  }, [url, style]);
  
  const bodyStyleMatch = style.match(/body\s*\{([^}]+)\}/);
  //const h1StyleMatch = style.match(/h1\s*\{([^}]+)\}/);
  
    var h1StyleRegex = /h1[^{]*\{([^}]*)\}/;
    var h1StyleMatch = style.match(h1StyleRegex);

  const bodyStyle = bodyStyleMatch ? bodyStyleMatch[1] : '';
  const h1Style = h1StyleMatch ? h1StyleMatch[1] : '';

  let parsedBodyStyle = '';
  bodyStyle.split(';').forEach((styleProp,index) => {
    const [property, value] = styleProp.split(':');
    if (property && value) {
      parsedBodyStyle += property.trim() + ':' +value.trim() + ' !important;';
      //const propName = property.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
      //parsedBodyStyle[propName] = value.trim() + ' !important';
    }
  });

  let parsedH1Style = '';
  h1Style.split(';').forEach((styleProp,index) => {
    const [property, value] = styleProp.split(':');
    if (property && value) {
      parsedH1Style += property.trim() + ':' +value.trim() + ' !important;';
      
      //const propName = property.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
      //parsedH1Style[propName] = value.trim() + ' !important';
    }
  });

  //console.log(parsedBodyStyle,parsedH1Style)
  //const d = new Date();
  //let time = d.getTime();

  const getRandomNumberBetween = (max,min) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  let time = getRandomNumberBetween(9,999999999)

  return (<div>
    <style>{`
        .body${time}{${parsedBodyStyle.replaceAll('"','')}}
        .header${time}{${parsedH1Style.replaceAll('"','')}}
    `}</style>
    <h2 className={`header${time}`}>{font.name}</h2>
    <p className={`body${time}`} >
        The Body Text Style.
    </p>
    </div>);
};




/*
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const DynamicFontComponent = ({fontLink,fontStyle}) => {
    useEffect(() => {
      const link = document.createElement('link');
      link.href = `${fontLink}&display=swap`;
      link.rel = 'stylesheet';
  
      document.head.appendChild(link);
  
      return () => {
        document.head.removeChild(link);
      };
    }, []);
  
    return (
      <div>
        <Helmet>
          <style>{`${fontStyle}`}</style>
        </Helmet>
        <h1>Heading</h1>
        <body>Design Text</body>
      </div>
    );
  };
  */