import React, { useState, useEffect } from "react";
import axios from "axios";

const WebsiteBuilder = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await axios.get("/api/pages");
      setPages(response.data);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const createPage = async (pageData) => {
    try {
      const response = await axios.post("/api/pages", pageData);
      setPages((prevPages) => [...prevPages, response.data]);
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const createBlock = async (blockData, pageId) => {
    try {
      const response = await axios.post(`/api/pages/${pageId}/blocks`, blockData);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        const pageIndex = updatedPages.findIndex((page) => page._id === pageId);
        updatedPages[pageIndex].blocks.push(response.data);
        return updatedPages;
      });
    } catch (error) {
      console.error("Error creating block:", error);
    }
  };

  const updateBlockPosition = async (blockId, newPosition, pageId) => {
    try {
      await axios.put(`/api/pages/${pageId}/blocks/${blockId}`, { position: newPosition });
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        const pageIndex = updatedPages.findIndex((page) => page._id === pageId);
        const blockIndex = updatedPages[pageIndex].blocks.findIndex((block) => block._id === blockId);
        updatedPages[pageIndex].blocks[blockIndex].position = newPosition;
        return updatedPages;
      });
    } catch (error) {
      console.error("Error updating block position:", error);
    }
  };

  const renderBlock = (block, pageId) => {
    switch (block.type) {
      case "banner":
        return (
          <div>
            <h3>{block.name}</h3>
            {/* Render banner block content */}
          </div>
        );
      case "hero":
        return (
          <div>
            <h3>{block.name}</h3>
            {/* Render hero block content */}
          </div>
        );
      // Add cases for other block types
      default:
        return null;
    }
  };
return (
    <div>
      <header>
        <h1>Website Builder</h1>
      </header>
      <main>
        {pages.map((page) => (
          <div key={page._id}>
            <h2>{page.name}</h2>
            {page.blocks.map((block, index) => (
              <div key={block._id}>
                {renderBlock(block)}
                {index > 0 && (
                  <button onClick={() => updateBlockPosition(block._id, index - 1, page._id)}>Move Up</button>
                )}
              </div>
            ))}
            <button onClick={() => createBlock({ type: "banner", name: "New Banner" }, page._id)}>Add Banner Block</button>
            <button onClick={() => createBlock({ type: "hero", name: "New Hero" }, page._id)}>Add Hero Block</button>
            {/* Add buttons for other block types */}
          </div>
        ))}
      </main>
    </div>
  );
};

export default WebsiteBuilder;
