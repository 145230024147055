import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, TextField, TextareaAutosize, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const QuoteBlockForm = ({ data, onUpdate }) => {
  //const [quote, setQuote] = useState(data);
  const [quote, setQuote] = useState(data || {});


  const handleChange = (event) => {
    const { name, value } = event.target;
    setQuote({ ...quote, [name]: value });
  };

  const handleItemChangeOld = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = [...quote.items];
    updatedItems[index][name] = value;
    setQuote({ ...quote, items: updatedItems });
  };
  
  const handleItemChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = quote.items.map((item, i) => 
        i === index ? { ...item, [name]: value } : item
    );
    setQuote({ ...quote, items: updatedItems });
  };

  const handleItemDelete = (index,event) => {
    event.stopPropagation();
    const newItems = [...quote.items];
    newItems.splice(index, 1);
    setQuote({ ...quote, items: newItems });
  };
  
  const StyledAccordionSummary = styled(AccordionSummary)({
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const StyledIconButton = styled(IconButton)({
    //marginRight: 'auto',
    cursor: 'grab !important',
  });

  const handleAddItem = () => {
    const newItem = {
      content: "",
      by: "",
      image: {
        id: "",
        description: "",
        alt_description: "",
        url: "",
        author: "",
        authorLink: "",
        type: "unsplash",
        download: "",
        urlSmall: ""
      },
      idx: quote.items.length
    };
    setQuote({ ...quote, items: [...quote.items, newItem] });
  };

  const handleRemoveItem = (index) => {
    const updatedItems = quote.items.filter((item, i) => i !== index);
    setQuote({ ...quote, items: updatedItems });
  };

  //const handleSave = () => {
    //onUpdate(quote);
  //};
  const handleSave = () => {
    const updatedData = { ...data, ...quote };
    console.log('my updated',updatedData)
    onUpdate(updatedData);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Function to handle the image search
  const handleImageSearch = async () => {
    try {
      // Call the API with the search term
      const response = await fetch(`https://api.unsplash.com/search/photos?query=${searchTerm}&per_page=10&client_id=${process.env.REACT_APP_UNSPLASH_KEY}`);
      const data = await response.json();

      // Update the search results state
      setSearchResults(data.results);
    } catch (error) {
      console.error('Error occurred during image search:', error);
    }
  };

  // Function to select an image from the search results
  const handleSelectImage = (image) => {
    // Update the selected item with the chosen image
    /*
    const updatedItems = quote.items.map((item) =>
      item.idx === image.idx ? { ...item, image: { ...item.image, ...image } } : item
    );
    */
   console.log('image',image)
    const updatedItems = quote.items.map((item,index) =>
        index === image.idx ? { ...item, image: { 
            ...item.image,
            id: (image.image.id) ? image.image.id : null,
            description: (image.image.description) ? image.image.description : '',
            alt_description: (image.image.alt_description) ? image.image.alt_description : '',
            url: (image.image.urls && image.image.urls.regular) ? image.image.urls.regular : '',
            urlSmall: (image.image.urls && image.image.urls.thumb) ? image.image.urls.thumb : '',
            author: (image.image.user && image.image.user.name) ? image.image.user.name : '',
            authorLink: (image.image.user && image.image.user.links && image.image.user.links.html) ? image.image.user.links.html : '',
            type: 'unsplash',
            download: (image.image.links && image.image.links.download) ? image.image.links.download : '',
        } } : item
    );


    // Update the quote state with the updated items
    setQuote({ ...quote, items: updatedItems });

    // Clear the search term and search results
    setSearchTerm('');
    setSearchResults([]);
  };

  
  
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (event) => {
      event.preventDefault();
  };

  const handleDrop = async (event, targetIndex) => {
      event.preventDefault();
      const draggedIndex = event.dataTransfer.getData('text/plain');
      const draggedItem = quote.items[draggedIndex];

      const newItems = [...quote.items];
      newItems.splice(draggedIndex, 1);
      newItems.splice(targetIndex, 0, draggedItem);

      let newQuote = {... quote, items: newItems};
      setQuote(newQuote);
      onUpdate(newQuote); 
  };



  return (
    <div>
      <label>        
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Header'}
          name="header"
          value={quote.header}
          onChange={handleChange}
        />
      </label>
      <br />
      <GriderLayout 
        left={'Background Color:'}
        right={<input
          type="color"
          name="background"
          value={quote.background}
          onChange={handleChange}
        />}
      />
      <h4>Items:</h4>
      {quote.items.map((item, index) => (
        <Accordion
          key={index}                                       
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          onTouchStart={(event) => handleDragStart(event, index)}
          onTouchMove={handleDragOver}
          onTouchEnd={(event) => handleDrop(event, index)}
          >
        <StyledAccordionSummary>
          <StyledIconButton>
            <DragIndicatorIcon />
          </StyledIconButton>
          <Typography variant="h6">{item.by}</Typography>
          <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleItemDelete(index,event)}><DeleteIcon /></IconButton>
        </StyledAccordionSummary>
        <AccordionDetails>
        <div key={index}>
          {/*<h5>Item {index + 1}</h5>*/}
          <label>
            <TextField 
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Title'}
                name="title"
                value={item.title}
                onChange={(event) => handleItemChange(event, index)}
            />
          </label>
          <label>
            Content:
            <TextareaAutosize
                name="content"
                minRows={4}
                aria-label="Content"
                placeholder="Content"
                value={item.content}
                onChange={(event) => handleItemChange(event, index)}
                style={{width: '100%'}}
                />
          </label>
          <label>
            <TextField 
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'by'}
                name="by"
                value={item.by}
                onChange={(event) => handleItemChange(event, index)}
            />
          </label>

          {item.image && <ImageUploaderNew image={item.image} onUpdateImage={(e) => handleSelectImage({ idx: index, image: e })} />}

          {/*item.image && item.image.url && <img src={item.image.url} width={'100%'} />*/}

            {/* Image search input */}
            {/*<TextField 
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Search Images'}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for an image"
            />
            <button onClick={handleImageSearch}>Search</button>*/}

            {/* Render the search results */}
            {/*searchResults.map((result) => (
                <div key={`${result.id}-${index}`}>
                    <img src={result.urls.thumb} alt={result.alt_description} />
                    <button onClick={() => handleSelectImage({ idx: index, image: result })}>Select</button>
                </div>
            ))*/}


            {/* Render the list items */}
            {/*quote.items.map((item) => (
                <div key={item.idx}>
                {/* Render the item fields *1/}
                </div>
            ))*/}



          {/* Add more fields for other properties of the image */}
          <button onClick={() => handleRemoveItem(index)}>Remove Item</button>
        </div>
        </AccordionDetails>
        </Accordion>
      ))}
      <button onClick={handleAddItem}>Add Item</button>
        <br />                
      <GriderLayout left={'Use Block Version'} 
            right={<input
              type="checkbox"
              value={'block'}
              checked={(quote.version==='block')}
              onChange={(e) => setQuote({ ...quote, version: e.target.checked ? 'block' : 'old' })}
            />} 
          />
      <GriderLayout left={'Hide'} 
        right={<input
          type="checkbox"
          value={true}
          checked={quote.isHidden}
          onChange={(e) => setQuote({ ...quote, isHidden: e.target.checked})}
        />} 
      />
      <Button variant='outlined' color='primary' className='my-3' fullWidth onClick={handleSave}>Save</Button>
    </div>
  );
};

export default QuoteBlockForm;
