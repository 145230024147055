import React, {useState, useEffect} from 'react';
import { Route, Routes, Navigate, Outlet, redirect, useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Container } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { usePlatformTheme } from '../NEW/contexts/PlatformThemeContext';

const PrivateRoute = ({user, children}) => {
    const [session, setSession] = useState(null)

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
      })

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
      })

      return () => subscription.unsubscribe()
    }, [])

  
    const navigate = useNavigate();

    const {platformTheme} = usePlatformTheme();
    
    // const auth = null; // determine if authorized, from context or however you're doing it
    // const usersupabase = supabase.auth.session();
    //user = true;
    if (user === null) {
        //return <Navigate to="/login" />;//<div>Loading...</div>;
    }

    console.log(user)

    const RedirectLogin = () => {
        return redirect('./login')
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    //return user && user!==null ? <Outlet /> : <Navigate to="/login" />;
    if(!session){
        console.log('gone here')
        //return <Navigate to="/login" replace />
       // return <RedirectLogin />;
        //return navigate('/login');
        //window.location.href = '/dashboard/login'
        if (!session) {
          return (
            <section className='vh-100'>
              <Container maxWidth='100%' style={{paddingLeft: 0, paddingRight: 0}}>
                <Row className="align-items-center" style={{margin: 0}}>
                  <Col className='vh-100 align-items-center bg-dark text-light d-flex col-md-8 text-center'>
                        <img src='https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/a-website-builder-with-a-girl.svg' style={{verticalAlign: 'middle', height: '50%'}} className='mx-auto d-block' />
                  </Col>
                  <Col className='py-5 col-md-4 px-5 order-first'>    {/*appearance={{ theme: ThemeSupa }}*/}
                      <div className='d-flex align-items-center'>
                        {(platformTheme && platformTheme.logo_url) ?
                          <div className="pt-5">
                            <img src={platformTheme.logo_url} height="40" /> 
                            + 
                            <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" style={{width: 60}} className='p-2 ' />
                            <span className='h3 fw-bold'>
                              ContentSimi Websites
                            </span>
                          </div>
                          : 
                          <div className="pt-5">
                            <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" alt="logo" style={{width: 60}} className='p-2 ' />
                            <span className='h3 fw-bold'>
                              ContentSimi Websites
                            </span>
                          </div>
                        }
                      </div>
                    <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa, style: { label: {fontSize: '1.2rem'}, input: {fontSize: '1.2rem'}, a: { fontSize: '1.2rem'} } }} providers={[]} />
                  </Col>
                </Row>
              </Container>
            </section>
          )
        }
    } else{
        return children;
    }
}

/*
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Routes>
    <Route
      {...rest}
      element={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Navigate to="/login" />
        )
      }
    />
    </Routes>
  );
};
*/


export default PrivateRoute;