// src/TemplatingParser.js
/*
export function parseTemplate(template, data) {
    let output = template;

    // Replace variables
    const variablePattern = /{{\s*(\w+)\s*}}/g;
    output = output.replace(variablePattern, (_, variableName) => {
        return data[variableName] || '';
    });

    // Replace loops
    const loopPattern = /{%\s*loop\s*(\w+)\s*%}([\s\S]*?){%\s*endloop\s*%}/g;
    output = output.replace(loopPattern, (_, loopVariable, loopContent) => {
        const items = data[loopVariable] || [];
        return items.map(item => {
            return parseTemplate(loopContent, { ...data, [loopVariable]: item });
        }).join('');
    });

    return output;
}
*/

// src/TemplatingParser.js
/*
export function parseTemplate(template, data) {
    let output = template;

    // Replace variables
    const variablePattern = /{{\s*(\w+(?:\.\w+)?)\s*}}/g;
    output = output.replace(variablePattern, (_, variablePath) => {
        return resolveVariablePath(data, variablePath);
    });

    // Replace loops
    const loopPattern = /{%\s*loop\s*(\w+)\s*as\s*(\w+)\s*%}([\s\S]*?){%\s*endloop\s*%}/g;
    output = output.replace(loopPattern, (_, loopVariable, loopAlias, loopContent) => {
        const items = data[loopVariable] || [];
        return items.map(item => {
            return parseTemplate(loopContent, { ...data, [loopAlias]: item });
        }).join('');
    });

    return output;
}

function resolveVariablePath(data, variablePath) {
    const steps = variablePath.split('.');
    let value = data;

    for (const step of steps) {
        value = value[step];
        if (!value) break;
    }

    return value || '';
}
*/

// src/TemplatingParser.js
/*
export function parseTemplate(template, data) {
    let output = template;

    // Replace variables
    const variablePattern = /{{\s*(\w+(?:\.\w+)?)\s*}}/g;
    output = output.replace(variablePattern, (_, variablePath) => {
        return resolveVariablePath(data, variablePath);
    });

    // Replace loops
    const loopPattern = /{%\s*loop\s*(\w+)\s*as\s*(\w+)\s*%}((?:.|\n)*?){%\s*endloop\s*%}/g;
    output = output.replace(loopPattern, (_, loopVariable, loopAlias, loopContent) => {
        const items = data[loopVariable] || [];
        return items.map(item => {
            return parseTemplate(loopContent, { ...data, [loopAlias]: item });
        }).join('');
    });

    return output;
}

function resolveVariablePath(data, variablePath) {
    const steps = variablePath.split('.');
    let value = data;

    for (const step of steps) {
        value = value[step];
        if (!value) break;
    }

    return value || '';
}
*/

// src/TemplatingParser.js

/*
export function parseTemplate(template, data) {
    let output = template;

    // Replace loops first to handle nested properties correctly
    const loopPattern = /{%\s*loop\s*(\w+)\s*as\s*(\w+)\s*%}((?:.|\n)*?){%\s*endloop\s*%}/g;
    output = output.replace(loopPattern, (_, loopVariable, loopAlias, loopContent) => {
        const items = data[loopVariable] || [];
        return items.map(item => {
            return parseTemplate(loopContent, { ...data, [loopAlias]: item });
        }).join('');
    });

    // Then replace variables
    const variablePattern = /{{\s*(\w+(?:\.\w+)?)\s*}}/g;
    output = output.replace(variablePattern, (_, variablePath) => {
        return resolveVariablePath(data, variablePath);
    });

    return output;
}

function resolveVariablePath(data, variablePath) {
    const steps = variablePath.split('.');
    let value = data;

    for (const step of steps) {
        if (typeof value !== 'object' || value === null) {
            return '';
        }
        value = value[step];
    }

    return value !== undefined ? value : '';
}
*/
/*
export const parseTemplateOld = (template, data) => {
    const regex = /\{\{([a-z0-9_.\[\]]+)\}\}/gi;
    const loopRegex = /{% loop ([a-z0-9_.\[\]]+) as (\w+) %}([\s\S]*?){% endloop %}/gi;
  
    const getValueFromDataOld = (path, data) => {
      const steps = path.split('.');
      let currentValue = data;
      for (let step of steps) {
        if (step.endsWith(']')) {
          const parts = step.split('[');
          currentValue = currentValue[parts[0]][parseInt(parts[1])];
        } else {
          currentValue = currentValue[step];
        }
      }
      return currentValue;
    };

    const getValueFromDataOld2 = (path, data) => {
        const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
        const steps = path.split('.');
        let currentValue = data;
        for (let step of steps) {
          const sliceMatch = sliceRegex.exec(step);
          if (sliceMatch) {
            const arrayName = sliceMatch[1];
            const startIdx = sliceMatch[2] ? parseInt(sliceMatch[2]) : 0;
            const endIdx = sliceMatch[3] ? parseInt(sliceMatch[3]) : currentValue[arrayName].length;
            currentValue = currentValue[arrayName].slice(startIdx, endIdx);
          } else if (step.endsWith(']')) {
            const parts = step.split('[');
            currentValue = currentValue[parts[0]][parseInt(parts[1].replace(']', ''))];
          } else {
            currentValue = currentValue[step];
          }
        }
        return currentValue;
      };

      const getValueFromDataOld3 = (path, data) => {
        const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
        const steps = path.split('.');
        let currentValue = data;
        for (let step of steps) {
          const sliceMatch = sliceRegex.exec(step);
          if (sliceMatch) {
            const arrayName = sliceMatch[1];
            const startIdx = sliceMatch[2] ? parseInt(sliceMatch[2]) : 0;
            const endIdx = sliceMatch[3] ? parseInt(sliceMatch[3]) : currentValue[arrayName]?.length;
            currentValue = currentValue[arrayName]?.slice(startIdx, endIdx) ?? '';
          } else if (step.endsWith(']')) {
            const parts = step.split('[');
            const index = parseInt(parts[1].replace(']', ''));
            currentValue = currentValue?.[parts[0]]?.[index] ?? '';
          } else {
            currentValue = currentValue?.[step] ?? '';
          }
          // If currentValue is ever undefined, return an empty string
          if (currentValue === undefined) {
            return '';
          }
        }
        return currentValue;
      };
      
      const getValueFromData = (path, data) => {
        const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
        const steps = path.split('.');
        let currentValue = data;
        for (let step of steps) {
          if (sliceRegex.test(step)) {
            const [_, arrayName, start, end] = sliceRegex.exec(step);
            const startIdx = start ? parseInt(start) : 0;
            const endIdx = end ? parseInt(end) : undefined;
            currentValue = (currentValue[arrayName] ?? []).slice(startIdx, endIdx);
          } else if (step.includes('[')) {
            const [arrayName, index] = step.split('[');
            currentValue = (currentValue[arrayName] ?? [])[parseInt(index)];
          } else {
            currentValue = currentValue?.[step];
          }
          if (currentValue === undefined) {
            return '';
          }
        }
        return currentValue ?? '';
      };
      
  
    // Handling loops
    template = template.replace(loopRegex, (_, loopVar, iteratorName, loopContent) => {
      const loopData = getValueFromData(loopVar, data);
      if (!Array.isArray(loopData)) {
        return '';  // Error: Loop variable is not an array
      }
      return loopData.map(item => {
        let processedContent = loopContent;
        const matches = [...loopContent.matchAll(regex)];
        for (let match of matches) {
          const fullPath = match[1];
          if (fullPath.startsWith(`${iteratorName}.`)) {
            const itemPath = fullPath.substring(iteratorName.length + 1);
            const value = getValueFromData(itemPath, item);
            processedContent = processedContent.replace(match[0], value);
          }
        }
        return processedContent;
      }).join('');
    });
  
    // Handling direct replacements
    template = template.replace(regex, (_, path) => {
      return getValueFromData(path, data);
    });
  
    return template;
  };

  export const parseTemplateOld2 = (template, data) => {
    const regex = /\{\{([a-z0-9_.\[\]]+)\}\}/gi;
    const loopRegex = /{% loop ([a-z0-9_.\[\]]+) as (\w+) %}([\s\S]*?){% endloop %}/gi;
  
    const getValueFromDataOld = (path, data) => {
      const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
      const steps = path.split('.');
      let currentValue = data;
      for (let step of steps) {
        if (sliceRegex.test(step)) {
          const [_, arrayName, start, end] = sliceRegex.exec(step);
          const startIdx = start ? parseInt(start) : 0;
          const endIdx = end ? parseInt(end) : undefined;
          currentValue = (currentValue[arrayName] ?? []).slice(startIdx, endIdx);
        } else if (step.includes('[')) {
          const [arrayName, index] = step.split('[');
          currentValue = (currentValue[arrayName] ?? [])[parseInt(index)];
        } else {
          currentValue = currentValue?.[step];
        }
        if (currentValue === undefined) {
          return '';
        }
      }
      return currentValue ?? '';
    };

    const getValueFromData = (path, data) => {
        const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
        const steps = path.split('.');
        let currentValue = data;
        for (let step of steps) {
          if (sliceRegex.test(step)) {
            const match = sliceRegex.exec(step);
            const arrayName = match[1];
            const startIdx = match[2] !== '' ? parseInt(match[2]) : 0;
            const endIdx = match[3] !== '' ? parseInt(match[3]) : undefined;
            currentValue = currentValue[arrayName] ? currentValue[arrayName].slice(startIdx, endIdx) : [];
          } else if (step.endsWith(']')) {
            const parts = step.split('[');
            const index = parseInt(parts[1].slice(0, -1));
            currentValue = (currentValue[parts[0]] || [])[index];
          } else {
            currentValue = currentValue[step];
          }
          if (currentValue === undefined) {
            return '';
          }
        }
        return currentValue;
      };
      
  
    // Handling loops
    template = template.replace(loopRegex, (_, loopVar, iteratorName, loopContent) => {
      const loopData = getValueFromData(loopVar, data);
      if (!Array.isArray(loopData)) {
        return ''; // Error: Loop variable is not an array
      }
      return loopData.map(item => {
        let processedContent = loopContent;
        const matches = [...loopContent.matchAll(regex)];
        for (let match of matches) {
          const fullPath = match[1];
          if (fullPath.startsWith(`${iteratorName}.`)) {
            const itemPath = fullPath.substring(iteratorName.length + 1);
            const value = getValueFromData(itemPath, item);
            processedContent = processedContent.replace(match[0], value);
          }
        }
        return processedContent;
      }).join('');
    });
  
    // Handling direct replacements
    template = template.replace(regex, (_, path) => {
      return getValueFromData(path, data);
    });
  
    return template;
  };
*//*
  export const parseTemplateOld3 = (template, data) => {
    const regex = /\{\{([a-z0-9_.\[\]]+)\}\}/gi;
    const loopRegex = /{% loop ([a-z0-9_.\[\]]+) as (\w+) %}([\s\S]*?){% endloop %}/gi;
    const sliceRegex = /(\w+)\[(\d*):(\d*)\]/; // Regex to match the slice syntax
  
    const getValueFromData = (path, data) => {
      // Check for slice syntax and split into segments if present
      const sliceMatch = sliceRegex.exec(path);
      if (sliceMatch) {
        const [_, arrayName, start, end] = sliceMatch;
        const arrayData = data[arrayName] || [];
        const startIdx = start ? parseInt(start) : 0;
        const endIdx = end ? parseInt(end) : arrayData.length;
        return arrayData.slice(startIdx, endIdx);
      }
  
      // Normal path processing
      const steps = path.split('.');
      let currentValue = data;
      for (let step of steps) {
        currentValue = currentValue?.[step];
        if (currentValue === undefined) {
          return '';
        }
      }
      return currentValue;
    };
    // This is a utility function to escape special characters for use in a regular expression
    function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
    
    // Handling loops with slicing
    template = template.replace(loopRegex, (fullMatch, loopVar, iteratorName, loopContent) => {
    // Check if loopVar contains slicing
    const sliceMatch = loopVar.match(sliceRegex);
    let loopData = [];
  
    if (sliceMatch) {
      const [_, baseVar, startSlice, endSlice] = sliceMatch;
      const baseData = getValueFromData(baseVar, data);
      if (!Array.isArray(baseData)) {
        return ''; // Error: Base variable is not an array
      }
      // Parse slice indices, defaulting to 0 for start and array length for end if not specified
      const startIdx = startSlice ? parseInt(startSlice, 10) : 0;
      const endIdx = endSlice ? parseInt(endSlice, 10) : baseData.length;
      loopData = baseData.slice(startIdx, endIdx);
    } else {
      // No slicing, get the whole array
      loopData = getValueFromData(loopVar, data);
    }
  
    if (!Array.isArray(loopData)) {
      return ''; // Error: Loop variable is not an array
    }
  
    return loopData.map(item => {
      let processedContent = loopContent;
      const matches = [...loopContent.matchAll(regex)];
      for (let match of matches) {
        const fullPath = match[1];
        if (fullPath.startsWith(`${iteratorName}.`)) {
          const itemPath = fullPath.substring(iteratorName.length + 1);
          const value = getValueFromData(itemPath, item);
          processedContent = processedContent.replace(new RegExp(escapeRegExp(match[0]), 'g'), value);
        }
      }
      return processedContent;
    }).join('');
  });
  
  
    // Handling direct replacements
    template = template.replace(regex, (_, path) => {
      return getValueFromData(path, data);
    });
  
    return template;
  };
  */
  export const parseTemplate = (template, data) => {
    const regex = /\{\{([a-z0-9_.\[\]]+)\}\}/gi;
    const loopRegex = /{% loop ([a-z0-9_]+)(?:\[(\d*):(\d*)\])? as (\w+) %}([\s\S]*?){% endloop %}/gi;
  
    const getValueFromData = (path, data) => {
      const steps = path.split('.');
      let currentValue = data;
      for (let step of steps) {
        if (step.endsWith(']')) {
          const parts = step.split('[');
          const index = parseInt(parts[1].slice(0, -1), 10);
          currentValue = (currentValue?.[parts[0]] ?? [])[index];
        } else {
          currentValue = currentValue?.[step];
        }
        if (currentValue === undefined) {
          return '';
        }
      }
      return currentValue;
    };
  
    // Handling loops with optional slicing
template = template.replace(loopRegex, (fullMatch, arrayName, start, end, iteratorName, loopContent) => {
    let loopData = getValueFromData(arrayName, data);
  
    // Process slicing if start and end are provided
    if (start !== undefined || end !== undefined) {
      const startIndex = start ? parseInt(start, 10) : 0;
      const endIndex = end ? parseInt(end, 10) : loopData.length;
      loopData = loopData.slice(startIndex, endIndex);
    }
  
    // Ensure loopData is an array
    if (!Array.isArray(loopData)) {
      return ''; // Error: Loop variable is not an array or undefined
    }
  
    return loopData.map(item => {
      let processedContent = loopContent;
      const matches = [...loopContent.matchAll(regex)];
      for (let match of matches) {
        const fullPath = match[1];
        if (fullPath.startsWith(`${iteratorName}.`)) {
          const itemPath = fullPath.substring(iteratorName.length + 1);
          const value = getValueFromData(itemPath, item);
          processedContent = processedContent.replace(new RegExp(escapeRegExp(match[0]), 'g'), value);
        }
      }
      return processedContent;
    }).join('');
  });
  
    // Handling direct replacements
    template = template.replace(regex, (_, path) => {
      return getValueFromData(path, data);
    });
  
    return template;
  };
  
  // Helper function to escape special characters in a string for use in a regular expression
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  