import { Accordion, AccordionDetails, AccordionSummary, Typography, TextField, TextareaAutosize, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import AIWritingGenerator from './AIWritingGenerator';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const TeamForm = ({ data, onUpdate }) => {
  const [listData, setListData] = useState(data || {});
  const [isDragging, setIsDragging] = useState(false);

  const handleItemChange = (index, field, value) => {
    console.log('index',index,'field',field,'value',value);
    const updatedItems = [...listData.items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setListData({ ...listData, items: updatedItems });
  };
  const handleItemDelete = (index,event) => {
    event.stopPropagation();
    const newItems = [...listData.items];
    newItems.splice(index, 1);
    setListData({ ...listData, items: newItems });
  };

  const handleSave = () => {
    onUpdate(listData);
  };
  
  const StyledAccordionSummary = styled(AccordionSummary)({
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const StyledIconButton = styled(IconButton)({
    //marginRight: 'auto',
    cursor: 'grab !important',
  });

  const handleAddItem = () => {
    const newItem = {
      name: "",
      content: "",
      idx: listData.items.length,
      button: {
          label: "More info",
          type: "url",
          link: "#",
      },
      image: {
          id: "",
          description: "",
          alt_description: "",
          url: "",
          author: "",
          authorLink: "",
          type: "unsplash",
          download: "",
          urlSmall: ""
      }
    };
    setListData({ ...listData, items: [...listData.items, newItem] });
  };

  
  const handleSelectImage = (image) => {
   console.log('image',image)
    const updatedItems = listData.items.map((item,index) =>
        index === image.idx ? { ...item, image: {
            ...item.image,
            id: (image.image.id) ? image.image.id : null,
            description: (image.image.description) ? image.image.description : '',
            alt_description: (image.image.alt_description) ? image.image.alt_description : '',
            url: (image.image.urls && image.image.urls.regular) ? image.image.urls.regular : '',
            urlSmall: (image.image.urls && image.image.urls.thumb) ? image.image.urls.thumb : '',
            author: (image.image.user && image.image.user.name) ? image.image.user.name : '',
            authorLink: (image.image.user && image.image.user.links && image.image.user.links.html) ? image.image.user.links.html : '',
            type: 'unsplash',
            download: (image.image.links && image.image.links.download) ? image.image.links.download : '',
        } } : item
    );
    setListData({ ...listData, items: updatedItems });
  };
  
  const handleDragStart = (event, index) => {
    event.stopPropagation();
    event.dataTransfer.setData('text/plain', index);
    setIsDragging(true);
};

const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
};
/*
const handleDrop = async (event, targetIndex) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData('text/plain');
    const draggedItem = listData.items[draggedIndex];

    const newItems = [...listData.items];
    newItems.splice(draggedIndex, 1);
    newItems.splice(targetIndex, 0, draggedItem);

    let newListData = listData;
    newListData['items'] = newItems;
    setListData(newListData);
    onUpdate(newListData); 
};
*/

const handleDrop = async (event, targetIndex) => {
  event.preventDefault();
  const draggedIndex = event.dataTransfer.getData('text/plain');
  const draggedItem = listData.items[draggedIndex];
  const targetItem = listData.items[targetIndex];

  const newItems = [...listData.items];
  newItems[draggedIndex] = targetItem;
  newItems[targetIndex] = draggedItem;

  let newListData = {...listData, items: newItems};
  setListData(newListData);
  onUpdate(newListData); 
};


  return (
    <div>
      <GriderLayout left={'Background'} 
        right={<input
          type="color"
          value={listData.background}
          onChange={(e) => setListData({ ...listData, background: e.target.value })}
        />} 
      />
      
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Headline'}
        value={listData.headline}
        onChange={(e) => setListData({ ...listData, headline: e.target.value })}
      />
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Content'}
        value={listData.content}
        onChange={(e) => setListData({ ...listData, content: e.target.value })}
      />
      {/* Include other props here */}
      {listData.items &&
        listData.items.map((item, index) => (
        <>
        <Accordion
          key={index}                               
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          onTouchStart={(event) => handleDragStart(event, index)}
          onTouchMove={handleDragOver}
          onTouchEnd={(event) => handleDrop(event, index)}
          //ref={drop} 
          style={{ opacity: isDragging ? 0.5 : 1 }}
          >
            
        <StyledAccordionSummary
          //expandIcon={}
        >
          <StyledIconButton>
            <DragIndicatorIcon />
          </StyledIconButton>
          <Typography variant="h6">{item.name}</Typography>
          <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleItemDelete(index,event)}><DeleteIcon /></IconButton>
        </StyledAccordionSummary>
        {/*
        <AccordionSummary>
          <Typography variant="h6">{item.name}</Typography>
        </AccordionSummary>
        */}
        <AccordionDetails>
          <div key={index}>
            <TextField
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Name'}
                value={item.name}
                onChange={(e) => handleItemChange(index, 'name', e.target.value)}
            />
            <TextField
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'designation'}
                value={item.designation}
                onChange={(e) => handleItemChange(index, 'designation', e.target.value)}
            />
            <label>About:</label>
            <AIWritingGenerator props={{blockType: 'list', data: item, toUpdate: 'item.about'}} onUpdate={(e) => handleItemChange(index, 'about', e)} />
            <TextareaAutosize
                name="about"
                minRows={4}
                style={{width: '100%'}}
                aria-label="About"
                placeholder="About"
                value={item.about}
                onChange={(e) => handleItemChange(index, 'about', e.target.value)}
            />
            <label>Image:</label>
          <ImageUploaderNew image={item.image} onUpdateImage={(e) => handleSelectImage({idx: index, image: e})} />
          </div>
          </AccordionDetails>
        </Accordion>
        </>
        ))}
        <button onClick={handleAddItem}>Add Item</button>
        <br />                    
        <GriderLayout left={'Use Block Version'} 
            right={<input
              type="checkbox"
              value={'block'}
              checked={(listData.version==='block')}
              onChange={(e) => setListData({ ...listData, version: e.target.checked ? 'block' : 'old' })}
            />} 
          />
        <GriderLayout left={'Hide'} 
          right={<input
            type="checkbox"
            value={true}
            checked={listData.isHidden}
            onChange={(e) => setListData({ ...listData, isHidden: e.target.checked})}
          />} 
        />
      <Button variant='outlined' color='primary' className='my-5' fullWidth onClick={handleSave}>Save</Button>
    </div>
  );
};

export default TeamForm;
