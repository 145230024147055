import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { hexToRgba } from '../../helpers/colors';
import { cleanSubmenus, generateSlug } from '../../helpers/randoms';


//import styled from 'styled-components';
//import { Link } from 'react-router-dom';

//import styled from '@emotion/styled';
const renderSubmenus = (submenus,renderIndex) => {
  if (!submenus || submenus.length === 0) {
    return null;
  }
  return (
    <ul className="dropdown-menu" key={renderIndex}>
      {submenus.map((submenu, index) => (
        <React.Fragment key={index+renderIndex}>
          <div key={index+renderIndex}><a className="dropdown-item" href={`${(submenu.link) ? submenu.link : generateSlug(submenu.menu)}`} target={`${(submenu.openInNewTab) ? '_blank' : '_self'}`}>{submenu.menu}</a></div>
          {renderSubmenus(submenu.submenus,index)}
        </React.Fragment>
      ))}
    </ul>
  );
};

const HeaderNavigationBlock = ({websiteSettings, menus, designType}) => {
    let NavContainer = {
        backgroundColor: '#fcc',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
      }
      
      const NavLinks = {
        display: 'flex',
        justifyContent: 'space-between',
        //maxWidth: '600px',
        //width: '100%',
      };
      
      const NavLink = {
        color: '#fff',
        margin: '0 10px',
        textDecoration: 'none',
      }
    
      

    const settings = (websiteSettings) ? websiteSettings : JSON.parse(localStorage.getItem('websiteSettings'));
    //console.log('update');
    if(settings && settings.navigationColor){
      //console.log('update in');      
      // transparent Nav for Better Stuffs
      const hexCode = settings.navigationColor;
      const opacity = 0.5;
      const rgbaValue = hexToRgba(hexCode, opacity);
      NavContainer['backgroundColor'] = rgbaValue;
    }
    NavContainer['margin'] = 0;
    NavContainer['zIndex'] = '1000 !important';

    /*
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr className="dropdown-divider"></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
    */

        /*
    // Recursive component to render submenus
  const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
      const handleWindowClick = () => {
        setOpenDropdown(false);
      };
  
      window.addEventListener('click', handleWindowClick);
  
      return () => {
        window.removeEventListener('click', handleWindowClick);
      };
    }, []);
  
    const handleDropdownToggle = () => {
      setOpenDropdown(!openDropdown);
    };
    useLayoutEffect(() => {
  
      const dropdownElement = document.getElementById('menu-dropdown');
  
      if (dropdownElement) {
        dropdownElement.addEventListener('click', handleDropdownToggle);
      }
  
      return () => {
        if (dropdownElement) {
          dropdownElement.removeEventListener('click', handleDropdownToggle);
        }
      };
    }, [openDropdown]);
    */
    menus = menus.map(item => cleanSubmenus(item));
    console.log('menus',menus)
  return (
    <div style={(designType==='fullblown') ? {} : NavContainer}>
      <div style={(designType==='fullblown') ? {} : NavLinks}>
        {/**<a style={NavLink} href="/">Home</a>
        <a style={NavLink} href="#about">About</a>
        <a style={NavLink} href="#services">Services</a>
        <a style={NavLink} href="#contact">Contact</a>
        {menus && menus.map((menu,key) => <a style={NavLink} href={menu.link} key={key}>{menu.label}</a>)}
        **/}
        
        <nav key={24} style={{zIndex: 1000}} className={`navbar navbar-expand-lg ${(designType==='fullblown') ? 'navbar-dark fixed-top sticky-top' : ''}`}>
        {/*<a className="navbar-brand" href="#">Navbar</a>*/}
        <div className="container-fluid">
        {/*<span className="navbar-brand"></span>*/}
        <button className="navbar-toggler collapsed ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
        <div className="navbar-nav ms-auto mb-2 mb-lg-0">
          {menus.map((item, index) =>
            item.submenus ? (
              <div className="nav-item dropdown" key={index} style={(designType==='fullblown') ? {} : NavLink}>
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={(designType==='fullblown') ? {} : NavLink}>
                  {item.menu}
                </a>
                {renderSubmenus(item.submenus,index)}
              </div>
            ) : (
              <div className="nav-item" style={(designType==='fullblown') ? {} : NavLink}>
                <a className="nav-link" aria-current="page" href={`${(item.link) ? item.link : generateSlug(item.menu)}`} target={`${(item.openInNewTab) ? '_blank' : '_self'}`} key={index} style={(designType==='fullblown') ? {} : NavLink}>{item.menu}</a>
              </div>
            )
          )}
          </div>
          </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderNavigationBlock;
