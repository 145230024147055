import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MessageComponent() {
  const [message, setMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    // Simulating message change
    const timer = setInterval(() => {
      setMessage('New message');
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (message) {
      setShowSnackbar(true);
    }
  }, [message]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MessageComponent;
