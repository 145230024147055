import React, { useContext, useState } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import { MessageContext } from '../contexts/MessageContext';

const MessageDisplayer = () => {
  const { messages } = useContext(MessageContext);
  console.error(messages);
  //const [open, setOpen] = useState(false);
  if(messages && messages.length>0){
    //setOpen(true)
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={(messages.length > 0)}
      //open={open}
      autoHideDuration={5000}
      message={messages}
    >
      {/*messages.map((message, index) => (
        <React.Fragment key={index}>
          <SnackbarContent
            message={message}
            style={{ backgroundColor: 'red' }} // Customize the style here
          />
        </React.Fragment>
      ))*/}
    </Snackbar>
  );
};

export default MessageDisplayer;



/*
import React, { useContext } from 'react';
//import { Snackbar, SnackbarContent } from '@mui/material';
import { MessageContext } from '../contexts/MessageContext';
import { Snackbar, SnackbarContent } from '@mui/material';

const MessageDisplayer = () => {
  const { messages } = useContext(MessageContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={messages.length > 0}
      autoHideDuration={5000}
    >
      {messages.map((message, index) => (
        <SnackbarContent
          key={index}
          message={message}
          //style={{ backgroundColor: 'red' }} // Customize the style here
        />
      ))}
    </Snackbar>
  );
};

export default MessageDisplayer;
*/

/*
import React, { useContext } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import { MessageContext } from '../contexts/MessageContext';

const MessageDisplayer = () => {
  const { messages } = useContext(MessageContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={messages.length > 0}
      autoHideDuration={5000}
    >
        {messages.map((message, index) => (
        <SnackbarContent
            key={index}
            message={message}
            //style={{ backgroundColor: 'red' }} // Customize the style here
        />
        ))}
    </Snackbar>
  );
};

export default MessageDisplayer;
*/