import { Skeleton } from "@mui/material";
import { Col, Row } from "react-bootstrap";


  export const getAllAnnouncementPositions = () => {
    const fonts = [
      {
        name: 'Top Banner',
        design: <>
        <Row className="bg-light border" style={{height: '120px'}}>
          <Col variant='md-2' className="my-2">
            <Skeleton variant="rectangular" height={10} animation={false} />
          </Col>
        </Row>
        </>,
      },
      {
        name: 'Center Expanded',
        design: <>
        <Row className="bg-light border" style={{height: '120px'}}>
          <Col variant='md-2' className="my-2 v-align-middle text-center">
            <Skeleton variant="rectangular" height={50} width={'50%'} animation={false} className="mx-auto" />
          </Col>
        </Row>
        </>,
      },          
    ];
    return fonts;
  }