import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

function NewBlock() {
  const [fetchedData, setFetchedData] = useState(null);
  const [dynamicBlocks, setDynamicBlocks] = useState([]);

  useEffect(() => {
    fetchDataFromChatGPT()
      .then(data => setFetchedData(data))
      .catch(error => console.error(error));
  }, []);

  const fetchDataFromChatGPT = async () => {
    // Perform API call to ChatGPT and get the data
    // For example:
    //const response = await fetch('https://api.example.com/chatgpt');
    const data = []; //await response.json();
    return data;
  };

  const addDynamicBlock = () => {
    const newBlock = `Dynamic Block ${dynamicBlocks.length + 1}`;
    setDynamicBlocks(prevBlocks => [...prevBlocks, newBlock]);
  };

  const deleteDynamicBlock = (index) => {
    setDynamicBlocks(prevBlocks => prevBlocks.filter((_, i) => i !== index));
  };

  return (
    <div>
      {fetchedData && (
        <div>
          <h2>Fetched Data</h2>
          <p>{fetchedData}</p>
        </div>
      )}

      <button onClick={addDynamicBlock}>Add Dynamic Block</button>

      {dynamicBlocks.map((block, index) => (
        <div key={index}>
          <h2>{block}</h2>
          <p>Content for {block}</p>
          <button onClick={() => deleteDynamicBlock(index)}>Delete Block</button>
        </div>
      ))}
    </div>
  );
}

export default NewBlock;
