import React from 'react';
import { Resizable } from 'react-resizable';
import { useNode } from '@craftjs/core';

export const BoxComponent = ({ children, style }) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
    props
  } = useNode();

  return (
    <Resizable
      width={props?.width || 200}
      height={props?.height || 200}
      onResizeStop={(e, data) => {
        setProp(props => {
          props.width = data?.size?.width;
          props.height = data?.size?.height;
        });
      }}
    >
      <div ref={ref => connect(drag(ref))} style={{ ...style, width: props?.width || 200, height: props?.height || 200 }}>
        {children}
      </div>
    </Resizable>
  );
};

BoxComponent.craft = {
  props: {
    width: 200,
    height: 200,
    style: { border: '1px solid' }
  }
};
