import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const FaqsBlock = ({ block, blockId }) => {
    const theblock = block;
      console.log('list blockId', blockId)

      const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'accordion';
      const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='accordion') ?      
        <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
          <Container>
            <div className="row text-start d-flex flex-row flex-between align-items-center">    
              <div className='col-md-7 mx-auto px-3'> 
              <h2 className='display-3'>{theblock.headline}</h2>
              <div className='h5'><MarkdownRenderer markdownString={theblock.content} /></div>         
              <div className="accordion border-bottom mt-5 accordion-flush" id={`accordionFlush-block-${blockId}`}>
                {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{height: '1px'}}>
                        <div className="progress-bar" style={{width: '0%'}}></div>
                      </div>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${blockId}-${index}`} aria-expanded="false" aria-controls={`flush-collapse-${blockId}-${index}`}>
                          {column.question}
                        </button>
                      </h2>
                      <div id={`flush-collapse-${blockId}-${index}`} className={`accordion-collapse collapse ${(index===0) ? '' : ''}`} data-bs-parent={`#accordionFlush-block-${blockId}`}>
                        <div className="accordion-body">
                          <p><MarkdownRenderer markdownString={column.answer} /></p>
                          {column.button && 
                            <div className='my-3'>
                              {(column.button.type==='url') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.background}}>{column.button.label}</a> : <Button>{column.button.label}</Button>}
                            </div>
                          }
                        </div>
                      </div>
                  </div>
                ))}
              </div>
              </div>
            </div>
          </Container>
        </div>      
      : ''}
      </>
    );
  };

export default FaqsBlock;