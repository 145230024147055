import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
import { convertStyleStringToObject } from '../../helpers/randoms';
const ListBlock = ({ block, blockId }) => {
    const theblock = block;

    function getRandomRadius() {
        const randomValues = [];
      
        for (let i = 0; i < 8; i++) {
          let randomPercentage = Math.floor(Math.random() * 50) + 1;
          randomPercentage = 0;
          randomValues.push(randomPercentage + "%");
          if(i===3){
            randomValues.push("/");
          }
        }
      
        return randomValues.join(" ");
      }
      
      function CurvyImage() {
        const borderRadius = getRandomRadius();
      
        return (
          <div className="curvy-image" style={{ borderRadius }}>
            {/* Your image here */}
          </div>
        );
      }

      const generateRandomPath = () => {
        const randomRadius = Math.random() * 100;
        const randomX = Math.random() * 100;
        const randomY = Math.random() * 100;
        const randomPath = `circle(${randomRadius}% at ${randomX}% ${randomY}%)`;
        return ''; //randomPath;
        //setRandomPath(randomPath);
      };
      console.log('list blockId', blockId)

      const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'serviceCumTestimonial';
      const arrowHtmlEntity = '&rarr;';
    return (
      <>      
      {theblock.customCss && <style>{theblock.customCss.replace("\n","")}</style>}
      {(designType==='') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center">
            <div className='h2 pt-5 col-md-8 mx-auto'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className='col-md-4 col-sm-12'>
                <h3>{column.title}</h3>        
                {column.image &&  
                    <div className="random-path-image my-3" style={{ clipPath: generateRandomPath() }}>
                        <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{aspectRatio: '4/3', borderRadius: getRandomRadius()}} />
                    </div>
                }
                <Card.Body><MarkdownRenderer markdownString={column.content} /></Card.Body>
                {column.button && 
                  <div className='my-3'>
                    {(column.button && column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.buttonBackground+' !important'}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                  </div>
                }
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      {(designType==='4blockpacked') ?
      <div className={`pt-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container variant='fluid' style={{maxWidth: '100%'}}>
          <div className="row text-center py-5">
            <div className='h2'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-center border-top border-bottom">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`${(theblock.items.length===3 && index===0) ? 'col-md-6 col-sm-12' : 'col-md-3 col-sm-12'} p-0 d-flex flex-column flex-between ${((index%2)===0) ? 'darkaccentbg' : 'lightaccentbg'}`}>
                {column.image &&  
                    <div className="random-path-image" style={{ clipPath: generateRandomPath() }}>
                        <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{borderRadius: getRandomRadius()}} />
                    </div>
                }   
                <Card.Body className='px-3 py-5'>
                  <h3>{column.title}</h3>
                  <MarkdownRenderer markdownString={column.content} />
                  {column.button &&
                    <div className='my-3'>
                      {(column.button && column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.buttonBackground+' !important'}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                    </div>
                  }
                </Card.Body>
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      {(designType==='bottomRightImage') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center py-5">
            <div className='h3'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>          
          <div className="row d-flex justify-content-center align-items-stretch items">
              {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                <div key={index} className={`${column?.cssClasses?.item} col-sm-12 col-md-4 px-2 d-flex align-items-stretch  justify-content-center mb-3 item-single`}>
                  <div className="border px-0 pt-5 100vw flex-grow-1 align-content-between flex-column d-flex bg-white">
                    <div className={`${column?.cssClasses?.textBlock} mx-4 mb-2`} style={convertStyleStringToObject(column?.cssStyles?.textBlock)}>
                      <div className='h4'>{column.title}</div>
                      <Card.Body><MarkdownRenderer markdownString={column.content} /></Card.Body>
                      {column.button && column.button.label && 
                        <div className='my-3 mt-auto'>
                          {(column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.buttonBackground+' !important'}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                        </div>
                      }
                    </div>
                    {column.image && 
                      <div className={`${column?.cssClasses?.imageBlock} ms-4 mt-auto`} style={{...{backgroundImage: `url(${column.image.url})`, backgroundSize: 'cover', backgroundPosition: '0 0', height: '280px'}, ...convertStyleStringToObject(column?.cssStyles?.imageBlock)}}>
                        
                      </div>
                    }
                  </div>
                </div>
              ))}
          </div>
        </Container>
      </div>
      : ''}
      {(designType==='accordion') ?
      
        <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
          <Container>
            <div className="row text-start d-flex flex-row flex-between align-items-center">    
              <div className='col-md-6 px-3'> 
              <h2><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></h2>
              <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>         
              <div className="accordion border-bottom mt-5 accordion-flush" id={`accordionFlush-block-${blockId}`}>
                {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{height: '1px'}}>
                        <div className="progress-bar" style={{width: '0%'}}></div>
                      </div>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${blockId}-${index}`} aria-expanded="false" aria-controls={`flush-collapse-${blockId}-${index}`}>
                          {column.title}
                          {column.question}
                        </button>
                      </h2>
                      <div id={`flush-collapse-${blockId}-${index}`} className={`accordion-collapse collapse ${(index===0) ? '' : ''}`} data-bs-parent={`#accordionFlush-block-${blockId}`}>
                        <div className="accordion-body">
                          <MarkdownRenderer markdownString={column.content} />
                          {column.answer}
                          {column.button && 
                            <div className='my-3'>
                              {(column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.buttonBackground+' !important'}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                            </div>
                          }
                        </div>
                      </div>
                  </div>
                ))}
              </div>
              </div>
              <div className='col-md-6'>
                <div className="accordion-respective-image-container">
                  {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                    <div key={index} className={`image-div ${(index===0) ? 'active':''}`}>
                      {column.image && 
                          <div className={`random-path-image img-fluid my-3`} style={{ clipPath: generateRandomPath() }}>
                              <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{borderRadius: getRandomRadius()}} />
                          </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      
      : ''}
      {(designType==='serviceCumTestimonial') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center">
            <div className='h2 pt-5 col-md-8 mx-auto'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>    
          <div className="row text-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (  
              <div key={index} className={`d-flex align-items-center row py-3 ${((index%2)===0) ? 'darkaccentbg' : 'lightaccentbg'} border mb-5`} style={convertStyleStringToObject(column?.cssStyles?.item)}>
                <div className={`col-sm-12 col-md-6 ${((index%2)===0) ? 'order-last' : 'order-first'}`}>
                      {column.image && <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{aspectRatio: '4/3', borderRadius: getRandomRadius(), mixBlendMode: 'multiplyi', opacity: 0.9}} />}
                </div>
                <div className='col-sm-12 col-md-6 py-5 px-5 text-start'>
                  <h3>{column.icon && <img loading='lazy' src={column.icon.url} alt={column.title} height={'50'} style={{mixBlendMode: 'multiply'}} /> }{column.title}</h3>  
                  <Card.Body><MarkdownRenderer markdownString={column.content} /></Card.Body>
                  {column.button &&
                    <div className='my-3 text-end'>
                      {(column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: `${theblock.buttonBackground} !important`}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      {(designType==='oneFeaturedServiceOthersMinor') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center">
            <div className='h2 pt-5 col-md-8 mx-auto'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`${(index===0) ? 'col-md-12' : 'col-md-4'} col-sm-12`}>
                {(index===0) ?
                  <div className={`row py-3 ${((blockId%2)===0) ? 'darkaccentbg' : 'lightbg'} border mb-5 d-flex align-items-center`}>
                    <div className='col-md-6'>
                          {column.image && <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{aspectRatio: '4/3', borderRadius: getRandomRadius()}} />}
                    </div>
                    <div className='col-md-6 py-5 px-5 text-start'>
                      <h3>{column.title}</h3>  
                      <Card.Body><MarkdownRenderer markdownString={column.content} /></Card.Body>
                      {column.button &&
                        <div className='my-3 text-end'>
                          {(column.button && column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: `${theblock.buttonBackground} !important`}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                        </div>
                      }
                    </div>
                  </div>
                  :
                  <>
                  <h3>{column.title}</h3>     
                  {column.image &&  
                      <div className="random-path-image my-3" style={{ clipPath: generateRandomPath() }}>
                          <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{aspectRatio: '4/3', borderRadius: getRandomRadius()}} />
                      </div>
                  }
                  <Card.Body><MarkdownRenderer markdownString={column.content} /></Card.Body>
                  {column.button &&
                    <div className='my-3'>
                      {(column.button && column.button.type==='url' && column.button.label!='') ? <a href={column.button.link} className='btn btn-primary' style={{backgroundColor: theblock.buttonBackground+' !important'}}>{column.button.label}</a> : <Button href={column.button.link}>{column.button.label}</Button>}
                    </div>
                  }
                  </>
                }
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      {(designType==='saas2X2square') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center">
            <div className='h2 py-2 col-md-6 mx-auto border-bottom'><h2 className='display-3'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
            <div className='h5 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-start py-5">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-6 col-sm-12 px-3 my-3`}>
                <div className={`card  ${(index!==0) ? 'darkaccentbg lightaccentcolor' : 'darkbg'}`}>
                <div className='row px-5 py-5'>
                  <div className='col border-bottom'>
                    <div className='text-light h2'>{column.title}</div>
                  </div>
                  <div className='col-md-3 text-end'>
                    {(column.button && column.button.type==='url' && column.button.label!='') ? 
                      <a 
                        className='fw-bold' 
                          style={{textDecoration: 'none',
                          color: '#000',
                        }}
                        href={column.button.link}
                        >
                          <div className='text-center' style={{textDecoration: 'none', 
                          height: '100px', 
                          width: '100px', 
                          background: '#fff', 
                          color: '#000',
                          borderRadius: '50%',
                          lineHeight: '100px'
                        }}>
                          <i className="bi bi-arrow-right"></i>
                        </div>
                        </a>
                        :
                        <>
                        {column.button && 
                          <Button href={column.button.link}>
                            {column.button.label}
                          </Button>
                        }
                        </>
                    }
                  </div>
                  <Card.Body className='h55'><MarkdownRenderer markdownString={column.content} /></Card.Body>
                </div>
                {column.image &&  
                    <div className="random-path-image" style={{ clipPath: generateRandomPath() }}>
                        <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{aspectRatio: '4/3', borderRadius: getRandomRadius()}} />
                    </div>
                }
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default ListBlock;