
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // For adding siblings or children
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // For copying elements
import EditIcon from '@mui/icons-material/Edit'; // For editing elements

export const LayersList = ({ elements, onSelectLayer, onAddSibling, onAddChild, onCopyElement }) => {
    const handleClick = (element, event) => {
        event.stopPropagation(); // This stops the event from bubbling up to parent elements
        onSelectLayer(element);
    };
    return (
        <ul style={{padding: 0, margin: 0}}>
            {elements.map(element => (
                <li key={element.id} className="border-top pointer" style={{display: 'block', alignItems: 'center', paddingLeft: '0.6rem', }}>
                    
                    <span style={{ flexGrow: 1, cursor: 'pointer' }} onClick={(e) => handleClick(element, e)}>
                        {element.tag} - {element.text}
                    </span>
                    
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => onSelectLayer(element)} />
                    <AddCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={() => onAddChild(element.id)} />
                    <ContentCopyIcon style={{ cursor: 'pointer' }} onClick={() => onCopyElement(element)} />

                    {element.children && element.children.length > 0 && (
                        <LayersList elements={element.children} onSelectLayer={onSelectLayer} onAddSibling={onAddSibling} onAddChild={onAddChild} onCopyElement={onCopyElement} />
                    )}
                </li>
            ))}
        </ul>
    );
};
