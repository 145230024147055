import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { getRandomIpsumLogo } from '../../helpers/randoms';
const AwardsBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} id={`section-${blockId}`}>
        <Container>
          <div className="row text-center">
            <div className='pt-5 col'><h2 className='display-3'>{theblock.headline}</h2></div>
            <div className='col-md-12 pb-3'>{theblock.content}</div>
          </div>
          <div className="row text-center px-5 d-flex justify-content-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-${12/theblock.items.length} col-sm-12`}>
                <h5 className='badge bg-light'>{column.year}</h5>
                <h3>{column.title}</h3>
                <p>{column.content}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default AwardsBlock;