export const getStyles = (type="section-shape",identifier) => {
    // css
    // js
    // [type][identifier]
    const styles = {
        "section-shape": [
            {
                identifier: "triangle",                
                css: "",
            },
        ]
    }
}

export const getStylesOnly = `

html {
  scroll-behavior: smooth;
}
/* Shape 1: Triangle */
.shape-triangle {
  background: linear-gradient(45deg, #f0f0f0 50%, transparent 50%),
              linear-gradient(135deg, #f0f0f0 50%, transparent 50%);
  background-size: 100% 10px;
}

/* Shape 2: Diamond */
.shape-diamond {
  background: linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(135deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(225deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(315deg, #f0f0f0 25%, transparent 25%);
  background-size: 10px 10px;
}

/* Shape 3: Parallelogram */
.shape-parallelogram {
  background: linear-gradient(-45deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(135deg, #f0f0f0 25%, transparent 25%),
              linear-gradient(225deg, #f0f0f0 25%, transparent 25%);
  background-size: 10px 10px;
}

/* Shape 4: Pentagon */
.shape-pentagon {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

/* Shape 5: Hexagon */
.shape-hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

/* Shape 6: Circle */
.shape-circle {
  border-radius: 50%;
}

/* Shape 7: Heart */
.shape-heart {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  transform: rotate(-45deg);
  border-radius: 50%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 150px;
    background-color: #f0f0f0;
    border-radius: 50%;
  }

  &::before {
    top: -75px;
    left: 0;
  }

  &::after {
    top: -75px;
    right: 0;
  }
}`;

const getClipPaths = (type) => {
    // all clippaths here
    const clipPaths = {
        'rightSpeedArraow' : `clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);  z-index: 1 !important; position: relative !important;`
    }
    return clipPaths[type];
}

const getPhotoEffects = () => {
    // negetive margins
    // parallax
    // up down
    // border
    // etc
}