import { Button } from "@mui/material";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import BannerBlock from "../../../components/Blocks/BannerBlock";
import PlaceholderList from "../../components/PlaceholderList";

  const InternalDummyPage = ({page, children, guideNotice}) => {
    const headerContent = (page && page.title) ? <h1>{page.title}</h1> : <h1>Dummy Internal page</h1>;
    const footerContent = <p>Logged in as user@example.com</p>;
    const leftSideBarContent = <DashboardSideBarMenu />

    return (
      <InternalTemplate headerContent={headerContent} footerContent={footerContent} leftSideBarContent={leftSideBarContent}>
        {children}
        {guideNotice && <div className="alert alert-info my-5">{guideNotice}</div>}
        <PlaceholderList />
      </InternalTemplate>
      
    );
  };
  
  export default InternalDummyPage;