import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { voidElements } from '../../../../helpers/randoms';
import React from 'react';


const DynamicElement = ({ element, onAddChild, onEditElement, editMode=true, onDeleteElement }) => {
    const isVoidElement = voidElements.has(element.tag);
    // Function to handle adding a new child
    const handleAddChildClick = () => {
        // This is where you define the default properties for the new child element
        const newChild = {
            id: Date.now(), // Assign a unique ID
            tag: 'div', // Default tag for new child
            text: 'New Child', // Default text for new child
            classes: '',
            styles: {},
            dataTags: '',
            prompt: '',
            fieldName: '',
            children: [] // New child can also have children
        };
        onAddChild(element.id, newChild);
    };
    

    // Recursively render children elements, if any
    const renderChildren = (children) => {
        return children.map(child => (
            <DynamicElement key={child.id} element={child} onAddChild={onAddChild} onEditElement={onEditElement} editMode={editMode} onDeleteElement={onDeleteElement} />
        ));
    };

    // Create the element attributes
    const attributes = {
        className: element.classes,
        style: element.styles,
        'data-tags': element.dataTags,
        'data-prompt': element.prompt,
        'data-field-name': element.fieldName,
        // Add other attributes if needed
    };
    
     // Handle rendering of void elements
     if (isVoidElement) {
        // Self-closing tag for void elements
        return React.createElement(element.tag, { ...attributes, key: element.id });
    }

    // Create the element dynamically
    const Element = element.tag || 'div'; // Default to div if tag is not specified

    return (
        <> 
            {/*style={{ bord1er: '1px solid #ccc', pad1ding: '10px', marg1in: '10px' }}>*/}
            <Element {...attributes}>
                {element.text}
                {element.children && renderChildren(element.children)}
            </Element>
            {editMode && <>
                {/* Render children elements */}
                {/* Button to add a new child */}
                <button onClick={() => onEditElement(element)} style={{width: '40px'}}>
                    <EditIcon /> {/* Edit icon */}
                </button>
                <button onClick={handleAddChildClick} style={{width: '40px'}}>
                    <AddCircleOutlineIcon /> {/* Add Child icon */}
                </button>
                <button onClick={() => onDeleteElement(element.id)} style={{width: '40px'}}>
                    <DeleteIcon /> {/* Delete icon */}
                </button>
            </>}
        </>
    );
};

export default DynamicElement;