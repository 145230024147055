import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, TextareaAutosize, Typography, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageUploaderNew from '../ImageUploaderNew';
import { Col, Row } from 'react-bootstrap';
import CssFilterConverter from 'css-filter-converter';
import ColorPickerComponent from '../../ColorPickerComponent';
import { rgbToHex, rgbToHexNew } from '../../../../helpers/colors';

function BlockBackgroundTextureForm({ block, onUpdate }) {
    //const cssFilterConverter = new CssFilterConverter();
    const [backgroundSprinkles, setBackgroundSprinkles] = useState(block?.backgroundSprinkles || []);

    const handleUpdateSprinkle = (index, key, value) => {
        const updatedSprinkles = [...backgroundSprinkles];
        updatedSprinkles[index] = { ...updatedSprinkles[index], [key]: value };
        if(key==='filterColor'){
            updatedSprinkles[index] = { ...updatedSprinkles[index], filter: convertColorToFilter(value) };
        }
        setBackgroundSprinkles(updatedSprinkles);
        //onUpdate({ ...block, backgroundSprinkles: updatedSprinkles });
        onUpdate(updatedSprinkles);
    };

    const handleAddSprinkle = () => {
        setBackgroundSprinkles([...backgroundSprinkles, { image: '', classes: '', styles: '', filterColor: '', filter: '' }]);
    };

    const handleRemoveSprinkle = (index) => {
        const updatedSprinkles = backgroundSprinkles.filter((_, i) => i !== index);
        setBackgroundSprinkles(updatedSprinkles);
        //onUpdate({ ...block, backgroundSprinkles: updatedSprinkles });
        onUpdate(updatedSprinkles)
    };
    const handleDuplicateSprinkle = (index) => {
        const sprinkleToDuplicate = backgroundSprinkles[index];
        if (!sprinkleToDuplicate) return;

        // Create a deep copy of the sprinkle to duplicate
        const newSprinkle = {
            ...sprinkleToDuplicate,
            image: sprinkleToDuplicate.image,
            classes: sprinkleToDuplicate.classes,
            styles: sprinkleToDuplicate.styles,
            filterColor: sprinkleToDuplicate.filterColor,
            filter: sprinkleToDuplicate.filter,
        };

        setBackgroundSprinkles([...backgroundSprinkles, newSprinkle]);
    };



    
    function convertColorToFilter(hexColor) {
        //const cssFilterConverter = new CssFilterConverter();
        const theFilter = CssFilterConverter.hexToFilter(rgbToHexNew(hexColor));
        return theFilter.color;
    }

    return (
        <div>
            {backgroundSprinkles.map((sprinkle, index) => (
                <Accordion key={index}>
                    <AccordionSummary>
                        <Typography variant="h6" className='me-5'>Background Sprinkle {index + 1}</Typography>
                        <>
                            {sprinkle.image && <img src={sprinkle.image} style={{maxHeight: '40px'}} />}
                        </>
                        <IconButton onClick={() => handleDuplicateSprinkle(index)}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton onClick={() => handleRemoveSprinkle(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>
                            <Col>
                                Image:
                                <ImageUploaderNew
                                    image={{ url: sprinkle.image }}
                                    onUpdateImage={(e) => handleUpdateSprinkle(index, 'image', e.url)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Classes:
                                <TextareaAutosize
                                    minRows={4}
                                    aria-label="Classes"
                                    placeholder="Classes"
                                    value={sprinkle.classes}
                                    onChange={(e) => handleUpdateSprinkle(index, 'classes', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Styles:
                                <TextareaAutosize
                                    minRows={4}
                                    aria-label="Styles"
                                    placeholder="Styles"
                                    value={sprinkle.styles}
                                    onChange={(e) => handleUpdateSprinkle(index, 'styles', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Filter Color:
                                <ColorPickerComponent
                                    initialColor={sprinkle.filterColor || '#cccccc'}
                                    onColorChange={(e) => handleUpdateSprinkle(index,'filterColor',e)}
                                />
                            </Col>
                        </Row>
                    </AccordionDetails>
                </Accordion>
            ))}
            <button onClick={handleAddSprinkle}>Add New Sprinkle</button>
        </div>
    );
}

export default BlockBackgroundTextureForm;
