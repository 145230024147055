import { Button, TextField, TextareaAutosize } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
//import ImageUploader from './ImageUploader';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { cleanDomain, generateSlug, isValidDomain } from '../../../helpers/randoms';
import StripeComponent from './StripeComponent';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsite } from '../../redux/slices/websiteSlice';
import axios from 'axios';
import { MessageContext } from '../../contexts/MessageContext';
import DnsManagerForm from './DnsManagerForm';

const DomainForm = ({ data = {}, websiteId, onUpdate }) => {
  console.log('in page form: new',data);
  //const [slugEditedManually, setSlugEditedManually] = useState(false);
  const [websiteData, setWebsiteData] = useState({});
  const [pageTitle, setPageTitle] = useState(data.title || '');
  const [pageName, setPageName] = useState(data.name || '');
  const [pageURL, setPageURL] = useState(data.url || '');
  const [redirectTo, setRedirectTo] = useState(data.redirectTo || '');
  const [is404Page, setIs404Page] = useState(data.is404 || false);
  const [slugEditedManually, setSlugEditedManually] = useState(false);
  const [nameEditedManually, setNameEditedManually] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [haveDomain, setHaveDomain] = useState(false);
  const [purchaseDomain, setPurchaseDomain] = useState(false);

  const [searchingDomain, setSearchingDomain] = useState(false);
  const [attachingDomain, setAttachingDomain] = useState(false);

  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { addMessage } = useContext(MessageContext);
  
  const currentEditedWebsitePageId = useSelector(state => state.websites.currentEditedWebsitePageId);
  const theWbsiteData = useSelector(state => state.websites.websites.find(website => website.id === parseInt(websiteId)));

  const handleSearch = () => {
    // Replace with your API endpoint
    const apiUrl = `https://sanmayadhal.com/domains?name=${searchingDomain}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setDomains(data.data.domains);
      })
      .catch((error) => {
        setError(error.toString());
      });
  };

  const handleDomainSelect = (domain) => {
    //setSelectedDomain(domain);
  };

  const handleLinkClick = () => {
    if (selectedDomain) {
      // Handle navigation or other actions with the selected domain
      //window.location.href = `http://${selectedDomain}`;
    }
  };


  const handleSave = async() => {      
      if(!websiteId){
        addMessage('Some issue occured, report us.');
        return;
      }
      if(!attachingDomain){
        addMessage('Please enter a domain.');
        return;
      }
      if (!isValidDomain(attachingDomain)) {
        addMessage('Please enter a valid domain.');
        return;
      }
      const cleanedDomain = cleanDomain(attachingDomain);
      setIsLoading(true);
      addMessage('');
      try {
        const response = await axios.post('https://bvorqhdsibaukalonivs.supabase.co/functions/v1/add-domain-to-website/add-domain', { domain: cleanedDomain }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
          },
        });
  
        console.log(response.data.message);
      } catch (error) {
        console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
      } finally {
        dispatch(updateWebsite({id: websiteId, data: {domain: cleanedDomain}}))
        setIsLoading(false);
        addMessage(`Your domain added. If your CNAME record points correctly, it'll take few minutes to appear on your domain`)
      }
  }

  useEffect(() => {
    setWebsiteData(theWbsiteData)
  }, [theWbsiteData]);


  return (
    <>
      <div>
        {/*<StripeComponent />*/}
        {websiteData && websiteData.domain && <h6>Website: <a target='_blank' href={`https://${websiteData.domain}`}>{websiteData.domain}</a></h6>}
        {!haveDomain && !purchaseDomain && 
          <>
            <Button variant='outlined' color='secondary' onClick={() => {setPurchaseDomain(false); setHaveDomain(true)}}>I have a domain</Button>
            <Button variant='outlined' color='secondary' onClick={() => {setHaveDomain(false); setPurchaseDomain(true)}}>Purchase a domain</Button>
          </>
        }
        {haveDomain &&
          <>
            <h2>Connect your domain</h2>
            <label>
              <TextField 
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Domain Name'}
                value={attachingDomain? attachingDomain : (websiteData) ? websiteData.domain: ''}
                onChange={(e) => setAttachingDomain(e.target.value)}
              />
            </label>
            <div>
              <div>Once you provide, set the CNAME record to: <br /><b>{websiteData && websiteData.staging_domain}</b></div>
              {websiteData && websiteData.domain && <>The current website URL is <a target='_blank' href={`https://${websiteData.domain}`}>{websiteData.domain}</a></>}
              {/*Records: 
              <ol>
                <li>
                  
                </li>
              </ol>*/}
            </div>
            <br />
            <Button onClick={handleSave} fullWidth className='my-3' variant='outlined' color='primary' disabledi={(attachingDomain) ? true: false}>Connect it</Button>
          </>
        }
        {purchaseDomain &&
          <>
            <h2>Purchase a new domain</h2>
            <label>
              <TextField 
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Domain Name'}
                value={searchingDomain}
                onChange={(e) => setSearchingDomain(e.target.value)}
              />
            </label>
            <div>
              <h1>Select a Domain</h1>
              {error ? (
                <p>Error fetching domains: {error}</p>
              ) : (
                <>
                  <span>
                    {domains && domains.map((domain) => (
                      <div key={domain} className='border-bottom py-3 pointer' onClick={handleDomainSelect(domain.domain)}>
                        {domain.domain}
                      </div>
                    ))}
                  </span>
                  <button onClick={handleLinkClick} disabled={!selectedDomain}>
                    Go to Website
                  </button>
                </>
              )}
            </div>
            <br />
            <Button onClick={handleSearch} fullWidth className='my-3' variant='outlined' color='primary'>Search the domain</Button>
          </>
        }
        <div className='py-5'>        
          {!haveDomain && purchaseDomain && 
            <>
              <Button variant='outlined' color='secondary' onClick={() => {setPurchaseDomain(false); setHaveDomain(true)}}>I have a domain</Button>
            </>
          }       
          {haveDomain && !purchaseDomain && 
            <>
              <Button variant='outlined' color='secondary' onClick={() => {setHaveDomain(false); setPurchaseDomain(true)}}>Purchase a domain</Button>
            </>
          }
        </div>
      </div>
      <DnsManagerForm />
    </>
  );
};

export default DomainForm;
