import { Checkbox, FormControlLabel, Radio, TextareaAutosize, buttonBaseClasses } from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import { getAllBrandLogos } from '../../helpers/brands';
import { CheckBox, TheaterComedy } from '@mui/icons-material';

const theblock = {
  "title": "Registration Form",
  "fields": [
      {
          "label": "First Name",
          "type": "text",
          "required": true,
          "placeholder": "Enter your first name"
      },
      {
          "label": "Last Name",
          "type": "text",
          "required": true,
          "placeholder": "Enter your last name"
      },
      {
          "label": "Email",
          "type": "email",
          "required": true,
          "placeholder": "Enter your email"
      },
      {
          "label": "Age",
          "type": "number",
          "required": true,
          "placeholder": "Enter your age"
      },
      {
          "label": "Date of Birth",
          "type": "date",
          "required": true
      },
      {
          "label": "Gender",
          "type": "select",
          "required": true,
          "options": [
              {
                  "value": "male",
                  "display": "Male"
              },
              {
                  "value": "female",
                  "display": "Female"
              },
              {
                  "value": "other",
                  "display": "Other"
              }
          ]
      }
  ]
};
const FormBlock = ({ block, children, blockId }) => {
    const theblock = block;
    const brands = getAllBrandLogos();

    let alignClass = 'text-center';

    if(theblock.align==='left'){
      alignClass = 'text-start'
    } else if(theblock.align==='right'){
      alignClass = 'text-end'
    }

    console.log('Alignment',theblock)
    
    const type = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'heroic'; //'singlesaasshot';

    console.log('design',type)
/*
    const theStyle = `<style>
      .left:before {
          background-image: url(${theblock.background.image.url});
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          content: '';
          display: block;
          ${(blockId>1) ? 'height: 100%;' : 'position: absolute;'}
          z-index: -1;
          width: 40%;
          top: 0px;
          left: 0px;
          bottom: 0px;
      }
      .left:after {
        background-color: ${theblock.background.color};
        content: '';
        display: block;
        ${(blockId>1) ? 'height: 100%;' : 'position: absolute;'}
        z-index: -1;
        width: 60%;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
      .right:after {
        background-image: url(${theblock.background.image.url});
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        content: '';
        display: block;
        ${(blockId>1) ? 'height: 100%;' : 'position: absolute;'}
        z-index: -1;
        width: 40%;
        top: 0px;
        right: 0px;
        bottom: 0px;
    }
    .right:before {
      background-color: ${theblock.background.color};
      content: '';
      display: block;
      ${(blockId>1) ? 'height: 100%;' : 'position: absolute;'}
      z-index: -1;
      width: 60%;
      top: 0px;
      left: 0px;
      bottom: 0px;
    }
    </style>`;
*/

    const theStyleu = '';

    console.log('loh')

    const FormDisplayer = ({formJson}) => {
    console.log('loh')
      return(
        <>
          {/*formJson.title && <h2>{formJson.title}</h2>*/}
          <form 
            action={formJson.submitTo}
            method={formJson.submitMethod || 'POST'}
            data-success-msg={formJson.messages.success}
            data-failure-msg={formJson.messages.failure}
            className='text-start'
            data-uuid={formJson.uuid}
            data-success-event={formJson.successEvent}
            data-failure-event={formJson.failureEvent}
            data-submit-event={formJson.submitEvent}
          >
            {formJson.title && <input type='hidden' name='_s_form_name' value={formJson.title} />}
            {formJson.fields && formJson.fields.length>0 && formJson?.fields?.map((field,index) => (
              <span key={index} className='mt-3 d-block'>
              {field.label} : 
              {['checkbox','choices'].includes(field.type) && field?.choices?.map((choice) => (
                  <FormControlLabel
                  key={choice.choiceText}
                  control={
                      <input type='checkbox'
                      checked={false}
                      name={`${field.label}[${choice.choiceText}]`}
                      />
                  }
                  label={choice.choiceText}
                  className='me-2 ps-2'
                  />
              ))}                                    
              {field.type === 'radio' &&
              <>
              {field.label} : 
              {field?.choices?.map((choice) => (
                  <FormControlLabel
                  key={choice.choiceText}
                  control={
                      <input type='radio'
                      checked={false}
                      name={`${field.label}`}
                      value={choice.choiceText}
                      />
                  }
                  label={choice.choiceText}
                  className='me-2 ps-2'
                  />
              ))}
              </>
              }
              {field.type==='textarea' &&  
                <textarea name={field.label} placeholder={field.label} required={field.required} className='form-control' minrows={6}></textarea>
              }
              {['email','text',"date","password"].includes(field.type) &&         
                <input className='form-control mb-3' type={field.type} name={field.label} placeholder={field.label} required={field.required} />
              }
              </span>
            ))}
            <input className='btn btn-primary mt-3 btn-lg px-5' type='submit' value={formJson.submitText} />
          </form>
        </>
      )
    }


    return (
      <>
      {(type==='heroic') ?
      <div id={`section-${blockId}`} data-modal-id={theblock.uuid} className={`section ${(alignClass)} ${(theblock.isModal) ? 'd-none' : ''}`} style={(theblock && theblock.background && theblock.background.image && theblock.background.image.url) ? {backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage : `url(${theblock.background.image.url})` } : { background: '#ffc'}}>
        <div className='py-sm-5 p-md-5 brandtransparentbg' style={{background: (theblock && theblock.background && theblock.background.opacity) ? `rgba(0,0,0,${theblock.background.opacity/100})` : 'rgba(0,0,0,0.6)', color: 'white', width: '100%', margin:0 }} >
        <Container style={{opacity: 1}} className='py-sm-5 p-md-5'>
          <div className="row p-md-5 py-sm-5">
            <Col md='10' xs={12} className='mx-auto'>
              {theblock.headline && <h2 className='highlight-this fw-bold display-3'>{theblock.headline}</h2>}
              <div dangerouslySetInnerHTML={{ __html: theblock.content }} />
              {children}
              <FormDisplayer formJson={theblock} />
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      {['videoBackgroundHero','videoDownBackgroundHero'].includes(type) ?
      <div id={`section-${blockId}`} data-modal-id={theblock.uuid} className={`section ${(alignClass)} video-background darkbg  ${(theblock.isModal) ? 'd-none': ''}`} style={{background: (theblock && theblock.background && theblock.background.color) ? theblock.background.color : ''}}>
        <div className='p-5'>
        <Container style={{opacity: 1}} className='p-5'>
          <div className="row p-md-5 py-sm-5">
            <Col md={12}>
              {/*
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3C_22eBWpjg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              */}
            </Col>
            <Col md={12} className={`${(['videoDownBackgroundHero'].includes(type)) ? 'order-first mb-5' : ''}`}>
              {theblock.headline && <h2 className='highlight-this fw-bold display-3'>{theblock.headline}</h2>}
              <div dangerouslySetInnerHTML={{ __html: theblock.content }} />              
              {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} className='btn btn-primary my-3'>{theblock.button.label}</a>}
              {children}
              <FormDisplayer formJson={theblock} />
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      {(['leftContentRightImage','leftContentPlusTestimonialRightImage','leftContentPlusBrandRightImage','rightContentLeftImage','rightContentLeftVideo'].includes(type)) ?
      <div 
        id={`section-${blockId}`}
        data-modal-id={theblock.uuid}
        className={`section ${(alignClass)} ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}  ${(theblock.isModal) ? 'd-none': ''}`}
        style={
          (theblock && theblock.background && theblock.background.image && theblock.background.image.url) 
          ? 
          {backgroundPosition: (['leftContentRightImage','leftContentPlusTestimonialRightImage','leftContentPlusBrandRightImage'].includes(type)) ? '100%' : '0', backgroundSize: '50% 100%', backgroundImage : `url(${theblock.background.image.url})`, backgroundRepeat: 'no-repeat' }
          : ''}>
        <div className='p-5'>
        <Container fluid style={{opacity: 1}} className='py-5'>
          <div className="row py-md-5 py-sm-5 align-middle">
            <Col md={6} className={`p-5 align-middle ${(['rightContentLeftImage','rightContentLeftVideo'].includes(type)) ? 'order-last' : ''}`}>
              {theblock.headline && <h2 className='highlight-this fw-bold display-3 typewriter-text' aria-speed="93">{theblock.headline}</h2>}
              <div dangerouslySetInnerHTML={{ __html: theblock.content }} />
              {children}
              <FormDisplayer formJson={theblock} />
              {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} className='btn btn-primary my-3'>{theblock.button.label}</a>}
              {(['leftContentPlusTestimonialRightImage','leftContentPlusBrandRightImage'].includes(type)) ? 
              <>
                <hr />
                {(type==='leftContentPlusTestimonialRightImage') && 'here testimonials'}
                {(type==='leftContentPlusBrandRightImage') && 
                  <>
                    <h5 className='fw-bold'>Brands who trust us:</h5>
                    {brands?.map((brand,index) => (
                      <>
                      <img loading='lazy' src={brand.logoUrl} alt={brand.name} key={index} height={40} className='me-2 brandImageFilter' />
                      {brand.logoSvg}
                      </>
                    ))}
                  </>
                }
              </>
              : ''}
            </Col>
            <Col md={6} className={`d-flex justify-content-center align-items-center ${(['rightContentLeftImage','rightContentLeftVideo'].includes(type)) ? 'left leftBrandBg' :'right rightBrandBg'}`}>
              {/*(theblock && theblock.background && theblock.background.image && theblock.background.image.url) && <img src={theblock.background.image.url} alt={theblock.background.image.description} width={'100%'} className='align-middle img-fluid' />*/}
              {(['rightContentLeftVideo'].includes(type)) &&            
                {/*
                <iframe width="560" height="315" src="https://www.youtube.com/embed/3C_22eBWpjg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                */}
              }
              {children}
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      </>
    );
  };

export default FormBlock;