import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import React, { useEffect, useState, Fragment, useContext } from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import { MessageContext } from "../contexts/message";
//import CloseIcon from '@mui/icons-material/Close';

const CreateUser = () => {
    // it creates user from given url

    // then it logins via that
    // it sends confirmation email
    //const { showMessage } = useContext(MessageContext);
    const messageContext = React.useContext(MessageContext);
    const navigate = useNavigate();

    //const handleClick = () => {
    //    showMessage('This is a message from SomePage');
    //};
        
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }    
        setAlertOpen(false);
    };
      
    const generatePassword = (length) => {
        const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
        const passwordArray = [];
        
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          const randomCharacter = characters.charAt(randomIndex);
          passwordArray.push(randomCharacter);
        }
        return passwordArray.join('');
    }
    
    const createUserWithEmailAndPassword = async (email, password) => {
        try {
        const { user, error } = await supabase.auth.signUp({
            email,
            password,
        });
    
        if (error) {
            console.error(error);
            return null;
        }
    
        return user;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    
    const loginUserWithEmailAndPassword = async (email, password) => {
        try {
        const { user, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });
    
        if (error) {
            console.error(error);
            return null;
        }
    
        return user;
        } catch (error) {
            console.error(error);
        return null;
        }
    };
    
    const handleUserAutoCreate = async () => {
        const newPassword = generatePassword(12);
        //const email = 'example@example.com'; // Replace with desired email address
    
        //createUserWithEmailAndPassword(email, newPassword)
        const {data, error} = await supabase.auth.signUp({
            email: email,
            password: newPassword,
        });
    
        //console.error(res);
        //return true;
        
        if (error && error !== null) {
            console.error(error, email, newPassword);
            console.log('1. User creation failed.');
            console.log(error.message);
            
            if(error.message=="User already registered"){
                setAlertMessage('User already present, redirecting to login page.');
                setAlertOpen(true);                
                setTimeout(() => {
                    window.location.replace("/dashboard/websites/create")
                    
                    // window.location.href = "/dashboard/websites/new"
                    console.log('superb')
                }, 3000);
                
            }   else{
                setAlertMessage('User couldn\'t create');
                setAlertOpen(true);
            }
            //messageContext.showMessage('Couldn\'t create user. Check if you already have an account with us')
            return null;
        }

        //console.error(error, email, newPassword);
        //console.log(user)

        const user = data.user;
    
        //return user;
        if (user) {
            let datalogin = false;
            let errorlogin = false;
            console.log('User created:', user);
            // now login the user                
            const res = await supabase.auth.signInWithPassword({
                email: email,
                password: newPassword,
            });
            console.log(res);
            
            if (res.error && res.error!==null) {
                //console.error(res.error);
                console.log('1. User login failed.');
                setAlertMessage('User couldn\'t login');
                setAlertOpen(true);
                messageContext.showMessage('Couldn\'t login user')
                return null;
            }
            const loggedinuser = res.data.user;
            if(loggedinuser){
                    //console.log('User logged in:', user);
                    setIsLoggedIn(true);
                    messageContext.showMessage('User logged in.')
                    // now login the user
                        
            } else {
                console.log('2.User login failed.');
                setAlertMessage('User couldn\'t login');
                setAlertOpen(true);
                messageContext.showMessage('Couldn\'t login user. Try after some time')
                return null;
            }
            
        } else {
            console.log('3. User creation failed.');
            setAlertMessage('User creation failed');
            setAlertOpen(true);
            messageContext.showMessage('Couldn\'t create user. Check if you already have an account with us')
            return null;
        }
        
    };

    useEffect(() => {
        // redirect if loggedIn, throw error if not
        
        if(isLoggedIn){
            setAlertMessage('We will now send user');
            setAlertOpen(true);
            //navigate('/dashboard')
            //this.history.push("/dashboard") //doing redirect here.
            //window.history.forward("/dashboard")
            window.location.replace("/dashboard")

        }

    }, [isLoggedIn]);
    useEffect(() => {

    }, [alertMessage, alertOpen]);

    const action = (
        <Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            X {/*<CloseIcon fontSize="small" />*/}
          </IconButton>
        </Fragment>
      );

    return (
        <div>
            <button onClick={handleUserAutoCreate}>Join</button>            
                <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message={alertMessage}
                action={action} />
            
        </div>
    );
}

export default CreateUser;


