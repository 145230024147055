import { Accordion, AccordionDetails, AccordionSummary, Typography, TextField, TextareaAutosize, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import AIWritingGenerator from './AIWritingGenerator';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const CalendlyForm = ({ data, onUpdate }) => {
  const [listData, setListData] = useState(data || {});
  const [isDragging, setIsDragging] = useState(false);

  const handleSave = () => {
    onUpdate(listData);
  };
  

  return (
    <div>
      <GriderLayout left={'Background'} 
        right={<input
          type="color"
          value={listData.background}
          onChange={(e) => setListData({ ...listData, background: e.target.value })}
        />} 
      />      
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Headline'}
        value={listData.headline}
        onChange={(e) => setListData({ ...listData, headline: e.target.value })}
      />
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'calendly meeting link'}
        value={listData.meetingLink}
        onChange={(e) => setListData({ ...listData, meetingLink: e.target.value })}
      />
      <TextareaAutosize
                name="content"
                minRows={4}
                style={{width: '100%'}}
                aria-label="Content"
                placeholder="Content"
                value={listData.content}
                onChange={(e) => setListData({ ...listData, content: e.target.value })}
            />
        
        <TextareaAutosize
                name="templateCode"
                minRows={4}
                style={{width: '100%', fontFamily: 'monospace !important'}}
                aria-label="Custom Template Code"
                placeholder='
                <div class="container bg-dark text-light">
                  Hello, {{headline}}!
                  {% loop items as item %}
                  <div>{{item.title}}</div>
                  {% endloop %}
                  </div>
                '
                value={listData.templateCode}
                onChange={(e) => setListData({ ...listData, templateCode: e.target.value })}
            />          
          <GriderLayout left={'Use Custom Template'} 
            right={<input
              type="checkbox"
              value={true}
              checked={listData.useCustomTemplate}
              onChange={(e) => setListData({ ...listData, useCustomTemplate: e.target.checked })}
            />} 
          />
          <TextareaAutosize
                  name="gridCss"
                  minRows={4}
                  style={{width: '100%', fontFamily: 'monospace !important'}}
                  aria-label="Custom grid CSS"
                  placeholder='Put your CSS here'
                  value={listData.customGridCss}
                  onChange={(e) => setListData({ ...listData, customGridCss: e.target.value })}
              />
          <TextField
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Section parent Class'}
                value={listData.sectionParentClass}
                onChange={(e) => setListData({ ...listData, sectionParentClass: e.target.value })}
            />
          <GriderLayout left={'Use Grid CSS'} 
              right={<input
                type="checkbox"
                value={true}
                checked={listData.useGridCss}
                onChange={(e) => setListData({ ...listData, useGridCss: e.target.checked })}
              />} 
            />
            <GriderLayout left={'Hide'} 
              right={<input
                type="checkbox"
                value={true}
                checked={listData.isHidden}
                onChange={(e) => setListData({ ...listData, isHidden: e.target.checked})}
              />} 
            />
        <br />
      <Button variant='outlined' color='primary' className='my-5' fullWidth onClick={handleSave}>Save</Button>
    </div>
  );
};

export default CalendlyForm;
