import { createClient } from '@supabase/supabase-js';

//const supabaseUrl = 'https://bvorqhdsibaukalonivs.supabase.co'; //'YOUR_SUPERBASE_PROJECT_URL';
//const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ2b3JxaGRzaWJhdWthbG9uaXZzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ1MjQxMjksImV4cCI6MjAwMDEwMDEyOX0.7J5419rhJofnvQSMq_cFVdZhFwDRn1tiNFc2F4AQ_CI'; //'YOUR_PUBLIC_API_KEY';


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);
