import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { EditorBlock, Block } from '../components/Block';
import { Container, Typography, Button } from '@mui/material';

const Editor = () => {
    const [blocks, setBlocks] = useState([]);
    const [website, setWebsite] = useState({
        name: '',
        domain: '',
        blocks: [],
      });      
    const addBlock = (blockType) => {
        let newBlock;
        switch (blockType) {
          case 'threeColumn':
            newBlock = { type: 'threeColumn', columns: ['new','content','here'] };
            break;
          case 'card':
            newBlock = { type: 'card', title: '', content: '' };
            break;
          case 'youtubeVideo':
            newBlock = { type: 'youtubeVideo', videoUrl: '' };
            break;
          case 'testimonial':
            newBlock = { type: 'testimonial', columns: ['new','content','here'] };
            break;
          default:
            return;
        }
        setBlocks([...blocks, newBlock]);
        setWebsite((prevWebsite) => ({
            ...prevWebsite,
            blocks: [...prevWebsite.blocks, newBlock],
          }));
      };
      return (
        <Container maxWidth="100%">
          <h1>Editor</h1>
      
          {/* Existing code */}
          {/* ... */}
      
          {/* Render existing blocks */}
          {blocks.map((block, index) => (
            <>
            <EditorBlock key={index} block={block} />
            </>
          ))}
      
          {/* UI for adding new blocks */}
          <>
            <button onClick={() => addBlock('threeColumn')}>Add 3-Column Block</button>
            <button onClick={() => addBlock('card')}>Add Card Block</button>
            <button onClick={() => addBlock('youtubeVideo')}>Add YouTube Video Block</button>
            <button onClick={() => addBlock('testimonial')}>Add Testimonials Block</button>
          </>
        </Container>
      );            
}

export default Editor;