import { Accordion, AccordionDetails, AccordionSummary, Typography, TextField, TextareaAutosize, Button, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import AIWritingGenerator from './AIWritingGenerator';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AceCodeEditor from '../AceCodeEditor';
import { deepClone, setDotNotationObjectValue } from '../../../helpers/randoms';


const ListForm = ({ data, onUpdate }) => {
  const [listData, setListData] = useState(data || {});
  const [isDragging, setIsDragging] = useState(false);

  const handleItemChangeOld = (index, field, value) => {
    console.log('index',index,'field',field,'value',value);
    const updatedItems = [...listData.items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setListData({ ...listData, items: updatedItems });
  };
  const handleItemChange = (index, field, value) => {
    const updatedItems = deepClone(listData.items); // Creating a deep clone of items
    setDotNotationObjectValue(updatedItems[index], field.split('.'), value);
    setListData({ ...listData, items: updatedItems });
  };
  
  const handleItemChangeOldOld = (index, field, value) => {
    const updatedItems = [...listData.items];
    const updatedItem = { ...updatedItems[index] };
    setDotNotationObjectValue(updatedItem, field, value);
    updatedItems[index] = updatedItem;
    setListData({ ...listData, items: updatedItems });
  };

  const handleItemDelete = (index,event) => {
    event.stopPropagation();
    const newItems = [...listData.items];
    newItems.splice(index, 1);
    setListData({ ...listData, items: newItems });
  };

  const handleSave = () => {
    onUpdate(listData);
  };
  
  const StyledAccordionSummary = styled(AccordionSummary)({
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const StyledIconButton = styled(IconButton)({
    //marginRight: 'auto',
    cursor: 'grab !important',
  });

  const handleAddItem = () => {
    const newItem = {
      title: "",
      content: "",
      idx: listData.items.length,
      button: {
          label: "More info",
          type: "url",
          link: "#",
      },
      image: {
          id: "",
          description: "",
          alt_description: "",
          url: "",
          author: "",
          authorLink: "",
          type: "unsplash",
          download: "",
          urlSmall: ""
      }
    };
    setListData({ ...listData, items: [...listData.items, newItem] });
  };

  
  const handleSelectImage = (image) => {
   console.log('image',image)
    const updatedItems = listData.items.map((item,index) =>
        index === image.idx ? { ...item, image: {
            ...item.image,
            id: (image.image.id) ? image.image.id : null,
            description: (image.image.description) ? image.image.description : '',
            alt_description: (image.image.alt_description) ? image.image.alt_description : '',
            url: (image.image.urls && image.image.urls.regular) ? image.image.urls.regular : '',
            urlSmall: (image.image.urls && image.image.urls.thumb) ? image.image.urls.thumb : '',
            author: (image.image.user && image.image.user.name) ? image.image.user.name : '',
            authorLink: (image.image.user && image.image.user.links && image.image.user.links.html) ? image.image.user.links.html : '',
            type: 'unsplash',
            download: (image.image.links && image.image.links.download) ? image.image.links.download : '',
        } } : item
    );
    setListData({ ...listData, items: updatedItems });
  };
  const handleSelectIcon = (icon) => {
   console.log('icon',icon)
    const updatedItems = listData.items.map((item,index) =>
        index === icon.idx ? { ...item, icon: {
            ...item.icon,
            id: (icon.icon.id) ? icon.icon.id : null,
            description: (icon.icon.description) ? icon.icon.description : '',
            alt_description: (icon.icon.alt_description) ? icon.icon.alt_description : '',
            url: (icon.icon.urls && icon.icon.urls.regular) ? icon.icon.urls.regular : '',
            urlSmall: (icon.icon.urls && icon.icon.urls.thumb) ? icon.icon.urls.thumb : '',
            author: (icon.icon.user && icon.icon.user.name) ? icon.icon.user.name : '',
            authorLink: (icon.icon.user && icon.icon.user.links && icon.icon.user.links.html) ? icon.icon.user.links.html : '',
            type: 'unsplash',
            download: (icon.icon.links && icon.icon.links.download) ? icon.icon.links.download : '',
        } } : item
    );
    setListData({ ...listData, items: updatedItems });
  };
  
  const handleDragStart = (event, index) => {
    event.stopPropagation();
    event.dataTransfer.setData('text/plain', index);
    setIsDragging(true);
};

const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
};
/*
const handleDrop = async (event, targetIndex) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData('text/plain');
    const draggedItem = listData.items[draggedIndex];

    const newItems = [...listData.items];
    newItems.splice(draggedIndex, 1);
    newItems.splice(targetIndex, 0, draggedItem);

    let newListData = listData;
    newListData['items'] = newItems;
    setListData(newListData);
    onUpdate(newListData); 
};
*/

const handleDrop = async (event, targetIndex) => {
  event.preventDefault();
  const draggedIndex = event.dataTransfer.getData('text/plain');
  const draggedItem = listData.items[draggedIndex];
  const targetItem = listData.items[targetIndex];

  const newItems = [...listData.items];
  newItems[draggedIndex] = targetItem;
  newItems[targetIndex] = draggedItem;

  let newListData = {... listData, items: newItems};
  setListData(newListData);
  onUpdate(newListData); 
};






function customHint(editor) {
  const cursor = editor.getCursor();
  const token = editor.getTokenAt(cursor);
  
  const prefix = token.string.trim().split('.')[0];

  // Adjusted this line to fetch properties from the object directly
  const propsList = data[prefix];

  let list = [];
  if (propsList) {
    list = propsList;
  }

  return {
    list: list,
    from: { line: cursor.line, ch: token.start },
    to: { line: cursor.line, ch: token.end }
  };
}

  return (
    <div>
      <GriderLayout left={'Background'} 
        right={<input
          type="color"
          value={listData.background}
          onChange={(e) => setListData({ ...listData, background: e.target.value })}
        />} 
      />
      
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Headline'}
        value={listData.headline}
        onChange={(e) => setListData({ ...listData, headline: e.target.value })}
      />
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Content'}
        value={listData.content}
        onChange={(e) => setListData({ ...listData, content: e.target.value })}
      />
      
      <h5 className='fw-bold'>Button</h5>
      <br />
      <>
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Button Label'}
          value={listData?.button?.label}
          onChange={(e) =>
            setListData({ ...listData, button: {...listData.button, label: e.target.value }})
          }
        />
      </label>
      <br />
      <GriderLayout
        left={'Button Type:'}
        right={<select
          value={listData?.button?.type}
          onChange={(e) =>
            setListData({ ...listData, button: {...listData.button, type: e.target.value }})
          }        >
          <option value="url">URL</option>
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>}
      />
      <br />
      <GriderLayout left={'External Link'} 
        right={<input
          type="checkbox"
          value={true}
          checked={listData?.button?.external}          
          onChange={(e) =>
            setListData({ ...listData, button: {...listData.button, external: e.target.checked }})
          }
        />}
      />
      <br />
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Button Link'}
          value={listData?.button?.link}
          onChange={(e) =>
            setListData({ ...listData, button: {...listData.button, link: e.target.value }})
          }
        />
      </label>
      <br />
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Click Event'}
          value={listData?.button?.clickEvent}          
          onChange={(e) =>
            setListData({ ...listData, button: {...listData.button, clickEvent: e.target.value }})
          }
        />
      </label>
      </>
      {/* Include other props here */}
      {listData.items &&
        listData.items.map((item, index) => (
        <>
        <Accordion
          key={index}                               
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          onTouchStart={(event) => handleDragStart(event, index)}
          onTouchMove={handleDragOver}
          onTouchEnd={(event) => handleDrop(event, index)}
          //ref={drop} 
          style={{ opacity: isDragging ? 0.5 : 1 }}
          >
            
        <StyledAccordionSummary
          //expandIcon={}
        >
          <StyledIconButton>
            <DragIndicatorIcon />
          </StyledIconButton>
          <Typography variant="h6">{item.title}</Typography>
          <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleItemDelete(index,event)}><DeleteIcon /></IconButton>
        </StyledAccordionSummary>
        {/*
        <AccordionSummary>
          <Typography variant="h6">{item.title}</Typography>
        </AccordionSummary>
        */}
        <AccordionDetails>
          <div key={index}>
            <TextField
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Title'}
                value={item.title}
                onChange={(e) => handleItemChange(index, 'title', e.target.value)}
            />
            <label>Content:</label>
            <AIWritingGenerator props={{blockType: 'list', data: item, toUpdate: 'item.content'}} onUpdate={(e) => handleItemChange(index, 'content', e)} />
            <TextareaAutosize
                name="content"
                minRows={4}
                style={{width: '100%'}}
                aria-label="Content"
                placeholder="Content"
                value={item.content}
                onChange={(e) => handleItemChange(index, 'content', e.target.value)}
            />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'Button Label'}
                  value={(item.button) ? item.button.label: ''}
                  onChange={(e) =>
                      handleItemChange(index, 'button', { ...item.button, label: e.target.value })
                  }
              />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'Button Link'}
                  value={(item.button) ? item.button.link: ''}
                  onChange={(e) =>
                      handleItemChange(index, 'button', { ...item.button, link: e.target.value })
                  }
              />
              <GriderLayout left={'External Link'} 
                right={<input
                  type="checkbox"
                  value={true}
                  checked={item?.button?.external}
                  onChange={(e) =>
                      handleItemChange(index, 'button', { ...item.button, external: e.target.checked })
                  }
                />} 
              />
            <label>Image:</label>
            {/*<input
              type="text"
              value={item.image.url}
              onChange={(e) =>
                handleItemChange(index, 'image', { ...item.image, url: e.target.value })
              }
            />*/}
            {/*item.image && item.image.url && <img src={item.image.url} width={'100%'} />*/}
            <ImageUploaderNew image={item.image} onUpdateImage={(e) => handleSelectImage({idx: index, image: e})} />
            <ImageUploaderNew image={item.icon} onUpdateImage={(e) => handleSelectIcon({idx: index, icon: e})} />
            <h4>Classes</h4>
            <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Classes Item'}
                  value={item?.cssClasses?.item}
                  onChange={(e) => handleItemChange(index, 'cssClasses.item', e.target.value)}
              />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Classes Text Block'}
                  value={item?.cssClasses?.textBlock}
                  onChange={(e) => handleItemChange(index, 'cssClasses.textBlock', e.target.value)}
              />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Classes Image Block'}
                  value={item?.cssClasses?.imageBlock}
                  onChange={(e) => handleItemChange(index, 'cssClasses.imageBlock', e.target.value)}
              />
              <h4>Styles</h4>
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Styles Item'}
                  value={item?.cssStyles?.item}
                  onChange={(e) => handleItemChange(index, 'cssStyles.item', e.target.value)}
              />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Styles Text Block'}
                  value={item?.cssStyles?.textBlock}
                  onChange={(e) => handleItemChange(index, 'cssStyles.textBlock', e.target.value)}
              />
              <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'CSS Styles Image Block'}
                  value={item?.cssStyles?.imageBlock}
                  onChange={(e) => handleItemChange(index, 'cssStyles.imageBlock', e.target.value)}
              />

          </div>
          </AccordionDetails>
        </Accordion>
        </>
        ))}
        <button onClick={handleAddItem}>Add Item</button>
        <br />
        Custom Template Code:
        <div style={{width: '100%'}}>
            {/*<AceCodeEditor
            data={data}
            value="Hello, "
            onChange={(newValue) => console.log(newValue)}
            width="100%"
            height="400px"
          />*/}
          {/*<CodeMirror
            value={listData.templateCode}
            onBeforeChange={(editor, data, value) => { setListData({ ...listData, templateCode: value }) }}
            options={{
              mode: 'javascript',
              theme: 'default',
              lineNumbers: true,
              extraKeys: {
                'Ctrl-Space': 'autocomplete',
              },
              hintOptions: {
                hint: customHint,
              },
            }}
          />*/}
        </div>
        <TextareaAutosize
                name="templateCode"
                minRows={4}
                style={{width: '100%', fontFamily: 'monospace !important'}}
                aria-label="Custom Template Code"
                placeholder='
                <div class="container bg-dark text-light">
                  Hello, {{headline}}!
                  {% loop items as item %}
                  <div>{{item.title}}</div>
                  {% endloop %}
                  </div>
                '
                value={listData.templateCode}
                onChange={(e) => setListData({ ...listData, templateCode: e.target.value })}
            />          
          <GriderLayout left={'Use Custom Template'} 
            right={<input
              type="checkbox"
              value={true}
              checked={listData.useCustomTemplate}
              onChange={(e) => setListData({ ...listData, useCustomTemplate: e.target.checked })}
            />} 
          />
          <TextareaAutosize
                  name="customCss"
                  minRows={4}
                  style={{width: '100%', fontFamily: 'monospace !important'}}
                  aria-label="Custom CSS"
                  placeholder='Your styles here'
                  value={listData.customCss}
                  onChange={(e) => setListData({ ...listData, customCss: e.target.value })}
              />              
          <GriderLayout left={'Use Block Version'} 
            right={<input
              type="checkbox"
              value={'block'}
              checked={(listData.version==='block')}
              onChange={(e) => setListData({ ...listData, version: e.target.checked ? 'block' : 'old' })}
            />} 
          />
          <GriderLayout left={'Hide'} 
            right={<input
              type="checkbox"
              value={true}
              checked={listData.isHidden}
              onChange={(e) => setListData({ ...listData, isHidden: e.target.checked})}
            />} 
          />
        <br />
      <Button variant='outlined' color='primary' className='my-5' fullWidth onClick={handleSave}>Save</Button>
    </div>
  );
};

export default ListForm;
