import { Grid } from "@mui/material"

export const GriderLayout = ({left,right}) => {
    return(    
      <Grid container spacing={2} className='border-bottom mb-2'>
        <Grid item style={{ flex: '1 0 auto' }}>
            {left}
        </Grid>
        <Grid item style={{ minWidth: 0 }} className='text-end'>
            {right}
        </Grid>
      </Grid>
    )
}