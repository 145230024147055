import styled from '@emotion/styled';
import {Col, Container, Row} from 'react-bootstrap';

const StyledDiv = styled.div`
  width: 100%;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background-color: ${props => props.backgroundColor};
`;

const HeroBlock = ({ block, blockId }) => {
    
  const theblock = block;

  const style = (block && block.componentDesign) ? block.componentDesign.identifier : 'fullbackground';
  if(style==='fullbackground'){
    return (
      <StyledDiv backgroundColor={(theblock && theblock.background) ? theblock.background : ""} className={` border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <div className='container py-5' >
          <div className="row">
            <svg className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392"
                   style={{marginLeft: '-445px', position: 'absolute', marginTop: '-40px', opacity: 0.2}}>
              <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor">
                  </rect>
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)">
              </rect>
            </svg>
            <div 
              className={`col-md-6 ${(theblock && theblock.image && theblock.image.align && theblock.image.align==="right") ? "order-second" : "order-first" }`}
              style={{zIndex: 40, backgroundImage: (theblock && theblock.image && theblock.image.media) ? `url('${theblock.image.media.url}')` : '', backgroundPosition: 'center center', backgroundSize: 'cover'}}
            >
            </div>
            <div className='col-md-6' style={{zIndex: 40}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 py-5">
                    <div dangerouslySetInnerHTML={{ __html: theblock.content }} />
                    <span className='btn btn-primary btn-lg mt-3'>Contact Us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
    );

  } else if(style==='1/3rdImgLeft' || style==='1/3rdImgRight'){
    return (
      <StyledDiv backgroundColor={(theblock && theblock.background) ? theblock.background : ""} className={` border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <div className='container py-5' >
          <div className="row border shadow-sm mb-5 bg-white rounded d-flex align-items-stretch">
            
          <svg className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392"
                   style={{marginLeft: '-445px', position: 'absolute', marginTop: '-40px', opacity: 0.2}}>
              <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor">
                  </rect>
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)">
              </rect>
            </svg>
            <div 
              className={`col ${(style==="1/3rdImgLeft") ? "order-second" : "order-first" }`}
              style={{backgroundImage: (theblock && theblock.image && theblock.image.media) ? `url('${theblock.image.media.url}')` : '', 
              backgroundPosition: 'center center', 
              backgroundSize: 'cover', 
              minHeight: '100%',
              zIndex: 40,
            }}
            >.
            </div>
            <div className='col-md-8 d-flex align-items-center' style={{minHeight: "560px", zIndex: 40}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 py-5">
                    <div dangerouslySetInnerHTML={{ __html: theblock.content }} />
                    <span className='btn btn-primary btn-lg mt-3'>Contact Us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
    );

  } else{
      return (
        <StyledDiv backgroundColor={(theblock && theblock.background) ? theblock.background : ""}>
          <Container>
            <div className="row">
              <div className='col-md-6 py-5' style={{zIndex: 40}}>
                <div dangerouslySetInnerHTML={{ __html: theblock.content }} />
              </div>
              <div 
                className={`col-md-6 ${(theblock && theblock.image && theblock.image.align && theblock.image.align==="right") ? "order-second" : "order-first" }`}
                style={{zIndex: 40, backgroundImage: (theblock && theblock.image && theblock.image.media) ? `url('${theblock.image.media.url}')` : '', backgroundPosition: 'center center', backgroundSize: 'cover'}}
              >
              <svg className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392"
                   style={{marginLeft: '-445px', position: 'absolute', marginTop: '-40px', opacity: 0.2}}
                  >
                <defs>
                  <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor">
                    </rect>
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)">
                </rect>
              </svg>
                {(theblock && theblock.image) && <img loading='lazy' src={(theblock && theblock.image && theblock.image.media) ? theblock.image.media.url : ''} width="100%" className='d-none' />}
              </div>
            </div>
          </Container>
        </StyledDiv>
      );
    }
  };

export default HeroBlock;