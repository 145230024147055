// EditorContext.js
import React from 'react';

const EditorContext = React.createContext({
  changeScreenType: false,
  font: {},
  fontIndex: null,
  colorPallette: [],
  editedSectionIndex: false,
  // Add more properties for templates, settings, etc.
  // template: '',
  // settings: {},
  setEditorData: () => {},
});

export default EditorContext;
