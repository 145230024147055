import React from 'react';
import { Block } from '../components/Block';
import DOMPurify from 'dompurify';

const About = (props) => {
  const sanitizedHtml = DOMPurify.sanitize(props.texts);
  // 
  return (
  <div className='App'>
      <Block children={sanitizedHtml}></Block>
  </div>
  );
};

export default About;
