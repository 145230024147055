import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import AwsPublish from './AwsPublish';

const HeaderContainer = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
  text-align: left;
  background: aliceblue;
  border-bottom: 2px solid;
`;

const downloadHTMLFile = () => {
  
  const htmlContent = localStorage.getItem('htmlContent');
  const fileName = 'downloaded-file.html';

  // Create a blob from the HTML content
  const blob = new Blob([htmlContent], { type: 'text/html' });

  // Create a data URL from the blob
  const dataUrl = URL.createObjectURL(blob);

  // Create a temporary anchor element
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = fileName;

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up the temporary URL object
  URL.revokeObjectURL(dataUrl);
}

const Header = ({name, children}) => {
  const [sitePublished, setSitePublished] = useState(false);
  return (
    <HeaderContainer>
      {name && <h3>{name}</h3>}
      {' '}
      <Button variant="outlined">Make me look like big company: Rs. 25,000, produces 50+ pages</Button>
      {' '}
      <Button variant="outlined" onClick={downloadHTMLFile}>Download</Button>
      {' '}
      <AwsPublish />
      {' '}
      {children}
    </HeaderContainer>
  );
};

export default Header;
