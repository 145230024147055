import React, { createContext, useState } from 'react';

// Create the context
const MessageContext = createContext();

// Create the provider component
const MessageProvider = ({ children }) => {
    /*
  const [messages, setMessages] = useState([]);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };
  */
 
  const [messages, setMessages] = useState(false);

  const addMessage = (message) => {
    //setMessages((prevMessages) => [...prevMessages, message]);
    setMessages(message)
  };


  return (
    <MessageContext.Provider value={{ messages, addMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };