import React, {useEffect, useState} from 'react';
import HeroBlock from '../components/Blocks/HeroBlock';
import BannerBlock from '../components/Blocks/BannerBlock';
import TestimonialBlock from '../components/Blocks/TestimonialBlock';
import ListBlock from '../components/Blocks/ListBlock';
import GoogleMapsBlock from '../components/Blocks/GoogleMapsBlock';
import CalendlyBlock from '../components/Blocks/CalendlyBlock';
import HeaderBlock from './Blocks/HeaderBlock';
import FooterBlock from './Blocks/FooterBlock';
import FormBlock from './Blocks/FormBlock';
import LogosBlock from './Blocks/LogosBlock';
import FaqsBlock from './Blocks/FaqsBlock';
import StatsBlock from './Blocks/StatsBlock';
import AwardsBlock from './Blocks/AwardsBlock';
import TeamBlock from './Blocks/TeamBlock';
import FeatureBlock from './Blocks/FeatureBlock';
import ProcessBlock from './Blocks/ProcessBlock';
import CtaBlock from './Blocks/CtaBlock';
import TableBlock from './Blocks/TableBlock';
import PricingBlock from './Blocks/PricingBlock';
import HtmlBlock from './Blocks/HtmlBlock';
import TemplateBlock from './Blocks/SmallComponents/TemplateBlock';
import HowItWorksBlock from './Blocks/HowItWorksBlock';
import ModernBlockDesigns from './Blocks/SmallComponents/ModernBlockDesigns';
import BlocksDesignProperties from '../NEW/components/parts/BlocksDesignProperties';
import { generateId } from '../helpers/randoms';
import FreeFormBlock from '../NEW/components/parts/FreeFormBlock';

const WebsitePreview = ({pageData, pageId}) => {
  console.log('preview page id',pageId)
  const chooseBlock = (block,key,_csId=0) =>  {
            const blockType = block?.WebsiteBlock?.type;

            if (!blockType) return; // Exit early if there's no block type
            let updatedBlock = { ...block };

            if (blockType === 'list' && block.pageFill?.section_style === 'logo_list') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'logos' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'customer_list') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'customers' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'stats') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'stats' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'faq') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'faqs' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'awards') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'awards' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'team') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'team' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'feature_list') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'features' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'methods_process_list') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'process' };
            } else if (blockType === 'list' && block.pageFill?.section_style === 'cta') {
              updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'cta' };
            }

            if (block.templateCode && block.templateCode!==undefined && block.templateCode !='' && block.useCustomTemplate) {
              return <TemplateBlock block={block} blockId={key} />;
            }
            if(block.version==='block'){
              return <BlocksDesignProperties block={block} blockId={key} _csId={_csId} />;
            }
            switch (updatedBlock.WebsiteBlock.type) {
            case 'banner':
            return <BannerBlock block={block} blockId={key} />;
            case 'header':
            return <HeaderBlock block={block} blockId={key} />;
            case 'footer':
            return <FooterBlock block={block} blockId={key} />;
            case 'list':
            return <ListBlock block={block} blockId={key} />;
            //return <BlocksDesignProperties block={block} blockId={key} />;
            case 'table':
            return <TableBlock block={block} blockId={key} />;
            case 'pricing':
            return <PricingBlock block={block} blockId={key} />;
            case 'features':
            return <FeatureBlock block={block} blockId={key} />;
            //return <ModernBlockDesigns block={block} blockId={key} />;
            case 'process':
            return <ProcessBlock block={block} blockId={key} />;
            case 'hero':
            return <HeroBlock block={block} blockId={key} />;   
            case 'html':
            return <HtmlBlock block={block} blockId={key} />;        
            case 'full_blog_post':
            return <HtmlBlock block={block} blockId={key} />;        
            case 'quote':
            return <TestimonialBlock block={block} blockId={key} />;
            case 'logos':
            return <LogosBlock block={block} blockId={key} />;
            case 'customers':
            return <LogosBlock block={block} blockId={key} />;
            case 'stats':
            return <StatsBlock block={block} blockId={key} />;
            case 'how_it_works':
            return <HowItWorksBlock block={block} blockId={key} />;
            case 'awards':
            return <AwardsBlock block={block} blockId={key} />;
            case 'team':
            return <TeamBlock block={block} blockId={key} />;
            case 'faqs':
            return <FaqsBlock block={block} blockId={key} />;
            case 'cta':
            return <CtaBlock block={block} blockId={key} />;
            //return <BlocksDesignProperties block={block} blockId={key} />;
            case 'formblock':
            return <FormBlock block={block} blockId={key} />;
            case 'calendly':
            return <CalendlyBlock block={block} blockId={key} />;
            case 'freeform':
            return <FreeFormBlock block={block} blockId={key} />;
            default:
            return null;
        }
    return null;
  }

  /*
  return (
    <>
    {(pageData && pageData.blocks && pageData.blocks.length>0) && pageData.blocks.map((theBlockData, key) => (
      <div id={`section-${key}`} key={key} className={`${(key===1) ? 'top-div-full-blown' : ''} editable-section`} data-pg-id={pageId} data-section-id={key}>{chooseBlock(theBlockData,key)}</div>
    ))}
    </>
  );
  */
  return (
    <>
      {/*(pageData && pageData.blocks && pageData.blocks.length > 0) && 
        pageData.blocks
        .filter(theBlockData => !theBlockData.isHidden) // Filter out blocks that are hidden
        .map((theBlockData, key) => (
          <div id={`section-${key}`} key={key} className={`${(key === 1) ? 'top-div-full-blown' : ''} editable-section`} data-pg-id={pageId} data-section-id={key}>
            {chooseBlock(theBlockData, key)}
          </div>
        ))*/}
      {/*(pageData && pageData.blocks && pageData.blocks.length > 0) &&
        pageData.blocks.map((theBlockData, key) => (
          !theBlockData.isHidden && 
          <div id={`section-${key}`} key={key} className={`${(key === 1) ? 'top-div-full-blown' : ''} editable-section`} data-pg-id={pageId} data-section-id={key}>
            {chooseBlock(theBlockData, key)}
          </div>
        ))
      */}
      {(pageData && pageData.blocks && pageData.blocks.length > 0) && (
        (() => {
          let visibleKey = -1; // Track the index of visible blocks
          let _csId = 0;
          return pageData.blocks.map((theBlockData, key) => {
            if (!theBlockData.isHidden) {
              visibleKey++; // Increment for each visible block
              _csId = generateId();
              return (
                <div
                  id={`section-${key}`}
                  key={key}
                  className={`${visibleKey % 2 === 0 ? 'even-background' : 'odd-background'} ${(visibleKey === 1 && theBlockData.WebsiteBlock.type!=='html') ? 'top-div-full-blown' : ''} editable-section -cs-id-${_csId}`}
                  data-pg-id={pageId}
                  data-section-id={key}
                >
                  {chooseBlock(theBlockData, key, _csId)}
                </div>
              );
            }
            return null;
          });
        })()
      )}
    </>
  );
};

export default WebsitePreview;
