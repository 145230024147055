import { Skeleton } from "@mui/material";
import { Col, Row } from "react-bootstrap";

export const getTemplates = () => {
    const fontsi = [''];
    const fonts = {
      'Crimson Text + Work Sans' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:400,700" rel="stylesheet">',
        style: `body {
          font-family: 'Work Sans', sans-serif;
         }
         h1, h2, h3, h4, h5, h6 {
          font-family: 'Crimson Text', serif;
         }`,
      },
      'Oswald + Cardo' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Cardo:400,700|Oswald" rel="stylesheet">',
        style: `body {
          font-family: 'Cardo', serif;
         }
         h1, h2, h3, h4, h5, h6 {
          font-family: 'Oswald', sans-serif;
          text-transform: uppercase;
         }`,
      },
      'Quicksand (Medium + Light)' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Quicksand:300,500" rel="stylesheet">',
        style: `body {
          font-family: 'Quicksand', sans-serif;
          font-weight: 300;
         }
         h1, h2, h3, h4, h5, h6 {
          font-weight: 500;
         }`,
      },
      'Abril Fatface + Roboto' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Abril+Fatface|Roboto:300,700" rel="stylesheet">',
        style: `body {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
         }
         h1, h2, h3, h4, h5, h6 {
          font-family: 'Abril Fatface', serif;
         }`,
      },           
    };
    const randomFont = () => {
      const fontKeys = Object.keys(fonts);
      const randomIndex = Math.floor(Math.random() * fontKeys.length);
      const randomFontKey = fontKeys[randomIndex];
      return fonts[randomFontKey];
    };
    
    const selectedFont = randomFont();
    return selectedFont;
  }

  export const getAllTemplates = () => {
    const fonts = [
      {
        name: 'Crimson Text + Work Sans',
        design: <Skeleton variant="rectangular" height={150} animation={false} />,
      },
      {
        name: 'Oswald + Cardo',
        design: <>
          <Row>
            <Col variant='md-2'>
              <Skeleton variant="rectangular" height={150} style={{marginBottom: 5}} animation={false} />
            </Col>
            <Col variant='md-10'>              
              <Skeleton variant="rectangular" height={50} style={{marginBottom: 2}} animation={false} />
              <Skeleton variant="rectangular" height={50} style={{marginBottom: 2}} animation={false} />
              <Skeleton variant="rectangular" height={45} style={{marginBottom: 2}} animation={false} />
            </Col>
          </Row>
        </>,
      },
      {
        name: 'Quicksand (Medium + Light)',
        design: <Skeleton variant="rectangular" height={250} animation={false} />,
      },
      {
        name: 'Abril Fatface + Roboto',
        design: <Skeleton variant="rectangular" height={100} animation={false} />,
      },           
    ];
    return fonts;
  }