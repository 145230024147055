import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { generateRandomPath, getRandomIpsumLogo, getRandomRadius, markedToHtml } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const HowItWorksBlock = ({ block, blockId }) => {
    let theblock = {...block};
    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className='row py-5'>
            <div className='col-md-12'>
              <div className="row mb-3">
                <div className='fw-bold col'><h2 className='display-3 text-center'><MarkdownRenderer markdownString={theblock.headline} /></h2></div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className="row px-5 d-flex justify-content-center">
                {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                  <div key={index} className={`col-md-6 col-sm-12 my-3 d-flex`}>
                    <div>
                      <h4 className='display-2 pe-5'>{column.step_number}</h4>
                    </div>
                    <div>
                      <h5>
                        {column.icon && <i className={`${column.icon} me-2`}></i>}
                        {column.title}
                      </h5>
                      <p><MarkdownRenderer markdownString={column.content} /></p>
                    </div>
                  </div>
                ))}
              </div>   
            </div>
          </div>
          <div className='row'>
            <div className='col-12 pb-3'>
              <MarkdownRenderer markdownString={theblock.content} />
            </div>
            <div className='col-12'>
              {theblock.button && theblock.button.label && theblock.button.label.length===0 &&
                <div className='my-3'>
                  {(theblock.button && theblock.button.type==='url' && theblock.button.label!='') ? <a href={theblock.button.link} className='btn btn-primary' style={{backgroundColor: theblock.background}}>{theblock.button.label}</a> : <Button href={theblock.button.link}>{theblock.button.label}</Button>}
                </div>
              }
            </div>
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default HowItWorksBlock;