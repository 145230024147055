// this component will be availiable besides each fields to regenerate content and replace
// this will have dropdowns and various tones, aspects etc,

import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import { getEditedContent, getHeadlineForParagraph, getParagraphForHeadline } from "../../../helpers/prompts";

// this can get the tones, etc from context or previously found way.
const AIWritingGenerator = ({props, onUpdate}) => {
    // you can update this id too if given
    const initialState = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const [open, setOpen] = useState(false);
    const [openAi, setOpenAi] = useState(false);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpenAi(true)
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpenAi(false)
    };
  
    const handleOptionClick = (value) => {
      console.log(value);
      handleClose();
    };
    const handleClicku = () => {

    }
    const handleMainClick = () => {
        setOpen(!open);
        handleClose()
    }
    console.log('anchorEl',anchorEl,'open',open);


    console.log('the component design',props.data.componentDesign);
    if(props.blockType==='banner'){
        return (
            <>
                <div className='text-end'>
                    <span className='btn btn-sm btn-outline-secondary' onClick={handleMainClick}>AI Writer</span>
                </div>
                {open &&
                    <>
                    {(props.toUpdate === 'headline') ?
                        <>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent((props.data.header) ? props.data.headline : props.data.header,'header',(props.data.componentDesign) ? props.data.componentDesign.text.header.limit : 50,'creative')
                            .then((result) => onUpdate(result))}>
                            Re-generate
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent((props.data.header) ? props.data.headline : props.data.header,'header',(props.data.componentDesign) ? props.data.componentDesign.text.header.limit : 50,'creative')
                            .then((result) => onUpdate(result))}>
                            Rewrite what I wrote
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getHeadlineForParagraph(props.data.content,'header',(props.data.componentDesign) ? props.data.componentDesign.text.header.limit : 50,'creative')
                            .then((result) => onUpdate(result))}>
                            Write for the content below
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getHeadlineForParagraph(props.data.sectionBuilderDescription,'header',(props.data.componentDesign) ? props.data.componentDesign.text.header.limit : 50,'creative')
                            .then((result) => onUpdate(result))}>
                            Follow section instruction
                        </span>
                    </> : ''}
                    {(props.toUpdate === 'content') ?
                        <>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent(props.data.content,'content',(props.data.componentDesign) ? props.data.componentDesign.text.content.limit : 80,'creative')
                            .then((result) => onUpdate(result))}>
                            Re-generate
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent(props.data.content,'content',(props.data.componentDesign) ? props.data.componentDesign.text.content.limit : 80,'creative')
                            .then((result) => onUpdate(result))}>
                            Rewrite what I wrote
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getParagraphForHeadline((props.data.header) ? props.data.headline : props.data.header,(props.data.componentDesign) ? props.data.componentDesign.text.content.limit : 80,'creative')
                            .then((result) => onUpdate(result))}>
                            Write for above headline
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getParagraphForHeadline(props.data.sectionBuilderDescription,(props.data.componentDesign) ? props.data.componentDesign.text.content.limit : 50,'creative')
                            .then((result) => onUpdate(result))}>
                            Follow section instruction
                        </span>
                    </> : ''}                        
                    {/*<Button variant="outlined" size="small" onClick={handleClick} className='btn btn-sm btn-outline-secondary' >
                        AI writer
                    </Button>
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={() => handleOptionClick('option1')}>
                            1. Writing form
                        </MenuItem>
                        <MenuItem onClick={() => handleOptionClick('option2')}>
                            2. buttons
                        </MenuItem>
                        <MenuItem onClick={() => handleOptionClick('option3')}>
                            3. Tones
                        </MenuItem>
                        <MenuItem onClick={() => handleOptionClick('option4')}>
                            4. Custom form.
                        </MenuItem>
                        <MenuItem onClick={() => handleOptionClick('option4')}>
                            5. get a professional writer.
                        </MenuItem>
                    </Menu>*/}
                    </>
                }
            </>
        )
    }
    if(props.blockType==='list'){
        return (
            <>
                <div className='text-end'>
                    <span className='btn btn-sm btn-outline-secondary' onClick={handleMainClick}>AI Writer</span>
                </div>
                {open &&
                    <>
                    {(props.toUpdate === 'item.title') ?
                        <>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => 
                                getEditedContent(props.data.title,'heading',(props.data.componentDesign) ? props.data.componentDesign.text.items.header.limit: 7,'creative')
                                .then((result) => onUpdate(result))}>
                                Re-generate
                            </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => 
                                getEditedContent(props.data.title,'heading',(props.data.componentDesign) ? props.data.componentDesign.text.items.header.limit : 7,'creative')
                                .then((result) =>
                                    onUpdate(result))}>
                            Rewrite what I wrote
                        </span>                    
                        </> : ''}
                    {(props.toUpdate === 'item.content') ?
                        <>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent(props.data.content,'paragraph',(props.data.componentDesign) ? props.data.componentDesign.text.items.content.limit : 50)
                            .then((result) => onUpdate(result))}>
                            Re-generate
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getEditedContent(props.data.content,'paragraph',(props.data.componentDesign) ? props.data.componentDesign.text.items.content.limit : 50)
                            .then((result) => onUpdate(result))}>
                            Rewrite what I wrote
                        </span>
                        <span 
                            className='btn btn-sm btn-outline-secondary' 
                            onClick={() => getParagraphForHeadline(props.data.title,(props.data.componentDesign) ? props.data.componentDesign.text.items.content.limit : 50)
                            .then((result) => onUpdate(result))}>
                            Write for above headline
                        </span>
                        </> : ''}
                    </>
                }
            </>
        )
    }
}
export default AIWritingGenerator;

// let's design your website. Do you want website for your a. business, b. personal website, c. ngo, d. other?
// okay: what's your goal of making this website? a. selling services, b. raising funds, c. getting job offers 