import React, { useState } from 'react';
import { parseTemplate } from '../../../helpers/templateParser';

const TemplateBlock = ({ block, blockId }) => {
    let theblock = {...block};
    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const renderResult = parseTemplate(theblock.templateCode, theblock);

    return (
        <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
            {theblock.customGridCss && <style>{theblock.customGridCss.replace("\n","")}</style>}
            <div dangerouslySetInnerHTML={{ __html: renderResult }} />
        </div>
    );
}

export default TemplateBlock;
