import { Link, useParams } from "react-router-dom";
import WebsitePreviewerLive from "../../../components/WebsitePreviwerLive";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import { getAllFontCombos, getFontCombos } from "../../../helpers/fontChoices";
import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DynamicFontComponent } from "../../components/DynamicFontComponent";
import { getAllTemplates } from "../../../helpers/templateChoices";
import ColorPalette from "../../components/ColorPalette";
import { supabase } from "../../../supabase";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import EditorForm from "../../components/Forms/EditorForm";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { getAllAnnouncementPositions } from "../../../helpers/announcements";
import EditorContext from "../../contexts/EditorContext";
import SingleWebsitePreview from "./SingleWebsitePreview";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getAllForms } from "../../../helpers/forms";
import SEOForm from "../../components/Forms/SEOForm";
import { SEOSettings } from "../../../helpers/seoFormat";
import LogoForm from "../../components/Forms/LogoForm";
import { setCurrentEditedWebsitePageId, updateWebsite } from "../../redux/slices/websiteSlice";
import { useDispatch, useSelector } from "react-redux";
import DomainForm from "../../components/Forms/DomainForm";
import AnalyticsForm from "../../components/Forms/AnalyticsForm";
import ColorPickerComponent from "../../components/ColorPickerComponent";
import { updateThemeVariables } from "../../../helpers/randoms";


const FontSelection = React.memo(({ fontCombos, onSelectFontCombo, selectedFontCombo }) => {
    const { theSiteId } = useParams();
    //const [theIndex, setTheIndex] = useState(null);
    const { font, fontIndex, colorPallette,  setEditorData } = useContext(EditorContext);    
    const handleFontChange = (selectedFont,selectedFontIndex) => {
        setEditorData((prevEditorData) => ({
          ...prevEditorData,
          font: selectedFont,
          fontIndex: selectedFontIndex,
        }));
        onSelectFontCombo(selectedFont,selectedFontIndex)
      };
    const changeFontCombo = (font,index) => {
        console.error('Font selected',font)
        //setFontCombo(font)
        //setFontComboIndex(index)
        onSelectFontCombo(font,index)
        //handleFontChange(font,index)
    }
    return (
      <>
        {fontCombos.map((singleFontCombo, index) => (
          <div
            key={index}
            onClick={() => { handleFontChange(singleFontCombo,index)}}
            className={`${(index===fontIndex) ? 'bg-gray':''} border-bottom pointer mt-3`}
            role="button"
          >
            {(index===fontIndex) ? <TaskAltIcon color="primary" /> : ''}
            <div>
                <DynamicFontComponent font={singleFontCombo} />
            </div>
          </div>
        ))}
      </>
    );
  });
  const ColorSelection = React.memo(({ colorCombos, onSelectColorCombo }) => {
    
    const { theSiteId } = useParams();
    const websiteData = useSelector(state => state.websites?.websites?.find(website => website.id === parseInt(theSiteId))); 
      return (
        <>
          {colorCombos.map((singleColorCombo, index) => (
            <div
              key={index}
              onClick={() => onSelectColorCombo(singleColorCombo)}
              className="border-bottom pointer mt-3"
              role="button"
            >
            </div>
          ))}
        </>
      );
    });
    const FormSelection = React.memo(({ forms, onSelectForm }) => {
      
      const { theSiteId } = useParams();
        let allForms = (forms) ? forms : getAllForms();
        return (
          <>
            {allForms && allForms.map((singleForm, index) => (
              <div
                key={index}
                onClick={() => onSelectForm(singleForm)}
                className="border-bottom pointer mt-3"
                role="button"
              >
                {singleForm.name}
              </div>
            ))}
          </>
        );
      });

    const ThemeCssVariables = ({siteId}) => {
      async function fetchCSSAndExtractVariables(cssUrls) {
        const cssVarRegex = /(--[\w-]+)\s*:\s*([^;}]+)/g;
      
        async function fetchCSS(url) {
          const response = await fetch(url);
          return await response.text();
        }
      
        function extractVariables(cssContent) {
          const variables = [];
          let match;
          while ((match = cssVarRegex.exec(cssContent))) {
            variables.push({ name: match[1], value: match[2] });
          }
          return variables;
        }
      
        const allVariables = [];
        for (const url of cssUrls) {
          const cssContent = await fetchCSS(url);
          const variables = extractVariables(cssContent);
          allVariables.push(...variables);
        }
      
        return allVariables;
      }

      const [cssVariables, setCssVariables] = React.useState([]);
      const [finalCssVariables, setFinalCssVariables] = React.useState([]);
      const { theSiteId } = useParams();
      const dispatch = useDispatch();
      const websiteData = useSelector(state => state.websites?.websites?.find(website => website.id === parseInt(theSiteId)));
      let themeColors = [
        { name: '--cs-darkShade', value: '#333333' },
        { name: '--cs-lightShade', value: '#f5f5f5' },
        { name: '--cs-lightAccent', value: '#c8e6c9' },
        { name: '--cs-primaryTinted', value: '#ffcc80' },
        { name: '--cs-brandColor', value: '#ff5722' },
        { name: '--cs-oppositeColor', value: '#03a9f4' }
      ];
      console.log('themeColors type:', Array.isArray(themeColors)); // should be true
      console.log('cssVariables type:', Array.isArray(websiteData?.cssVariables)); // should be true
      
      if(websiteData?.cssVariables){
        themeColors = updateThemeVariables(themeColors,websiteData.cssVariables);
      }
      /*
      React.useEffect(() => {
        setFinalCssVariables([...websiteData?.cssVariables]);
      }, [websiteData]);
      */

      React.useEffect(() => {
        // Check if websiteData.cssVariables is an array, if not, default to an empty array
        const cssVariables = Array.isArray(websiteData?.cssVariables) ? websiteData.cssVariables : [];
        setFinalCssVariables([...cssVariables]);
      }, [websiteData]);
      

      React.useEffect(() => {
        const cssUrls = ['https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css', 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css'];
        fetchCSSAndExtractVariables(cssUrls)
          .then(vars => {
            setCssVariables([...themeColors, ...vars]);
          })
          .catch(error => {
            console.error('Failed to fetch or parse CSS', error);
          });
      }, []);
      
      function isColor(value) {
        // Enhanced pattern to match hex, rgb, rgba, hsl, hsla, and named colors
        const hexPattern = /^#([0-9a-f]{3,4}|[0-9a-f]{6}|[0-9a-f]{8})\b/i;
        const rgbPattern = /^(rgb|hsl)a?\(/i;
        const namedColorsPattern = /^[a-z]+$/i; // This is a simplistic check for named colors

        return hexPattern.test(value.trim()) || 
              rgbPattern.test(value.trim()) || 
              namedColorsPattern.test(value.trim());
      }
      function convertShorthandHexToFull(hex) {
        if (hex.length === 4) {
          return '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
        }
        return hex;
      }
      const handleColorChange = (key, newColor) => {
        // Convert shorthand hex to full hex if needed
        const fullColor = convertShorthandHexToFull(newColor);
        // Update the specific CSS variable with the new color
        /*
        const newCssVariables = cssVariables.map((variable, index) =>
          index === key ? { ...variable, value: fullColor } : variable
        );
        */
        let updated = false;
        let updatedTwo = false;
        const newCssVariables = cssVariables.map((variable) => {
          if (variable.name === key) {
            updated = true;
            return { ...variable, value: fullColor };
          }
          return variable;
        });
        
        if (!updated) {
          newCssVariables.push({ name: key, value: fullColor });
        }
        
        setCssVariables(newCssVariables);
        
        const newTwoCssVariables = finalCssVariables.map((variable) => {
          if (variable.name === key) {
            updatedTwo = true;
            return { ...variable, value: fullColor };
          }
          return variable;
        });
        
        if (!updatedTwo) {
          newTwoCssVariables.push({ name: key, value: fullColor });
        }
        console.log(newTwoCssVariables,theSiteId);
        setFinalCssVariables(newTwoCssVariables);
        dispatch(updateWebsite({id: parseInt(theSiteId),data: {cssVariables: newTwoCssVariables}}))
      };
    
      const handleValueChangeOld = (key, newValue, newUnit) => {
        // Construct the new value with unit
        const valueWithUnit = `${newValue}${newUnit}`;
        // Update the specific CSS variable with the new value
        const newCssVariables = cssVariables.map((variable, index) =>
          index === key ? { ...variable, value: valueWithUnit } : variable
        );
        setCssVariables(newCssVariables);
        
        const newTwoCssVariables = finalCssVariables.map((variable, index) =>
          index === key ? { ...variable, value: valueWithUnit } : variable
        );
        setFinalCssVariables(newTwoCssVariables);
      };
      const handleValueChange = (key, newValue, newUnit) => {
        // Construct the new value with unit
        const valueWithUnit = (newUnit) ? `${newValue}${newUnit}` : `${newValue}`;
        
        let updated = false;
        let updatedTwo = false;
      
        // Update or add the specific CSS variable with the new value
        const newCssVariables = cssVariables.map((variable) => {
          if (variable.name === key) {
            updated = true;
            return { ...variable, value: valueWithUnit };
          }
          return variable;
        });
      
        if (!updated) {
          newCssVariables.push({ name: key, value: valueWithUnit });
        }
      
        setCssVariables(newCssVariables);
      
        // Similar update or add operation for finalCssVariables
        const newTwoCssVariables = finalCssVariables.map((variable) => {
          if (variable.name === key) {
            updatedTwo = true;
            return { ...variable, value: valueWithUnit };
          }
          return variable;
        });
      
        if (!updatedTwo) {
          newTwoCssVariables.push({ name: key, value: valueWithUnit });
        }
      
        setFinalCssVariables(newTwoCssVariables);
        dispatch(updateWebsite({id: parseInt(theSiteId),data: {cssVariables: newTwoCssVariables}}))
      };

      const removeVariable = (name) => {
        // Filter out the variable with the specified name from cssVariables
        //const updatedCssVariables = cssVariables.filter(variable => variable.name !== name);
        //setCssVariables(updatedCssVariables);
      
        // Filter out the variable with the specified name from finalCssVariables
        const updatedFinalCssVariables = finalCssVariables.filter(variable => variable.name !== name);
        setFinalCssVariables(updatedFinalCssVariables);
        dispatch(updateWebsite({id: parseInt(theSiteId),data: {cssVariables: updatedFinalCssVariables}}))
      };
      
      

      return (
        <>
          {finalCssVariables && finalCssVariables.map((cssVariable,key) => (
              <div className="border-bottom row">
                <div className="col">
                  {cssVariable.name}:
                </div>
                <div className="col">
                  {cssVariable.value};
                </div>
                <div className="col text-end">
                  <span className="btn btn-danger" onClick={(e) => removeVariable(cssVariable.name)}>
                    X
                  </span>
                </div>
              </div>
          ))}
          {cssVariables && cssVariables.filter((variable, index, self) => 
              index === self.findIndex((t) => (
                t.name === variable.name
              ))
            ).map((cssVariable, key) => {
            const isUnitType = /(\d+)(px|rem|em|pt|%|vh|vw)$/i.test(cssVariable.value);
            let match = cssVariable.value.match(/(\d+)(px|rem|em|pt|%|vh|vw)$/i);
            return (
              <div key={key} className="row py-1">
                <Col className="p-0 m-0">
                  {cssVariable.name}
                </Col>
                <Col className="p-0 m-0 text-end ps-auto">
                  {isColor(cssVariable.value) ? (
                    <>
                      <ColorPickerComponent
                        initialColor={convertShorthandHexToFull(cssVariable.value)}
                        onColorChange={(newColor) => {
                          handleColorChange(cssVariable.name, newColor);
                        }}
                      />
                      {/*<input 
                        type="color" 
                        name="value" 
                        value={convertShorthandHexToFull(cssVariable.value)} 
                        onChange={(e) => {
                          handleColorChange(cssVariable.name,e.target.value)
                        }}
                        className="form-control"
                      />*/}
                    </>
                  ) : isUnitType ? (
                    <>
                    {/*<Row>
                      <Col className="pe-0 m-0">
                      <input 
                        type="number" 
                        name="value" 
                        value={match ? match[1] : ''} 
                        onChange={e => {
                          handleValueChange(cssVariable.name, e.target.value,'rem');
                        }}
                        className="form-control"
                      />
                      </Col>
                      <Col className="ps-0 m-0">
                      <select 
                        name="unit" 
                        value={match ? match[2] : 'px'} 
                        onChange={e => {
                          handleValueChange(cssVariable.name, cssVariable.value, e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="px">px</option>
                        <option value="rem">rem</option>
                        <option value="em">em</option>
                        <option value="pt">pt</option>
                        <option value="%">%</option>
                        <option value="vh">vh</option>
                        <option value="vw">vw</option>
                      </select>
                      </Col>
                      </Row>*/}
                      <Row>
                        <Col className="pe-0 m-0">
                          <input 
                            type="number" 
                            name="value" 
                            value={match ? match[1] : ''} 
                            onChange={e => {
                              // When the value changes, call handleValueChange with the new value and the current unit
                              const currentUnit = match ? match[2] : 'px';
                              handleValueChange(cssVariable.name, e.target.value, currentUnit);
                            }}
                            className="form-control"
                          />
                        </Col>
                        <Col className="ps-0 m-0">
                          <select 
                            name="unit" 
                            value={match ? match[2] : 'px'} 
                            onChange={e => {
                              // When the unit changes, call handleValueChange with the current value and the new unit
                              const currentValue = match ? match[1] : '';
                              handleValueChange(cssVariable.name, currentValue, e.target.value);
                            }}
                            className="form-control"
                          >
                            <option value="px">px</option>
                            <option value="rem">rem</option>
                            <option value="em">em</option>
                            <option value="pt">pt</option>
                            <option value="%">%</option>
                            <option value="vh">vh</option>
                            <option value="vw">vw</option>
                          </select>
                        </Col>
                      </Row>
                      </>
                  ) : (
                    <input 
                      type="text" 
                      name="value" 
                      value={cssVariable.value} 
                      onChange={(e) => {
                        // Update the value of the cssVariable here
                        handleValueChange(cssVariable.name, e.target.value);
                      }}
                      className="form-control"
                    />
                  )}
                </Col>
              </div>
            );
          })}
        </>
      )
    }

    const TemplateSelection = React.memo(({templates, onSelectTemplate}) => {
      const { theSiteId } = useParams();
        return (<>
            {/*templates.map((singleTemplate,index) => (
                <div onClick={() =>onSelectTemplate(singleTemplate)} key={index} className="border-bottom pointer mt-3" role="button">
                    {singleTemplate.name}
                    {singleTemplate.design}
                </div>
            ))*/}
            <ThemeCssVariables siteId={theSiteId} />
        </>)
    })
    

    const AnnouncementSelection = React.memo(({announcements, onSelectAnnouncement}) => {
      const { theSiteId } = useParams();
        return (<>
            {announcements.map((singleAnnouncement,index) => (
                <div onClick={() =>onSelectAnnouncement(singleAnnouncement)} key={index} className="border-bottom pointer mt-3" role="button">
                    {singleAnnouncement.name}
                    {singleAnnouncement.design}
                </div>
            ))}
        </>)
    })
    
    const WebsiteEditorTypeSections = React.memo(({onChangeScreenType}) => {
        const editorTypes = [
          {name: 'Logo', message: 'Add/Edit Logo'},
          {name: 'Font', message: 'Choose/Edit Font/Text Designs'},
          {name: 'Color', message: 'Select/Change Colors'},
          {name: 'Template', message: 'Choose Pre-designed Templates'},
          {name: 'Page', message: 'Add/Edit the Pages, Sections and Contents'},
          {name: 'SEO', message: 'Optimize website for Google, Bing etc Search Engines'},
          {name: 'Analytics', message: 'Understand website visitors'},
          {name: 'Form', message: 'Generate Forms'},
          {name: 'Announcements & chat', message: 'Popup Announcements and Chats'},
          {name: 'Email & CRM', message: 'Get the data from submitted form / Connect your CRM'},
          {name: 'Domain', message: 'Connect the site to a domain/purchase'}
        ];
        return(
            <div className="sticky-top">
                <Link className="h3" style={{lineHeight: '3rem', textDecoration: 'none'}} to={'/dashboard'}>Back to Home</Link>
                <hr />
                {editorTypes.map((editorType,index) => (
                    <div className="border-bottom py-2" role='button' onClick={() => onChangeScreenType(editorType.name)} key={index}>
                      <span className="h4 d-block fw-bold ">{editorType.name}</span>
                      <small>{editorType.message}</small>
                    </div>
                ))}
            </div>
        )
    })
    
    const ColorPalettesBox = React.memo(({onChangeColorPallette}) => {
      const { theSiteId } = useParams();
        const colorPalettes = ['Default','Light','Dark','Multicolor','BandStand','Dark1','Multicolor1','BandStand'];
        return(
            <>
                {colorPalettes.map((colorPalette,index) => (
                    <div key={index}>
                        <ColorPalette onColorPalletSelect={(e) => onChangeColorPallette(e)} />
                    </div>
                ))}
            </>
        )
    })

    const EditorSidebar = React.memo(({onDataUpdate}) => {
         
        const dispatch = useDispatch();
        const { colorPallette,  setEditorData } = useContext(EditorContext);
        
          const handleColorChange = (selectedColor) => {
            setEditorData((prevEditorData) => ({
              ...prevEditorData,
              colorPallette: selectedColor,
            }));
          };

          const handleScreenTypeChange = (screen) => {
            /*
            setEditorData((prevEditorData) => ({
              ...prevEditorData,
              changeScreenType: screen,
            }));
            */
           
           dispatch(setCurrentEditedWebsitePageId(null))
           setChangeScreenType(screen)
           localStorage.setItem('EditorScreenOpen',screen)
          };
        
        const [changeScreenType, setChangeScreenType] = useState(localStorage.getItem('EditorScreenOpen') ? localStorage.getItem('EditorScreenOpen') : false);
        const { theSiteId } = useParams();
        const leftSideBarContent = <DashboardSideBarMenu />
        const fontCombos = getAllFontCombos();
        const templates = getAllTemplates();
        const announcements = getAllAnnouncementPositions();
        const SEOSettingsJSON = SEOSettings();
    
        const [fontCombo, setFontCombo] = useState({});
        const [fontComboIndex, setFontComboIndex] = useState(null);
        const [template, setTemplate] = useState('');
        const [websiteHtml, setWebsiteHtml] = useState('');
        const [selectedColorPalette, setSelectedColorPalette] = useState([]);
    
        const [updateCount, setUpdateCount] = useState(0);
    
        const [viewportWidth, setViewportWidth] = useState('100%');
        const htmlChanged = (html) => {
            setWebsiteHtml(html)
        }
        const changeColorPallette = (color) => {
            //onChangeColorPallette(color)
            //setSelectedColorPalette(color)
            handleColorChange(color)
            onUpdateBrandGuide('colorCombo',color)
        }
        const changeTamplate = (template) => {
            setTemplate(template)
            //onChangeTemplate(template)
        }
        
        const dataUpdated = () => {
            console.error('Content modified')
            setUpdateCount(updateCount+1)
            onDataUpdate(updateCount+1)
        }

        
        const onUpdateBrandGuideOld = async (theDbKey,data,theKey) => {
          const key = `brandGuide->${theDbKey}`;
          const { data: websiteData, error } = await supabase
          .from('website')
          .update({[key]: {...data,id:theKey} || supabase.postgres(key) || '{}' })
          .eq('id', theSiteId)
          .select()

          console.log('font updated',websiteData)
          dataUpdated()

        }        
        const onUpdateBrandGuide = async (key, value, valueKeyProp) => {

          console.log('To Brand update',key,value,valueKeyProp)

          // Retrieve the existing JSONB data from the table
          const { data, error } = await supabase
            .from('website')
            .select('brandGuide')
            .eq('id', theSiteId)
            .single();

          if (error) {
            console.error('Error retrieving data brand guide:', error);
            return;
          }

          // Check if the key exists in the JSONB data
          
          if (!data?.brandGuide?.hasOwnProperty(key)) {
            console.error(`Key '${key}' does not exist in the 'brandGuide' object.`);
            //return;
          }

          // Modify the JSONB data by updating the key
          let updatedData = {};
          if(valueKeyProp){
            updatedData = {
              ...data,
              brandGuide: {
                ...data.brandGuide,
                [key]: {...value, id: valueKeyProp},
              },
            };
          } else{            
            updatedData = {
              ...data,
              brandGuide: {
                ...data.brandGuide,
                [key]: {...value},
              },
            };
          }
          
          console.log('To Brand update modified',updatedData)

          // Update the JSONB data in the table
          /*
          const { error: updateError } = await supabase
            .from('website')
            .update({ brandGuide: updatedData.brandGuide })
            .eq('id', theSiteId);

          if (updateError) {
            console.error('Error updating data:', updateError);
            return;
          }
          */

          dispatch(updateWebsite({id: theSiteId,data: {brandGuide: updatedData.brandGuide}}))

          console.log('Data column updated successfully!');
        };
        
        const palletteWidth = {
            0: '30%',
            1: '30%',
            2: '10%',
            3: '15%',
            4: '15%',
        }

        console.log('screen type: ',changeScreenType)

        const ChangeScreenMain = () => {            
            if(!changeScreenType || changeScreenType===undefined || changeScreenType === '' || changeScreenType === 'false' || changeScreenType===null){
                return <WebsiteEditorTypeSections onChangeScreenType={handleScreenTypeChange} />
            } else{
              return <span role="button" onClick={() => handleScreenTypeChange(false)}><KeyboardBackspaceIcon /></span>
            }
        }
        
        return(
            <> 
            <div style={{position: '-webkit-sticky', position: 'sticky', top: 0, background: '#f9f7f2',zIndex: 1000}} >
              <ChangeScreenMain />
            </div>
            {/*(changeScreenType==='Font') ? 
            <>
                {fontCombos && fontCombos.length>0 && fontCombos.map((singleFontCombo,index) => (
                    <div onClick={() =>changeFontCombo(singleFontCombo)} key={index} className="border-bottom pointer mt-3" role="button">
                        <DynamicFontComponent font={singleFontCombo} />
                    </div>
                ))}
            </>
                : ''*/}                
            {(changeScreenType === 'Logo') ? (
                <>
                <h4 className="fw-bold">Edit Logo</h4>
                <hr />
                <LogoForm websiteId={theSiteId} onUpdate={dataUpdated} />
                </>
            ) : ('')}          
            {(changeScreenType === 'Page') ? (
                <div style={{height: '100vh'}}>
                  <div style={{position: '-webkit-sticky', position: 'sticky', top: '25px', background: '#f9f7f2', zIndex: 1000}}>
                    <h4 className="fw-bold">Edit Page</h4>
                    <hr />
                  </div>
                  <EditorForm websiteId={theSiteId} onUpdate={dataUpdated} />
                </div>
            ) : ('')}
            {(changeScreenType === 'Font') ? (
                <>
                <h4 className="fw-bold">Font combinations</h4>
                <hr />
                <FontSelection fontCombos={fontCombos} onSelectFontCombo={(font,index) => onUpdateBrandGuide('fontCombo',font,index)} />
                </>
            ) : (
                ''
            )}
            {(changeScreenType==='Template') ? (
                <>
                <h4 className="fw-bold">Template</h4>
                <hr />
                <TemplateSelection templates={templates} onSelectTemplate={changeTamplate} />
                </>
            )                    
            : ('')}  
            {(changeScreenType === 'Form') ? (
                <>
                <h4 className="fw-bold">Forms</h4>
                <hr />
                <FormSelection onSelectForm={(e) => alert(e.name)} />
                </>
            ) : (
                ''
            )} 
            {(changeScreenType==='Announcements & chat') ? (
                <>
                <h4 className="fw-bold">Announcements & chat</h4>
                <hr />
                <AnnouncementSelection announcements={announcements} onSelectAnnouncement={changeTamplate} />
                </>
            )                    
            : ('')}
            {(changeScreenType==='Email & CRM') ? (
                <>
                <h4 className="fw-bold">Email & CRM</h4>
                <hr />
                <p>
                  We offer Google Workspace Emails. Feel Free to ask us
                </p>
                </>
            )                    
            : ('')}
            {(changeScreenType==='Domain') ? (
                <>
                <h4 className="fw-bold">Domain</h4>
                <hr />
                <p>
                  Buy and Connect your Domain with us
                </p>
                  <DomainForm websiteId={theSiteId} onUpdate={dataUpdated} />
                </>
            )                    
            : ('')}
            {(changeScreenType==='Analytics') ? (
                <>
                <h4 className="fw-bold">Analytics Settings</h4>
                <hr />
                  <AnalyticsForm websiteId={theSiteId} onUpdate={dataUpdated} />
                </>
            )                    
            : ('')}
            { (changeScreenType==='Color') ? 
            <>
            <h4 className="fw-bold">Select a color pallette</h4>
            {(colorPallette && colorPallette.length>0) && 
              <>                        
                <hr />
                <div className="row">
                  <div className="col-10">
                    <div className="color-palette row" style={{borderRadius: '15px', border: '2px solid black', overflow: 'hidden'}} onClick={() => changeColorPallette(colorPallette)}>
                    {colorPallette && colorPallette.map((color, index) => (
                      <div
                        key={index}
                        className="color-box d-inline-block"
                        style={{ backgroundColor: `rgb(${color.join(',')})`, width: palletteWidth[index], height:35, border: '0px solid #fcc', aspectRatio: 1/1 }}
                      />
                    ))}
                    </div>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <span className="color-palette" onClick={() => changeColorPallette(colorPallette.reverse())}>
                      <SwapHorizIcon />
                    </span>
                  </div>
                </div>
              </>
            }
            <hr />
            <ColorPalettesBox onChangeColorPallette={changeColorPallette} />
            </>
        : '' }
        { (changeScreenType==='SEO') ? 
        <>
          <h4 className="fw-bold">SEO</h4>
            <SEOForm websiteId={theSiteId}  initialSEOData={SEOSettingsJSON} onSubmit={(e) => alert(JSON.stringify(e))} />
        </>
        : '' }      
        {(['Content','Analytics'].includes(changeScreenType)) ? (
                <>
                <h4 className="fw-bold">{changeScreenType}</h4>
                <hr />
                Coming soon ..
                </>
            ) : ('')}     
        </>
        )
    })

const SingleWebsite = () => {    
    const { theSiteId } = useParams();
    const leftSideBarContent = <DashboardSideBarMenu />
    const fontCombos = getAllFontCombos();
    const templates = getAllTemplates();
    const announcements = getAllAnnouncementPositions();

    const [changeScreenType, setChangeScreenType] = useState('Font');
    const [fontCombo, setFontCombo] = useState({});
    const [fontComboIndex, setFontComboIndex] = useState(null);
    const [template, setTemplate] = useState('');
    const [websiteHtml, setWebsiteHtml] = useState('');
    const [selectedColorPalette, setSelectedColorPalette] = useState([]);

    console.log('tracking color in main',selectedColorPalette)

    const [updateCount, setUpdateCount] = useState(0);


    const WebsiteEditorTypeSections = () => {
        const editorTypes = ['Font','Template','Page','SEO','Analytics','Color','Logo','Form','Content','Announcements & chat','Email & CRM','Domain'];
        return(
            <>
                {editorTypes.map((editorType,index) => (
                    <Button className="fw-bold" onClick={() => setChangeScreenType(editorType)} key={index}>{editorType}</Button>
                ))}
            </>
        )
    }

    
    const ColorPalettesBox = () => {
        const colorPalettes = ['Default','Light','Dark','Multicolor','BandStand','Dark1','Multicolor1','BandStand'];
        return(
            <>
                {colorPalettes.map((colorPalette,index) => (
                    <div key={index}>
                        <ColorPalette onColorPalletSelect={(e) => setSelectedColorPalette(e)} />
                    </div>
                ))}
            </>
        )
    }
/*
    useEffect(() => {

    },[])
*/

    //const ScreenshotComponent = () => {
      function saveImageFromURL(url, fileName) {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const dataURL = reader.result;
              const anchorElement = document.createElement('a');
              anchorElement.href = dataURL;
              anchorElement.download = fileName;
              anchorElement.click();
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error saving image:', error);
          });
      }

      function applyTransparencyAndSaveImage(imageURL, transparency, fileName) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
      
        const image = new Image();
        image.crossOrigin = 'Anonymous';
      
        return new Promise((resolve, reject) => {
          image.onload = () => {
            // Set canvas dimensions to match the image
            canvas.width = image.width;
            canvas.height = image.height;
      
            // Apply transparency
            context.globalAlpha = transparency;
      
            // Draw the image on the canvas
            context.drawImage(image, 0, 0);
      
            // Convert the canvas to a data URL
            const dataURL = canvas.toDataURL('image/png');
      
            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = fileName;
            link.click();
      
            resolve();
          };
      
          image.onerror = (error) => {
            reject(error);
          };
      
          image.src = imageURL;
        });
      }
      

      // Usage example
      const imageUrl = 'https://images.unsplash.com/photo-1502726299822-6f583f972e02?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMzc3MDh8MHwxfHNlYXJjaHw0OXx8V2F0ZXIlMjBzdXJ2ZXllcnxlbnwwfHx8fDE2ODc1OTczNzZ8MA&ixlib=rb-4.0.3&q=80&w=1080'; // Replace with the URL of the image
      const imageFileName = 'image.jpg'; // Replace with the desired file name
      //saveImageFromURL(imageUrl, imageFileName);
      
      //const imageURL = 'https://example.com/path/to/image.jpg';
        const transparency = 0.7;
        //const fileName = 'image.png';
      /*
        applyTransparencyAndSaveImage(imageUrl, transparency, imageFileName)
        .then(() => {
            console.log('Image saved successfully!');
        })
        .catch((error) => {
            console.error('Error saving image:', error);
        });
      */

    console.log(changeScreenType,selectedColorPalette,fontCombo);
    const htmlChanged = (html) => {
        setWebsiteHtml(html)
    }
    const changeColorPallette = (color) => {
        console.log('here in color change function main',color)
        setSelectedColorPalette(color)
    }
    const changeTamplate = (template) => {
        setTemplate(template)
    }
    const changeFontCombo = (font,index) => {
        console.error('Font selected',font)
        setFontCombo(font)
        setFontComboIndex(index)
    }
    const dataUpdated = () => {
        console.error('Content modified')
        setUpdateCount(updateCount+1)
    }
    
    const palletteWidth = {
        0: '30%',
        1: '30%',
        2: '10%',
        3: '15%',
        4: '15%',
    }    
    const [editorData, setEditorData] = useState({
        changeScreenType: false,
        font: {},
        fontIndex: null,
        colorPalette: [],
        // Add initial values for other properties
        // template: '',
        // settings: {},
    });
    
    //const { font, color } = useContext(EditorContext);
    //console.log(font);

    const contextValue = {
        ...editorData,
        setEditorData,
    };
    
    const iframeRef = useRef(null);

    return(
        <EditorContext.Provider value={contextValue}>
            <InternalTemplate leftSideBarContentu={leftSideBarContent}>
                <Row className="overflow-y-auto vh-100" style={{height: 'calc(100vh-80px)'}}>
                    <Col className="col-md-3 vh-100 pb-5 text-start ps-5 pe-3" style={{maxHeight: '100vh', overflowY: 'auto'}}>
                        <EditorSidebar onChangeFontCombou={changeFontCombo} onChangeTemplateu={changeTamplate} onChangeColorPalletteu={changeColorPallette} onDataUpdate={dataUpdated} />
                    </Col>
                    <Col>
                        <SingleWebsitePreview theSiteId={theSiteId} iframeRef={iframeRef}></SingleWebsitePreview>
                    </Col>
                </Row>
            </InternalTemplate>
        </EditorContext.Provider>
    )
}

//export default SingleWebsite;
export default React.memo(SingleWebsite);
