import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { convertStyleStringToObject, generateRandomPath, getRandomIpsumLogo, getRandomRadius, markedToHtml } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const FeatureBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {theblock.useGridCss ? 
        <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
          <Container className={theblock.sectionParentClass}>
              {theblock.customGridCss && <style>{theblock.customGridCss.replace("\n","")}</style>}
              <h2 className='headline'>{theblock.headline}</h2>
              <div className='content'><MarkdownRenderer markdownString={theblock.content} /></div>
              <div className='items'>
              {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                <div key={index} className={`item item-${index}`}>
                    {column.image && 
                      <Card.Img className='item-image' loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={convertStyleStringToObject(column.image.style)} />
                    }
                  <h4 className='item-title'>
                    {column.icon && <i className={`${column.icon} me-2`}></i>}
                    {column.title}
                  </h4>
                  <div className='item-content'><MarkdownRenderer markdownString={column.content} /></div>
                </div>
              ))}
              </div>
          </Container>
        </div>
      :
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className='row py-5'>
            <div className='col-md-5'>
              <div className="row">
                <div className='fw-bold col'><h2 className='display-3'>{theblock.headline}</h2></div>
                <div className='col-md-12 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
              </div>
            </div>
            <div className='col-md-7'>
              <div className="row px-5 d-flex justify-content-center">
                {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                  <div key={index} className={`${((theblock.items.length%2)!==0 && index===0) ? 'col-md-12' : 'col-md-6'} col-sm-12 my-3`}>
                      {column.image &&  
                        <div className="random-path-image my-3" style={{ clipPathi: generateRandomPath() }}>
                            <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{borderRadiusi: getRandomRadius(), ...convertStyleStringToObject(column.image.style)}} />
                        </div>
                      }
                    <h4>{column.title}</h4>
                    <p><MarkdownRenderer markdownString={column.content} /></p>
                  </div>
                ))}
              </div>   
            </div>
          </div>
        </Container>
      </div>
      : ''}
      </>
      }
      </>
    );
  };

export default FeatureBlock;