import React, { useEffect, useState } from 'react';
import { Editor, Frame, Element, useEditor } from '@craftjs/core';
import { CraftifiedComponent } from './Parts/CraftifiedComponent';
import { findOne } from '../../data/BaseDbOps';
import { ToolPalette } from './ToolPalette';
import { ContentSidebar } from './ContentSideBar';
import { convertStyleStringToObjectNew } from '../../../helpers/randoms';
import { TextComponent } from './Parts/TextComponent';
import { ImageComponent } from './Parts/ImageComponent';
import { PlaceholderComponent } from './PlaceholderComponent';
// ... other imports

function NewEditor() {
    const [site, setSite] = useState('default');


    // Nested component that uses useEditor
    const EditorContent = () => {
        const [isDataLoaded, setIsDataLoaded] = useState(false);
        
        const { nodes } = useEditor();

        //const rootNode = nodes.find((node) => node.parentId === null);
        console.log('my new soft', nodes)

        const { actions, connectors: { create }, query } = useEditor();
        /*
        useEffect(() => {
            const loadPageDataFew = async () => {
                const data = await findOne('website', 173);
                if (data && data.data.blocks) {
                    // Safely get the root node's ID
                    const serialized = query.serialize();
                    //console.log("Serialized Data:", serialized);
                    const parseSerialized = JSON.parse(serialized);
                    const rootNodeId = parseSerialized.ROOT.props.id;
                    //console.log("Serialized Data node:", rootNodeId);
                    //const rootNode = selectRootNode();
                    //console.log("Serialized Data node:", rootNode);
                    const allNodes = query.getNodes();

                    console.log('nodes',allNodes);


                    if (rootNodeId) {
                        data.data.blocks.forEach(block => {
                            const newNode = query.createNode(<CraftifiedComponent block={block} />);
                            actions.add(newNode, rootNodeId);
                        });
                    } else {
                        console.error("Root node ID not found");
                    }
                }
            };
            const loadPageDataOld = async () => {
                const data = await findOne('website', 173);
                if (data && data.data.blocks) {
                    const serialized = query.serialize();
            
                    // Check if the serialized data has nodes
                    if (!serialized || typeof serialized !== 'object' || !serialized.nodes) {
                        console.error("Serialized data is invalid or nodes are not present:", serialized);
                        return;
                    }
            
                    const allNodes = query.parseSerializedNode(serialized).nodes;
            
                    // Find the first canvas node which is a direct child of the ROOT
                    const mainCanvasNodeId = Object.keys(allNodes).find(nodeId => 
                        allNodes[nodeId].isCanvas && allNodes[nodeId].parent === 'ROOT'
                    );
            
                    if (mainCanvasNodeId) {
                        data.data.blocks.forEach(block => {
                            const newNode = query.createNode(<CraftifiedComponent block={block} />);
                            actions.add(newNode, mainCanvasNodeId);
                        });
                    } else {
                        console.error("Main canvas node ID not found");
                    }
                }
            };
            const loadPageDataN = async () => {
                const data = await findOne('website', 173);
                if (data && data.data.blocks) {
                    const serialized = query.serialize();
            
                    // Check if the serialized data itself is valid
                    if (!serialized || typeof serialized !== 'object' || !serialized.nodes) {
                        console.error("Serialized data is invalid:", serialized);
                        //return;
                    }
            
                    // The ROOT node is the main canvas node in this case
                    const mainCanvasNodeId = 'myroot';
            
                    data.data.blocks.forEach(block => {
                        const newNode = query.createNode(<CraftifiedComponent block={block} />);
                        actions.add(newNode, mainCanvasNodeId);
                    });
                }
            };
            const loadPageData = async () => {
                if (isDataLoaded) return; // Prevents loading data if it's already loaded
                const data = await findOne('website', 173);
                if (data && data.data.blocks) {
                    const mainCanvasNodeId = 'ROOT'; // Directly using 'ROOT' as the main canvas node ID

                    data.data.blocks.forEach(block => {
                        const newNode = query.createNode(<CraftifiedComponent block={block} />);
                        actions.add(newNode, mainCanvasNodeId);
                    });
                }
            };

            
            
            
            

            loadPageData();
        }, [actions, query]);
        */

        useEffect(() => {
            let isCancelled = false;
    
            const loadPageData = async () => {
                const data = await findOne('website', 173);
                if (!isCancelled && data && data.data.blocks) {
                    const mainCanvasNodeId = 'ROOT'; // Assuming 'ROOT' is your main canvas node
    
                    // Clear existing nodes from the main canvas node
                    const existingNodes = query.node(mainCanvasNodeId).descendants();
                    existingNodes.forEach(nodeId => actions.delete(nodeId));

                    data.data.blocks.forEach(block => {
                        const newNode = query.createNode(<CraftifiedComponent block={block} />);
                        actions.add(newNode, mainCanvasNodeId);
                    });
                }
            };
    
            loadPageData();
            
    
            // Set up an interval for periodic refetching
            const intervalId = setInterval(() => {
                console.log("Refetching data...");
                //loadPageData();
            }, 10000); // Adjust the interval as needed

            // Cleanup function
            return () => {
                isCancelled = true;
                clearInterval(intervalId);
            };
        }, [actions, query]);

        return null; // This component does not render anything itself
    };

    return (
        <div className="editor-container d-flex">
            <Editor resolver={{CraftifiedComponent, TextComponent, ImageComponent, PlaceholderComponent  }}>
                <ToolPalette />
                    <div style={{ position: 'relative', height: '100%', width: `calc(100vw - 600px)`, marginLeft: '200px', marginRight: '400px' }}>
                    <Frame>
                        <Element id="myroot" is="div" canvas
                        style={{
                            ...convertStyleStringToObjectNew("min-height: 500px; width: 100%; border: 1px dashed #ccc;"),
                            position: 'relative', // Establish this div as the positioning context
                        }}
                        styleu={convertStyleStringToObjectNew("min-height: 500px; width: 100%; border: 1px dashed #ccc; position: relative;")}
                        >
                            {/* This div acts as a flexible canvas */}
                        </Element>    
                    </Frame>             
                    </div>
                <ContentSidebar />
                <EditorContent />
            </Editor>
        </div>
    );
}

export default NewEditor;
