import { Col, Button } from "react-bootstrap";
import HtmlViewer from "../../../components/HtmlViewer";
import NewWebsite from "../../../pages/NewWebsite";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import Header from "../../../components/Header";
import { supabase } from "../../../supabase";
import React, { useEffect, useState } from "react";
import MultiStepForm from "../../components/MultiStepForm";
import PlaceholderList from "../../components/PlaceholderList";
import NewEditor from "../../components/EditorV2/NewEditor";

  const NewEditorPage = ({match}) => { 

    return (
      <NewEditor />
    );
  };
  
  export default NewEditorPage;