import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

function MarkdownRenderer({ markdownString }) {
  
  // Define a custom renderer
  const renderer = new marked.Renderer();
  const originalTableRenderer = renderer.table;

  // Override the default table renderer to include Bootstrap classes
  renderer.table = (header, body) => {
    return originalTableRenderer.call(renderer, header, body).replace('<table>', '<table class="table table-striped text-start">');
  };

  // Override the default text renderer
  renderer.text = function(text) {
      return text.replace(/==([^=]+)==/g, '<mark>$1</mark>');
  };

  // Use the custom renderer with marked
  marked.setOptions({ renderer });
  
  const rawHtml = (markdownString) ? marked(markdownString) : '';
  const sanitizedHtml = DOMPurify.sanitize(rawHtml);

  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

export default MarkdownRenderer;
