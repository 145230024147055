import React, { useEffect, useState } from 'react';
import { Block } from '../components/Block';
import DOMPurify from 'dompurify';
import HeroBlock from '../components/Blocks/HeroBlock';
import { Container } from 'react-bootstrap';
import BannerBlock from '../components/Blocks/BannerBlock';
import TestimonialBlock from '../components/Blocks/TestimonialBlock';
import ListBlock from '../components/Blocks/ListBlock';
import GoogleMapsBlock from '../components/Blocks/GoogleMapsBlock';
import CalendlyBlock from '../components/Blocks/CalendlyBlock';
import loadGoogleFonts from '../helpers/fonts';
import { getBannerBlock } from '../helpers/prompts';

const Blocks = (props) => {
  // 
  return (
    <Container style={{maxWidth: "100%"}}>
      <h1>Blocks</h1>
      <h2>Banner</h2>
      <BannerBlock></BannerBlock>
      <h2>Hero</h2>
      <HeroBlock></HeroBlock>
      <h2>Testimonial</h2>
      <TestimonialBlock></TestimonialBlock>
      <h2>List</h2>
      <ListBlock></ListBlock>
      <h2>Google Maps</h2>
      <h2>Calendly</h2>
      <CalendlyBlock></CalendlyBlock>
    </Container>
  );
};

export default Blocks;
