import React, { useEffect } from 'react';
import AWS from 'aws-sdk';

const BUCKET_NAME = 'websitebuilder-sanmaya-'+Math.random();
const FILE_NAME = 'index.html';
const DOMAIN_NAME = 'theremydomain.com';


const CreateS3BucketConfigureHostingAndAddDomain = () => {
  useEffect(() => {
    // Configure AWS SDK
    AWS.config.update({
      accessKeyId: 'AKIAQ6NJMFA26PQLZW2F',
      secretAccessKey: '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',
      region: 'us-east-1',
    });

    const s3 = new AWS.S3();

    // Create the S3 bucket
    const createBucket = async () => {
      const params = {
        Bucket: BUCKET_NAME,
      };

      try {
        await s3.createBucket(params).promise();
        console.log('Bucket created successfully!');
      } catch (error) {
        console.error('Error creating bucket:', error);
      }
    };

    // Configure static website hosting for the bucket
    const configureHosting = async () => {
      const params = {
        Bucket: BUCKET_NAME,
        WebsiteConfiguration: {
          IndexDocument: {
            Suffix: FILE_NAME,
          },
        },
      };

      try {
        await s3.putBucketWebsite(params).promise();
        console.log('Static website hosting configured successfully!');
      } catch (error) {
        console.error('Error configuring static website hosting:', error);
      }
    };

    // Add a domain to the bucket
    const addDomain = async () => {
      const params = {
        Bucket: BUCKET_NAME,
        WebsiteConfiguration: {
          RedirectAllRequestsTo: {
            HostName: DOMAIN_NAME,
          },
        },
      };

      try {
        await s3.putBucketWebsite(params).promise();
        console.log('Domain added successfully!');
      } catch (error) {
        console.error('Error adding domain:', error);
      }
    };

    // Upload a file to the bucket
    const uploadFile = async () => {
      const fileContent = localStorage.getItem("htmlContent"); // Replace with your file content

      const uploadParams = {
        Bucket: BUCKET_NAME,
        Key: FILE_NAME,
        Body: fileContent,
      };

      try {
        await s3.upload(uploadParams).promise();
        console.log('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    createBucket();
    configureHosting();
    addDomain();
    uploadFile();
  }, []);

  return (
    <div>
      <h1>Create S3 Bucket, Configure Hosting, and Add Domain</h1>
      <p>Check the browser console for status messages.</p>
    </div>
  );
};

export default CreateS3BucketConfigureHostingAndAddDomain;
