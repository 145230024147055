import React, { useEffect, useState } from "react";
import { supabase } from "../../../supabase";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import ReactVirtualizedTable from "./WebsitesTable";
import { Button, Link, Typography } from "@mui/material";
import AwsPublish from "../../../components/AwsPublish";
import DashboardPage from "./Dashboard";
import BannerBlock from "../../../components/Blocks/BannerBlock";
import { redirect, useNavigate } from "react-router-dom";
import DelayedButton from "../../components/DelayedButton";
import { Col, Row } from "react-bootstrap";
import HtmlViewer from "../../../components/HtmlViewer";
import PlaceholderList from "../../components/PlaceholderList";
import AddIcon from '@mui/icons-material/Add';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DelayedLink from "../../components/DelayedLink";

  // Dashboard Page
  const WebsitesListPage = ({listView=true}) => {
    const [websites, setWebsites] = useState([]);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [showBlankSlate, setShowBlankSlate] = useState(false);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedWebsiteIndex, setExpandedWebsiteIndex] = useState(null); // State to track the expanded div index
    const headerContent = <h1 className="mx-3 px-5">Websites <DelayedLink to={'/dashboard/websites/create'} variant="outlined" color="primary" style={{float: 'right'}}>Create a new website <AddIcon /></DelayedLink></h1>;

    //const headerContent = <h1 className="mx-3 px-5">Websites {websites && <u className="">{websites.length}</u>} <DelayedLink to={'/dashboard/websites/create'} variant="outlined" color="primary" style={{float: 'right'}}>Create a new website <AddIcon /></DelayedLink></h1>;
    const footerContent = <p>Logged in as user@example.com</p>;
    const leftSideBarContent = <DashboardSideBarMenu />
    
    const navigate = useNavigate();

    const handleExpand = (index) => {
        /*
        if (expandedWebsiteIndex === index) {
          setExpandedWebsiteIndex(null); // Collapse the expanded div if it's already expanded
        } else {
          setExpandedWebsiteIndex(index); // Expand the clicked div
        }
        */
        setExpandedWebsiteIndex(index === expandedWebsiteIndex ? null : index);
    };
    
    useEffect(() => {
        const getWebsites = async () => {
            let { data: website, error } = await supabase
            .from('website')
            .select('*')
            .order('id', { ascending: false })
            setWebsites(website)
            setShowBlankSlate(website.length>0 ? false : true);
            
            setTimeout(() => {
                setIsLoading(false);
            },1000)
            console.log(website)
        }
        if (dataUpdated) {
            getWebsites();
            setDataUpdated(false); // Reset dataUpdated flag after re-fetching
        } else {
            getWebsites(); // Fetch data on initial render
        }
    },[dataUpdated]);

    const handleDataUpdate = () => {
        setDataUpdated(true); // Set dataUpdated flag to trigger re-fetch in the parent
    };

    const deleteWebsite = async (websiteId) => {    
        console.log(websiteId)
        /*
        const { error } = await supabase
        .from('website')
        .delete()
        .eq('id', websiteId)
        if(!error){
            console.log('website deleted')
            setDataUpdated(true);
            return true
        } 
        */
        try {
            const { data, error } = await supabase.from('website').delete().eq('id', websiteId);
            if (error) {
              throw new Error(error.message);
            }
            console.log('Record deleted successfully');
            setDataUpdated(true);
          } catch (error) {
            console.error('Error deleting record:', error);
          }
        
        console.log('website couldn\'t be deleted','error')
        return false;
    }

    const BlankSlateContent = () => {
        const bannerBlockData = {
            headline: 'You have no websites to show.',
            //content: '<button class="btn btn-primary">Get Started Here</button>'
            background : {
                image: {
                    url: 'https://www.prowebdesign.ro/pwd/wp-content/uploads/2020/07/designvars-mainsite.png',
                    url: 'https://mycodelesswebsite.com/wp-content/uploads/2019/05/Hero-Website-Example.png',
                    url: 'https://assets.foliowebsites.com/c15da1f2b5e5ed6e6837a3802f0d1593/web-816/2048/5afe00e9a2ad7a5e.png',
                    url: 'https://phoenixbranding.in/wp-content/uploads/2018/09/website-banner.png',
                },
                opacity: 30,
            }
        }
        return(
            <>
                
                {/*<DashboardPage displayBlocks={['main']} />*/}
                
                <BannerBlock block={bannerBlockData}>
                    <DelayedButton to="/dashboard/websites/create" delay={300} size="large" variant="contained" color="primary">
                        Why not create one now. Takes only 5minute
                    </DelayedButton>
                </BannerBlock>
            </>
        )
    }
    listView = false;
    return (
      <InternalTemplate headerContent={headerContent} footerContent={footerContent} leftSideBarContent={leftSideBarContent}>
        <Row className="d-none">
            <Col className="text-end px-5 py-3">
                <DelayedButton to="/dashboard/websites/create" delay={3000} size="large" variant="contained" color="primary">
                    Create a New Website
                </DelayedButton>
            </Col>    
        </Row>
        {/*<ReactVirtualizedTable></ReactVirtualizedTable>*/}
        {isLoading ? 
        
        <Row className="px-5">
            {Array.from({ length: 5 }).map((_, index) => (
            <Col className="col-md-6 my-3 border-bottom" key={index}>
                <PlaceholderList />
            </Col>
            ))}
        </Row>
         : <>
        {!showBlankSlate && listView &&
        <>     
            <table className="table table-fluid">
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            View
                        </th>
                        <th>
                            Domain
                        </th>
                        <th>
                            Stage Domain
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                {websites && websites.map((website, websiteIndex) => 
                    <tr key={websiteIndex}>
                        <td>
                            {website.name}
                            {website.data.name}
                        </td>
                        <td>
                            {/*website.html && <iframe title="HTML Viewer" srcDoc={website.html} style={{ width: '100%', height: '30vh' }} />*/}
                            {website.html && <HtmlViewer theHtml={website.html} freeHeight={true} isSmall={true} />}
                        </td>
                        <td>
                            {website.domain &&
                            <Link href={`https://${website.domain}`} target="_blank">Contact</Link>}
                        </td>
                        <td>
                            {website.staging_domain && <Link href={`https://${website.staging_domain}`} target="_blank">Open Staging</Link>}
                        </td>
                        <td>
                            {website.status}
                        </td>
                        <td>
                            <Button>Edit</Button>
                            <Button>Add Domain</Button>
                            <Button>Add SEO Booster</Button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </>}
        {!showBlankSlate && !listView &&
            <Row className="px-5">
            {websites && websites.map((website, websiteindex) => 
                <Col className={`my-3 border-bottom item ${expandedWebsiteIndex === websiteindex ? 'col-md-12' : 'col-md-6'}`} key={websiteindex}>
                    <div className="bg-whit1e px-3 py-3">
                        <div>
                            <Typography variant="h6" fontWeight="bold">
                                {website.name && website.name}
                            </Typography>
                            {(website.domain) ? <Link href={`https://${website.domain}`} target="_blank" style={{marginRight: '10px'}}>Live <ArrowOutwardIcon /></Link> : <span style={{marginRight: '10px'}}>Live</span>} {' '}  {' '} 
                            {(website.staging_domain) ? <Link href={`https://${website.staging_domain}`} target="_blank" style={{marginRight: '10px'}}>Staging <ArrowOutwardIcon /></Link> : <span style={{marginRight: '10px'}}>Staging</span>}
                            <button onClick={() => handleExpand(websiteindex)} className="expand-button btn btn-out1line-secondary">{(expandedWebsiteIndex === websiteindex) ? 'Shrink' : 'Expand'}</button>
                        </div>
                        <div className="border-bottom">
                            {/*website.html && <iframe title="HTML Viewer" srcDoc={website.html} style={{ width: '100%', height: '30vh' }} />*/}
                            {/*website.html && <HtmlViewer theHtml={website.html} freeHeight={true} isSmall={true} />*/}
                            <HtmlViewer bigViewiewPort={true} theHtml={website.html} freeHeight={true} isSmall={(expandedWebsiteIndex === websiteindex) ? false : true} isExpanded={(expandedWebsiteIndex === websiteindex) ? true : false} isBlank={!(website.html)} />
                        </div>
                        <div className="mt-3">
                            {website.status}
                            <DelayedButton to={`/dashboard/websites/create/${website.id}`} delay={300} size="medium" variant="outlined" color="primary">Edit</DelayedButton> {' '} 
                            {/*<AwsPublish theContent={website.html} theSiteId={website.id} onDataUpdate={handleDataUpdate}  /> {' '}*/}
                            {!website.domain && <><Button variant="outlined">Add Domain</Button> {' '}</>}
                            <Button variant="outlined">Add SEO Booster</Button> {' '} 
                            <Button variant="outlined" onClick={() => deleteWebsite(website.id)}>Delete</Button> {' '} 
                            <DelayedButton to={`/dashboard/websites/preview/${website.id}`} delay={300} size="medium" variant="outlined" color="primary">Preview</DelayedButton> {' '} 
                        </div>
                    </div>
                </Col>
            )}
            </Row>
        }        
        {showBlankSlate && <BlankSlateContent />}
        </>
        }
      </InternalTemplate>
      
    );
  };
  
  export default React.memo(WebsitesListPage);