import { Button, TextField, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';
//import ImageUploader from './ImageUploader';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';

const HeroForm = ({ data, onUpdate }) => {
  const [heroData, setHeroData] = useState(data || {});
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSave = () => {
    onUpdate(heroData);
  };


  return (
    <div>
      <label>Content:</label>
        <TextareaAutosize
            minRows={4}
            aria-label="Content"
            placeholder="Content"
            style={{width: '100%'}}
            value={heroData.content}
            onChange={(e) => setHeroData({ ...heroData, content: e.target.value })}
      />
      <GriderLayout
        left={'Text Alignment:'}
        right={<select
          value={heroData.align}
          onChange={(e) => setHeroData({ ...heroData, align: e.target.value })}
        >
          <option value="top">Top</option>
          <option value="middle">Middle</option>
          <option value="bottom">Bottom</option>
        </select>}
      />
      {heroData.image && heroData.image.media && <ImageUploaderNew image={heroData.image.media} 
      onUpdateImage={(e) => {
        setHeroData({ ...heroData, image: { ...heroData.image, media: { ...heroData.image.media, url: e.urls.regular, id: e.id } } });
      }}
      />}
      <br />

      <GriderLayout
        left={'Image Alignment:'}
        right={<select
          value={heroData.image.align}
          onChange={(e) =>
            setHeroData({ ...heroData, image: { ...heroData.image, align: e.target.value } })
          }
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>}
      />
      <GriderLayout
        left={'Image Corner Radius:'}
        right={<select
          value={heroData.image.cornerRadius}
          onChange={(e) =>
            setHeroData({ ...heroData, image: { ...heroData.image, cornerRadius: e.target.value } })
          }
        >
          <option value="none">None</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>}
      />
      <GriderLayout
        left={'Background Color:'}
        right={<input
          type="color"
          value={heroData.background}
          onChange={(e) => setHeroData({ ...heroData, background: e.target.value })}
        />}
      />
      <br />                  
      <GriderLayout left={'Use Block Version'} 
            right={<input
              type="checkbox"
              value={'block'}
              checked={(heroData.version==='block')}
              onChange={(e) => setHeroData({ ...heroData, version: e.target.checked ? 'block' : 'old' })}
            />} 
          />
      <GriderLayout left={'Hide'} 
        right={<input
          type="checkbox"
          value={true}
          checked={heroData.isHidden}
          onChange={(e) => setHeroData({ ...heroData, isHidden: e.target.checked})}
        />} 
      />
      <Button onClick={handleSave} fullWidth className='my-3' variant='outlined' color='primary'>Save</Button>
    </div>
  );
};

export default HeroForm;
