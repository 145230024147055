import { useEffect, useState } from "react";
import { supabase } from "../../../supabase";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import ReactVirtualizedTable from "./WebsitesTable";
import { Button } from "@mui/material";

  // Dashboard Page
  const PaymentsListPage = () => {
    const headerContent = <h1>Payments & Subscriptions</h1>;
    const footerContent = <p>Logged in as user@example.com</p>;
    const leftSideBarContent = <DashboardSideBarMenu />
    const [payments, setPayments] = useState([]);
    
    useEffect(() => {
        const getPayments = async () => {
            let { data: payment, error } = await supabase
            .from('payment')
            .select(`*,website:website(*)`)
            setPayments(payment)
            console.log(payment)
        }
        getPayments();
    },[]);

    return (
      <InternalTemplate headerContent={headerContent} footerContent={footerContent} leftSideBarContent={leftSideBarContent}>
        {/*<ReactVirtualizedTable></ReactVirtualizedTable>*/}
        <table className="table table-fluid">
            <thead>
                <tr>
                    <th>
                        Date
                    </th>
                    <th>
                        Details
                    </th>
                    <th>
                        Note
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
            {payments.map((payment) => 
                <tr>
                    <td>
                        {payment.date}
                    </td>
                    <td>
                        {payment.website.name}
                        {payment.website.domain}
                    </td>
                    <td>
                        {payment.note}
                    </td>
                    <td>
                        {payment.status} Paid
                    </td>
                    <td>
                        <Button>Invoice</Button>
                        <Button>Pay</Button>
                        <Button>Comment</Button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
      </InternalTemplate>
      
    );
  };
  
  export default PaymentsListPage;