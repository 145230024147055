import React from 'react';
import { Skeleton } from '@mui/material';
import { Col, Row } from 'react-bootstrap';

const PlaceholderList = ({type,iframeHeight='200px'}) => {
  if(type==='newWebsite' || type==='newWebsiteRightOnly'){
    return <>
      <Row>
        {(type==='newWebsite') &&
        <Col className='col-md-2'>
          <Skeleton height={48} style={{ marginBottom: 5 }} width="40%" />
          <Skeleton width="60%" />
          <Skeleton height={48} style={{ marginBottom: 5 }} width="40%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={'100vh'} />
          <Skeleton height={45} />
          <Skeleton height={25} />
        </Col>
        }
        <Col className='col'>
          <div style={{background: 'aliceblue', padding: '20px'}} className=''>
            <Skeleton variant="rectangular" height={36.5} width="30%" style={{ marginBottom: 5, display: 'inline-block', margin: '0 2px'}} />
            <Skeleton variant="rectangular" height={36.5} width="10%" style={{ marginBottom: 5, display: 'inline-block', margin: '0 2px'}} />
            <Skeleton variant="rectangular" height={36.5} width="10%" style={{ marginBottom: 5, display: 'inline-block', margin: '0 2px'}} />
            <Skeleton variant="rectangular" height={36.5} width="10%" style={{ marginBottom: 5, display: 'inline-block', margin: '0 2px'}} />
            <Skeleton variant="rectangular" height={36.5} width="10%" style={{ marginBottom: 5, display: 'inline-block', margin: '0 2px'}} />
          </div>
          <Skeleton variant="rectangular" height={'100vh'} />
          <Skeleton height={45} />
          <Skeleton height={25} />
        </Col>
      </Row>
    </>
  } else if(type==='iframe'){
    return <>
      <Row>
        <Col className='col'>
          <Skeleton variant="rectangular" height={iframeHeight} />
        </Col>
      </Row>
    </>
  }
  return (
    <div>
        <>
        <Skeleton height={40} style={{ marginBottom: 5 }} width="40%" />
        <Skeleton width="60%" />
        <Skeleton variant="rectangular" height={400} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} width="70%" />
        </>
    </div>
  );
};

export default PlaceholderList;
