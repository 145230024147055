import React, { useEffect, useState } from 'react';
import { convertStyleStringToObject } from '../../../helpers/randoms';
import MarkdownRenderer from '../MarkdownRenderer';
import DOMPurify from 'dompurify';

const partClasses = {
    'section': 'section',
    'block': 'block',
    'header': 'header',
    'blockPreHeader': 'block-preheader',
    'blockTitle': 'block-title',
    'blockIcon': 'block-icon',
    'blockImage': 'block-image',
    'blockText': 'block-text',
    'blockLink': 'block-link',
    'button': 'button',
    'items': 'items',
    'item': 'item',
    'itemPreHeader': 'item-preheader',
    'itemIcon': 'item-icon',
    'itemTitle': 'item-title',
    'itemImage': 'item-image',
    'itemText': 'item-text',
    'itemLink': 'item-link',
}

function generateCSSForBlock(block, _csId) {
    if (!block || !block.designProperties) {
      return ''; // Return an empty string if block or its design properties are not available
    }
  
    const blockParts = Object.keys(block.designProperties);
    const cssRules = blockParts.map(part => {
      const styles = block.designProperties[part].styles;
      const partClass = (partClasses?.[part]) ? partClasses[part] : part; 
      return (styles) ? `.-cs-id-${_csId} .cs-${partClass} { ${styles} }`.replace("\n",' ') : '';
    });
  
    return cssRules.join(' ').replace("\n",' ');
  }

function BlocksDesignProperties({type='block', block, _csId=0}) {
    // type: block, items, item, blockPreHeader, blockTitle, blockIcon, blockImage, blockText, blockIcon, blockLink, itemPreheader, itemIcon, itemTitle, itemText, itemLink
    const allCss = generateCSSForBlock(block,_csId);
    const allCssPrint = DOMPurify.sanitize(`${allCss}`);
    return (
        <section        
            className={`cs-section ${block?.designProperties?.section?.classes}`} 
            styleu={convertStyleStringToObject(block?.designProperties?.section?.styles)}
        >
            {allCss && <style dangerouslySetInnerHTML={{ __html: allCssPrint }} />}
            {block?.backgroundSprinkles && 
                <>
                    {block?.backgroundSprinkles?.map((image,sprinkleIndex) => (
                        <span 
                            className={`cs-block-backgroundSprinkles ${image?.classes}`}
                            style={{...convertStyleStringToObject(image?.styles), position: 'absolute', zIndex: 1, filter: image.filter}}
                            key={sprinkleIndex}
                        >
                            <img src={image?.image} alt={image?.alt} style={{height: 'inherit', width: 'inherit'}} />
                        </span>
                    ))}
                </>
            }
            <div 
                className={`cs-block ${block?.designProperties?.block?.classes}`} 
                style={{position: block?.backgroundSprinkles ? 'relative': 'relative', ...convertStyleStringToObject(block?.designProperties?.block?.styles), height: (block?.designProperties?.block?.dividerBottomType==='wavy-shape') ? '100vh': 'auto'}}>
                    {block?.designProperties?.block?.dividerBottomType === 'wavy-shape' ?
                    <>
                    <div class="custom-shape-divider-bottom-1701187929">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                        </svg>
                    </div>
                    <style>
                    {`.custom-shape-divider-bottom-1701187929 {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            overflow: hidden;
                            line-height: 0;
                            transform: rotate(180deg);
                        }

                        .custom-shape-divider-bottom-1701187929 svg {
                            position: relative;
                            display: block;
                            width: calc(100% + 1.3px);
                            height: 209px;
                        }

                        .custom-shape-divider-bottom-1701187929 .shape-fill {
                            fill: ${(convertStyleStringToObject(block?.designProperties?.block?.styles)?.backgroundColor) ? '#ffffff99' : '#FFFFFF'};
                        }`}
                    </style>
                    </>
                    : ''}


                <div 
                    className={`cs-header ${block?.designProperties?.header?.classes}`} 
                >
                    {block.preHeader && <div 
                        className={`cs-block-preheader ${block?.designProperties?.preHeader?.classes}`} 
                    >
                        <MarkdownRenderer markdownString={block.preHeader} />
                    </div>}
                    {block.icon && <div 
                        className={`cs-block-icon ${block?.designProperties?.blockIcon?.classes}`}
                    >
                        {block.icon}
                    </div>}
                    <MarkdownRenderer markdownString={block.header} />
                    {block.title && !block.headline && <div 
                        className={`cs-block-title ${block?.designProperties?.blockTitle?.classes}`} 
                    >
                        <MarkdownRenderer markdownString={block.title} />
                    </div>}
                    {block.headline && <div 
                        className={`cs-block-headline ${block?.designProperties?.blockTitle?.classes}`} 
                    >
                        <MarkdownRenderer markdownString={block.headline} />
                    </div>}
                    {block.image && <div 
                        className={`cs-block-image ${block?.designProperties?.blockImage?.classes}`} 
                    >
                        <img src={block?.image?.url} altLater={block?.image?.alt} alt={block?.headline || block?.title} width={'100%'} />
                    </div>}
                    {block.content && <div 
                        className={`cs-block-text ${block?.designProperties?.blockText?.classes}`} 
                    >
                        
                        <MarkdownRenderer markdownString={block.content} />
                    </div>}
                    {block?.button && block?.button?.label && <div 
                        className={`cs-block-link ${block?.designProperties?.blockLink?.classes}`} 
                    >
                        {block?.button?.type==='button' ? <button className={`cs-button ${block?.button?.classes}`} styleu={`${convertStyleStringToObject(block?.button?.styles)}`} href={block?.button?.link}>{block?.button?.label}</button> : ''}
                        {block?.button?.type==='link' ? <a className={`cs-button ${block?.button?.classes}`} styleu={`${convertStyleStringToObject(block?.button?.styles)}`} href={block?.button?.link}>{block?.button?.label}</a>: ''}                        
                        <a className={`cs-button ${block?.button?.classes}`} styleu={`${convertStyleStringToObject(block?.button?.styles)}`} href={block?.button?.link}>{block?.button?.label}</a>
                    </div>}
                
                </div>
                <div 
                    className={`cs-items ${block?.designProperties?.items?.classes}`} >
                        {block?.items?.map((item, itemIndex) => (                            
                            <div 
                                className={`cs-item ${block?.designProperties?.item?.classes}`} 
                                key={itemIndex}
                            >
                                {(item.preHeader || item.designation) && <div 
                                    className={`cs-item-preheader ${block?.designProperties?.itemPreHeader?.classes}`} 
                                >
                                    <MarkdownRenderer markdownString={item.preHeader} />                                    
                                    <MarkdownRenderer markdownString={item.designation} />
                                </div>}
                                {item.icon && <div 
                                    className={`cs-item-icon ${block?.designProperties?.itemIcon?.classes}`}
                                >
                                    {item.icon}
                                </div>}
                                {(item.title || item.name) && <div 
                                    className={`cs-item-title ${block?.designProperties?.itemTitle?.classes}`} 
                                >
                                    <MarkdownRenderer markdownString={item.title} />
                                    <MarkdownRenderer markdownString={item.name} />
                                </div>}
                                {item?.image?.url && <div 
                                    className={`cs-item-image ${block?.designProperties?.itemImage?.classes}`} 
                                >
                                    <img src={item?.image?.url} altLater={item?.image?.alt} alt={item?.title || item?.name} width={'100%'} />
                                </div>}
                                {(item.content || item.about) && <div 
                                    className={`cs-item-text ${block?.designProperties?.itemText?.classes}`} 
                                >
                                    <MarkdownRenderer markdownString={item.content} />
                                    <MarkdownRenderer markdownString={item.about} />
                                </div>}                                
                                {item.by && <div 
                                    className={`cs-item-text ${block?.designProperties?.itemText?.classes}`} 
                                >
                                    <MarkdownRenderer markdownString={item.by} />
                                </div>}
                                {item?.button?.label && <div 
                                    className={`cs-item-link ${block?.designProperties?.itemLink?.classes}`} 
                                >
                                    {item?.button?.type==='button' ? 
                                    <button target={`${item?.button?.external ? '_blank': '_self'}`} className={`${item?.button?.classes}`} href={item?.button?.link}>
                                        {item?.button?.label}
                                    </button> : ''}
                                    {item?.button?.type==='link' ? 
                                    <a target={`${item?.button?.external ? '_blank': '_self'}`} className={`${item?.button?.classes}`} href={item?.button?.link}>
                                        {item?.button?.label}
                                    </a> : ''}
                                    {item?.button?.type==='url' || !item?.button?.type ? 
                                    <a target={`${item?.button?.external ? '_blank': '_self'}`} className={`${item?.button?.classes}`} href={item?.button?.link}>
                                        {item?.button?.label}
                                    </a> : ''}
                                </div>}                            
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default BlocksDesignProperties;
