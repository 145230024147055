// here we expose the stuffs to public about the site

import CreateUser from "../../onboardingcomponents/AutoSignup";
import PublicTemplate from "../../templates/Public";
import DashboardPage from "../internal/Dashboard";

// Public Home Page
const HomePage = () => {
    const headerContent = ''; //<h1>Welcome to our website</h1>;
    const footerContent = ''; // <p>Contact us at contact@example.com</p>;
  
    return (
      <PublicTemplate headerContent={headerContent} footerContent={footerContent}>
        
        <DashboardPage displayBlocks={['main']} />
        <CreateUser />
      </PublicTemplate>
      
    );
  };

  export default HomePage;