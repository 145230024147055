import React from 'react';
import { useNode, useEditor } from '@craftjs/core';

const DeleteButton = () => {
  const { actions, query } = useEditor();
  const { id } = useNode(node => ({ id: node.id }));

  const handleDelete = () => {
    actions.delete(id);
  };

  return (
    <button onClick={handleDelete}>Delete</button>
  );
};

export default DeleteButton;
