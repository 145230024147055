import { jsonrepair } from "jsonrepair";
import { fetchImage, fetchImageWithAI, generateImage, generateText, getUsableAIGeneratedImage } from "./prompts";
import { cleanAndExtractJSON, generateSlug, getKeyByValue, getRandomIpsumLogo, pickPropsFromNested, pickRandomValue, removePropsFromMenu } from "./randoms";
import { parse } from "best-effort-json-parser";
import { isEmptyArray } from "formik";
import { getAllComponents } from "./templateComponents";

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

function getRandomWord() {
    const words = ["apple", "banana", "cherry", "date", "elderberry", "fig", "grape", "honeydew", "kiwi", "lemon", "mango", "nectarine", "orange", "papaya", "quince", "raspberry", "strawberry", "tangerine", "watermelon", "xigua", "yuzu", "zucchini"];
    return words[Math.floor(Math.random() * words.length)];
}

function generateRandomSentence(wordCount) {
    let sentence = '';
    for (let i = 0; i < wordCount; i++) {
        sentence += getRandomWord() + ' ';
    }
    // Capitalize the first letter and add a period at the end.
    sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1, -1) + '.';
    return sentence;
}

function generateRandomParagraph(sentenceCount, wordCountPerSentence) {
    let paragraph = '';
    for (let i = 0; i < sentenceCount; i++) {
        paragraph += generateRandomSentence(wordCountPerSentence) + ' ';
    }
    return paragraph.trim();
}

export const getRandomPic = (type='placeholder') => {
    if(type=='person'){
        return 'https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.9703707763942713-pexels-andrea-piacquadio-3777943.jpg'; // get random from few pics
    }
    if(type=='personheadshot'){
        return 'https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.9703707763942713-pexels-andrea-piacquadio-3777943.jpg'; // get random from few pics
    }
    if(type=='placeholder'){
        return 'https://placehold.co/600x300@2x/FFFFFF/FFAAAA.png?';
    }
    if(type=='placeholderturned'){
        return 'https://placehold.co/300x600@2x/FFFFFF/FFAAAA.png?';
    }
    if(type=='placeholdersquare'){
        return 'https://placehold.co/600x300@2x/FFFFFF/FFAAAA.png?';
    }
}

const style_name = {
    "banner": "banner", // done // done
    "service_list": "list", // done // done
    "feature_list": "features", // done // done
    "team": "list", // done // done
    "customer_list": "customers", // done
    "logo_list": "logos", // done // done - improvement needed
    "form": "form", // done // done
    "stats": "stats", // done // done
    "how_it_works": "how_it_works", // done // done
    "testimonials": "quote", // done - improvement needed // done
    "map": "map",
    "awards": "awards", // done // done
    "methods_process_list": "process", // done // done
    "gallery": "list",
    "faq": "faqs", // done // done
    "cta": "cta",
    "table": "table",
    "pricing": "pricing",
    "hero": "hero",
    "html": "html",
    "full_blog_post": "full_blog_post",
    "calendly": "calendly",
    "freeform": "freeform",

}

const blankData = {
    banner : {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a1",
            "description": "Add a full-width banner",
            "type": "banner",
            "active": true,
            "name": "Banner"
        },
        "idx": 1,
        "headline": "Your amazing headline goes here",
        "content": generateRandomParagraph(2,10), //"The description as they describe",
        "align": "center",
        "background": {
            "type": "image",
            "overlay": {
                "color": "#3D9970",
                "amount": 70
            },
            "image": {
                "id": "",
                "description": "",
                "alt_description": "",
                "url": getRandomPic('placeholder'),
                "author": "",
                "authorLink": "",
                "type": "unsplash",
                "download": "",
            }
        },
        "button": {
            "label": "Contact",
            "type": "url",
            "link": "#"
        }
    },
    cta : {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a1",
            "description": "Add a full-width banner",
            "type": "cta",
            "active": true,
            "name": "CTA Section"
        },
        "idx": 1,
        "headline": "Your amazing headline goes here",
        "content": generateRandomParagraph(2,10), //"The description as they describe",
        "align": "center",
        "background": {
            "type": "image",
            "overlay": {
                "color": "#3D9970",
                "amount": 70
            },
            "image": {
                "id": "",
                "description": "",
                "alt_description": "",
                "url": getRandomPic('placeholder'),
                "author": "",
                "authorLink": "",
                "type": "unsplash",
                "download": "",
            }
        },
        "button": {
            "label": "Contact",
            "type": "url",
            "link": "#"
        }
    },
    list: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "list",
            "active": true,
            "name": "List"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    stats: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "stats",
            "active": true,
            "name": "Stats"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    how_it_works: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "how_it_works",
            "active": true,
            "name": "How it Works"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    table: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "A block for Tabular list",
            "type": "table",
            "active": true,
            "name": "Table"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
    },
    pricing: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "A block for pricing and packages",
            "type": "pricing",
            "active": true,
            "name": "pricing"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
    },
    awards: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "awards",
            "active": true,
            "name": "Awards"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    faqs: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "FAQs section",
            "type": "faqs",
            "active": true,
            "name": "FAQs"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    features: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "features",
            "active": true,
            "name": "Features"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    team: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "team",
            "active": true,
            "name": "Team"
        },
        "idx": 3,
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    customers: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a6",
            "description": "Add multiple items with images and text",
            "type": "customers",
            "active": true,
            "name": "Customers"
        },
        "idx": 3,
        "headline": "Our Amazing Customers",//"Featured",
        "content": generateRandomParagraph(2,10),
        "aspectRatio": "3:2",
        "cornerRadius": "medium",
        "justify": "start",
        "align": "center",
        "showImage": true,
        "showHeading": true,
        "showContent": true,
        "showButton": true,
        "items": [],
    },
    quote: {
        "WebsiteBlock": {
          "_id": "629f98a1eb0b4972268051a3",
          "description": "Display a quote or testimonial",
          "type": "quote",
          "active": true,
          "name": "Quote/testimonial"
        },
        "idx": 2,
        //"items": [],
        "items": [
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": getRandomPic('personheadshot'),
                "urlSmall": getRandomPic('personheadshot'),
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          },
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": getRandomPic('personheadshot'),
                "urlSmall": getRandomPic('personheadshot'),
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          },
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",                
                "url": getRandomPic('personheadshot'),
                "urlSmall": getRandomPic('personheadshot'),
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          }
        ],
        "align": "center",
        "header": "We love our customers for their sweet words", //"Testimonials",
        "content": generateRandomParagraph(2,10),
        "background": "#83dfb6"
    },
    portfolio: {
        "WebsiteBlock": {
          "_id": "629f98a1eb0b4972268051a3",
          "description": "Display a list of portfolio, with project, testimonial and photos",
          "type": "portfolio",
          "active": true,
          "name": "Portfolio"
        },
        "idx": 2,
        //"items": [],
        "items": [
          {            
            "featuredImage": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": getRandomPic('personheadshot'),
                "urlSmall": getRandomPic('personheadshot'),
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
            },
            "images": [
                {
                    "_id": "64536415bbca55b72afa69f9",
                    "author": "Stephanie",
                    "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                    "type": "unsplash",
                    "url": getRandomPic('personheadshot'),
                    "urlSmall": getRandomPic('personheadshot'),
                    "Business": "644759ffb29fcec827b43346",
                    "createdAt": "2023-05-04T07:51:49.971Z",
                    "__v": 0,
                    "id": "64536415bbca55b72afa69f9"
                },
                {
                    "_id": "64536415bbca55b72afa69f9",
                    "author": "Stephanie",
                    "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                    "type": "unsplash",
                    "url": getRandomPic('personheadshot'),
                    "urlSmall": getRandomPic('personheadshot'),
                    "Business": "644759ffb29fcec827b43346",
                    "createdAt": "2023-05-04T07:51:49.971Z",
                    "__v": 0,
                    "id": "64536415bbca55b72afa69f9"
                }
            ],
            "title": "",
            "content": "",
            "quote":{
                "content": "Some testimony",
                "by": "- John Smith, CEO",
                "image": {
                    "_id": "64536415bbca55b72afa69f9",
                    "author": "Stephanie",
                    "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                    "type": "unsplash",
                    "url": getRandomPic('personheadshot'),
                    "urlSmall": getRandomPic('personheadshot'),
                    "Business": "644759ffb29fcec827b43346",
                    "createdAt": "2023-05-04T07:51:49.971Z",
                    "__v": 0,
                    "id": "64536415bbca55b72afa69f9"
                },
              },
              "idx": 0
          },
        ],
        "align": "center",
        "header": "Our Amazing Portfolio", //"Testimonials",
        "content": generateRandomParagraph(2,10),
        "background": "#83dfb6"
    },  
    logos: {
        "WebsiteBlock": {
          "_id": "629f98a1eb0b4972268051a3",
          "description": "Display logos of customers",
          "type": "logos",
          "active": true,
          "name": "Logos Section"
        },
        "idx": 2,
        "items": [
            {
                "image": {
                  "url": getRandomIpsumLogo(),
                  "urlSmall": getRandomIpsumLogo(),
                },
                "idx": 1
            },
            {
                "image": {
                  "url": getRandomIpsumLogo(),
                  "urlSmall": getRandomIpsumLogo(),
                },
                "idx": 2
            },
            {
                "image": {
                  "url": getRandomIpsumLogo(),
                  "urlSmall": getRandomIpsumLogo(),
                },
                "idx": 3
            },
            {
                "image": {
                  "url": getRandomIpsumLogo(),
                  "urlSmall": getRandomIpsumLogo(),
                },
                "idx": 4
            },
            {
                "image": {
                  "url": getRandomIpsumLogo(),
                  "urlSmall": getRandomIpsumLogo(),
                },
                "idx": 5
            },
        ],
        "align": "center",
        "header": "We love our customers for their sweet words", //"Testimonials",
        "content": generateRandomParagraph(2,10),
        "background": "#83dfb6"
    },
    hero: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "Add an image alongside a block of text",
            "type": "hero",
            "active": true,
            "name": "Text + image"
        },
        "idx": 4,
        //"content": "",
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "align": "middle",
        "image": {
            "media": {
                "id": "",
                "description": "",
                "alt_description": "",
                "url": "https://placehold.co/600x400@2x/FFFFFF/FFAAAA.png?text=Damn+Hot+Pic",//"https://source.unsplash.com/featured/?nature,water",
                "author": "",
                "authorLink": "",
                "type": "unsplash",
                "download": "",
            },
            "aspectRatio": "3:2",
            "align": "left",
            "cornerRadius": "medium"
        },
        "background": "#83dfb6"
    },
    html: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "Add an custom HTML block",
            "type": "html",
            "active": true,
            "name": "Custom HTML block"
        },
        "idx": 4,
        //"content": "",
        "headline": "Amazing headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "align": "middle",
        "background": "#83dfb6"
    },
    full_blog_post: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "Add an full length blog post",
            "type": "full_blog_post",
            "active": true,
            "name": "A full blog post"
        },
        "idx": 4,
        //"content": "",
        "headline": "The Blog Headline",//"Featured",
        "content": generateRandomParagraph(2,10),
        "align": "middle",
        "background": "#83dfb6"
    },
    calendly: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "Add an calendly widget",
            "type": "calendly",
            "active": true,
            "name": "A calendly embed block"
        },
        "idx": 4,
        //"content": "",
        "headline": "Schedule",//"Featured",
        "content": generateRandomParagraph(2,10),
        "align": "middle",
        "background": "#83dfb6"
    },
    freeform: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "Add an freeform widget",
            "type": "freeform",
            "active": true,
            "name": "A freeform embed block"
        },
        "idx": 4,
        //"content": "",
        "headline": "Schedule",//"Featured",
        "content": generateRandomParagraph(2,10),
        "align": "middle",
        "background": "#83dfb6"
    },
    form: {
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a2",
            "description": "A form block",
            "type": "formblock",
            "active": true,
            "name": "Text + image + Form"
        },
        "idx": 4,
        "fields": [],
        "header": "Contact Form",
        "headline": "",
        "content": "",
        "submitTo": "#",
        "submitMethod": "post",
        "submitText": "Submit",
        "messages": {
            success: "Thanks for submitting",
            failure: "Sorry try again, or try calling us",
        },
        "align": "center",
        "image": {
            "media": {
                "id": "",
                "description": "",
                "alt_description": "",
                "url": getRandomPic('placeholder'),
                "author": "",
                "authorLink": "",
                "type": "unsplash",
                "download": "",
            },
            "aspectRatio": "3:2",
            "align": "left",
            "cornerRadius": "medium"
        },
        "background": {
            "color" : "#83dfb6",
            "type": "image",
            "overlay": {
                "color": "#3D9970",
                "amount": 70
            },
            "image": {
                "id": "",
                "description": "",
                "alt_description": "",
                "url": getRandomPic('placeholder'),
                "author": "",
                "authorLink": "",
                "type": "unsplash",
                "download": "",
            }
        },
    },
};
export const formTypeData = (extraType) => {
    const fields = {
        default: [           
            {
                "label": "First Name",
                "type": "text",
                "required": true,
                "placeholder": "Enter your first name"
            },
            {
                "label": "Last Name",
                "type": "text",
                "required": true,
                "placeholder": "Enter your last name"
            },
            {
                "label": "Email",
                "type": "email",
                "required": true,
                "placeholder": "Enter your email"
            },
        ]
    }
    return fields[extraType] || false;
}
export const getBlankBlockData = async(type,extraType='default',callAI=false) => {
    console.log(type);
    if(!Object.keys(blankData).includes(type)){
        type = style_name[type];
    }
    let res = blankData[type];
    // if type is form, it can take extra params or generate from AI or else give basic first name, last name, email fields,
    /*
    if(type==='form'){
        let fields = [];
        if(formTypeData(extraType)){
            fields = formTypeData(extraType);
        } else{
            //we will call the AI to give some fields;
            const aiForm = await generateText(`[no prose] [Output only JSON] Give form fields for "paitient admission in hospital" in the following manner in strict JSON. Desired JSON format:  {fields:
                [         
                        {
                            "label": "First Name",
                            "type": "text",
                            "required": true,
                            "placeholder": "Enter your first name"
                        },
                        {
                            "label": "Last Name",
                            "type": "text",
                            "required": true,
                            "placeholder": "Enter your last name"
                        },
                        {
                            "label": "Email",
                            "type": "email",
                            "required": true,
                            "placeholder": "Enter your email"
                        },
                    ]
                }
            `);
            const aifields = JSON.parse(cleanAndExtractJSON(aiForm));
            fields = aifields['fields'];
            console.log('fields:',fields);
        }
        res['fields'] = fields;
        console.log('res:',res);
    }
    */
    console.log('res new:',res);
    return res;
}
export const getAllBlockTypes = () => {
    // get only block type and display name and context
    return Object.keys(blankData);
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export const getBlankPageData = async ({breadcrumb=false,companyData,allMenus=[],extraInstruction,addMessage,ignoreCompanyData=false,userProvidedSections=false}) => {
    // get the last stuff for name
    // get blank banner,
    // pull contents from ai.
    let pageDetailsNew = false;
    allMenus = removePropsFromMenu(allMenus,['pageDescription','type','section']); //,)['menu','link','submenus']
    if(!breadcrumb){
        const pageDetails = await generateText(
            `[no prose] [Output only JSON] Follow instructions strictly. ${extraInstruction? 'Customer instruction is: '+extraInstruction : 'Customer instruction is: Create another page, apart from the already created pages in menu'}`,
            'writer',
            false,
            (ignoreCompanyData) ? `` : `Create a details of the page, The company data given as follows: ${(typeof companyData === 'object')? JSON.stringify(companyData) : companyData}. Already existing menus are: ${JSON.stringify(allMenus)}`,
            false,
            false,
            [],
            [{
                "name": "generate_new_page_details",
                "description": "Generate details of a new web page",
                "parameters": {
                    "type": "object",
                    "properties": {
                        "menu": {
                            "type": "string",
                            "description": "The page name",
                        },
                        "pageDescription": {
                            "type": "string",
                            "description": "This page will focus on xyz. Example: The 'About Us' page should narrate the journey of the restaurant, its mission for promoting veganism, and its commitment to using organic ingredients. Include a timeline of milestones and a message from the founder.",
                        },
                        "breadcrumb": {
                            "type": "array",
                            "description": "Provide a bread crumb in an array taking the menu property of each page",
                            "items": {
                                "type": "string"
                            },
                            "example": ["parent", "child", "subchild"],
                        },
                    },
                }
            }]).then(res => { return res }).catch(error => console.error(error))
  
            if(pageDetails.content=== null && pageDetails.function_call){
                pageDetailsNew = JSON.parse(pageDetails.function_call.arguments);
                console.log(pageDetails.function_call.arguments)
            } else{
                pageDetailsNew = pageDetails;
            }
            breadcrumb = pageDetailsNew;
    }
    breadcrumb = (breadcrumb) ? breadcrumb.breadcrumb : false;
    let pageDescription = (breadcrumb) ? breadcrumb.pageDescription : '';
    let name = (breadcrumb) ? breadcrumb[breadcrumb.length - 1] : 'Blank Page';
    const blankBannerBlock = {
        ... await getBlankBlockData('banner'),
        headline: name
    };
    console.log('in blank page banner block',blankBannerBlock)
    //let getAllBlocks = await fillWithAIPage({companyName: companyData.companyName, companyIndustry: companyData.companyIndustry},breadcrumb);
    let getAllBlocks = await fillWithAIPage({companyData,pageBreadcrumb: breadcrumb,pageDescription,allMenus,extraInstruction, addMessage, ignoreCompanyData, userProvidedSections}) || [];
    
    let page = {
        title: name,
        name: (breadcrumb && breadcrumb.length>0) ? breadcrumb.join(' > ') : name,
        url: generateSlug(name),
        data: {
            pageDescription: pageDescription,
            blocks: Array.isArray(getAllBlocks) ? [...getAllBlocks] : [blankBannerBlock],
        },
    }
    return page;
}
function isObject(item) {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

export const rectifyOpenAIBadFunctionResponse = (parsedResponse,functionKey,realKey) => {
    if(parsedResponse.functions){
        if(parsedResponse.functions[functionKey]){
            return parsedResponse.functions[functionKey];
        }
    } else if(parsedResponse[`functions.${functionKey}`]){
        return parsedResponse[`functions.${functionKey}`];
    } else if(parsedResponse[`functions:${functionKey}`]){
        return parsedResponse[`functions:${functionKey}`];
    }
    return parsedResponse;
}

export const extractAIJSON = (response) => {
    if (isObject(response)) {
        return response;
    } else if (typeof response === "string") {
        let stack = [];
        let start = -1;
        let end = -1;

        // Iterate over the string to find start and end of JSON content
        for (let i = 0; i < response.length; i++) {
            if (response[i] === '{') {
                if (start === -1) start = i;
                stack.push('{');
            } else if (response[i] === '}') {
                stack.pop();
                if (stack.length === 0 && start !== -1) {
                    end = i;
                    break;
                }
            }
        }

        if (start !== -1 && end !== -1) {
            const jsonStr = response.substring(start, end + 1);
            try {
                return JSON.parse(jsonStr);
            } catch (e) {
                console.error("Failed to parse JSON:", e);
                return null;
            }
        } else {
            console.error("No JSON found in the response");
            return null;
        }
    }
}


export const getBlankPageDataInfo = async (breadcrumb=false,companyData,allMenus=[]) => {
    // get the last stuff for name
    // get blank banner,
    // pull contents from ai.
    allMenus = removePropsFromMenu(allMenus,['pageDescription','type','section']); //,)['menu','link','submenus']
    breadcrumb = (breadcrumb) ? breadcrumb.breadcrumb : false;
    let pageDescription = (breadcrumb) ? breadcrumb.pageDescription : '';
    let name = (breadcrumb) ? breadcrumb[breadcrumb.length - 1] : 'Blank Page';
    const blankBannerBlock = {
        ... await getBlankBlockData('banner'),
        headline: name
    };
    console.log('in blank page banner block',blankBannerBlock)
    //let getAllBlocks = await fillWithAIPage({companyName: companyData.companyName, companyIndustry: companyData.companyIndustry},breadcrumb);
    
    const sectionsDesired = await generateText(`[no prose] [Output only JSON]. Follow instructions strictly. We need to generate all sections of a given page for a website of the client. 
    
    For that we have some given questionires from client. Analyze them thoroughly, if we need to, then further ask some clarifying questions, which will help us design what content to keep on website.
    
    The page for which you need to generate the sections has following page name  ${(breadcrumb) ? name : ''}.
    And the page wants to achieve the following: ${pageDescription}.
    The company information we got is: ${(typeof companyData === 'object')? JSON.stringify(companyData) : companyData}.
    Here's the website's menus with page links, so you don't repeat yourself (like about us page shouldn't have services section, if we have dedicated services pages) : ${JSON.stringify(allMenus)}
    Don't write more than 10 questions. This is for one page, we already have other pages, so focus on this one.
    `,false,false,'You are a great website copy writer',false,false,[], //'You are a great UX designer'
    [{
        "name": "generate_page_more_info",
        "description": "Questions for extra information to design the page",
        "parameters": {
            "type": "object",
            "properties": {
            "sections": {
                "type": "array",
                "description": "Questions.",
                "items": {
                    "type": "object",
                    "properties": {
                        "question": {
                            "type": "string",
                            "description": "A question to clarify",
                        },
                        "hint": {
                            "type": "string",
                            "description": "Hint for answer",
                        },
                        "example": {
                            "type": "string",
                            "description": "An example answer",
                        },
                        /*
                        "answer": {
                            "type": "string",
                            "description": "Answer the above question from the info you received earlier, You can guess or put some believable dummy answer too if you are unsure",
                        },
                        */
                    }
                }
                },
            }
        }
    }],750);
    let sections = {sections: []};
    if(sectionsDesired.content=== null && sectionsDesired.function_call){
        sections = sectionsDesired.function_call.arguments;
    } else{
        sections = sectionsDesired;
    }
    console.log('AI Call',sections);
    const allSections = JSON.parse(sections);
    console.log('AI Call',allSections);
    return allSections;
}

export const getBlankBlockDataWithAIContent = async (sectionType,breadcrumb=false,companyData,allMenus=[],extraInstruction) => {
    // get the page data, page description, 
    // get blank banner,
    // pull contents from ai.
    if(sectionType==='calendly' || sectionType==='freeform') return await getBlankBlockData(sectionType);
    allMenus = removePropsFromMenu(allMenus,['pageDescription','type','section']); //,)['menu','link','submenus']
    breadcrumb = (breadcrumb) ? breadcrumb.breadcrumb : false;
    let pageDescription = (breadcrumb) ? breadcrumb.pageDescription : '';
    let name = (breadcrumb) ? breadcrumb[breadcrumb.length - 1] : 'Blank Page';
    console.log('AI section blank',sectionType,breadcrumb,companyData,allMenus)
    
    //Here's a complete list of sections styles that can be used and when it can be used: ${JSON.stringify(sectionDescriptions)}
    const theSectionDescription = sectionDescriptions.find(sectionDescription => sectionDescription.value = sectionType) || {};
    let sectionFill = await fillWithAISection({
        companyData: companyData, //{companyName: companyData.companyName, companyIndustry: companyData.companyIndustry},
        pageBreadcrumb: breadcrumb,
        sectionData: {...{...theSectionDescription, description: theSectionDescription.description +" " +extraInstruction },section_style: getKeyByValue(style_name,sectionType)},
        pageDescription,
        allMenus
    }) || {};


    console.log('section fill', sectionFill);

    let button = {
        ...(sectionFill.button_text ? { label: sectionFill.button_text } : {}),
    }
    
    // add images to it
    let blankBannerBlock = {
        ...await getBlankBlockData(sectionType),
        headline: sectionFill.title || name,
        title: sectionFill.title || name,
        header: sectionFill.title || name,
        content: sectionFill.content,
        description: sectionFill.content,
        sectionBuilderTitle: sectionType,
        sectionBuilderDescription: sectionDescriptions.find(sectionDescription => sectionDescription.value = sectionType).description || '',
        ...(sectionFill.items && sectionFill.items.length ? { items: sectionFill.items } : {}),
        ...(sectionFill.fields && sectionFill.fields.length ? { fields: sectionFill.fields } : {}),
        ...(sectionFill.button_text && sectionFill.button_text.length ? { button: { label: sectionFill.button_text, type: sectionFill.button_type} } : {}),
        ...(sectionFill.button && sectionFill.button.label && sectionFill.button.label.length ? { button: { label: sectionFill.button.label, type: sectionFill.button.type, link: sectionFill.button.link} } : {}),
        pageFill: sectionDescriptions.find(sectionDescription => sectionDescription.value = sectionType) | {},
        sectionFill: sectionFill,
    };
    
    // Now, after initialization, you can modify it based on its properties
    if (blankBannerBlock.image) {
        blankBannerBlock.image = await fetchImageWithAI(blankBannerBlock.headline,'',companyData);
    }
    if (blankBannerBlock.background && blankBannerBlock.background.image) {
        blankBannerBlock.background.image = await fetchImageWithAI(blankBannerBlock.headline,'',companyData);
    }
    if (blankBannerBlock.items) {
        blankBannerBlock.items = await Promise.all(
            blankBannerBlock.items.map(async item => {
                await delay(30000);  // 1 second delay
                if (item.title) {
                    return {
                        ...item,
                        image: await fetchImageWithAI(item.title,name,companyData) //await getUsableAIGeneratedImage(`A company ${companyData} need a image for a webpage ${name} and section with following ${item.title}.`,true)
                    };
                }
                return item;
            })
        );
    }
    if(getAllComponents(sectionType) && getAllComponents(sectionType)!==undefined){
        blankBannerBlock.componentDesign = pickRandomValue(getAllComponents(sectionType));
    }
    return blankBannerBlock;
}


const sectionDescriptions = [
    {
        value: "banner", 
        description: "A big display banner"
    },
    {
        value: "service_list", 
        description: "The list of services"
    },
    {
        value: "feature_list",
        description: "If a service/product have few features to be informed, use it"
    },
    {
        value: "team", 
        description: "The list of team mates with pic and a liitle details"
    },
    {
        value: "customer_list", 
        description: "Just the name of customers, if want testimonials, use 'testimonials' as section style instead",
    },
    {
        value: "logo_list", 
        description: "A list of logos, use only if customer serves b2b customers, very effective if medium and big size customers can be target market",
    },
    {
        value: "form", 
        description: "A form section"
    },
    {
        value: "stats", 
        description: "A section where various stats of the company can be shown. Often used in about us page or industries page etc"
    },
    {
        value: "how_it_works", 
        description: "A section for step by step explaination of process"
    },
    {
        value: "table", 
        description: "A block for Tabular list"
    },
    {
        value: "pricing", 
        description: "A block for Pricing & packages table"
    },
    {
        value: "testimonials", 
        description: "A section for testimonials"
    },
    {
        value: "map", 
        description: "A section for maps"
    },
    {
        value: "awards", 
        description: "A section for awards"
    },
    {
        value: "methods_process_list", 
        description: "If we need to highlight some methods or processes."
    },
    {
        value: "gallery", 
        description: "A gallery section, use if customer is creative agency or person and need to showcase work with pics, use this in about or career pages to show pics of company retrreats etc"
    },
    {
        value: "faq",
        description: "A section for frequently asked questions. Focus on what questions the visitor might be having about the page",
    },
    {
        value: "cta",
        description: "A call to action section that draws attention with big button, might contain form too. Like subscribe now, join today, signup etc."
    },
    {
        value: "html",
        description: "A HTML section that will be used, when it's not like any other sections provided. Use bootstrap classes."
    },
    {
        value: "full_blog_post",
        description: "Write a full length blog post here, use markdown."
    },
    {
        value: "calendly",
        description: "Calendly scheduling widget"
    }
]

export const fillWithAIPage = async ({companyData,pageBreadcrumb,pageDescription='',allMenus=[], extraInstruction, addMessage, ignoreCompanyData=false, userProvidedSections=false}) => {
    // this will generate setions
    /*
    {sections: [
        title: 'A short section type',
        description: 'briefly explain what this section should represent. no more than 20 words',
    ]}
    */
    let allSections = {
        sections: [

        ]
    };
    console.log('User Provided section',userProvidedSections, 'ignore company data',ignoreCompanyData);
    if(!userProvidedSections && !userProvidedSections.sections){
        const sectionsDesired = await generateText(`  
        [no prose] [Output only JSON]. Follow instructions strictly.   
        The page for which you need to generate the sections has following page name  ${(pageBreadcrumb) ? pageBreadcrumb[pageBreadcrumb.length - 1] : ''}.
        And the page wants to achieve the following: ${pageDescription}.
        ${extraInstruction}
        `,
        'writer',
        false,
        `You are a great website copy writer. We need to generate all sections of a given page. The response should be in following manner 
        
        the following description which was provided by the user during initial discussion about website.
        ${(ignoreCompanyData)? '' : `The company data is: ${(typeof companyData === 'object')? JSON.stringify(companyData) : companyData}.`}
        Use only the parts that might be essential to this page.
        Here's the website's menus with page links, so you don't repeat yourself (like about us page shouldn't have services section, if we have dedicated services pages) : ${JSON.stringify(allMenus)}

        Here's a complete list of sections styles that can be used and when it can be used: ${JSON.stringify(sectionDescriptions)}

        Don't write more than 10 sections. This is for one page, we already have other pages, so focus on this one.

        Don't add unnecessary section styles to a given page. Keep it focused. 
        Like for a Order Page keep order form first and then FAQs. Because the page's job is to take the order.`,
        false,
        false,
        [], //'You are a great UX designer'
        [{
            "name": "generate_page_sections",
            "description": "Generates all sections of a given page",
            "parameters": {
                "type": "object",
                "properties": {
                "sections": {
                    "type": "array",
                    "description": "The blocks/sections of page. Make as detailed as possible",
                    "items": {
                        "type": "object",
                        "properties": {
                            "title": {
                                "type": "string",
                                "description": "A short section title",
                            },
                            "description": {
                                "type": "string",
                                "description": "Briefly explain what this section should represent. Be specific",
                            },
                            "prompt": {
                                "type": "string",
                                "description": "Write a detailed ChatGPT prompt for this section/block that we can use to further populate this section with data and image.",
                            },
                            "section_style": {
                                "type": "string",
                                "description": "The style of the section, so that we can pick up designs that would look good",
                                "enum": ["banner","service_list","feature_list","team", "customer_list",
                                "logo_list","form","stats","how_it_works","testimonials","map","awards","methods_process_list","gallery","faq","cta","table","pricing","html","full_blog_post"
                                ]
                            },
                        },
                        "required": ["title","description","section_style"],
                    }
                    
                },
                },
                "required": ["sections"],
            }
        }],
        750);
        let sections = {sections: []};
        if(sectionsDesired.content=== null && sectionsDesired.function_call){        
            sections = sectionsDesired.function_call.arguments;
        } else{
            sections = sectionsDesired;
        }
        console.log('AI Call',sections);
        const allSectionsTemp = extractAIJSON(sections);
        allSections = rectifyOpenAIBadFunctionResponse(allSectionsTemp,'generate_page_sections'); //(allSectionsTemp && allSectionsTemp['functions:generate_page_sections']) ? allSectionsTemp['functions:generate_page_sections'] : allSectionsTemp;
    } else{
        console.log('in user provided section',userProvidedSections)
        allSections = userProvidedSections;
    }
    console.log('AI Call',allSections);
    /*
    let res = [];
    if(allSections.sections.map( async (section,index) => {
        let sectionFill = await fillWithAISection({companyData,pageBreadcrumb,sectionData: section});
        console.log('section fill',sectionFill)
        let blankBannerBlock = {
            ... await getBlankBlockData('banner'),
            headline: sectionFill.title,
            content: sectionFill.description,
            sectionBuilderTitle: section.title,
            sectionBuilderDescription: section.description,
        };
        res.push(blankBannerBlock);
    }))
    return res;
    */
    const res = [];

    const promises = allSections?.sections?.map(async (section, index) => {
        if(!section.section_style) return false;
        await delay(10000);  // 1 second delay
        addMessage(`Adding a ${section?.section_style?.replace('_',' ')} section: ${section.title} - ${section.description} `,section,index)
        let sectionFill = await fillWithAISection({companyData, pageBreadcrumb, sectionData: section,pageDescription,allMenus,ignoreCompanyData}) || {};
        console.log('section fill', sectionFill);

        let button = {
            ...(sectionFill.button_text ? { label: sectionFill.button_text } : {}),
        }
        
        // add images to it
        let blankBannerBlock = {
            ...await getBlankBlockData(section.section_style),
            headline: sectionFill.title,
            title: sectionFill.title,
            header: sectionFill.title,
            content: sectionFill.content,
            description: sectionFill.content,
            sectionBuilderTitle: section.title,
            sectionBuilderDescription: section.description,
            ...(sectionFill.items && sectionFill.items.length ? { items: sectionFill.items } : {}),
            ...(sectionFill.fields && sectionFill.fields.length ? { fields: sectionFill.fields } : {}),
            ...(sectionFill.button_text && sectionFill.button_text.length ? { button: { label: sectionFill.button_text, type: sectionFill.button_type} } : {}),
            ...(sectionFill.button && sectionFill.button.length ? { button: sectionFill.button } : {}),
            pageFill: section,
            sectionFill: sectionFill,
        };
        
        // Now, after initialization, you can modify it based on its properties
        if (blankBannerBlock.image) {
            blankBannerBlock.image = await fetchImageWithAI(blankBannerBlock.headline,'',companyData);
        }
        if (blankBannerBlock.background && blankBannerBlock.background.image) {
            blankBannerBlock.background.image = await fetchImageWithAI(blankBannerBlock.headline,'',companyData);
        }
        if (blankBannerBlock.items) {
            blankBannerBlock.items = await Promise.all(
                blankBannerBlock.items.map(async item => {
                    await delay(30000);  // 1 second delay
                    if (item.title) {
                        return {
                            ...item,
                            image: await fetchImageWithAI(item.title,'',companyData)//await getUsableAIGeneratedImage(`A company which filled these answers ${companyData} need a image for a webpage ${pageBreadcrumb[pageBreadcrumb.length - 1]} and section with following ${item.title}.`,true)
                        };
                    }
                    return item;
                })
            );
        }
        return blankBannerBlock;
    });
    function wrapPromise(promise) {
        return promise
          .then(result => ({ status: 'fulfilled', value: result }))
          .catch(error => ({ status: 'rejected', reason: error }));
    }
    
    //const resi = await Promise.all(promises);

    const resi = await Promise.all(promises.map(wrapPromise))
    .then(results => {
      const fulfilled = results.filter(p => p.status === 'fulfilled');
      const rejected = results.filter(p => p.status === 'rejected');
  
      console.log('Fulfilled promises:', fulfilled);
      console.log('Rejected promises:', rejected);
      return fulfilled.map((section,index) => {
        return section.value;
      });
    });

    return resi;
}

export const fillWithAISection = async({companyData,pageBreadcrumb,sectionData,pageDescription,allMenus=[],blockData,blockDesign,ignoreCompanyData=false}) => {
    // check if it's an collection
    // has     
    console.log('input', sectionData, sectionData.section_style)
    const sectionDesired = await generateText(
        `[no prose] [Output only JSON] Follow instructions strictly. The section description is this: ${sectionData.description}
        And section details is: ${JSON.stringify(sectionData)}`,
        false,
        false,
        (sectionData.section_style==='full_blog_post') ? `Write a full length blog post.` : `You are a great website copy writer. For a company with input data, generate a section content in following manner.
        The page for which you need to generate the sections has following pageTitle: ${(pageBreadcrumb) ? pageBreadcrumb[pageBreadcrumb.length - 1] : ''}.
        The page has following desription: ${pageDescription}.
        the following description which was provided by the user during initial discussion about website.
        ${(ignoreCompanyData) ? '' : `The company data is: ${(typeof companyData === 'object')? JSON.stringify(companyData) : companyData}.`}
        Use only the parts that might be essential to this page.
        For Links if needed, Here's the website's menus with page links: ${JSON.stringify(allMenus)}.
        Feel free to link to other pages, that leads to more conversion. Take 'link' prop and use markdown format. For labels use SEO optimized keywords.
        Don’t repeat the user request verbatim in the content. Never use placeholder
        \nnames like Jane Doe or Acme Inc., instead use real names and companies.
        \n\n
        Follow the expected tone. But the goal is to make the page stand out and be memorable. 
        Avoid words like Elevate, Transform in headlines/titles, use direct and converting tone.
        `,
        false,
        false,
        [],
        [{... sectionAIFunctionProp(sectionData.section_style) }]
    );
    // Text on the page should be as creative, wild, exciting, and entertaining as possible. Don’t be afraid to\nbe funny, sarcastic, or even a little bit rude.
    let section = {};
    if(sectionDesired.content=== null && sectionDesired.function_call){
        section = sectionDesired.function_call.arguments;
    } else{
        section = sectionDesired;
    }
    console.log('AI Section Call',section);
    const thesectionTemp = extractAIJSON(section);
    const thesection = rectifyOpenAIBadFunctionResponse(thesectionTemp,'generate_page_section_details'); //(thesectionTemp && thesectionTemp['functions:generate_page_section_details']) ? thesectionTemp['functions:generate_page_section_details'] : thesectionTemp;
    return thesection;
}

const sectionAIFunctionProp = (section_style) => {
    section_style = (section_style!='list') ? section_style : 'service_list';
    console.log('section style input', section_style)
    const functions = {
        'testimonials' : {
            "name": "generate_page_section_details",
            "description": "Generate testimonials section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "A small impactful paragraph. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "List of individual testimonials, understand a testimonial should highlight the pain-point being resolved",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "A short 2-4 word highlight of either pain point or aha moment",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "Their Customer's message",
                                },
                                "by": {
                                    "type": "string",
                                    "description": "Their Customer's name",
                                },
                                "company_name": {
                                    "type": "string",
                                    "description": "Their Customer's company_name if b2b",
                                },
                            }
                        },
                    },
                }
            }
        },
        'banner' : {
            "name": "generate_page_section_details",
            "description": "Generate banner section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words. Use '==' markdown for highlight if needed.",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",            
                    },
                    "button": {
                        "type": "object",
                        "description": "The primary call to action button",
                        "properties": {
                            "label": {
                                "type": "string",
                                "description": "Label of the button. Make it crisp but powerful",
                            },
                            "type": {
                                "type": "string",
                                "description": "Either button or link",
                                "enum": ["button","link"]
                            },
                            "link": {
                                "type": "string",
                                "description": "the url slug for the page or section or cta it refering to",
                            },
                        }
                    }
                }
            }
        },
        'cta' : {
            "name": "generate_page_section_details",
            "description": "Generate a CTA section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words, drive attention",
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",
                    },
                    "button_type": {
                        "type": "string",
                        "description": "What CTA is trying to achieve",
                        "enum": ['signup','subscribe','join','call','email','whatsapp','schedule','chat','book','company_career_apply','other'],
                    },
                    "button_text": {
                        "type": "string",
                        "description": "The label on the button, straight and crisp and drive attention",
                    },
                    "has_form": {
                        "type": "boolean",
                        "description": "If should be linked to a form or not",
                    }
                }
            }
        },
        'service_list' : {
            "name": "generate_page_section_details",
            "description": "Generate the services section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words. Use '==' markdown for highlighting 1-2 words if needed.",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "Couple of services",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "A short title, might be service name or aha",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "What we offer in the service, keep it short and bring attention and curiosity, can write bullet points",
                                },
                                "button": {
                                    "type": "object",
                                    "properties": {
                                        "label": {
                                            "type": "string",
                                            "description": "Label to display. Make it crisp but powerful",
                                        },
                                        "type": {
                                            "type": "string",
                                            "description": "Either button or link",
                                            "enum": ["button","link"]
                                        },
                                        "link": {
                                            "type": "string",
                                            "description": "the url slug for the page or section or cta it refering to",
                                        },
                                    }
                                }
                            }
                        },
                    }
                }
            }
        },
        'feature_list' : {
            "name": "generate_page_section_details",
            "description": "Add a features section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",
                    },
                    "items": {
                        "type": "array",
                        "description": "List of features/sub-features. This array is mandatory",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "Feature title, keep it short",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "A small description of the feature and highlight how the user would benefit. Keep it short",
                                }
                            },
                            "required": ["title","content"],
                        },
                    }
                },
                "required": ["title","content","items"],
            }
        },
        'team' : {
            "name": "generate_page_section_details",
            "description": "Add a team section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "The teammates",
                        "items": {
                            "type": "object",
                            "properties": {
                                "name": {
                                    "type": "string",
                                    "description": "Name of the member, if provided, use it else, generate based on company location",
                                },
                                "designation": {
                                    "type": "string",
                                    "description": "Designation of the member, if provided, use it else, generate based on company industry and location",
                                },
                                "about": {
                                    "type": "string",
                                    "description": "The details of the work, about him/her, experience etc of member",
                                },
                                "gender": {
                                    "type": "string",
                                    "description": "Guess the gender of the member if not provided",
                                    "enum": ['male','female'],
                                }
                            }
                        },
                    }
                }
            }
        },
        'customer_list' : {
            "name": "generate_page_section_details",
            "description": "Add a customers section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "List of individual featured customers",
                        "items": {
                            "type": "object",
                            "properties": {
                                "by": {
                                    "type": "string",
                                    "description": "Their Customer's name",
                                },
                                "company_name": {
                                    "type": "string",
                                    "description": "If b2b, Their customer's company_name",
                                },
                            }
                        },
                    }
                }
            }
        },
        'logo_list' : {
            "name": "generate_page_section_details",
            "description": "Add the logo list section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "depending on section style, we can have items, like for services_list we can have couple of services, for faqs, we can have question and answer etc, for testimonials: individual testimonial",
                        "items": {
                            "type": "object",
                            "properties": {
                                "company_name": {
                                    "type": "string",
                                    "description": "A dummy company name",
                                },
                            }
                        },
                    }
                }
            }
        },
        'stats' : {
            "name": "generate_page_section_details",
            "description": "Add a stats and numbers section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "Various stats list",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "What's the stat is about. Eg: if we want to show Impacted 300 Industries, here write 'Industries'",
                                },
                                "stat": {
                                    "type": "number",
                                    "description": "What's the stat is about. Eg: if we want to show Impacted 300 Industries, here write '300'",
                                },
                                "type": {
                                    "type": "string",
                                    "description": "The type of stat",
                                    "enum": ["number","percentage","year"]
                                }
                            }
                        },
                    }
                }
            }
        },
        'how_it_works' : {
            "name": "generate_page_section_details",
            "description": "Add a step by step process",
            "parameters": {
                "type": "object",
                "properties": {
                    "headline": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "button_text": {
                        "type": "string",
                        "description": "The call to action button",                
                    },
                    "button_link": {
                        "type": "string",
                        "description": "The button link",                
                    },
                    "button_icon": {
                        "type": "string",
                        "description": "The font awesome icon code",
                    },
                    "items": {
                        "type": "array",
                        "description": "Various steps list",
                        "items": {
                            "type": "object",
                            "properties": {
                                "step_number": {
                                    "type": "number",
                                    "description": "Step number",
                                },
                                "title": {
                                    "type": "string",
                                    "description": "Step's title. Not always needed",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "What's the step content",
                                },
                                "icon": {
                                    "type": "string",
                                    "description": "The font awesome icon code",
                                },
                            }
                        },
                    }
                }
            }
        },
        'table' : {
            "name": "generate_page_section_details",
            "description": "Add a table section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The table data in markdown format",                
                    },
                }
            }
        },
        'pricing' : {
            "name": "generate_page_section_details",
            "description": "Add a pricing & packages table section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The pricing & packages and features table data in markdown format. if not present create some imaginary features and prices packages",                
                    },
                }
            }
        },
        'hero' : {
            "name": "generate_page_section_details",
            "description": "Add a hero section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The content",
                    },
                }
            }
        },
        'html' : {
            "name": "generate_page_section_details",
            "description": "Add a custom HTML section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The content in HTML, We use Bootstrap library for design",
                    },
                }
            }
        },
        'full_blog_post' : {
            "name": "generate_page_section_details",
            "description": "Add a Full length blog post",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful and good for SEO",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The 500+ words of entire blog post body, use markdown",
                    },
                }
            }
        },
        'awards' : {
            "name": "generate_page_section_details",
            "description": "Generate the awards section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "Award name and descriptions",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "Award title",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "A small description",
                                },
                                "year": {
                                    "type": "string",
                                    "description": "Year of the award",
                                }
                            }
                        },
                    }
                }
            }
        },
        'methods_process_list' : {
            "name": "generate_page_section_details",
            "description": "Generate the processes section, can be used for manufacturing or services etc.",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "items": {
                        "type": "array",
                        "description": "Individual process",
                        "items": {
                            "type": "object",
                            "properties": {
                                "title": {
                                    "type": "string",
                                    "description": "Process title",
                                },
                                "content": {
                                    "type": "string",
                                    "description": "Process description",
                                }
                            }
                        },
                    }
                }
            }
        },
        'faq' : {
            "name": "generate_page_section_details",
            "description": "Generate an effective faq section for the given page info and section info. Focus on what questions the visitor might be having about the page. Ignore other questions",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline of the FAQ section. Make impactful. Max 5-6 words",           
                    },
                    "content": {
                        "type": "string",
                        "description": "The gist of the section. Limit it effectively",        
                    },
                    "items": {
                        "type": "array",
                        "description": "List of questions and answers. Understand/guess the target audience and what quesions they might be having",
                        "items": {
                            "type": "object",
                            "properties": {
                                "question": {
                                    "type": "string",
                                    "description": "The question. Focus on what questions the visitor might be having about the page",
                                },
                                "answer": {
                                    "type": "string",
                                    "description": "A small answer, keep it short.",
                                },
                            }
                        },
                    }
                }
            }
        },
        'gallery' : {
            "name": "generate_page_section_details",
            "description": "Generate a gallery section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    }
                }
            }
        },
        'map' : {
            "name": "generate_page_section_details",
            "description": "Generate a map section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    }
                }
            }
        },
        'form' : {
            "name": "generate_page_section_details",
            "description": "Generate a form section",
            "parameters": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "description": "The title or headline. Make impactful. Max 5-6 words",                
                    },
                    "content": {
                        "type": "string",
                        "description": "The detailed content. Limit it effectively",                
                    },
                    "fields": {
                        "type": "array",
                        "description": "Form fields",
                        "items": {
                            "type": "object",
                            "properties": {
                                "label": {
                                    "type": "string",
                                    "description": "The field label",
                                },
                                "type": {
                                    "type": "string",
                                    "description": "The input type, use the enum values only",
                                    "enum": ["text","color","textarea","date","email","password","checkbox","radio","choices"],
                                },
                                "choices": {
                                    "type": "array",
                                    "description": "Optional, only used for types like: 'choices', 'checkbox','radio'",
                                    "items": {
                                        "type": "object",
                                        "properties": {
                                            "choiceText": {
                                                "type": "string",
                                                "description": "The field label",
                                            },
                                            "meaning":{
                                                "type": "string",
                                                "description": "little bit explaination",
                                            },
                                        },
                                    },
                                },
                                "required": {
                                    "type": "boolean",
                                    "description": "Wheather this field is necessary or not",
                                },
                                "placeholder": {
                                    "type": "string",
                                    "description": "Placeholder for the fields",
                                },
                                "hint": {
                                    "type": "string",
                                    "descriptions": "Hints, or examples for user to understand things better."
                                },
                            }
                        },
                    }
                }
            }
        }
    }
    return functions[section_style] || false;
}