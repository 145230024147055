import { Accordion, AccordionDetails, AccordionSummary, IconButton, TextareaAutosize, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ColorPickerComponent from '../../ColorPickerComponent';
import { Col, Row } from 'react-bootstrap';
import ImageUploaderNew from '../ImageUploaderNew';
import CssFilterConverter from 'css-filter-converter';
import { isClassPresentInString, replaceClassesInString, replaceOrAddClass } from '../../../../helpers/randoms';



function BlocksDesignPropertiesForm({type='block', block = {}, onUpdate}) {
    // type: block, items, item, blockPreHeader, blockTitle, blockIcon, blockImage, blockText, blockIcon, blockLink, itemPreheader, itemIcon, itemTitle, itemText, itemLink
    
    const parseCssToObjectOld = (cssString) => {
        console.log(cssString)
        const cssObject = {};
        if(!cssString) return cssObject;
        const styles = cssString.split(';');
      
        styles.forEach(style => {
          if (style.trim() !== '') {
            let [property, value] = style.split(':');
            if (property && value) {
              property = property.trim();
              value = value.replace('!important', '').trim(); // Remove !important
              cssObject[property] = value;
            }
          }
        });
      
        return cssObject;
    }
    const parseCssToObject = (cssString) => {
        const cssObject = {};
        if (!cssString) return cssObject;
      
        // Splitting by semi-colon outside of parentheses (to avoid splitting URLs)
        const styles = cssString.split(/;(?![^()]*\))/g);
      
        styles.forEach(style => {
          if (style.trim() !== '') {
            // Finding first colon to split property and value (to handle colons in URLs)
            const colonIndex = style.indexOf(':');
            if (colonIndex !== -1) {
              let property = style.substring(0, colonIndex).trim();
              let value = style.substring(colonIndex + 1).trim().replace('!important', '');
              cssObject[property] = value;
            }
          }
        });
      
        return cssObject;
      };
      

    const cssObjectToString = (cssObject) => {
        console.log('cssObject',cssObject);
        if(!cssObject) return '';
        return Object.entries(cssObject)
          .map(([property, value]) => `${property}: ${value} !important;\n`)
          .join(' ');
    }
    const designPropertiesToDesignStyles = (designProperties) => {
        const newDesignStyles = {};
        if(!designProperties) return newDesignStyles;
        Object.entries(designProperties).forEach(([blockElement, vals]) => {
          // Assuming vals is an object and it has a 'styles' property that is a string
          newDesignStyles[blockElement] = parseCssToObject(vals.styles);
        });
        return newDesignStyles;
    }
    const extractImageUrlFromCssObject = (backgroundImage) => {
        // Extracts URL from `url("image_url")`
        console.log('backgroundImage/Image',backgroundImage);
        return backgroundImage ? backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2') : false;
    }
      
    const [designProperties, setDesignPropertiesState] = useState( block?.designProperties || {});
    const [designStyles, setDesignStyles] = useState(designPropertiesToDesignStyles(block?.designProperties) || {});

    const updateDesignProperties = (blockDesignProperty, key = 'styles', value) => {
        // Use the function form of setState to ensure we have the latest state
        setDesignPropertiesState(prevDesignProperties => {
            const newDesignProperties = {
                ...prevDesignProperties,
                [blockDesignProperty]: {
                    ...prevDesignProperties[blockDesignProperty],
                    [key]: value
                }
            };

            setDesignStyles({...designStyles, [blockDesignProperty] : parseCssToObject(newDesignProperties?.[blockDesignProperty]?.styles)})
    
            console.log('newDesignProperties',newDesignProperties);
            // Call onUpdate with the new state immediately
            onUpdate(newDesignProperties);
    
            // Return the new state to update the component's state
            return newDesignProperties;
        });
    }
    
    /*
    const blocksDesignProperties = {
        blockClasses: {},
        blockStyles: {},
        itemsClasses: {},
        itemsStyles: {},
        blocks: {
            title: {
                classes: '',
                styles: {},
            },
            content: {
                classes: '',
                styles: {},
            },
        }
    };
    */

    const blocksDesignPropertiesNew = [
        'section',
        'block',
        'header',
        'blockPreHeader',
        'blockTitle',
        'blockIcon',
        'blockImage',
        'blockText',
        'blockLink',
        'button',
        'items',
        'item',
        'itemPreHeader',
        'itemIcon',
        'itemTitle',
        'itemImage',
        'itemText',
        'itemLink',
    ];
    
    const blocksDesignStylesNew = [
        {
            prop: 'color',
            type: 'color',
        },
        {
            prop: 'background-color',
            type: 'color',
        },
        {
            prop: 'background-image',
            type: 'imageUpload',
        },
        {
            prop: 'background-position',
            type: 'alignment',
        },
        {
            prop: 'background-repeat',
            type: 'selector',
            options: ['repeat','repeat-x','repeat-y','no-repeat','initial','inherit'],
        },
        {
            prop: 'font-family',
            type: 'fontSelection',
        },
        {
            prop: 'font-size',
            type: 'size',
        },
        {
            prop: 'width',
            type: 'size',
        },
        {
            prop: 'height',
            type: 'size',
        },
        {
            prop: 'position',
            type: 'position',
        },
        {
            prop: 'text-align',
            type: 'alignment',
        },
    ];

      

    const AddCSSProperties = ({blockDesignProperty}) => {
        const addCSSProperty = (key,val) => {
            const newDesignStyles = {...designStyles, [blockDesignProperty]: {...designStyles?.[blockDesignProperty], [key]: val}};
            setDesignStyles(newDesignStyles);
            updateDesignProperties(blockDesignProperty,'styles',cssObjectToString(newDesignStyles?.[blockDesignProperty]));
        }
        const addClassProperty = (type,classToAdd) => {
            const replacerArray = {
                'fontSelection': {
                    replace: ['header-font', 'body-font'],
                },
            };
            //console.log('prp',designProperties?.[blockDesignProperty]?.classes,replacerArray,replacerArray[type]);
            const newClasses = replaceOrAddClass(designProperties?.[blockDesignProperty]?.classes, replacerArray?.[type]?.replace, classToAdd);
            updateDesignProperties(blockDesignProperty,'classes',newClasses);
        }
        console.log("My test", blocksDesignStylesNew)
        return(
            <>
            {blocksDesignStylesNew.map((blocksDesignStyle,index) => (
                <div className='row' key={index}>
                    <Col>
                        {blocksDesignStyle.prop}
                    </Col>
                    <Col>
                        {blocksDesignStyle.type==='color' ? <ColorPickerComponent initialColor={convertShorthandHexToFull(designStyles?.[blockDesignProperty]?.[blocksDesignStyle.prop] || '#ffffff')} onColorChange={(e) => addCSSProperty(blocksDesignStyle.prop,e)} /> : <></>}
                        {blocksDesignStyle.type==='alignment' ?
                        <Row>
                            <span className={`border col pointer px-auto ${designStyles?.[blockDesignProperty]?.[blocksDesignStyle.prop] === 'left' ? 'bg-light' : ''}`} onClick={(e) => addCSSProperty(blocksDesignStyle.prop,'left')}><FormatAlignLeftIcon /></span>
                            <span className={`border col pointer px-auto ${designStyles?.[blockDesignProperty]?.[blocksDesignStyle.prop] === 'center' ? 'bg-light' : ''}`} onClick={(e) => addCSSProperty(blocksDesignStyle.prop,'center')}><FormatAlignCenterIcon /></span>
                            <span className={`border col pointer px-auto ${designStyles?.[blockDesignProperty]?.[blocksDesignStyle.prop] === 'right' ? 'bg-light' : ''}`} onClick={(e) => addCSSProperty(blocksDesignStyle.prop,'right')}><FormatAlignRightIcon /></span>
                        </Row> 
                        : 
                        <></>}
                        {blocksDesignStyle.type==='imageUpload' ?
                        <Row>
                            <ImageUploaderNew image={{url: extractImageUrlFromCssObject(designStyles?.[blockDesignProperty]?.[blocksDesignStyle.prop])}} onUpdateImage={(e) => addCSSProperty(blocksDesignStyle.prop, `url('${e?.url}')`) }  />
                        </Row> 
                        : 
                        <></>}
                        {blocksDesignStyle.type==='fontSelection' ?
                        <Row>
                            <span className={`border col pointer px-auto ${(isClassPresentInString(designProperties?.[blockDesignProperty]?.classes,'header-font')) ? 'bg-light' : ''}`} onClick={(e) => addClassProperty(blocksDesignStyle.type,'header-font')}>Header Font</span>
                            <span className={`border col pointer px-auto ${(isClassPresentInString(designProperties?.[blockDesignProperty]?.classes,'body-font')) ? 'bg-light' : ''}`} onClick={(e) => addClassProperty(blocksDesignStyle.type,'body-font')}>Body Font</span>
                        </Row> 
                        : 
                        <></>}

                    </Col>
                </div>
            ))}
            </>
        )
    }
    
    function convertShorthandHexToFull(hex) {
        if (hex.length === 4) {
          return '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
        }
        return hex;
    }

    function convertColorToFilter(hexColor) {
        return CssFilterConverter(hexColor);
    }
    
    const StyledAccordionSummary = styled(AccordionSummary)({
        justifyContent: 'space-between',
        alignItems: 'center',
    });

    const StyledIconButton = styled(IconButton)({
        //marginRight: 'auto',
        cursor: 'grab !important',
    });
    console.log("My test", blocksDesignPropertiesNew)

    return (
        <div className="editor-container">
            {blocksDesignPropertiesNew.map((blockDesignProperty, index) => (
                <React.Fragment key={blockDesignProperty}>
                    <Accordion
                        key={index}                               
                        //draggable
                        //onDragStart={(event) => handleDragStart(event, index)}
                        //onDragOver={handleDragOver}
                        //onDrop={(event) => handleDrop(event, index)}
                        //onTouchStart={(event) => handleDragStart(event, index)}
                        //onTouchMove={handleDragOver}
                        //onTouchEnd={(event) => handleDrop(event, index)}
                        //ref={drop} 
                        //style={{ opacity: isDragging ? 0.5 : 1 }}
                    >
                    <StyledAccordionSummary>
                        <StyledIconButton>
                            <DragIndicatorIcon />
                        </StyledIconButton>
                        <Typography variant="h6">{blockDesignProperty}</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                    Classes:
                    <TextareaAutosize
                        minRows={4}
                        aria-label="maximum height"
                        placeholder="Classes"
                        value={designProperties[blockDesignProperty]?.classes || ''}
                        onChange={(e) => updateDesignProperties(blockDesignProperty,'classes',e.target.value)}
                        style={{ width: '100%' }}
                    />
                    Styles:
                    <AddCSSProperties blockDesignProperty={blockDesignProperty} />
                    <TextareaAutosize
                        minRows={4}
                        aria-label="maximum height"
                        placeholder="Styles"
                        value={designProperties[blockDesignProperty]?.styles || ''}
                        onChange={(e) => updateDesignProperties(blockDesignProperty,'styles',e.target.value)}
                        style={{ width: '100%' }}
                    />
                    Divider top design:
                    <TextareaAutosize
                        minRows={4}
                        aria-label="maximum height"
                        placeholder="divider top type"
                        value={designProperties[blockDesignProperty]?.dividerTopType || ''}
                        onChange={(e) => updateDesignProperties(blockDesignProperty,'dividerTopType',e.target.value)}
                        style={{ width: '100%' }}
                    />
                    Divider bottom design:
                    <TextareaAutosize
                        minRows={4}
                        aria-label="maximum height"
                        placeholder="divider bottom type"
                        value={designProperties[blockDesignProperty]?.dividerBottomType || ''}
                        onChange={(e) => updateDesignProperties(blockDesignProperty,'dividerBottomType',e.target.value)}
                        style={{ width: '100%' }}
                    />
                    </AccordionDetails>
                    </Accordion>
                </React.Fragment>
            ))}
        </div>
    );
}

export default BlocksDesignPropertiesForm;
