import { Button, Grid, TextField, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
//import ImageUploader from './ImageUploader';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { generateSlug } from '../../../helpers/randoms';
import { Col, Row } from 'react-bootstrap';
import BlocksDesignPropertiesForm from './parts/BlockDesignPropertiesForm';
import GlobalTemplateForm from './GlobalTemplateForm';
import BlocksBackgroundTextureForm from './parts/BlockBackgroundTextureForm';

/*
  Objectives:
  1. Creates templates from sections like Few and design - like a blog page
  2. Dummy content to fill it up.
  3. Link sections with AI prompts on variables, to different field. Later you could add openai plugins too to access internet etc
  4. Insert sections like: write short testimonials to put on {section_identifier.title} for my {company} - like it can write case study pages if customer gives some info about customer_company+name+designation
  5. A subscribe form, with Heading changing to above stuff
  6. A image automatically added/suggested/generated
  7. Create Excerpts
*/
const GlobalAdvancedForm = ({ data = {}, onUpdate, children }) => {
  console.log('in page form: new',data);
  //const [slugEditedManually, setSlugEditedManually] = useState(false);
  const [pageData, setPageData] = useState(data || {});
  const [pageTitle, setPageTitle] = useState(data.title || '');
  const [pageName, setPageName] = useState(data.name || '');
  const [pageURL, setPageURL] = useState(data.url || '');
  const [redirectTo, setRedirectTo] = useState(data.redirectTo || '');
  const [is404Page, setIs404Page] = useState(data.is404 || false);
  const [slugEditedManually, setSlugEditedManually] = useState(false);
  const [nameEditedManually, setNameEditedManually] = useState(false);
  const [screenType, setScreenType] = useState('data');

  const onUpdateDesign = (designProperties) => {
    const newPageData = {...pageData, designProperties: designProperties};
    console.log('at parent, saving design', newPageData);
    setPageData(newPageData);
  }
  const onUpdateBackgroundSprinkles = (backgroundSprinkles) => {
    const newPageData = {...pageData, backgroundSprinkles: backgroundSprinkles};
    console.log('at parent, saving sprinkles', newPageData);
    setPageData(newPageData);
  }

  const handleSave = () => {
    onUpdate(pageData);
  }

  const DataForm = ({data}) => {
    return(
      <>
        DataForm
      </>
    )
  }
  const DesignForm = ({data}) => {
    return(
      <div className='container'>
        DesignForm
        <>
          First the Grid Layout
        </>
        <>
          Elements in grid layout positions
        </>
        <BlocksDesignPropertiesForm block={data} />
      </div>
    )
  }
  const InteractionsForm = ({data}) => {
    return(
      <>
        InteractionsForm
      </>
    )
  }  
  const TemplateForm = ({data}) => {
    return(
      <>
        <h1>Convert to template</h1>
        <Button>Make it a Template</Button>
        Template name, Reference, Set As global, Template Type, 
        <GlobalTemplateForm  onUpdate={onUpdateDesign} />
        <hr />
        <h1>Choose a template</h1>

      </>
    )
  }  
  const CodeForm = ({data}) => {
    return(
      <>
        CodeForm
      </>
    )
  }


  return (
    <div>
      <Row className='p-2'>
        <Col onClick={() => setScreenType('data')} className={`pointer border ${(screenType==='data') ? 'bg-light' : 'bg-dark text-light'}`}>
          Data
        </Col>
        <Col onClick={() => setScreenType('design')} className={`pointer border ${(screenType==='design') ? 'bg-light' : 'bg-dark text-light'}`}>
          Style
        </Col>
        <Col onClick={() => setScreenType('interactions')} className={`pointer border ${(screenType==='interactions') ? 'bg-light' : 'bg-dark text-light'}`}>
          Interactions
        </Col>
        <Col onClick={() => setScreenType('template')} className={`pointer border ${(screenType==='template') ? 'bg-light' : 'bg-dark text-light'}`}>
          Template
        </Col>
        <Col onClick={() => setScreenType('code')} className={`pointer border ${(screenType==='code') ? 'bg-light' : 'bg-dark text-light'}`}>
          Code
        </Col>
        <Col onClick={() => setScreenType('backgroundTexture')} className={`pointer border ${(screenType==='backgroundTexture') ? 'bg-light' : 'bg-dark text-light'}`}>
          background sprinkled images
        </Col>
      </Row>
      {screenType==='data' && <>{children}</>}
      {screenType==='design' && <BlocksDesignPropertiesForm onUpdate={(e) => onUpdateDesign(e)} block={pageData} />}
      {screenType==='backgroundTexture' && <BlocksBackgroundTextureForm onUpdate={(e) => onUpdateBackgroundSprinkles(e)} block={pageData} />}
      {screenType==='interactions' && <InteractionsForm />}
      {screenType==='template' && <TemplateForm />}
      {screenType==='code' && <CodeForm />}
      {screenType!=='data' && 
      <>
      <GriderLayout left={'Use Old Version'} 
        right={<input
          type="checkbox"
          value={'old'}
          checked={(pageData.version!=='block')}
          onChange={(e) => setPageData({ ...pageData, version: e.target.checked ? 'old' : 'block' })}
        />} 
      />
      <br />
      <GriderLayout left={'Hide'} 
        right={<input
          type="checkbox"
          value={true}
          checked={pageData.isHidden}
          onChange={(e) => setPageData({ ...pageData, isHidden: e.target.checked})}
        />} 
      />
      
      <Button onClick={handleSave} fullWidth className='my-3' variant='outlined' color='primary'>Save</Button>
      </>
      }
    </div>
  );
};

export default GlobalAdvancedForm;
