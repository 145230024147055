import { useEffect, useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Image} from 'react-bootstrap';
const TestimonialBlock = ({ block, blockId }) => {
    /*
    const [theblock, setTheBlock] = useState({
        "WebsiteBlock": {
            "_id": "629f98a1eb0b4972268051a3",
            "description": "Display a quote or testimonial",
            "type": "quote",
            "active": true,
            "name": "Quote/testimonial"
        },
        "idx": 2,
        "items": [
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8cGVyc29ufGVufDB8fHx8MTY4MzEwNTc2Nw&ixlib=rb-4.0.3&q=80&w=1080",
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          },
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8cGVyc29ufGVufDB8fHx8MTY4MzEwNTc2Nw&ixlib=rb-4.0.3&q=80&w=1080",
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          },
          {
              "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
              "by": "- John Smith, CEO",
              "image": {
                "_id": "64536415bbca55b72afa69f9",
                "author": "Stephanie",
                "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
                "type": "unsplash",
                "url": "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8cGVyc29ufGVufDB8fHx8MTY4MzEwNTc2Nw&ixlib=rb-4.0.3&q=80&w=1080",
                "Business": "644759ffb29fcec827b43346",
                "createdAt": "2023-05-04T07:51:49.971Z",
                "__v": 0,
                "id": "64536415bbca55b72afa69f9"
              },
              "idx": 0
          }
        ],
        "align": "center",
        "header": "Testimonials",
        "background": "#83dfb6"
    });

    useEffect(() => {
      setTheBlock(block);
    }, [block]);
    */
   
    const theblock = block;

    const itemsCount = (theblock && theblock.items && theblock.items.length>0) ? theblock.items.length : 0;

    console.log('testimonial',block)

    const type = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'singlesaasshotoneslide'; //'singlesaasshot';

    console.error('Quote Design',type)

    const SvgQuote = ({color="#d26d17", width=100, height=100}) => {
      return (<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.16 107.5" width={width} height={height}>
      <defs>
        <style>
        {`
          .cls-1 {
            fill: ${color};
          }
        `}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <path className="cls-1" d="m11.48,34.14C19.14,21.69,32.97,10.31,52.97,0l3.75,7.03c-10,7.08-17.4,13.02-22.19,17.81-8.44,8.65-12.66,16.93-12.66,24.84,0,2.71.78,4.48,2.34,5.31,1.56.94,2.92,1.41,4.06,1.41,3.96-.52,6.56-.78,7.81-.78,6.67,0,12.13,2.42,16.41,7.27,4.27,4.84,6.41,10.7,6.41,17.58,0,7.71-2.34,14.14-7.03,19.3s-11.15,7.73-19.38,7.73c-9.69,0-17.53-3.46-23.52-10.39C2.99,90.18,0,80.94,0,69.38s3.83-22.79,11.48-35.23Zm86.95-.86c8.12-12.4,21.72-23.49,40.78-33.28l3.75,7.03c-9.38,6.56-16.56,12.34-21.56,17.34-8.86,8.85-13.28,17.08-13.28,24.69,0,2.19.57,3.96,1.72,5.31,1.14,1.35,2.71,2.03,4.69,2.03,3.96-.52,6.56-.78,7.81-.78,6.56,0,12,2.4,16.33,7.19,4.32,4.79,6.48,10.68,6.48,17.66,0,8.12-2.4,14.66-7.19,19.61-4.79,4.95-11.3,7.42-19.53,7.42-9.38,0-17.08-3.46-23.12-10.39-6.04-6.93-9.06-16.17-9.06-27.73s4.06-23.7,12.19-36.09Z"/>
      </g>
    </svg>)
    }

    console.log('quote blockId', blockId)
    return (
      <>
      {(type==='singlesaasshot') ? 
      <div className={`py-5 border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          {(theblock && theblock.header) && 
            <div className="row text-center">
              <Col className='h3'>{(theblock && theblock.header) ? theblock.header : ''}</Col>
            </div>
          }
          <div className="row text-center my-3">
            {theblock && theblock.items && theblock.items.map((column, index) => (
              <div key={index} className={`my-3 col-md-12 mx-auto ${(index%2===0) ? 'lightaccentbg' : 'darkaccentbg'}`}>
                <div className="row text-center">
                  {column.title && <h4>{column.title}</h4>}
                  <div className={`p-3 col-md-4 ${(index%2===0) ? 'order-last' : ''}`}>
                    {(column.image && column.image.url!=='') && <Image src={column.image.url} alt={column.by} thumbnail className='p-0 border-0' style={{aspectRatio: 1/1}} />}
                  </div>
                  <div className='p-5 col-md-8'>
                    <blockquote className='h5'>
                      <div className='text-start'>
                        <SvgQuote color="#ffccff" height={40} />
                      </div>
                      <br />
                      {column.content}
                    </blockquote>
                    <div className='quote-by'>{column.by}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>          
        </Container>
      </div>
      : ''}
      {(type==='threeColumnStatic' || type==='slide3Set') ? 
      <div className={`py-5 border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          {(theblock && theblock.header) && 
            <div className="row text-center">
              <Col className='h3'>{(theblock && theblock.header) ? theblock.header : ''}</Col>
            </div>
          }
          <div className="row text-center">
            {theblock && theblock.items && theblock.items.map((column, index) => (
              <div key={index} className={`${(itemsCount>2) ? 'col-md-4' : (itemsCount===1) ? 'col-md-12' : 'col-md-6'} mx-auto py-3`}>
                {column.title && <h4>{column.title}</h4>}
                <blockquote>{column.content}</blockquote>
                {(column.image && column.image.url!=='') && <Image src={column.image.urlSmall} alt={column.by} thumbnail roundedCircle className='rounded-circle thumbnail' />}
                <div className='quote-by'>{column.by}</div>
              </div>
            ))}
          </div>
        </Container>
      </div> : ''
      }
      {(type==='slide2Set') ? 
      <div className={`py-5 border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          {(theblock && theblock.header) && 
            <div className="row text-center">
              <Col className='h3'>{(theblock && theblock.header) ? theblock.header : ''}</Col>
            </div>
          }
          <div className="row text-center">
            {theblock && theblock.items && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-6 mx-auto py-4`}>
                {column.title && <h4>{column.title}</h4>}
                {(column.image && column.image.url!=='') && <Image src={column.image.urlSmall} alt={column.by} thumbnail roundedCircle className='rounded-circle thumbnail' />}
                <blockquote className='quote-content'>{column.content}</blockquote>
                <div className='quote-by'>{column.by}</div>
              </div>
            ))}
          </div>
        </Container>
      </div> : ''
      }
      {(type==='slide1Set') ? 
      <div className={`py-5 border-bottom ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          {(theblock && theblock.header) && 
            <div className="row text-center">
              <Col className='h3'>{(theblock && theblock.header) ? theblock.header : ''}</Col>
            </div>
          }
          <div className="row text-center">
            {theblock && theblock.items && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-12 mx-auto py-4`}>                
                {column.title && <h4>{column.title}</h4>}
                <blockquote>{column.content}</blockquote>
                {(column.image && column.image.url!=='') && <Image src={column.image.urlSmall} alt={column.by} thumbnail roundedCircle className='rounded-circle thumbnail' />}
                <div className='quote-by'>{column.by}</div>
              </div>
            ))}
          </div>
        </Container>
      </div> : ''
      }
      {(type==='singlesaasshotoneslide') ?
      <div className='py-5 border-bottom darkaccentbg singlesaasshotoneslide'>
        <Container>
          <div className="row text-center">
            {theblock && theblock.items && theblock.items.map((column, index) => (
              <>
              {(index===0) ? 
              <div key={index} className={`p-5 mx-auto col-md-10`}>                
                {column.title && <h4>{column.title}</h4>}
                <blockquote className='h4'>"{column.content}"</blockquote>
                -- {column.by}
              </div>
              : ''}
              </>
            ))}
          </div>
        </Container>
      </div>
      : ''}
    </>
    );
  };

export default TestimonialBlock;