import { buttonBaseClasses } from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import { getAllBrandLogos } from '../../helpers/brands';
import { TheaterComedy } from '@mui/icons-material';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const BannerBlock = ({ block, children, blockId }) => {
    const theblock = block;


    const brands = getAllBrandLogos();

    let alignClass = 'text-center';

    if(theblock.align==='left'){
      alignClass = 'text-start'
    } else if(theblock.align==='right'){
      alignClass = 'text-end'
    }

    console.log('Alignment',theblock)
    
    const type = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'heroic'; //'singlesaasshot';

    console.log('design',type)

    const theStyle = `<style>
      .left:before {
          background-image: url(${theblock?.background?.image?.url});
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          width: 40%;
          top: 0px;
          left: 0px;
          bottom: 0px;
      }
      .left:after {
        background-color: ${theblock.background.color};
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 60%;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
      .right:after {
        background-image: url(${theblock?.background?.image?.url});
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 40%;
        top: 0px;
        right: 0px;
        bottom: 0px;
    }
    .right:before {
      background-color: ${theblock.background.color};
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: 60%;
      top: 0px;
      left: 0px;
      bottom: 0px;
    }
    </style>`;


    return (
      <>
      {(type==='heroic') ?
      <div className={`section ${(alignClass)}`} style={(theblock && theblock.background && theblock.background.image) ? {backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage : `url(${theblock?.background?.image?.url})` } : { background: '#ffc'}}>
        <div className='p-md-5 py-5 brandtransparentbg' style={{backgroundColor: (theblock && theblock.background && theblock.background.opacity) ? `rgba(0,0,0,${theblock.background.opacity/100})` : 'rgba(0,0,0,0.6)', color: 'white', width: '100%', margin:0 }} >
        <Container style={{opacity: 1}} className='p-md-5 py-5'>
          <div className="row p-md-5 py-5 py-sm-5">
            <Col md={12} xs={12}>
              <h1 className='highlight-this fw-bold display-1'><MarkdownRenderer markdownString={theblock.headline} /></h1>
              <MarkdownRenderer markdownString={theblock.content} />
              {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} data-click={theblock.button.clickEvent} className='btn btn-primary my-3'>{theblock.button.label}</a>}
              {children}
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      {['videoBackgroundHero','videoDownBackgroundHero'].includes(type) ?
      <div className={`section ${(alignClass)} video-background darkbg`} style={{backgroundColor: (theblock && theblock.background && theblock.background.color) ? theblock.background.color : ''}}>
        <div className='p-md-5 py-5'>
        <Container style={{opacity: 1}} className='p-md-5 py-5'>
          <div className="row p-md-5 py-5 py-sm-5">
            <Col md={12}>
              {/*
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3C_22eBWpjg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              */}
            </Col>
            <Col md={12} className={`${(['videoDownBackgroundHero'].includes(type)) ? 'order-first mb-5' : ''}`}>
              <h1 className='highlight-this fw-bold display-1'><MarkdownRenderer markdownString={theblock.headline} /></h1>
              <MarkdownRenderer markdownString={theblock.content} />              
              {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} data-click={theblock.button.clickEvent} className='btn btn-primary my-3'>{theblock.button.label}</a>}
              {children}
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      {(['leftContentRightImage','leftContentPlusTestimonialRightImage','leftContentPlusBrandRightImage','rightContentLeftImage','rightContentLeftVideo'].includes(type)) ?
      <div className={`section ${(alignClass)}`}>   
       {/*styleu={{backgroundColor: theblock.background.color}}*/}
        <div dangerouslySetInnerHTML={{ __html: theStyle }} />
        <div className='p-md-5 py-5 lightColor'>
        <Container style={{opacity: 1}} className='py-5'>
          <div className="row py-md-5 py-sm-5 align-middle">
            <Col md={6} className={`p-md-5 py-5 align-middle ${(['rightContentLeftImage','rightContentLeftVideo'].includes(type)) ? 'order-last' : ''}`}>
              <h1 className='highlight-this fw-bold display-2 typewriter-texti' aria-speed="93"><MarkdownRenderer markdownString={theblock.headline} /></h1>
              <MarkdownRenderer markdownString={theblock.content} />
              {children}
              {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} data-click={theblock.button.clickEvent} className='btn btn-primary my-3'>{theblock.button.label}</a>}
              {(['leftContentPlusTestimonialRightImage','leftContentPlusBrandRightImage'].includes(type)) ? 
              <>
                <hr />
                {(type==='leftContentPlusTestimonialRightImage') && 'here testimonials'}
                {(type==='leftContentPlusBrandRightImage') && 
                  <>
                    <h5 className='fw-bold'>Brands who trust us:</h5>
                    {brands?.map((brand,index) => (
                      <>
                      <img loading='lazy' src={brand.logoUrl} alt={brand.name} key={index} height={40} className='me-2 brandImageFilter' />
                      {brand.logoSvg}
                      </>
                    ))}
                  </>
                }
              </>
              : ''}
            </Col>
            <Col md={6} className={`d-flex justify-content-center align-items-center ${(['rightContentLeftImage','rightContentLeftVideo'].includes(type)) ? 'left leftBrandBg' :'right rightBrandBg'}`}>
              {/*(theblock && theblock.background && theblock.background.image && theblock.background.image.url) && <img loading='lazy' src={theblock.background.image.url} alt={theblock.background.image.description} width={'100%'} className='align-middle img-fluid' />*/}
              {(['rightContentLeftVideo'].includes(type)) &&            
                {/*
                <iframe width="560" height="315" src="https://www.youtube.com/embed/3C_22eBWpjg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                */}
              }
              {children}
            </Col>
          </div>
        </Container>
        </div>
      </div> : ''
      }
      </>
    );
  };

export default BannerBlock;