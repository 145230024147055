import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';

function ColorPalette({onColorPalletSelect}) {
  const [colorPalette, setColorPalette] = useState([]);

  useEffect(() => {
    fetchColorPalette()
      .then((palette) => setColorPalette(palette));
  }, []);

  // Make an HTTP request to the Colormind API
  const fetchColorPalette = async () => {
    const url = 'http://colormind.io/api/';
    const data = {
      model: 'ui',
      //input: []
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data)
      });
      const result = await response.json();
      return result.result;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  console.log(colorPalette)

  const colorPaletteChanged = (colorPalette) => {
    onColorPalletSelect(colorPalette)
  }

  const width = {
    0: '30%',
    1: '30%',
    2: '10%',
    3: '15%',
    4: '15%',
  }

  return (
    <>
    {(colorPalette && colorPalette.length>0) ?
    <div className="color-palette row mb-2" role='button' style={{borderRadius: '15px', border: '2px solid #ccc', overflow: 'hidden'}} onClick={() => onColorPalletSelect(colorPalette)}>
      {colorPalette.map((color, index) => (
        <div
          key={index}
          className="color-box d-inline-block"
          style={{ backgroundColor: `rgb(${color.join(',')})`, width: width[index], height:35, border: '0px solid #fcc', aspectRatios: 1/1 }}
        />
      ))}
    </div>
    :
    <Skeleton height={50} />
    }
    </>
  );
}

export default React.memo(ColorPalette);
