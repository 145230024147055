import { useNode } from "@craftjs/core";
import { useEffect } from "react";
import DeleteButton from "../DeleteButton";

export const FreeFormComponent = ({ children, defaultPosition }) => {
    const {
      connectors: { connect, drag },
      actions: { setProp },
      position,
    } = useNode(node => ({
      position: node.data.props.position,
    }));
  
    useEffect(() => {
      if (!position) {
        setProp(props => (props.position = defaultPosition), 500);
      }
    }, [defaultPosition, position, setProp]);
  
    return (
      <div
        ref={ref => connect(drag(ref))}
        style={{
          position: 'absolute',
          left: `${position?.x}px`,
          top: `${position?.y}px`,
        }}
      >
        {children}
        <DeleteButton />
      </div>
    );
  };
  
  FreeFormComponent.craft = {
    props: {
      position: { x: 0, y: 0 },
    },
    related: {
      //settings: CustomSettings,
    },
  };
  