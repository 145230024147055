import React, { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { generateText } from '../../../helpers/prompts';

const BusinessFromIndustry = () => {
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);

      // Read the uploaded Excel file using PapaParse
      Papa.parse(file, {
        complete: async (result) => {
          const companyDescriptions = result.data.filter((row)=> row[0]!='Industry').slice(0,10).map((row) => row[0]); // Assuming company descriptions are in the first column
          console.log(companyDescriptions);
          const businessTypes = await getBusinessTypes(companyDescriptions);

          /*
          const dataWithBusinessTypes = result.data.slice(0,5).map((row, index) => ({
            companyDescription: row[0],
            businessType: businessTypes[index],
          }));
          */

          //setCompanyData(...companyData,businessTypes);
          setCompanyData((prevCompanyData) => [...prevCompanyData, ...businessTypes]);
          setIsLoading(false);
        },
      });
    }
  };

  const getBusinessTypes = async (companyDescriptions) => {
    // Use Axios (or another HTTP client) to send API requests to OpenAI (or another API)
    // Replace 'YOUR_API_KEY' with your actual API key
    const apiKey = 'YOUR_API_KEY';
    const apiUrl = 'https://api.example.com/query'; // Replace with the API URL
    const businessTypes = [];

    for (const description of companyDescriptions) {
      try {
        const thePrompt = `[no prose][Output only JSON]. A company has incorporated with description as '${description}' what are 10 types of companies it might be like? JSON output should be like: {businessTypes: ['business type 1','business type 2']}`;
        const response = await generateText(thePrompt);
        if(response){
          console.log(response);
          JSON.parse(response).businessTypes.map((businessType,index) => {
            businessTypes.push({companyDescription: description, businessType: businessType});
          })
        }
      } catch (error) {
        console.error(error);
        //businessTypes.push('Unknown');
      }
    }

    return businessTypes;
  };

  return (
    <div>
      <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Company Description</th>
              <th>Business Type</th>
            </tr>
          </thead>
          <tbody>
            {companyData.map((data, index) => (
              <tr key={index}>
                <td>{data.companyDescription}</td>
                <td>{data.businessType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BusinessFromIndustry;
