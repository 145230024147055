import React, { useState, useRef, useEffect } from 'react';

function CodeWriterForm({jsonData = {}}) {
  const [keys, setKeys] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef();

  //const keys = ['sender.name', 'receiver.name', 'date']; // Example keys, replace with your keys

  useEffect(() => {
    const handleKeyUp = (e) => {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setCursorPosition({ top: rect.top, left: rect.left });

      const word = getWordAtCursor();
      if (word.startsWith('{')) {
        console.log('keys in suggestion',keys)
        const suggestions = keys.filter((key) => key.startsWith(word.substring(1)));
        setSuggestions(suggestions);
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
      // Update dropdown position
      /*
      if (showSuggestions) {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        //setDropdownPosition({ top: rect.bottom, left: rect.left });
        const adjustment = 300; // Adjust this value as needed
        setDropdownPosition({ top: rect.bottom - adjustment, left: rect.left });
      }
      */
    };

    const editor = editorRef.current;
    editor.addEventListener('keyup', handleKeyUp);

    return () => {
      editor.removeEventListener('keyup', handleKeyUp);
    };
  }, [keys]);
  
  useEffect(() => {
    if (showSuggestions) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const adjustment = 300; // Adjust this value as needed
      setDropdownPosition({ top: rect.bottom - adjustment, left: rect.left });
    }
  }, [showSuggestions, suggestions]);
  
  useEffect(() => {
    setKeys(generateKeys(jsonData));
  }, [jsonData]);

  console.log('Json passed & generated', jsonData,keys)

  const getWordAtCursor = () => {
    const selection = window.getSelection();
    const node = selection.anchorNode;
    const offset = selection.anchorOffset;
    const text = node.textContent.substring(0, offset);
    const words = text.split(/\s+/);
    return words[words.length - 1] || '';
  };

  const generateKeys = (obj, prefix = '') => {
    console.log(obj)
    if(obj===null) return '';
    return Object.keys(obj).reduce((acc, key) => {
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        console.log(acc,obj,obj[key],key,newPrefix)
        acc = [...acc, ...generateKeys(obj[key], newPrefix)];
      } else {
        acc.push(newPrefix);
      }
      return acc;
    }, []);
  };
  

  /*
  const replaceWordAtCursor = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const wordsBefore = textBefore.split(/\s+/);
    wordsBefore[wordsBefore.length - 1] = replacement;
    const newTextBefore = wordsBefore.join(' ');
  
    node.textContent = newTextBefore + textAfter;
  
    // Move the cursor to the right side of the inserted text
    const newCursorPos = newTextBefore.length;
    range.setStart(node, newCursorPos);
    range.setEnd(node, newCursorPos);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  */
  /*
  const replaceWordAtCursor = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const lastSpaceIndex = textBefore.lastIndexOf(' ');
    const leadingText = textBefore.substring(0, lastSpaceIndex + 1); // preserve leading whitespaces
    const word = textBefore.substring(lastSpaceIndex + 1);
    const newTextBefore = leadingText + replacement;
  
    node.textContent = newTextBefore + textAfter;
  
    // Move the cursor to the right side of the inserted text
    const newCursorPos = newTextBefore.length;
    range.setStart(node, newCursorPos);
    range.setEnd(node, newCursorPos);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  */
 
  /*
  const replaceWordAtCursor = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const lastSpaceIndex = textBefore.lastIndexOf(' ');
    const leadingText = textBefore.substring(0, lastSpaceIndex + 1); // preserve leading whitespaces
  
    // Create a new span element to wrap the replacement text
    const span = document.createElement('span');
    span.textContent = replacement;
    span.style.backgroundColor = '#4b4b4b'; // or any other styles you want to apply
    //span.style.color = 'black'; // or any other styles you want to apply
  
    // Insert the leading text and the span at the cursor position
    range.deleteContents();
    range.insertNode(document.createTextNode(leadingText));
    range.insertNode(span);
  
    // Move the cursor to the right side of the inserted text
    range.setStartAfter(span);
    range.setEndAfter(span);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  */

  const replaceWordAtCursorOne = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const lastSpaceIndex = textBefore.lastIndexOf('{'); // changed from space to '{'
    const leadingText = textBefore.substring(0, lastSpaceIndex); // preserve leading whitespaces
  
    // Create a new span element to wrap the replacement text
    const span = document.createElement('span');
    span.textContent = replacement;
    span.style.backgroundColor = '#4b4b4b'; // or any other styles you want to apply
  
    // Insert the leading text and the span at the cursor position
    range.deleteContents();
    range.insertNode(document.createTextNode(leadingText));
    range.insertNode(span);
  
    // Create a text node to place the cursor in, after the span
    const cursorNode = document.createTextNode('');
    range.insertNode(cursorNode);
  
    // Move the cursor to the right side of the inserted text
    range.setStart(cursorNode, 0);
    range.setEnd(cursorNode, 0);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const replaceWordAtCursorTwo = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const lastBracketIndex = textBefore.lastIndexOf('{');
    const leadingText = textBefore.substring(0, lastBracketIndex); // remove the extra '{'
  
    // Create a new span element to wrap the replacement text
    const span = document.createElement('span');
    span.textContent = replacement;
    span.style.backgroundColor = 'yellow'; // or any other styles you want to apply
  
    // Clear the existing content and insert the leading text, the span, and the text after the cursor
    node.textContent = leadingText + textAfter;
    range.setStart(node, leadingText.length);
    range.setEnd(node, leadingText.length);
    range.deleteContents();
    range.insertNode(span);
  
    // Create a text node to place the cursor in, after the span
    const cursorNode = document.createTextNode('');
    span.parentNode.insertBefore(cursorNode, span.nextSibling);
  
    // Move the cursor to the right side of the inserted text
    range.setStart(cursorNode, 0);
    range.setEnd(cursorNode, 0);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  
  const replaceWordAtCursor = (replacement) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
    const offset = range.startOffset;
  
    const textBefore = node.textContent.substring(0, offset);
    const textAfter = node.textContent.substring(offset);
    const lastBracketIndex = textBefore.lastIndexOf('{');
    const leadingText = textBefore.substring(0, lastBracketIndex); // remove the extra '{'
  
    // Create a new span element to wrap the replacement text
    const span = document.createElement('span');
    span.textContent = replacement;
    span.style.backgroundColor = '#4b4b4b'; // or any other styles you want to apply
    span.style.padding = '0px 10px'; // or any other styles you want to apply
  
    // Clear the existing content and insert the leading text, the span, and the text after the cursor
    node.textContent = leadingText + textAfter;
    range.setStart(node, leadingText.length);
    range.setEnd(node, leadingText.length);
    range.deleteContents();
    range.insertNode(span);
  
    // Append a space after the span to place the cursor in
    const spaceNode = document.createTextNode(' ');
    span.parentNode.insertBefore(spaceNode, span.nextSibling);
  
    // Move the cursor to the right side of the inserted text
    range.setStart(spaceNode, 1);
    range.setEnd(spaceNode, 1);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  
  
  
  
  
  const handleSuggestionClick = (suggestion) => {
    replaceWordAtCursor(`{${suggestion}}`);
    setShowSuggestions(false);
    editorRef.current.focus(); // Refocus on the editor after selecting a suggestion
  };
  

  const handleKeyDown = (e) => {
    if (showSuggestions) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveSuggestion((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : prev));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        handleSuggestionClick(suggestions[activeSuggestion]);
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={editorRef}
        contentEditable
        onKeyDown={handleKeyDown}
        style={{ minHeight: '100px', border: '1px solid black', whiteSpace: 'pre-wrap', padding: '20px', background: 'black', color: 'wheat' }}
      ></div>
      {showSuggestions && (
        <div
          style={{
            position: 'absolute',
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            border: '1px solid black',
            background: 'white',
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ background: index === activeSuggestion ? '#e9e9e9' : 'transparent' }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CodeWriterForm;
