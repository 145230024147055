import { jsonrepair } from "jsonrepair";
import BannerBlock from "../components/Blocks/BannerBlock";
import { convertBase64ToDataUrl, openAiDataUrlToSupabaseUpload } from "./image";
import { cleanSubmenus } from "./randoms";
import { compressToUTF16 } from 'lz-string';


export const prompts = {
  'start': 'Create a short text for a "Accounting" business called "ZenoCorp". Limit it to max 25-30 words. Shorter the better. This can be sub-text below banner heading. The text:',
  'about': 'Create a 500 words rich text code with pics and h1, h2 and h3 tags for "About Us" page of a "Accounting" business called "ZenoCorp" in which the founder: "Sannu". Write about founders in separate paragraph. Use some text colors too. Use pics from unsplash.com',
  'shortHeading': '[no quotation] Create a short heading for a "Accounting" business called "ZenoCorp". It\'s website landing page heading, keep it short and straight to the point. In the imperative form. Like for Accounting business, it\'s "Simplifying Finance" or "Make your finance streamlined.". Max 10 words. Heading:', // Write in Odia:',
  //'shortHeadingServices': 'I have a webpage where I want to put many blocks to highlight types of services/features. For one such block, create a short heading. I have a "Accounting" business. It should be like "Blog Writing" or "Product description writing" or "Ghost writing service" for a content writing business',
  'shortHeadingServices': 'Create 1 short heading for one content block of a services page. Max 4 words. Do not suggest what you have suggested before. I have a "Accounting" business. It should be like "Blog Writing" or "Product description writing" or "Ghost writing service" for a content writing business',
  'shortDescription': 'Create a short description with an existing title "{{TITLE_HERE}}" for a "Accounting" business called "ZenoCorp":',
  'testimonial': 'Create a testimonial without a name for a "Accounting" business called "ZenoCorp". Make it short at sweet, within 120 words. Testimonial: ',
  'longTestimonial': 'Create a testimonial without a name for a "Accounting" business called "ZenoCorp": ',
  'testimonialCustomer': 'Give one name for a random fake person. Must be Indian',
  'testimonialCustomerNew': '[no prose][Output only JSON] Strictly follow the instructions. Give one name for a random fake person with his/her gender, nationality and country. Output an JSON array with keys "name", "gender", "nationality", "country":',
  'fullServicePageContent': 'Create 1000 words rich text content with h2, h3 tags and paragraphs and bullet points for a service page called "{{SERVICE_PAGE_TITLE}}" for a "Accounting" business called "ZenoCorp". Mention about various efforts in the service process as well:',
  'websiteColors': 'Give a json with keys as backgroundColor, NavigationBarColor and headingColor for a website for a "Accounting" business called "ZenoCorp"',
  'svgLogo': 'give an svg code for a logo for a company in "Content Writing" business called "BlackWitch", it should have some icon drawing that represents the brand and business',
  'imageSearch': 'Give an array of urls and names of 5 images from unsplash, that would suit a "Accounting" business',
  'getKindOfBusiness': 'A business can be categorized as only one among the following: SaaS, Product, Service, Education. For a "Accounting" business called "ZenoCorp" which category you will pick for it:',
  'sectionsOfFullServicePage': 'Suggest few short headings for some sections of a full services page on "{{SERVICE_PAGE_TITLE}}" business. Each should be of 2-4 words. Provide as an array',
  'menus': '[no prose][Output only JSON] [no double quote in value] Strictly follow the instructions. You are a great information architect API. Suggest few menus and sub-menus for a website of a "Accounting" business called "ZenoCorp".',
  'decidingPageLayout': 'I have a business ',
  

  // editing ability:
  'shortenLengthen' : 'Strictly follow the instruction. Rewrite the following {{content_type}} within "{{word_limit}}" words. Be "{{content_style}}" and use "{{tone}}" tone. My existing content: {{content}}',
  'smallParagraphForHeading' : 'Strictly follow the instruction. Rewrite a small content piece for given headline within "{{word_limit}}" words. Be "{{content_style}}" and use "{{tone}}" tone. My headline: {{content}}',
  'smallHeadingForParagraph' : 'Strictly follow the instruction. Write a fabulous small headline for given paragraph within "{{word_limit}}" words. Be "{{content_style}}" and use "{{tone}}" tone. My paragraph is: {{content}}',

  // conversion:
  'targetAudience': 'Who can be target audience for a "Accounting" business called "Zenocorp"? Only provide a RFC8259 compliant JSON response wthout deviation. The JSON response:',
  'targetAudienceTop10Questions': 'For a lead who is "{{target_audience}}" want to connect a "Accounting" business called "Zenocorp", what are top 10 questions, he wants to ask? Only provide a RFC8259 compliant JSON response wthout deviation. The JSON response:',

};

const removeQuotationMarks = (str) => {
  // Check if the string starts and ends with a quotation mark (single or double)
  if ((str.startsWith("'") && str.endsWith("'")) || (str.startsWith('"') && str.endsWith('"'))) {
    // Remove the first and last character from the string
    return str.slice(1, -1);
  } else {
    // If no quotation marks at the beginning and end, return the original string
    return str;
  }
}

const generateSvg = async (type='logo', prompt) => {
  const ILLUSTROKE_API_KEY = process.env.REACT_APP_ILLUSTROKE_KEY;
  await fetch("https://us-central1-illustroke-c1d67.cloudfunctions.net/extApp/generate", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ILLUSTROKE_API_KEY,
      },
      body: JSON.stringify({
        style: "flat",
        prompt: prompt,
        objectmode: "full",
        colormode: "color",
        n: 1
      })
  });
}

const generateAIImages = async (sectionType='service',sectionName='',prompt,extraData) => {
  // A pic for services section on a website. Service is: "Operational Excellence Services", use humans if possible or hands holding an app related to this if required, If using hand, make sure no extra finger or misplaced or deformed image is there
  // Headshot of A british female customer for "strategic acquisition assistance service"
  // half-body shot of an Indian CFO
}

export const fetchImage = async (query) => {
  let randomkeyGen = (max) => Math.floor(Math.random() * max) + 1;
  let randomkey = 0;
  try {
    const response = await fetch(`https://api.unsplash.com/search/photos?query=${query}&per_page=20&page=3&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`);
    const data = await response.json();
    randomkey = (data && data.results && data.results.length>1) ? randomkeyGen(data.results.length) : 0;
    const imageSrc = (data && data.results && data.results.length>0 && data.results[randomkey] && data.results[randomkey].urls) ? data.results[randomkey].urls.small : '';
    const imgObject = {
      "id": "FHnnjk1Yj7Y",
      "description": "Laptop and notepad",
      "alt_description": "MacBook Pro near white open book",
      "url": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
      "author": "Nick",
      "authorLink": "https://unsplash.com/@nickmorrison?utm_source=ContentSimi.co&utm_medium=referral",
      "type": "unsplash",
      "download": "https://api.unsplash.com/photos/FHnnjk1Yj7Y/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
    };
    if(data && data.results && data.results.length>0 && data.results[randomkey]){
      imgObject['id'] = data.results[randomkey].id;
      imgObject['description'] = data.results[randomkey].description;
      imgObject['url'] = data.results[randomkey].urls.regular;
      imgObject['urlSmall'] = data.results[randomkey].urls.small;
      imgObject['author'] = data.results[randomkey].user.name;
      imgObject['authorLink'] = data.results[randomkey].user.links.html;
      imgObject['type'] = 'unsplash';
      imgObject['download'] = data.results[randomkey].links.download;
    }
    return imgObject;
  } catch (error) {
    console.error('Failed to fetch image:', error);
  }
};

export async function generateLogo (name,query='flower') {
  const response = await fetch(`https://sanmayadhal.com/logos?name=${name}&icon=${query}`);
  const data = await response.json();
  console.log(data);
  return data;
}


export async function removeImageBackground (imageUrl) {
  const response = await fetch("https://sanmayadhal.com/pics/removebackground", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: {
      id: 1,
      img: imageUrl,
    }
  });  
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data = await response.json();
  console.log(data);
  return data;
}

export async function generateText(prompt, role = 'writer', wordLimit, systemMessage, toContinue, prevMessages, outPuts = [], functions = false,token=450) {

  //prompt = (prompt && typeof prompt === 'object' && prompt!==null) ? prompt.prompt : prompt;


  const roles = {
    brandOwner: 'You are a great brand owner.',
    writer: 'You are a great brand content writer for website copy.',
    editor: 'You are a great editor for website copy.',
    graphicDesigner: 'You are a great AI graphic designer.',
    producer: 'You are a great video producer.',
    salesMan: 'You are a great great sales person.',
    customer: 'You are a customer.',
  };

  let wordLimitPrompt = '';
  if (wordLimit) {
    if (wordLimit.min && wordLimit.max) {
      wordLimitPrompt = ` Word limit between: ${wordLimit.min}-${wordLimit.max}.`;
    } else if (wordLimit.min) {
      wordLimitPrompt = ` Minimum words desired: ${wordLimit.min}.`;
    } else if (wordLimit.max) {
      wordLimitPrompt = ` Max word limit: ${wordLimit.max}.`;
    } else {
      wordLimitPrompt = ` Word limit ${wordLimit}.`;
    }
  }

  systemMessage = systemMessage && systemMessage.length > 0 ? systemMessage : roles[role];

  let messages = [
    { "role": "system", "content": systemMessage },
    { "role": "user", "content": prompt + wordLimitPrompt }
  ];

  if (toContinue) {
    messages = prevMessages;
    messages.push({ "role": "user", "content": "continue" });
  }

  let thePayload = {
    "messages": messages,
    //model: 'gpt-3.5-turbo-0613',//-0613',//'gpt-4',//'gpt-3.5-turbo',//'gpt-4',
    model: 'gpt-4',//-1106-preview',
    max_tokens: token>0 ? token : 750, //750,
  }
  
  if(functions && functions.length>0){
     thePayload['functions'] = functions;
     thePayload['function_call'] = 'auto';
  }
  if (Array.isArray(functions) && functions.length === 1 && Object.keys(functions[0]).length === 0) {
    delete thePayload.functions;
    delete thePayload.function_call;
  }

  /*
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
    },
    body: JSON.stringify({...thePayload})
  });
  */

  //const thePayloadCompressed = compressToUTF16(JSON.stringify(thePayload));
  
  const response = await fetch("https://dream.sites.contentsimi.com/api/aigenerate", {
    method: "POST",
    body: JSON.stringify({...thePayload})
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  outPuts.push(data.choices[0].message.content);
  if(data.choices[0].finish_reason === "function_call"){    
    return data.choices[0].message;
  }
  if (data.choices[0].finish_reason !== "stop") {
    //messages.push({ "role": "assistant", "content": data.choices[0].message.content });
    messages.push(data.choices[0].message);
    return generateText(false, role, wordLimit, systemMessage, true, messages, outPuts,functions,token);
  }

  return outPuts.join('');
}

/*
export async function generateTextOld(prompt,role='writer',wordLimit,systemMessage,toContinue,prevMessages,outPuts=[]) {
  const roles = {
    brandOwner: 'You are a great brand owner.',
    writer: 'You are a great brand content writer for website copy.',
    editor: 'You are a great editor for website copy.',
    graphicDesigner: 'You are a great AI graphic designer.',
    producer: 'You are a great video producer.',
    salesMan: 'You are a great great sales person.',
    customer: 'You are a customer.',
  };
  if(!role){
    role = 'writer';
  }
  const preText = {
    thrilled: '',
  };

  let wordLimitPrompt = '';
  if(wordLimit){
    if(wordLimit.min && wordLimit.max){
      // between
      wordLimitPrompt = ` Word limit between: ${wordLimit.min}-${wordLimit.max}.`;
    } else if(wordLimit.min && !wordLimit.max){
      // min
      wordLimitPrompt = ` Minimum words desired: ${wordLimit.min}.`;
    } else if(!wordLimit.min && wordLimit.max){
      // within
      wordLimitPrompt = ` Max word limit: ${wordLimit.min}-${wordLimit.max}.`;
    } else{
      // exact;
      wordLimitPrompt = ` Word limit ${wordLimit}.`;
    }
  }
  if(systemMessage && systemMessage.length>0){
    
  } else{
    systemMessage = roles[role]; //'You are a great brand content writer for website copy.';
  }
  console.log(systemMessage);
  let messages = [
    {"role": "system", "content": systemMessage}, //"content": "You are a website creator."},
    {"role": "user", "content": prompt+wordLimit},
  ];
  if(toContinue){
    messages = prevMessages;
    messages.push({"role": "user", "content": "continue"});
  }

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer ${process.env.REACT_APP_OPENAI_KEY}"
    },
    body: JSON.stringify({
      "messages": messages,
      model: 'gpt-4',//'gpt-3.5-turbo',
      max_tokens: 250,
      //n: 1,
      //stop: "."
    })
  });  
  
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data = await response.json();
  outPuts.push(data.choices[0].message.content);
  if(data.choices[0].finish_reason!="stop"){
    messages.push({"role": "assistant", "content": data.choices[0].message.content});
    return generateText(false,role,wordLimit,systemMessage,true,messages,outPuts);
  }
  return outPuts.join('');
}
*/

export async function generateImage(prompt,modifyPrompt=false,n=2,format='b64_json') {
  if(modifyPrompt){
    prompt = await generateText('Modify the prompt for AI image generation. The input prompt: '+prompt);
    console.log('prompt',prompt)
  }
  const response = await fetch("https://api.openai.com/v1/images/generations", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
    },
    body: JSON.stringify({
      prompt: prompt,
      n: 2,
      size: "512x512",
      response_format: format,
      //stop: "."
    })
  });
  
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data = await response.json();
  return data;
}

export const getUsableAIGeneratedImage = async (prompt,modifyPrompt=false,count=1,format='b64_json') => {
  // call to prompt & then map
  //  Please write name clearly
  const images = await generateImage(prompt+'. Please don\'t include any text on image',modifyPrompt);
  console.log(images,modifyPrompt);
  if(images && images.data && images.data.length>0){        
      let randomkeyGen = (max) => Math.floor(Math.random() * max) + 1;
      let randomkey = 0;
      randomkey = (images.data.length>1) ? randomkeyGen(images.data.length) : 0;
      //images.data.map(async (image,index) => {
      const newImage = {url: await convertBase64ToDataUrl(images.data[0].b64_json)};
      console.log('newImage',newImage);
      //})
      const imgObject = {
        "id": "FHnnjk1Yj7Y",
        "description": "Laptop and notepad",
        "alt_description": "MacBook Pro near white open book",
        "url": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
        "author": "Nick",
        "authorLink": "https://unsplash.com/@nickmorrison?utm_source=ContentSimi.co&utm_medium=referral",
        "type": "unsplash",
        "download": "https://api.unsplash.com/photos/FHnnjk1Yj7Y/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
      };
      if(newImage){
        const supabaseImgURL = await openAiDataUrlToSupabaseUpload(newImage.url);
        console.log('newImage',newImage,supabaseImgURL);
        imgObject['id'] = randomkey;
        //imgObject['description'] = newImage.description;
        imgObject['url'] = supabaseImgURL;
        imgObject['urlSmall'] = supabaseImgURL;
        imgObject['author'] = 'ai';
        imgObject['authorLink'] = '';
        imgObject['type'] = 'unsplash';
        //imgObject['download'] = newImage.links.download;
      }
      console.log('imgObject',imgObject);
      return imgObject;
  } else{
    return false;
  }
}


export async function fetchImageWithAI(sectionTitle='',pageTitle='',companyData={}) {
  const queries = await generateText(`[no prose] [Output only JSON] Need 5 search queries for unsplash for a website section "${sectionTitle}" for a company with following data : ${companyData}. This should be more appropriate. Output as JSON array in following format {
    "search_queries": [
      "query 1",
      "query2",
    ]
  } `);
  console.log(queries);
  if(prompt){
    const allQueries = JSON.parse(queries);
    if(allQueries && allQueries.search_queries && allQueries.search_queries.length>0){
      const theImage = await fetchImage(allQueries.search_queries[1]);
      console.log('The Image for fetchImage 1',sectionTitle,pageTitle,theImage);
      return theImage;
    }
  }
  const theImageDefault = await fetchImage(sectionTitle);
  console.log('The Image for fetchImage default',sectionTitle,pageTitle,theImageDefault);
  return theImageDefault;
}

export async function getEditedContent (content,contentType='paragraph',wordLimit=60,contentStyle='professional',tone="passionate"){  
  return new Promise(async(resolve) => {
    const result = await generateText(prompts.shortenLengthen.replace("{{content}}", content).replace("{{tone}}", tone).replace("{{content_type}}", contentType).replace("{{word_limit}}", wordLimit).replace("{{content_style}}", contentStyle)).then(res => { return res }).catch(error => console.error(error))
    console.log(result);
    resolve(result);
  });
}

export async function getParagraphForHeadline (headline,wordLimit=85,contentStyle='professional',tone="passionate"){
  return new Promise(async(resolve) => {
    const result = await generateText(prompts.smallParagraphForHeading.replace("{{content}}", headline).replace("{{tone}}", tone).replace("{{word_limit}}", wordLimit).replace("{{content_style}}", contentStyle)).then(res => { return res }).catch(error => console.error(error))
    console.log(result);
    resolve(result);
  });
}

export async function getHeadlineForParagraph (content,wordLimit=7,contentStyle='professional',tone="passionate"){
  return new Promise(async(resolve) => {
    const result = await generateText(prompts.smallHeadingForParagraph.replace("{{content}}", content).replace("{{tone}}", tone).replace("{{word_limit}}", wordLimit).replace("{{content_style}}", contentStyle)).then(res => { return res }).catch(error => console.error(error))
    console.log(result);
    resolve(result);
  });
}

export async function getWebsiteColors (name, industry){
  const images = await generateText(prompts.websiteColors.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
  console.log(images);
  return images;
}


export async function getImagesViaChatGPT (name, industry){
  const images = await generateText(prompts.imageSearch.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
  console.log(images);
  return true;
}

export async function getMenus (name, industry, fullFormData = false){
  console.log('getting called again. getMenus')
  let extraPrompt = '';
  if(fullFormData){
    name = fullFormData.companyName || name;
    industry = fullFormData.companyIndustry || industry;
    extraPrompt = 'Details of the company is here: '+JSON.stringify(fullFormData);
  }
  const menusDesired = await generateText(
    prompts.menus.replace("ZenoCorp", name).replace("Accounting", industry)+extraPrompt,
    false,false,'You are a great UX designer',false,false,[],
    [{
        "name": "generate_menus",
        "description": "Generates all menus and submenus of the website",
        "parameters": {
            "type": "object",
            "properties": {
              "menus": {
                  "type": "array",
                  "description": "The menus array",
                  "items": {
                      "type": "object",
                      "properties": {
                          "menu": {
                              "type": "string",
                              "description": "The menu name",
                          },
                          "pageDescription": {
                              "type": "string",
                              "description": "This page will focus on xyz. Example: The 'About Us' page should narrate the journey of the restaurant, its mission for promoting veganism, and its commitment to using organic ingredients. Include a timeline of milestones and a message from the founder.",
                          },
                          "submenus": {
                            "type": "array",
                            "description": "The submenu array for the menu, this can remain or not remain, depends on menu",
                            "items": {
                                "type": "object",
                                "properties": {
                                  "menu": {
                                      "type": "string",
                                      "description": "The sub menu name",
                                  },
                                  "pageDescription": {
                                      "type": "string",
                                      "description": "This page will focus on xyz. Example: The 'About Us' page should narrate the journey of the restaurant, its mission for promoting veganism, and its commitment to using organic ingredients. Include a timeline of milestones and a message from the founder.",
                                  },
                                }
                            }
                          }
                      }
                  
                  },
              }
            }
          }
    }] 
  ).then(res => { return res }).catch(error => console.error(error))
  
  let menus = [];
  let uncleanedmenus = [];
  if(menusDesired.content=== null && menusDesired.function_call){
      uncleanedmenus = JSON.parse(menusDesired.function_call.arguments).menus;
      menus = uncleanedmenus.map(item => cleanSubmenus(item));
      console.log(menusDesired.function_call.arguments)
  } else{
      menus = menusDesired;
  }
  console.log(menus);
  const res = menus;
  console.log(res);
  return res;
}

export async function getBannerBlock(name, industry, websiteType='business', wordLimit=300) {
  const res = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a1",
        "description": "Add a full-width banner",
        "type": "banner",
        "active": true,
        "name": "Banner"
    },
    "idx": 1,
    "headline": "Accounting Experts in Bhubaneswar",
    "content": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
    "align": "center",
    "background": {
        "type": "image",
        "overlay": {
            "color": "#3D9970",
            "amount": 70
        },
        "image": {
            "id": "FHnnjk1Yj7Y",
            "description": "Laptop and notepad",
            "alt_description": "MacBook Pro near white open book",
            "url": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
            "author": "Nick",
            "authorLink": "https://unsplash.com/@nickmorrison?utm_source=ContentSimi.co&utm_medium=referral",
            "type": "unsplash",
            "download": "https://api.unsplash.com/photos/FHnnjk1Yj7Y/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
        }
    },
    "button": {
        "label": "Contact",
        "type": "url",
        "link": "#"
    }
  };
  let websiteTypeTxt = (websiteType==='business') ? industry : 'a person offering '+industry;
  const headingText = await generateText(prompts.shortHeading.replace("ZenoCorp", name).replace("Accounting", industry).replace("business",websiteType)).then(res => { return res }).catch(error => console.error(error))
  const contentText = await generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry).replace(industry, websiteTypeTxt).replace("business",websiteType)).then(res => { return res }).catch(error => console.error(error))
  res['headline'] = headingText ? removeQuotationMarks(headingText) : '';
  res['content'] = contentText ? removeQuotationMarks(contentText) : '';
  res['background']['image'] = await fetchImage(industry);
  //getImagesViaChatGPT(name,industry)
  return res;
}

export async function getAboutBlock(name, industry) {
  const res = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a2",
        "description": "Add an image alongside a block of text",
        "type": "hero",
        "active": true,
        "name": "Text + image"
    },
    "idx": 4,
    "content": "<h2>Meet the team</h2><p>We at ZenoCorp, an Accounting business team in Bhubaneswar, OR, are excited to provide our services to the community. Our team of experienced professionals is well-equipped to offer comprehensive services in the areas of Accounting, Auditing, and Taxation. We strive to provide the highest level of customer service and satisfaction, and we work to ensure that our clients receive the best advice and support. We understand the importance of keeping up with the latest trends and developments in the Accounting industry. Our dedication to our clients is what sets us apart from the competition. We look forward to working with you and helping you achieve your financial goals.</p>",
    "align": "middle",
    "image": {
        "media": {
            "id": "rH8O0FHFpfw",
            "description": "Don’t Forget",
            "alt_description": "person holding pencil and stick note beside table",
            "url": "https://images.unsplash.com/photo-1523289333742-be1143f6b766?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyN3x8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
            "author": "Marten",
            "authorLink": "https://unsplash.com/@martenbjork?utm_source=ContentSimi.co&utm_medium=referral",
            "type": "unsplash",
            "download": "https://api.unsplash.com/photos/rH8O0FHFpfw/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyN3x8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
        },
        "aspectRatio": "3:2",
        "align": "left",
        "cornerRadius": "medium"
    },
    "background": "#83dfb6"
  };
  const aboutText = await generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry).replace("25-30 words", '120')).then(res => { return res }).catch(error => console.error(error))
  res['content'] = aboutText;
  res['image']['media'] = await fetchImage(industry);
  return res;
}

export async function getServicesBlock(name, industry, count=3) {
  let res = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a6",
        "description": "Add multiple items with images and text",
        "type": "list",
        "active": true,
        "name": "List"
    },
    "idx": 3,
    "background": "#6fcba2",
    "headline": "Featured",
    "aspectRatio": "3:2",
    "cornerRadius": "medium",
    "justify": "start",
    "align": "center",
    "showImage": true,
    "showHeading": true,
    "showContent": true,
    "showButton": true,
    "items": [],
  };
  //const serviceText = await generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
  const serviceTitles = await generateText(`Suggest ${count} short headings for services section of a "${industry}" business. Each should be of 2-4 words. Provide as an array`).then(res => { return res }).catch(error => console.error(error))
  const allTitles = JSON.parse(serviceTitles);
  allTitles.map(async (title, key) => {
    res['items'][key] = {
      "title": title,
      "content": "Providing advice on accounting and taxation matters.",
      "idx": 5,
      "button": {
          "label": "More info",
          "type": "url",
          "link": "#"
      },
      "image": {
          "id": "iJMitgqRaZ8",
          "description": "",
          "alt_description": "official receipt on white surface",
          "url": "https://images.unsplash.com/photo-1545941962-1b6654eb8072?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
          "author": "Michael",
          "authorLink": "https://unsplash.com/@ml1989?utm_source=ContentSimi.co&utm_medium=referral",
          "type": "unsplash",
          "download": "https://api.unsplash.com/photos/iJMitgqRaZ8/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
      }
    };
    res['items'][key]['content'] = await generateText(prompts.shortDescription.replace("{{TITLE_HERE}}", title).replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
    let theImage = await fetchImage(title);
    if(theImage && theImage.id){

    } else{
      theImage = await fetchImage(industry);
    }
    res['items'][key]['image'] = theImage; //await fetchImage(industry);
  })
  //console.log(res);
  return res;
}

export async function getProductsBlock(name, industry, count=3) {
  let res = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a6",
        "description": "Add multiple items with images and text",
        "type": "list",
        "active": true,
        "name": "List"
    },
    "idx": 3,
    "background": "#6fcba2",
    "headline": "Featured",
    "aspectRatio": "3:2",
    "cornerRadius": "medium",
    "justify": "start",
    "align": "center",
    "showImage": true,
    "showHeading": true,
    "showContent": true,
    "showButton": true,
    "items": [],
  };
  //const serviceText = await generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
  const serviceTitles = await generateText(`Strictly follow the instructions. Suggest ${count} short headings for services section of a "${industry}" business. Each should be of 2-4 words. Provide as an JSON array`).then(res => { return res }).catch(error => console.error(error))
  const allTitles = JSON.parse(serviceTitles);
  allTitles.map(async (title, key) => {
    res['items'][key] = {
      "title": title,
      "content": "Providing advice on accounting and taxation matters.",
      "idx": 5,
      "button": {
          "label": "More info",
          "type": "url",
          "link": "#"
      },
      "image": {
          "id": "iJMitgqRaZ8",
          "description": "",
          "alt_description": "official receipt on white surface",
          "url": "https://images.unsplash.com/photo-1545941962-1b6654eb8072?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
          "author": "Michael",
          "authorLink": "https://unsplash.com/@ml1989?utm_source=ContentSimi.co&utm_medium=referral",
          "type": "unsplash",
          "download": "https://api.unsplash.com/photos/iJMitgqRaZ8/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
      }
    };
    res['items'][key]['content'] = await generateText(prompts.shortDescription.replace("{{TITLE_HERE}}", title).replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
    res['items'][key]['image'] = await fetchImage(industry);
  })
  //console.log(res);
  return res;
}

export async function getTestimonialsBlock(name, industry, count=1) {  
  let res = {
    "WebsiteBlock": {
      "_id": "629f98a1eb0b4972268051a3",
      "description": "Display a quote or testimonial",
      "type": "quote",
      "active": true,
      "name": "Quote/testimonial"
    },
    "idx": 2,
    "items": [],
    "align": "center",
    "header": "Testimonials",
    "background": "#83dfb6"
  };
  for(var i=0; i<=count; i++){
    res['items'][i] = {
      "content": "ZenoCorp in Bhubaneswar has been a great help for our company. Their accounting services are accurate, timely and reliable. We are very happy with the customer service provided and would highly recommend using them for any accounting needs. ",
      "by": "- John Smith, CEO",
      "image": {
        "_id": "64536415bbca55b72afa69f9",
        "author": "Stephanie",
        "authorLink": "https://unsplash.com/@steph?utm_source=ContentSimi.co&utm_medium=referral",
        "type": "unsplash",
        "url": "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwyOXx8cGVyc29ufGVufDB8fHx8MTY4MzEwNTc2Nw&ixlib=rb-4.0.3&q=80&w=1080",
        "Business": "644759ffb29fcec827b43346",
        "createdAt": "2023-05-04T07:51:49.971Z",
        "__v": 0,
        "id": "64536415bbca55b72afa69f9"
      },
      "idx": 0
    };
    res['items'][i]['content'] = await generateText(prompts.testimonial.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
    //res['items'][i]['by'] = await generateText(prompts.testimonialCustomer.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
    //res['items'][i]['image'] = await fetchImage('female face');
    let testimonialCustomerTxt = await generateText(prompts.testimonialCustomerNew.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => { return res }).catch(error => console.error(error))
    let testimonialCustomerJson = JSON.parse(testimonialCustomerTxt);
    console.log(testimonialCustomerTxt);
    console.log(testimonialCustomerJson);
    res['items'][i]['by'] = testimonialCustomerJson.name;
    res['items'][i]['image'] = await fetchImage(testimonialCustomerJson.nationality+' '+testimonialCustomerJson.gender+' face');
  }
  return res;
}


export function createWebsite(name, industry) {
  // get the website if id or something is passed.
  // each sections push to the stuffs below.

  // create a timestamp
  // access the websites object from the localStorage and add the website name to it and timestamp as the id of the website with basic property like businessName, location, SiteSettings.
  // siteSettings to include the navBarColor, fontColor, headingColor, get this from chatGPT
  // generate a bannerBlock with chatGPT with backgroundImage from xxx

  const BannerBlockStructure = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a1",
        "description": "Add a full-width banner",
        "type": "banner",
        "active": true,
        "name": "Banner"
    },
    "idx": 1,
    "headline": "Accounting Experts in Bhubaneswar",
    "content": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
    "align": "center",
    "background": {
        "type": "image",
        "overlay": {
            "color": "#3D9970",
            "amount": 70
        },
        "image": {
            "id": "FHnnjk1Yj7Y",
            "description": "Laptop and notepad",
            "alt_description": "MacBook Pro near white open book",
            "url": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
            "author": "Nick",
            "authorLink": "https://unsplash.com/@nickmorrison?utm_source=ContentSimi.co&utm_medium=referral",
            "type": "unsplash",
            "download": "https://api.unsplash.com/photos/FHnnjk1Yj7Y/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
        }
    },
    "button": {
        "label": "Contact",
        "type": "url",
        "link": "#"
    }
};



  // generate a services block with first calling few services and then generating content for it.
  // get the images in meanwhile for the sections
  // generate one testimonial
  // generate a contact form block
  // generate a map block
  // generate an hero for about block.

  // save all these.

  /*
Main Menu:
1. Home
2. About Us
3. Products
4. Services
5. Blog
6. Contact Us

Sub-menu options for each main menu item:

1. Home
- Welcome
- How it works
- Testimonials
- Our Philosophy
- Customer Support

2. About Us
- Our Story
- Our Team
- Vision and Mission
- Awards and Recognition
- Job Opportunities

3. Products
- Mattresses
- Beddings
- Pillows
- Furniture
- Sleep Accessories

4. Services
- Customized Mattresses
- Home Delivery and Installation
- Sleep Consultation
- Mattress Recycling
- Warranty and Returns

5. Blog
- Sleep Tips and Advice
- Latest sleep trends
- Guest Contributions
- Product Reviews
- Customer Stories

6. Contact Us
- Store Locations
- Customer Service
- FAQs
- Email Support
- Social Media Handles.
  */




}