
export const hexToRgba = (hex, opacity) => {
    const hexCode = hex.replace('#', '');
    const r = parseInt(hexCode.substring(0, 2), 16);
    const g = parseInt(hexCode.substring(2, 4), 16);
    const b = parseInt(hexCode.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

export const rgbToHex = (r, g, b) => {
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const red = componentToHex(r);
  const green = componentToHex(g);
  const blue = componentToHex(b);

  return "#" + red + green + blue;
}


const addRgb = (rgbarray) => {
  return 'rgb('+rgbarray.join(',')+')';
}
const rgbToRgba = (rgbstring,opacity=0.6) => {
  return rgbstring.replace(')',','+opacity+')');
}

export const getOppositeColor = (color) => {
  // Convert color to RGB format if needed
  if (color.charAt(0) === '#') {
    color = color.slice(1);
    color = parseInt(color, 16);
    color = [(color >> 16) & 255, (color >> 8) & 255, color & 255];
  } else if (color.startsWith('rgb')) {
    color = color.match(/\d+/g).map(Number);
  }

  // Invert each component of the color
  const invertedColor = color.map(component => 255 - component);

  // Convert the inverted color back to hex format
  const hexColor = '#' + invertedColor.map(component => component.toString(16).padStart(2, '0')).join('');

  return hexColor;
}

function colorStringToArray(color) {
  if (typeof color === "string") {
    // Remove any whitespaces and convert the string to lowercase
    color = color.replace(/\s/g, '').toLowerCase();

    if (color.startsWith("#")) {
      // Convert HEX string to RGB array
      const hex = color.replace("#", "");
      return [
        parseInt(hex.substring(0, 2), 16),
        parseInt(hex.substring(2, 4), 16),
        parseInt(hex.substring(4, 6), 16)
      ];
    } else if (color.startsWith("rgb(") && color.endsWith(")")) {
      // Extract the numbers from the RGB string using regular expression
      const rgbValues = color.match(/\d+/g);

      // Convert the string numbers to integers and return as an array
      return rgbValues.map(Number);
    }
  } else if (Array.isArray(color) && color.length === 3) {
    // Return the RGB array directly if it's a valid RGB array with 3 components
    return color.map(Number);
  }

  // If the input is invalid or unsupported, return null or handle the error accordingly
  return null;
}



export const getTintedColor = (color, step) => {
  // Ensure the step value is within the range [0, 1]
  let theColor = color;
  theColor = colorStringToArray(color)
  console.log('New tinted input',theColor)
  step = Math.min(1, Math.max(0, step));
  
  // Calculate the tinted color components
  const tintedColor = theColor.map((component) => Math.round(component + (255 - component) * step));
  
  return tintedColor;
}

export const applyTint = (primaryColorRaw, backgroundColorRaw, tintOpacity) => {
  let primaryColor = colorStringToArray(primaryColorRaw)
  let backgroundColor = colorStringToArray(backgroundColorRaw)
  // Ensure the tint opacity is within the range [0, 1]
  tintOpacity = Math.min(1, Math.max(0, tintOpacity));
  
  // Calculate the tinted color components
  const tintedColor = primaryColor.map((component, index) => {
    const backgroundComponent = backgroundColor[index];
    return Math.round(component * tintOpacity + backgroundComponent * (1 - tintOpacity));
  });
  
  return tintedColor;
}




export const prepareColorCssFromPallette = (pallette=[]) => {
  /*
  const darkShade = rgbToHex(pallette[4]);
  const lightShade = rgbToHex(pallette[0]);
  const darkAccent = rgbToHex(pallette[3]);
  const lightAccent = rgbToHex(pallette[1]);
  const brand = rgbToHex(pallette[2]);
  */
  const AIColor = {
    "primary": "#4E598C",
    "secondary": "#E3B505",
    "accent": "#FF7091",
    "background": "#F5F5F5",
    "text": "#333333"
  }

  const AIColorRgb = {
    "primary": "rgb(78, 89, 140)",
    "secondary": "rgb(227, 181, 5)",
    "accent": "rgb(255, 112, 145)",
    "background": "rgb(245, 245, 245)",
    "text": "rgb(51, 51, 51)"
  }

  let AIColorRgbArray = {
    "primary": [78, 89, 140],
    "secondary": [227, 181, 5],
    "accent": [255, 112, 145],
    "background": [245, 245, 245],
    "text": [51, 51, 51]
  }

  AIColorRgbArray = {
    "primary": [33, 150, 243],     // Blue
    "secondary": [251, 140, 0],    // Orange
    "accent": [156, 39, 176],      // Purple
    "background": [250, 250, 250], // Light Gray
    "text": [33, 33, 33]           // Dark Gray
  }
  
  
  if(pallette.length===0){
    pallette[0] = AIColorRgbArray.background;
    pallette[1] = AIColorRgbArray.accent;
    pallette[2] = AIColorRgbArray.secondary;
    pallette[3] = AIColorRgbArray.primary;
    pallette[4] = AIColorRgbArray.text;
  }
  
  
  const darkShade = addRgb(pallette[4]);
  const lightShade = addRgb(pallette[0]);
  const darkAccent = addRgb(pallette[3]);
  const lightAccent = addRgb(pallette[1]);
  const brand = addRgb(pallette[2]);
  const oppositeColor = getOppositeColor(darkShade)
  const primaryTinted = addRgb(applyTint(darkShade,lightShade,0.1))

  const colorStyle = `
    :root{
      --cs-darkShade : ${darkShade};
      --cs-lightShade : ${lightShade};
      --cs-lightAccent : ${lightAccent};
      --cs-darkAccent : ${darkAccent};
      --cs-primaryTinted : ${primaryTinted};
      --cs-brandColor : ${brand};
      --cs-oppositeColor : ${oppositeColor};
      --cs-brandColor-rgb: ${rgbToRgba(brand,0.6)};
    }
    .leftBrandBg:after{
      background-color: var(--cs-darkShade) !important;
      color: var(--cs-lightShade) !important;
    }
    .rightBrandBg:before{
      background-color: var(--cs-darkShade) !important;
      color: var(--cs-lightShade) !important;
    }
    .brandImageFilter{
      /*filter: ${convertImageToColorCssFilter(lightAccent)};*/
      filter: grayscale(100%) brightness(200%);
      opacity: 0.6;
    }
    .lightColor{
      color: var(--cs-lightShade) !important;
    }
    .darkbg, .footerbg{
      background-color: var(--cs-darkShade) !important;
      color: var(--cs-lightShade) !important;
    }    
    .darkbg .h3, body .h3{
      color: var(--cs-darkAccent) !important;
    }
    .lightbg, .bg-light, body{
      background-color: var(--cs-lightShade) !important;
      color: var(--cs-darkShade) !important;
    }
    .lightbg .h3, .bg-light .h3{
      color: var(--cs-lightAccent) !important;
    }
    /*
    .lightaccentbg{
      background-color: var(--cs-lightAccent) !important;
      background-color: var(--cs-primaryTinted) !important;
      color: var(--cs-darkShade) !important;
    }
    */   
    .lightaccentbg{
      background-color: var(--cs-lightAccent) !important;
      background-color: var(--cs-primaryTinted) !important;
      color: var(--cs-darkShade) !important;
    }
    .lightaccentbg h1, .lightaccentbg h2, .lightaccentbg h3, .lightaccentbg .h3, .lightaccentbg h4, .lightaccentbg h5, .lightaccentbg h6{
      color: var(--cs-darkShade) !important;
    }
    .darkaccentbg{
      background-color: var(--cs-darkAccent) !important;
      color: var(--cs-lightShade) !important;
    }
    .brandbg{
      background-color: var(--cs-brandColor) !important;
      color: var(--cs-lightShade) !important;
    }
    .headertransparentbg{
      background-color: var(--cs-brandColor-rgb) !important;
      color: var(--cs-lightShade) !important;
    }
    .brandtransparentbg{
      background-color: ${rgbToRgba('rgb(0,0,0)',0.4)} !important;
      background-col1or: var(--cs-lightAccent) !important;
      co1lor: var(--cs-darkShade) !important;
    }
    .brandtransparentbg .display-1{
      color: var(--cs-lightShade) !important;
    }
    .btn, .btn:hover, .btn:focus, .btn:active, .btn.active, .btn:active:focus, .btn:active:hover, .btn.active:focus, .btn.active:hover, .open > .btn.dropdown-toggle, .open > .btn.dropdown-toggle:focus, .open > .btn.dropdown-toggle:hover, .btn.btn-default, .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active, .btn.btn-default.active, .btn.btn-default:active:focus, .btn.btn-default:active:hover, .btn.btn-default.active:focus, .btn.btn-default.active:hover, .open > .btn.btn-default.dropdown-toggle, .open > .btn.btn-default.dropdown-toggle:focus, .open > .btn.btn-default.dropdown-toggle:hover, .navbar .navbar-nav > li > a.btn, .navbar .navbar-nav > li > a.btn:hover, .navbar .navbar-nav > li > a.btn:focus, .navbar .navbar-nav > li > a.btn:active, .navbar .navbar-nav > li > a.btn.active, .navbar .navbar-nav > li > a.btn:active:focus, .navbar .navbar-nav > li > a.btn:active:hover, .navbar .navbar-nav > li > a.btn.active:focus, .navbar .navbar-nav > li > a.btn.active:hover, .open > .navbar .navbar-nav > li > a.btn.dropdown-toggle, .open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:focus, .open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:hover, .navbar .navbar-nav > li > a.btn.btn-default, .navbar .navbar-nav > li > a.btn.btn-default:hover, .navbar .navbar-nav > li > a.btn.btn-default:focus, .navbar .navbar-nav > li > a.btn.btn-default:active, .navbar .navbar-nav > li > a.btn.btn-default.active, .navbar .navbar-nav > li > a.btn.btn-default:active:focus, .navbar .navbar-nav > li > a.btn.btn-default:active:hover, .navbar .navbar-nav > li > a.btn.btn-default.active:focus, .navbar .navbar-nav > li > a.btn.btn-default.active:hover, .open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle, .open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:focus, .open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:hover {
      background-color: var(--cs-brandColor) !important;
    }
    .bg-light h1, .bg-light h2, .bg-light h3, .bg-light h4, .bg-light h5, .bg-light h6{
      color: var(--cs-darkShade) !important;
    }
    /*
    h1, h2, h3, h4, h5, h6{
      color: var(--cs-lightShade) !important;
    }
    */
    .footer1{
      background-color: var(--cs-oppositeColor) !important;
    } 
    /*   
    mark:before {
      content: "";
      position: absolute;
      height: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--cs-brandColor) !important;
      z-index: -1;
    }     
    mark {
      position: relative;
      background: transparent !important;
      z-index: 1;
    }
    */
    /*
    mark {
      background: transparent !important;
      border: 13px solid #ede0a0;
      border-radius: 50%;
    }
    */
    mark {
      background: linear-gradient(
      -100deg,
      hsla(48,92%,75%,.3),
      hsla(48,92%,75%,.7) 95%,
      hsla(48,92%,75%,.1)
      );
      border-radius: 1em 0;
      padding: .3em;
    }
  `;

  return colorStyle;

}

const convertImageToColorCssFilter = (desiredColor) => {
  const convertColorToFilters = (color) => {
    const hexToRgb = (hex) => {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    };

    const rgbToFilters = (r, g, b) => {
      const brightness = (Math.max(r, g, b) + Math.min(r, g, b)) / 2;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let hue = 0;

      if (max === r) {
        hue = (g - b) / (max - min);
      } else if (max === g) {
        hue = 2 + (b - r) / (max - min);
      } else {
        hue = 4 + (r - g) / (max - min);
      }

      hue *= 60;

      if (hue < 0) {
        hue += 360;
      }

      return {
        brightness,
        hue,
      };
    };

    if (color.startsWith('#')) {
      const rgbColor = hexToRgb(color);
      return rgbToFilters(rgbColor.r, rgbColor.g, rgbColor.b);
    }

    if (color.startsWith('rgb')) {
      const rgbValues = color.match(/\d+/g);
      return rgbToFilters(
        parseInt(rgbValues[0]),
        parseInt(rgbValues[1]),
        parseInt(rgbValues[2])
      );
    }

    return {
      brightness: 100,
      hue: 0,
    };
  };

  const color = desiredColor; // Replace with your desired color (hex or RGB)
  const filters = convertColorToFilters(color);
  //return `grayscale(100%) hue-rotate(${filters.hue}deg) brightness(${filters.brightness}%)`;
  return `grayscale(100%) sepia(100%) hue-rotate(${filters.hue}deg)`;
}

export function rgbToHexNew(color) {
  return colorToHex(color)
}

export function colorToHex(color) {
  // Check if color is already in hex format
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
      return color;
  }

  // Convert RGB or RGBA to Hex
  let rgba = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)$/);
  if (rgba) {
      let hex = (x) => ("0" + parseInt(x).toString(16)).slice(-2);
      return "#" + hex(rgba[1]) + hex(rgba[2]) + hex(rgba[3]);
  }

  // Return null for invalid input
  return null;
}