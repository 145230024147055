import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

const themeColors = ['#D32F2F', '#C2185B', '#7B1FA2', '#512DA8', '#303F9F', '#1976D2']; // Add your theme colors here
  
const ColorPickerComponentOld = ({ initialColor, onColorChange }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(initialColor);
  
    const handleClose = () => setDisplayColorPicker(false);
    const handleToggle = () => setDisplayColorPicker(!displayColorPicker);
    const handleChange = (color) => {
      setColor(color.hex);
      onColorChange(color.hex);
    };
  
    return (
      <>
        <button onClick={handleToggle} style={{ backgroundColor: color, border: 'none', height: '20px', width: '20px' }} />
        {displayColorPicker && (
          <div style={{ position: 'absolute', zIndex: 2 }}>
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClose} />
            <SketchPicker color={color} onChangeComplete={handleChange} />            
            <div>
                {themeColors.map((themeColor, index) => (
                <label key={index} style={{ marginRight: '8px' }}>
                    <input
                    type="checkbox"
                    checked={themeColor === color}
                    onChange={() => setColor(themeColor)}
                    />
                    <span style={{ backgroundColor: themeColor, padding: '0 4px' }}>{themeColor}</span>
                </label>
                ))}
            </div>
            {/*
            <div>
                <p>Choose a variable:</p>
                <label>
                var1
                <input
                    type="text"
                    value={colorVariables.var1}
                    onChange={handleVariableChange('var1')}
                    placeholder="Variable 1"
                    style={{ marginLeft: '4px' }}
                />
                </label>
                <label>
                var2
                <input
                    type="text"
                    value={colorVariables.var2}
                    onChange={handleVariableChange('var2')}
                    placeholder="Variable 2"
                    style={{ marginLeft: '4px' }}
                />
                </label>
            </div>
            */}
          </div>
        )}
      </>
    );
};

// Helper function to convert various color formats to RGBA
const toRGBA = (color) => {
    if (typeof color === 'string' && color.startsWith('rgba')) {
      return color;
    } else if (typeof color === 'string' && color.startsWith('rgb')) {
      // Convert rgb to rgba
      return color.replace('rgb', 'rgba').replace(')', ', 1)');
    } else if (typeof color === 'string' && color.startsWith('#')) {
      // Convert hex to rgba
      const hex = color.replace('#', '');
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : 1;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    // If it's already an object, assume it's in the correct format
    return color;
  };
  
  // Helper function to convert RGBA color format to a string
  const rgbaToString = (rgba) => {
    if (typeof rgba === 'string') {
      return rgba;
    }
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  };

  /*
const ColorPickerComponent = ({ initialColor, onColorChange }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    // Ensure initial color is in RGBA format
    const [color, setColor] = useState(toRGBA(initialColor));
    const pickerRef = useRef(); // Add this line
  
    const handleClose = () => setDisplayColorPicker(false);
    const handleToggle = (event) => {
      console.log('toggle handle')

      setDisplayColorPicker(!displayColorPicker);
      // If the picker is about to be displayed, ensure it's positioned correctly
      if (!displayColorPicker) {
        const pickerElem = pickerRef.current;
        const inputElem = event.target;
        if (pickerElem && inputElem) {
          const inputRect = inputElem.getBoundingClientRect();
          pickerElem.style.position = 'absolute';
          pickerElem.style.left = `${inputRect.left}px`;
          pickerElem.style.top = `${inputRect.bottom + window.scrollY}px`;
        }
      }
    };
  
    const handleChange = (color) => {
      console.log('handle change', color)

      const newColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      setColor(newColor);
      onColorChange(newColor);
    };
    // Stop the click event from closing the color picker
    const handlePickerClick = (event) => {
        console.log('picker in click handle')
        event.stopPropagation();
    };
  
    /*
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <button onClick={handleToggle} style={{ backgroundColor: rgbaToString(color), border: 'none', height: '20px', width: '20px' }} />
        {displayColorPicker && (
          <div ref={pickerRef} style={{ position: 'absolute', zIndex: 2 }}> {/* Use the ref here *1/}
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClose} />
            <SketchPicker color={color} onChangeComplete={handleChange} />
          </div>
        )}
      </div>
    );
    *1/
   return (
    <div style={{ position: 'relative', display: 'inline-block' }} className="form-control">
      <button onClick={handleToggle} style={{ backgroundColor: rgbaToString(color), border: 'none', height: '20px', width: '20px', zIndex: 1000, width: '100%' }} />
      {displayColorPicker && (
        <div ref={pickerRef} style={{ position: 'absolute', zIndex: 1000 }}> {/* Ensure z-index is high enough *1/}
          <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 999, marginLeft: '-1rem', }} onChange={handleClose} />
          <div onClick={handlePickerClick}> {/* This div catches and stops the click event *1/}
            <SketchPicker color={color} onChangeComplete={handleChange} />
          </div>
        </div>
      )}
    </div>
   )
  };
*/
const ColorPickerComponent = ({ initialColor, onColorChange }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(toRGBA(initialColor));
  
    const handleClose = () => setDisplayColorPicker(false);
    const handleToggle = (event) => {
      // Prevent the button click from closing the color picker
      event.stopPropagation();
      setDisplayColorPicker(!displayColorPicker);
    };
  
    const handleChange = (color) => {
      const newColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      setColor(newColor);
      onColorChange(newColor);
    };
  
    return (
      <div style={{ position: 'relative', display: 'inline-block' }} className='form-control'>
        <button onClick={handleToggle} style={{ backgroundColor: rgbaToString(color), border: 'none', height: '20px', zIndex: 2, width: '100%' }}>
        </button>
        {displayColorPicker && (
          <div style={{ position: 'absolute', zIndex: 10000 }}> {/* This z-index should be higher than sidebar/content z-index */}
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999 }} onClick={handleClose} />
            <div className="sketch-picker" onClick={(e) => e.stopPropagation()}>
              <SketchPicker color={color} onChangeComplete={handleChange} />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  

export default ColorPickerComponent;


