import {React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Switch, Link, redirect, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NewBlock from './components/NewBlock';
import { Block, EditorBlock } from './components/Block';
import BlockSettings from './components/BlockSettings';
import Editor from './pages/Editor';
import WebsiteSettingsForm from './components/WebsiteSettingsForm';
import NewWebsiteSettingsForm from './components/NewWebsiteSettingsForm';
import WebsiteBuilder from './pages/Builder';
import Blocks from './pages/Blocks';
import './App.css';
import SitePreview from './pages/SiteCreated';
import WebsiteGenerator from './components/WebsiteGenerator';
import NewWebsite from './pages/NewWebsite';
import { Container } from '@mui/material';
import { Col } from 'react-bootstrap';
import HtmlViewer from './components/HtmlViewer';
import SpacesUploadForm from './pages/DoFile';
import GoogleCloudStorageForm from './pages/GcpFile';
import DigitalOceanUploadFileForm from './pages/DoTwoFile';
import AWSUploadFileForm from './pages/AwsFile';
import CreateS3BucketConfigureHostingAndAddDomain from './pages/AwsFileWithDomain';
import CreateS3BucketConfigureHostingAndAddDomainNew from './pages/AwsFileThree';
import AwsFileFour from './pages/AwsFileFour';
import SaaSUI from './pages/SaasUI';
import Signup from './components/Signup';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import PrivateRoute from './components/ProtectedRoute';
import { supabase } from './supabase';
import PricingPage from './NEW/pages/public/Pricing';
import HomePage from './NEW/pages/public/Home';
import DashboardPage from './NEW/pages/internal/Dashboard';
import WebsitesListPage from './NEW/pages/internal/WebsitesList';
import NewWebsitePage from './NEW/pages/internal/NewWebsite';
import PaymentsListPage from './NEW/pages/internal/PaymentsList';
import InternalDummyPage from './NEW/pages/internal/InternalDummyPage';
import { MessageProvider } from './NEW/contexts/MessageContext';
import MessageDisplayer from './NEW/components/MessageDisplayer';
import SingleWebsite from './NEW/pages/internal/SingleWebsite';
import BannerForm from './NEW/components/Forms/BannnerForm';
import SingleWebsiteContentEditor from './NEW/pages/internal/SingleWebsiteContentEditor';
import CreateWebsite from './NEW/pages/internal/CreateWebsite';
import BusinessFromIndustry from './NEW/pages/internal/BusinessFromIndustry';
import NewEditorPage from './NEW/pages/internal/NewEditorPage';
import { PlatformThemeProvider } from './NEW/contexts/PlatformThemeContext';
import ParkDomain from './NEW/pages/internal/ParkDomain';
import SingleWebsiteFuture from './NEW/pages/internal/SingleWebsiteFuture';

function App() {

  // keep a state and push elements to pages from prompt
  
  const prompts = {
    'start': 'Create a short rich text with h1 and h3 html tags for a "Accounting" business called "ZenoCorp":',
    'about': 'Create a 500 words rich text code with pics and h1, h2 and h3 tags for "About Us" page of a "Accounting" business called "ZenoCorp" in which the founder: "Sannu". Write about founders in separate paragraph. Use some text colors too. Use pics from unsplash.com',
    'shortHeading': 'Create a short heading for a "Accounting" business called "ZenoCorp":',
    //'shortHeadingServices': 'I have a webpage where I want to put many blocks to highlight types of services/features. For one such block, create a short heading. I have a "Accounting" business. It should be like "Blog Writing" or "Product description writing" or "Ghost writing service" for a content writing business',
    'shortHeadingServices': 'Create 1 short heading for one content block of a services page. Max 4 words. Do not suggest what you have suggested before. I have a "Accounting" business. It should be like "Blog Writing" or "Product description writing" or "Ghost writing service" for a content writing business',
    'shortDescription': 'Create a short description with an existing title "{{TITLE_HERE}}" for a "Accounting" business called "ZenoCorp":',
    'testimonial': 'Create a testimonial without a name for a "Accounting" business called "ZenoCorp": ',
    'testimonialCustomer': 'Give a name for one fake person with 2 words. Must be Indian. Use different surnames and gender." ',
    'fullServicePageContent': 'Create 1000 words rich text content with h2, h3 tags and paragraphs and bullet points for a service page called "{{SERVICE_PAGE_TITLE}}" for a "Accounting" business called "ZenoCorp". Mention about various efforts in the service process as well:',
    'websiteColors': 'Give a json with keys as backgroundColor, NavigationBarColor and headingColor for a website for a "Accounting" business called "ZenoCorp"',
    'svgLogo': 'give an svg code for a logo for a company in "Content Writing" business called "BlackWitch", it should have some icon drawing that represents the brand and business',
  }

  /*

    "Create a short rich text with h3 and p html tags for a \"Accounting\" business called \"ZenoCorp\":"

    "Create a SEO Meta Description for a \"Accounting\" business called \"ZenoCorp\":"

    "Create a short heading for a \"Accounting\" business called \"ZenoCorp\":"
    respose
    {"status":true,"message":"Content suggested","content":"The Experts in Accounting: ZenoCorp"}
    then feed that to
    "Create a short description with an existing title \"The Experts in Accounting: ZenoCorp\" for a \"Accounting\" business called \"ZenoCorp\":"

    "Create a short rich text with h3 and p html tags for a \"Accounting\" business called \"ZenoCorp\":"


    Testimonials:

    "Create a testimonial without a name for a \"Accounting\" business called \"ZenoCorp\":"

    "Give a name for a fake person with 2 words:"

    Services block:
    "Give a title with up to 4 words for a service for a \"Accounting\" business called \"ZenoCorp\":"

    Then take that title and produce the content:
    "Give a short description for a service with an existing title \"ZenoCorp Accounting Services\" for a \"Accounting\" business called \"ZenoCorp\":"

    "Create a short rich text with h3 and p html tags for a \"Accounting\" business called \"ZenoCorp\":"

    Contact form; 
    "Create a short rich text with h3 and p html tags for a contact form for a \"Accounting\" business called \"ZenoCorp\":"

    For page title:
    "Create a page Title for a business called \"ZenoCorp\":"


    Mapbox for maps.

  */

  const [homeText, setHomeText] = useState('');
  const [aboutText, setAboutText] = useState('');

  const [name, setName] = useState('Sumilo');
  const [industry, setIndustry] = useState('content writing');
  const [founders, setFounders] = useState('Some');
  const [sitePublished, setSitePublished] = useState(false);

  useEffect(() => {
    //generateText(prompts.start).then(res => setHomeText(res)).catch(error => console.error(error))
    //generateText(prompts.about).then(res => setAboutText(res)).catch(error => console.error(error))
    //contentGenerate();
    
    //generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => setHomeText(res)).catch(error => console.error(error))
    //generateText(prompts.about.replace("ZenoCorp", name).replace("Accounting", industry).replace("Sannu", founders)).then(res => setAboutText(res)).catch(error => console.error(error))
  }, []);


  const contentGenerate = () => {
    generateText(prompts.start.replace("ZenoCorp", name).replace("Accounting", industry)).then(res => setHomeText(res)).catch(error => console.error(error))
    generateText(prompts.about.replace("ZenoCorp", name).replace("Accounting", industry).replace("Sannu", founders)).then(res => setAboutText(res)).catch(error => console.error(error))
  }

  const removeQuotes = (str) => {
    // Check if the string starts and ends with double quotes
    if (str.startsWith('"') && str.endsWith('"')) {
      // Remove the first and last character (double quotes)
      return str.substring(1, str.length - 1);
    }
    
    return str; // Return the original string if quotes don't exist
  };
  
  const [serviceTitlesUsed, setServiceTitlesUsed] = useState([]);
  const [testimonialCustomerUsed, setTestimonialCustomerUsed] = useState([]);

  const setFreshData = () => {    
    const formData = localStorage.getItem('formData');
    let websiteDetails = JSON.parse(formData);
    console.log(websiteDetails);
    if(websiteDetails && websiteDetails.companyName && name!==websiteDetails.companyName){
      console.log('executing');
      setName(websiteDetails.companyName);
      setServiceTitlesUsed([]);
    }
    if(websiteDetails && websiteDetails.companyIndustry && industry!==websiteDetails.companyIndustry){
      console.log('executing')
      setIndustry(websiteDetails.companyIndustry);
      setServiceTitlesUsed([]);
    }
    return Promise.resolve(true);
  }
  
  const generateBlock = async (blocktype='normal', blockRef = {}, wordLimit=50) => {

    // make sure the data taken from form data and set

    console.log(name);
    console.log(industry);

    let getTitle = '';
    if(blocktype==='services'){
      let extraPrompt = (serviceTitlesUsed.length>0) ? serviceTitlesUsed.join('", "') : '';
      let extraPromptNew = (extraPrompt==='') ? '' : 'Already used services titles are: "'+ extraPrompt +'". Use something different';
      let extraPromptDescription = (wordLimit>0) ? ' Limiting word count  to '+wordLimit+' ' : '';
      getTitle = await generateText(prompts.shortHeadingServices.replace("ZenoCorp", name).replace("Accounting", industry) + extraPromptNew)
      .then(async res => {
          //console.log(res);
          let resi =  await generateText(extraPromptDescription + prompts.shortDescription.replace("{{TITLE_HERE}}", res).replace("ZenoCorp", name).replace("Accounting", industry)).then(res2 => { return res2 }).catch(err => console.log(err))
          //console.log('ok')
          //console.log(resi)
          setServiceTitlesUsed([...serviceTitlesUsed, res]);
          return {header: removeQuotes(res) , content: resi, type: '3-block', class: 'col-md-6', kind: 'service'};
        }
      )
      .catch(error => console.error(error))

    } else if(blocktype==='testimonial'){
      let extraPrompt = (blockRef.kind==='service') ? 'For "'+blockRef.header+'" service offered, ' : '';
      let extraPromptNew = (testimonialCustomerUsed.length>0) ? 'Already used names are: "'+ testimonialCustomerUsed.join('", "') +'": ' : '';
      //let extraPromptDescription = (wordLimit>0) ? ' Limiting word count  to '+wordLimit+' ' : '';
      getTitle = await generateText(extraPrompt + prompts.testimonial.replace("ZenoCorp", name).replace("Accounting", industry))
      .then(async res => {
          //console.log(res);
          let resi =  await generateText(prompts.testimonialCustomer + extraPromptNew).then(res2 => { return res2 }).catch(err => console.log(err))
          //console.log('ok')
          //console.log(resi)
          return {header: removeQuotes(resi) , content: removeQuotes(res), type: '3-block', class: 'col-md-6', kind: 'testimonial'};
        }
      )
      .catch(error => console.error(error))

    } else if(blocktype==='oneservicepage'){
      getTitle = await generateText(prompts.fullServicePageContent.replace("{{SERVICE_PAGE_TITLE}}", blockRef.header).replace("ZenoCorp", name).replace("Accounting", industry))
      .then(async res => {
          //console.log(res);
          return {header: removeQuotes(blockRef.header), content: res, type: '1-block', class: 'col-md-12', kind: 'service'};
        }
      )
      .catch(error => console.error(error))

    } else{
      getTitle = await generateText(prompts.shortHeading.replace("ZenoCorp", name).replace("Accounting", industry))
      .then(async res => {
          //console.log(res);
          let resi =  await generateText(prompts.shortDescription.replace("{{TITLE_HERE}}", res).replace("ZenoCorp", name).replace("Accounting", industry)).then(res2 => { return res2 }).catch(err => console.log(err))
          //console.log('ok')
          //console.log(resi)
          return {header: res , content: resi};
        }
      )
      .catch(error => console.error(error))
    }
    console.log(getTitle)
    return getTitle;
  }

  //const prompt = "Hello, can you please generate a short story for me?";

  async function generateText(prompt) {
    /*
    const responseOld = await fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
      },
      body: JSON.stringify({
        prompt: prompt,
        model: 'text-davinci-003',
        max_tokens: 250,
        //n: 1,
        //stop: "."
      })
    });
    */
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
      },
      body: JSON.stringify({
        "messages": [{"role": "user", "content": prompt}],
        model: 'gpt-3.5-turbo',
        max_tokens: 250,
        //n: 1,
        //stop: "."
      })
    });
    
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  
    const data = await response.json();
    return data.choices[0].message.content;
    //setHomeText(data.choices[0].text);
    //console.log(data.choices[0].text);
  }
  
  //generateText().catch(error => console.error(error));

  
  const [dynamicBlocks, setDynamicBlocks] = useState([]);
  
  const addDynamicBlock = async (type=null,blockRef) => {
    const newBlock = await generateBlock(type,blockRef);
    setDynamicBlocks(prevBlocks => [...prevBlocks, newBlock]);
  };
  const deleteDynamicBlock = (index) => {
    setDynamicBlocks(prevBlocks => prevBlocks.filter((_, i) => i !== index));
  };

  console.log(dynamicBlocks);

  const [message, setMessage] = useState('');

  const handleChildAction = (data) => {
    switch (data){
      case 'text':
        addDynamicBlock('services') 
      case 'hero':
        addDynamicBlock(data) 
      default:
        setMessage(data);
    }
  };

  const serviceChildTestimonial = (block, serviceTitle) => {
    if(block.kind==='service'){
      addDynamicBlock('testimonial');
    }
  }
  const serviceChildTestimonialButton = (block, serviceTitle) => {
    if(block.kind==='service'){
      return <button onClick={() => addDynamicBlock('testimonial',block)}>Write Testimonial for this.</button>
    }
  }
  const serviceChildExtendPageButton = (block, serviceTitle) => {
    if(block.kind==='service'){
      return <><button onClick={() => addDynamicBlock('oneservicepage',block)}>Extend this block to a Page.</button><small>This will create read more link and create another page</small></>
    }
  }

  
  const [isClickedCreate, setIsClickedCreate] = useState(false);

  const handleCreateSite = () => {
    setIsClickedCreate(true);
  };

  //const location = useLocation();
  //const shouldShowSection = location.pathname === '/about';
  const shouldShowSection = false;
  
  const [user, setUser] = useState(false);
  
  //const navigate = useNavigate();
  const fetchAndAssignUser = async () => {
    try {
        const { data: { user } } = await supabase.auth.getUser()
        setUser(user);
        console.log(user);
        //navigate('/dashboard');
        return redirect("/dashboard");
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
      // Assuming fetchAndAssignUser() returns a Promise that resolves with the data      
      fetchAndAssignUser();
  }, []);

  return (
    <PlatformThemeProvider>
    <MessageProvider>
      {/*
      <input type="text" name='c_name' placeholder='company name' value={name} onChange={cname => setName(cname.target.value)} />
      <input type="text" name='c_industry' placeholder='industry' value={industry} onChange={cindustry => setIndustry(cindustry.target.value)}  />
      <input type="text" name='c_founders' placeholder='name of founders' value={founders} onChange={cfounder => setFounders(cfounder.target.value)}  />
      <button onClick={contentGenerate}>Generate</button>
      */}
      {shouldShowSection && (
        <>
        <button onClick={addDynamicBlock}>Generate Block</button>
        <button onClick={() => {setFreshData().then(addDynamicBlock('services'))}}>Add Services Blocks</button>
        <button onClick={async () => {for (let i = 0; i < 3; i++){ setFreshData(); await addDynamicBlock('services');}}}>Add 3 Services Blocks</button>
        <button onClick={() => {setFreshData().then(addDynamicBlock('testimonial'))}}>Add Testimonials Blocks</button>
        </>
      )}
      <nav>
        {shouldShowSection && user && user.email}
      </nav>
      {shouldShowSection && <Header name='ContentSimi Website Creator' />}
      {/***<Navigation />***/}
      {shouldShowSection && <Container maxWidth="100%" >
      <div className='row'>
        <div className='col-md-2' style={{height: '100vh', overflow: 'scroll'}}>
        {/*
        <PrivateRoute
          path="/new"
          component={NewWebsite}
          isAuthenticated={user}
          />
        */}
        {shouldShowSection && (
          <>
          <p>Message from Child Component: {message}</p>
          <BlockSettings onAction={handleChildAction} />
          <NewWebsiteSettingsForm></NewWebsiteSettingsForm>
          </>
        )}
        </div>
        <div className='col-md-10'>
          {shouldShowSection &&
          <div className='row'>
            <Col>
              <HtmlViewer />
            </Col>
          </div>}
          <div className='row'>
            {dynamicBlocks.map((block, index) => (
              <div key={index}>
                <Block title={block.header} content={block.content} industry={industry}></Block>
                  {/*** <EditorBlock key={index} block={block} /> ***/}
                  <button onClick={() => deleteDynamicBlock(index)}>Delete Block</button>
                  {serviceChildTestimonialButton(block)}
                  {serviceChildExtendPageButton(block)}
              </div>
            ))}
          </div>
        </div>
      </div>
      </Container>}
      {shouldShowSection && <Footer />}
    <Router>
      
    <Routes>
        <Route path="/" elementTwo={<Home texts={homeText}  />} element={<HomePage displayBlocks={['main']} />} />
        <Route path="/about" element={<About texts={aboutText} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/builder" element={<WebsiteBuilder />} />
        <Route path="/blocks" element={<Blocks />} />
        <Route path='/created' element={<SitePreview />} />
        <Route path='/do' element={<SpacesUploadForm />} />
        <Route path='/dotwo' element={<DigitalOceanUploadFileForm />} />
        <Route path='/gcp' element={<GoogleCloudStorageForm />} />
        <Route path='/aws' element={<AWSUploadFileForm />} />
        <Route path='/awstwo' element={<CreateS3BucketConfigureHostingAndAddDomain />} />
        <Route path='/aws3' element={<CreateS3BucketConfigureHostingAndAddDomainNew />} />
        <Route path='/aws4' element={<AwsFileFour />} />
        <Route path='/saasui' element={<SaaSUI />} />
      </Routes>
        <Routes>
          {<Route path="/newthreetwo" element={<PrivateRoute user={user}><NewWebsite /></PrivateRoute>}></Route>}
          <Route path='/newtwo' element={<NewWebsite />} />
          <Route path='/new' element={<NewWebsite />} />
        </Routes>


      <Routes>
      <Route path="/newthreetwo" element={<PrivateRoute user={user}><NewWebsite /></PrivateRoute>}></Route>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login onUserLogin={user => fetchAndAssignUser()} />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/home" element={<HomePage />} />        
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/dashboard/domain" element={<PrivateRoute user={user}><ParkDomain /></PrivateRoute>} />
        <Route path="/dashboard/websites" element={<PrivateRoute user={user}><WebsitesListPage /></PrivateRoute>} />
        <Route path="/dashboard/websites/new" element={<PrivateRoute user={user}><NewWebsitePage /></PrivateRoute>} />
        <Route path="/dashboard/websites/create" element={<PrivateRoute user={user}><CreateWebsite /></PrivateRoute>} />
        <Route path="/dashboard/websites/new/:theSiteId" element={<PrivateRoute user={user}><NewWebsitePage /></PrivateRoute>} />
        <Route path="/dashboard/websites/editor/:theSiteId" element={<PrivateRoute user={user}><SingleWebsiteContentEditor /></PrivateRoute>} />
        <Route path="/dashboard/websites/preview/:theSiteId" element={<PrivateRoute user={user}><SingleWebsite /></PrivateRoute>} />
        <Route path="/dashboard/websites/preview/future" element={<PrivateRoute user={user}><SingleWebsiteFuture /></PrivateRoute>} />
        <Route path="/dashboard/payments" element={<PrivateRoute user={user}><PaymentsListPage /></PrivateRoute>} />
        <Route path="/dashboard/login" element={<InternalDummyPage page={{title: 'Login Page'}} guideNotice='Contact Us for More'><Login onUserLogin={user => fetchAndAssignUser()} /></InternalDummyPage>} />
        <Route path="/dashboard/signup" element={<InternalDummyPage page={{title: 'Signup Page'}}><Signup /></InternalDummyPage>} />
        <Route path="/dashboard/forgot-password" element={<InternalDummyPage page={{title: 'Don\'t worry! We all forget'}}></InternalDummyPage>} />
        <Route path="/dashboard/settings" element={<PrivateRoute user={user}><InternalDummyPage page={{title: 'Settings'}}></InternalDummyPage></PrivateRoute>} />
        <Route path="/dashboard/content/websites" element={<PrivateRoute user={user}><InternalDummyPage page={{title: 'Write for Website Pages'}}>
          <p>
            This tool is being designed to help content writers write fast bettter content for website.
          </p>
          </InternalDummyPage></PrivateRoute>} />
        <Route path="/dashboard/content/blogs" element={<PrivateRoute user={user}><InternalDummyPage page={{title: 'Write Blogs'}}>
          <p>
            This tool is being designed to help people write bettter content for blogs.
          </p>
          </InternalDummyPage></PrivateRoute>} />
        <Route path="/dashboard/content/testimonials" element={<PrivateRoute user={user}><InternalDummyPage page={{title: 'Write Testimonials'}}></InternalDummyPage></PrivateRoute>} />
        <Route path="/dashboard/content/landingpages" element={<PrivateRoute user={user}><InternalDummyPage page={{title: 'Write Landing Pages'}}></InternalDummyPage></PrivateRoute>} />
        <Route path="/ops/mcquery" element={<PrivateRoute user={user}><BusinessFromIndustry page={{title: 'Get Businesses from Industry'}}></BusinessFromIndustry></PrivateRoute>} />        
        <Route path="/dashboard/neweditor" element={<PrivateRoute user={user}><NewEditorPage /></PrivateRoute>} />
      </Routes>
      <MessageDisplayer />
    </Router>
    </MessageProvider>
    </PlatformThemeProvider>
  );
}

export default App;
