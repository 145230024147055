import React, { useEffect, useState } from 'react';
import { useNode } from '@craftjs/core';
import AwardsBlock from '../../../../components/Blocks/AwardsBlock';
import BannerBlock from '../../../../components/Blocks/BannerBlock';
import HeaderBlock from '../../../../components/Blocks/HeaderBlock';
import FooterBlock from '../../../../components/Blocks/FooterBlock';
import ListBlock from '../../../../components/Blocks/ListBlock';
import TableBlock from '../../../../components/Blocks/TableBlock';
import PricingBlock from '../../../../components/Blocks/PricingBlock';
import FeatureBlock from '../../../../components/Blocks/FeatureBlock';
import ProcessBlock from '../../../../components/Blocks/ProcessBlock';
import HeroBlock from '../../../../components/Blocks/HeroBlock';
import HtmlBlock from '../../../../components/Blocks/HtmlBlock';
import TestimonialBlock from '../../../../components/Blocks/TestimonialBlock';
import LogosBlock from '../../../../components/Blocks/LogosBlock';
import StatsBlock from '../../../../components/Blocks/StatsBlock';
import TeamBlock from '../../../../components/Blocks/TeamBlock';
import FaqsBlock from '../../../../components/Blocks/FaqsBlock';
import CtaBlock from '../../../../components/Blocks/CtaBlock';
import FormBlock from '../../../../components/Blocks/FormBlock';
import CalendlyBlock from '../../../../components/Blocks/CalendlyBlock';
import DeleteButton from '../DeleteButton';
import TemplateBlock from '../../../../components/Blocks/SmallComponents/TemplateBlock';
import BlocksDesignProperties from '../../parts/BlocksDesignProperties';
import HowItWorksBlock from '../../../../components/Blocks/HowItWorksBlock';

export const CraftifiedComponent = (props) => {
  const { connectors: { connect, drag } } = useNode();
  const { actions: { setProp } } = useNode();
  const [blockData, setBlockData] = useState({...props.block});

  console.log('props',props);
  useEffect(() => {
    setBlockData(props.block);
  }, [props]);

  const chooseBlockOld = ({block, key=4}) => {   
    switch (block.WebsiteBlock.type) {
        case 'banner':
        return <BannerBlock block={block} blockId={key} />;
        case 'header':
        return <HeaderBlock block={block} blockId={key} />;
        case 'footer':
        return <FooterBlock block={block} blockId={key} />;
        case 'list':
        return <ListBlock block={block} blockId={key} />;
        case 'table':
        return <TableBlock block={block} blockId={key} />;
        case 'pricing':
        return <PricingBlock block={block} blockId={key} />;
        case 'features':
        return <FeatureBlock block={block} blockId={key} />;
        case 'process':
        return <ProcessBlock block={block} blockId={key} />;
        case 'hero':
        return <HeroBlock block={block} blockId={key} />;   
        case 'html':
        return <HtmlBlock block={block} blockId={key} />;        
        case 'quote':
        return <TestimonialBlock block={block} blockId={key} />;
        case 'logos':
        return <LogosBlock block={block} blockId={key} />;
        case 'customers':
        return <LogosBlock block={block} blockId={key} />;
        case 'stats':
        return <StatsBlock block={block} blockId={key} />;
        case 'awards':
        return <AwardsBlock block={block} blockId={key} />;
        case 'team':
        return <TeamBlock block={block} blockId={key} />;
        case 'faqs':
        return <FaqsBlock block={block} blockId={key} />;
        case 'cta':
        return <CtaBlock block={block} blockId={key} />;
        case 'formblock':
        return <FormBlock block={block} blockId={key} />;
        case 'calendly':
        return <CalendlyBlock block={block} blockId={key} />;
        default:
        return <></>;
  }
  return <></>;
}


const chooseBlock = (block,key=4) =>  {
  const blockType = block?.WebsiteBlock?.type;

  if (!blockType) return; // Exit early if there's no block type
  let updatedBlock = { ...block };

  if (blockType === 'list' && block.pageFill?.section_style === 'logo_list') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'logos' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'customer_list') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'customers' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'stats') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'stats' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'faq') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'faqs' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'awards') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'awards' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'team') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'team' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'feature_list') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'features' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'methods_process_list') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'process' };
  } else if (blockType === 'list' && block.pageFill?.section_style === 'cta') {
    updatedBlock.WebsiteBlock = { ...updatedBlock.WebsiteBlock, type: 'cta' };
  }

  if (block.templateCode && block.templateCode!==undefined && block.templateCode !='' && block.useCustomTemplate) {
    return <TemplateBlock block={block} blockId={key} />;
  }
  if(block.version==='block'){
    return <BlocksDesignProperties block={block} blockId={key} />;
  }
  switch (updatedBlock.WebsiteBlock.type) {
  case 'banner':
  return <BannerBlock block={block} blockId={key} />;
  case 'header':
  return <HeaderBlock block={block} blockId={key} />;
  case 'footer':
  return <FooterBlock block={block} blockId={key} />;
  case 'list':
  return <ListBlock block={block} blockId={key} />;
  //return <BlocksDesignProperties block={block} blockId={key} />;
  case 'table':
  return <TableBlock block={block} blockId={key} />;
  case 'pricing':
  return <PricingBlock block={block} blockId={key} />;
  case 'features':
  return <FeatureBlock block={block} blockId={key} />;
  //return <ModernBlockDesigns block={block} blockId={key} />;
  case 'process':
  return <ProcessBlock block={block} blockId={key} />;
  case 'hero':
  return <HeroBlock block={block} blockId={key} />;   
  case 'html':
  return <HtmlBlock block={block} blockId={key} />;        
  case 'full_blog_post':
  return <HtmlBlock block={block} blockId={key} />;        
  case 'quote':
  return <TestimonialBlock block={block} blockId={key} />;
  case 'logos':
  return <LogosBlock block={block} blockId={key} />;
  case 'customers':
  return <LogosBlock block={block} blockId={key} />;
  case 'stats':
  return <StatsBlock block={block} blockId={key} />;
  case 'how_it_works':
  return <HowItWorksBlock block={block} blockId={key} />;
  case 'awards':
  return <AwardsBlock block={block} blockId={key} />;
  case 'team':
  return <TeamBlock block={block} blockId={key} />;
  case 'faqs':
  return <FaqsBlock block={block} blockId={key} />;
  case 'cta':
  return <CtaBlock block={block} blockId={key} />;
  //return <BlocksDesignProperties block={block} blockId={key} />;
  case 'formblock':
  return <FormBlock block={block} blockId={key} />;
  case 'calendly':
  return <CalendlyBlock block={block} blockId={key} />;
  default:
  return null;
}
return null;
}


  return (
    <div ref={ref => connect(drag(ref))}>
      {chooseBlock({...props, block: blockData}) }
      <span style={{position: 'absolute', marginTop: '-50px'}}><DeleteButton /></span>
    </div>
  );
};
