import { supabase } from "../../supabase"


export const createOne = async (table,inputData,condition=[]) => {
  const { data, error } = await supabase
      .from(table)
      .insert([inputData])
      .select()
  if(error){
      console.error(JSON.stringify(error))
      return error;
  }
  return data[0];
}
export const updateOne = async (table,inputData,value,column='id',condition=[]) => {
  const { data, error } = await supabase
      .from(table)
      .update([inputData])
      .eq(column,value)
      .select()
  if(error){
      console.error(JSON.stringify(error))
      return error;
  }
  console.log('update stuffs',inputData,column,value,data)
  return data[0];
}
export const findOne = async (table,value,column='id',selectString='*') => {
  const { data, error } = await supabase
      .from(table)
      .select(selectString)
      .eq(column,value)
      .single()
  if(error){
      console.error(JSON.stringify(error))
      return null;
  }
  return data;
}
export const deleteOne = async (table,value,column='id') => {
    console.error('to deleting data item:', table,value,column);
    const { error } = await supabase.from(table).delete().eq(column, value);
    if(error){
      console.error(JSON.stringify(error))
      return error;
    }
    return true;
}
export const findAll = async (table,selectString='*',conditions=[]) => {
  console.log('fetch input',table,selectString,conditions);
  let query = supabase.from(table).select(selectString)
      for (let condition of conditions) {
        const [column, operator, value] = condition;
    
        switch (operator) {
          case '=':
            query = query.eq(column, value);
            break;
          case 'LIKE':
            query = query.ilike(column, value);
            break;
          case 'gt':
            query = query.gt(column, value);
            break;
          case 'lt':
            query = query.lt(column, value);
            break;
          // ... other operators
          default:
            throw new Error(`Unsupported operator: ${operator}`);
        }
      }
      query = query.order('id', { ascending: false }).limit(10);
  const { data: res, error } = await query;    
  if(error){
      console.error(JSON.stringify(error))
      return null;
  }
  console.log('fetch output',res);
  return res;
}

//.order('column_name', { ascending: true });
const defaultSort = (table) => {
  switch(table){
    case 'page': 
      return {column: 'id', direction: 'ascending'}
    default:
      return {column: 'id', direction: 'ascending'}
  }
}