import React, {useEffect, useState} from 'react';
import WebsiteGenerator from '../components/WebsiteGenerator';
import { ButtonBase, Button, TextField } from '@mui/material';
import { supabase } from '../supabase';

const NewWebsite = ({websiteSettings,onSaved}) => {

  const [siteId, setSiteId] = useState(null);
  const [siteData, setSiteData] = useState({});
  const [siteFormData, setSiteFormData] = useState({});
  const [formData, setFormData] = useState({});

  /*
  const formData = {
    blocks: [
        {
            blockSettings: {},
            form: {
                fields: [
                    {
                        name: 'firstName',
                        type: 'text',
                    }
                ]
            }
        }
    ]
  }
  */

  // these features are needed to generate the website with following facilities.
  const form = [
    {
        name: 'websiteType',
        type: 'radio',
        options: ['personal', 'professional', 'business']
    },
    {
        name: 'offeringType',
        type: 'radio',
        options: ['product', 'service', 'both']
    },
    {
        name: 'companyName',
        type: 'text',
    },
    {
        name: 'companyIndustry',
    },
    {
        name: 'targetAudience',
    },
    {
        name: 'email',
        type: 'email',
    },
    {
        name: 'phoneNumber',
    },
    {
        name: 'city',
    },
    {
        name: 'whatsapp',
    },
    {
        name: 'intercom',
    },
    {
        name: 'calendly',
    },
    {
        name: 'seo',
        type: 'radio',
        options: ['basic','advanced']
    },
    {
        name: 'googlemapsEmbedCode',
    },
    {
        name: 'contactForm',
        type: 'checkbox',
    },
    {
        name: 'tone',
        type: 'radio',
        options: ['creative', 'professional', 'fun']
    },
    {
        name: 'writerType',
        type: 'radio',
        options: ['novice', 'expert']
    },
  ];

  // if whatsapp, we can enable whatsapp chat
  // if email send form data to email

  const handleSubmit = async(e) => {
    e.preventDefault();
    for (let field of e.target.elements) {
      if (field.name) {
        formData[field.name] = (selectedOptions[field.name]) ? selectedOptions[field.name] : field.value;
      }
    }
    if(formData && formData.companyName && formData.companyIndustry){

    } else{
      return null;
    }
    localStorage.setItem('formData', JSON.stringify(formData));
    // create a site and save stuffs there
    // if site is there then update it.
    const { data: { user } } = await supabase.auth.getUser()
    if(!siteId){
      const { data, error } = await supabase
          .from('website')
          .insert([
              { name: (formData && formData.companyName)? formData.companyName : Math.random(), formData: formData, owner:  user.id},
          ])
          .select()
      if(error){
          alert(JSON.stringify(error))
          return null;
      }
      console.log(data);
      setSiteId(data[0].id)
      setSiteFormData(data[0].formData)
      onSaved(data[0].id)
    } else{
      const { data, error } = await supabase
          .from('website')
          .update([
              { name: (formData && formData.companyName)? formData.companyName : Math.random(), formData: formData, owner:  user.id},
          ])
          .eq('id', siteId)
          .select()
      if(error){
          alert(JSON.stringify(error))
          return null;
      }
      console.log(data);
      setSiteId(data[0].id)
      setSiteFormData(data[0].formData)
    }
  }
  const insertWebsite = async() => {
    let htmlContent = localStorage.getItem('htmlContent');
    const homePageData = localStorage.getItem('homePageData');
    const formDataRaw = localStorage.getItem('formData');
    const formData = (formDataRaw) ? JSON.parse(formDataRaw) : {};
    const { data: { user } } = await supabase.auth.getUser()
    const { data, error } = await supabase
        .from('website')
        .insert([
            { name: (formData && formData.companyName)? formData.companyName : Math.random(), data: homePageData, html: htmlContent, owner:  user.id},
        ])
        .select()
    if(error){
        alert(JSON.stringify(error))
        return null;
    }
    console.log(data);
    setSiteId(data[0].id)
    setSiteData(data[0].data)
    setSiteFormData(data[0].formData)
    return data[0];
}
  
  const [isClickedCreate, setIsClickedCreate] = useState(false);
  const [state, setState] = useState([]);
  
  const handleCreateSite = () => {
    const formData = (siteFormData) ? siteFormData : (localStorage.getItem('formData')) ? JSON.parse(localStorage.getItem('formData')) : {};
    if (formData) {
        setState(formData);
        setIsClickedCreate(true);
        console.log(state);
    }
  }
  
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (event) => {
      const { name, value } = event.target;
      console.log(selectedOptions)
      setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: value,
      }));
      console.log(selectedOptions)
  };

  const HandleFormFieldDisplay = (props) => {
    //console.log(field)

    switch (props.field.type){
        case 'radio':
            return <div className='my-2'>
            {separateWordsWithUppercase(props.field.name)} : 
            <br />
            {props.field.options.map((option,key) => (
                <span className='px-2' key={key}>
                <label htmlFor={props.field.name.replace(' ','-')+'-'+option.replace(' ','-')}>
                <input
                  id={props.field.name.replace(' ','-')+'-'+option.replace(' ','-')}
                  type="radio"
                  name={props.field.name}
                  value={option}
                  checked={selectedOptions[props.field.name] === option}
                  onChange={handleOptionChange}
                />{' '} {separateWordsWithUppercase(option)}</label>
                </span>
            ))}
            </div>;
        case 'checkbox':
            return <div className='my-2' ><input type="checkbox" name={props.field.name} value={true} /> <label htmlFor={props.field.name}>{separateWordsWithUppercase(props.field.name)}</label></div>;
            //return <div className='my-2' ><label htmlFor={props.field.name.replace(' ','-')}><input type="checkbox" name={props.field.name} value={true} checked={selectedOptions[props.field.name] === true} onChange={handleOptionChange} /> {separateWordsWithUppercase(props.field.name)}</label></div>;
        default:
            //return <input type="text" name={props.field.name} placeholder={separateWordsWithUppercase(props.field.name)} className='form-control my-2' />;
            return <TextField variant="outlined" name={props.field.name} label={separateWordsWithUppercase(props.field.name)} fullWidth style={{ marginTop: 11 }} value={formData[props.field.name]} />
    }
  }
  function separateWordsWithUppercase(string) {
    // Regex pattern to match uppercase letters
    const pattern = /(?=[A-Z])/;
  
    // Use the regex pattern to split the string
    const words = string.split(pattern);
  
    // Filter out empty strings
    const filteredWords = words.filter(word => word !== '');
  
    // Join the filtered words with a space
    const separatedString = filteredWords.join(' ');
  
    return capitalizeEachWord(separatedString);
  }
  function toCamelCase(str) {
    return str.replace(/[-_](\w)/g, (_, c) => c.toUpperCase());
  }
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }
  
  
  

  const stepsForm = () => {
    const index = 0;
    const steps = [];
    const isSubmitting = () => {
        
    }
    const setFieldValue = () => {
        
    }
    return(
        <>
        {index > 0 && (
            <button type="button" onClick={() => setFieldValue('step', index - 1)}>
              Previous
            </button>
          )}
          {index < steps.length - 1 && (
            <button type="button" onClick={() => setFieldValue('step', index + 1)}>
              Next
            </button>
          )}
          {index === steps.length - 1 && (
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          )}
        </>
    )
  }

  useEffect(() => {
    console.log(formData)
  },['formData'])
  
  return (
    <>
    <form onSubmit={(e) => handleSubmit(e)}>
    {form.map((field,key) => (
      <HandleFormFieldDisplay key={key} field={field} className='my-3' />
    ))}
    <Button type="submit" variant="outlined">Save</Button>
    </form>
      <Button onClick={handleCreateSite} variant="outlined">Generate</Button>
      {isClickedCreate && <WebsiteGenerator website={{name: state.companyName, industry: state.companyIndustry}} />}
    </>
  );
};

export default NewWebsite;
