import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Button, Grid, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { getAllWebsites, updateWebsite, updateWebsitePageDefault, setCurrentEditedWebsitePageId } from '../../redux/slices/websiteSlice';


const jsonLdConfig = {
    "Article": ["headline", "datePublished", "image"],
    // Add more JSON-LD types and their fields here
};

const metaConfig = [
  { name: 'title', label: 'Title' },
  { name: 'description', label: 'Description' },
  { name: 'keywords', label: 'Keywords' },
  { name: 'author', label: 'Author' },
  { name: 'robots', label: 'Robots' },
  { name: 'canonical', label: 'Canonical' },
  { name: 'og:title', label: 'OG Title' },
  { name: 'og:description', label: 'OG Description' },
  { name: 'og:image', label: 'OG Image' },
  { name: 'og:url', label: 'OG URL' },
  { name: 'og:site_name', label: 'OG Site Name' },
  { name: 'og:type', label: 'OG Type' },
  { name: 'twitter:card', label: 'Twitter Card' },
  { name: 'twitter:site', label: 'Twitter Site' },
  { name: 'twitter:title', label: 'Twitter Title' },
  { name: 'twitter:description', label: 'Twitter Description' },
  { name: 'twitter:image', label: 'Twitter Image' },
  { name: 'twitter:creator', label: 'Twitter Creator' },
];


const SEOForm = ({websiteId, pageId=null, initialSEOData = {}, onSubmit }) => {
    const [seoData, setSEOData] = useState({});
    const [allowSearchEngine, setAllowSearchEngine] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(pageId || null);
    const [pages, setPages] = useState([]);
    const dispatch = useDispatch();

    const handleMetaChange = (e) => {
        const { name, value } = e.target;
        setSEOData(prevState => ({
            ...prevState,
            metas: {
                ...prevState.metas,
                [name]: value
            }
        }));
    };

    const handleJsonLdChange = (e, index) => {
        const { name, value } = e.target;
        let jsonLds = [...seoData.jsonLds];
        jsonLds[index][name] = value;
        setSEOData(prevState => ({
            ...prevState,
            jsonLds
        }));
    };
     
  useEffect(() => {    
    if(websiteId && websiteId>0 ){
      dispatch(getAllWebsites({startFromId: websiteId}));
    }
  }, []);
  const websiteData = useSelector(state => state.websites.websites.find(website => website.id === parseInt(websiteId)));
  const websiteDataTwo = useSelector(state => state.websites.websites);
  const websiteOrPageData = (selectedPageId>0) ? websiteData?.webpages?.find(webpage => webpage.id===parseInt(selectedPageId)) : websiteData;
    useEffect(() => {
      if (websiteOrPageData) {
            mySeoConfig = websiteOrPageData.seoConfig;
            if(mySeoConfig){
              setSEOData(mySeoConfig)
            }
            setAllowSearchEngine(websiteOrPageData.allowSearchEngine)
      }
      if(websiteData && websiteData.webpages){
        setPages(websiteData.webpages);
      }
  }, [websiteData]);

  useEffect(() => {
    console.log('websiteDataTwo',websiteDataTwo)
}, [websiteDataTwo]);
 

    let mySeoConfig = {};
    useEffect(() => {
        if (websiteOrPageData) {
              mySeoConfig = websiteOrPageData.seoConfig;
              if(mySeoConfig){
                setSEOData(mySeoConfig)
              }
              setAllowSearchEngine(websiteOrPageData.allowSearchEngine)
        }
        if(websiteData && websiteData.webpages){
          setPages(websiteData.webpages);
        }
    }, [websiteData, websiteOrPageData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(selectedPageId>0){
          dispatch(updateWebsitePageDefault({id: selectedPageId, data: {seoConfig: seoData}}))
        } else{
          dispatch(updateWebsite({id: websiteId, data: {seoConfig: seoData}}))
        }
        //onSubmit(seoData);
    };
    const onPageChange = (pId) => {
      setSelectedPageId(pId);
      console.log('pid',pId)
      dispatch(setCurrentEditedWebsitePageId(pId))
    }
    
    const handleAllowSearchEngine = (e) => {
      //e.preventDefault();
      setAllowSearchEngine(e)
      if(selectedPageId>0){
        dispatch(updateWebsitePageDefault({id: selectedPageId, data: {allowSearchEngine: e}}))
      } else{
        dispatch(updateWebsite({id: websiteId, data: {allowSearchEngine: e}}))
      }
      //onSubmit(seoData);
  };

    return (
      <>
        <select className="form-control"onChange={(e) => onPageChange(e.target.value)}>
          <option value="null">Default Home</option>
          {pages?.map((page,pageKey) => (
            <option value={page.id} key={pageKey}>{page.name}</option>            
          ))}
        </select>
        <form onSubmit={handleSubmit}>
            <h5>Meta Tags</h5>
            <Grid container spacing={2}>
                {/* Meta Fields */}
                {metaConfig.map(field => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    <TextField
                      name={field.name}
                      label={field.label}
                      value={seoData?.metas?.[field.name] || ''}
                      onChange={(e) => handleMetaChange(e)}
                      fullWidth
                    />
                  </Grid>
                ))}
                {/* ... (your existing meta fields here) ... */}

            </Grid>
            <h5 className='py-4'>JSON-LD Tags</h5>
            <Grid container spacing={2}>
                {/* JSON-LD Fields */}
                {seoData?.jsonLds?.map((jsonLd, index) => (
                    jsonLdConfig[jsonLd["@type"]].map(field => (
                        <Grid item xs={12} sm={6} key={field}>
                            <TextField
                                name={field}
                                label={field}
                                value={jsonLd[field] || ''}
                                onChange={(e) => handleJsonLdChange(e, index)}
                                fullWidth
                            />
                        </Grid>
                    ))
                ))}
            </Grid>
            <Button type="submit" variant="outlined" className='my-3' color="primary" fullWidth>
                Submit
            </Button>
        </form>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowSearchEngine}
              onChange={(e) => handleAllowSearchEngine(e.target.checked)}
            />
          }
          label="Allow Search Engine on this Page"
        />
      </>
    );
};

export default React.memo(SEOForm);
