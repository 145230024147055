import { useContext } from "react";
import TemplateStylesOne from "../components/StylesOne";
import WebsitePreview from "../components/WebsitePreview";
import ReactDOMServer from 'react-dom/server';
import { MessageContext } from "../NEW/contexts/MessageContext";
import { generateLogo, getAboutBlock, getBannerBlock, getMenus, getServicesBlock, getTestimonialsBlock, getWebsiteColors } from "./prompts";
import { getAllComponents } from "./templateComponents";
import { generateSlug, pickRandomArray, pickRandomValue } from "./randoms";

export async function generateWebsite({thePageData, websiteData=false}){
    const pageData = thePageData;
    if(!thePageData){
        return '';
    }
    const htmlContent = ReactDOMServer.renderToString(<WebsitePreview pageData={pageData} />);
    const pageTitle = (websiteData && websiteData.name) ? websiteData.name : thePageData.seo.title;
    //const inlineStyle = require('../App.css');
    //console.log(inlineStyle);

    const inlineStyle = TemplateStylesOne();

    // Generate the final HTML file
    const finalHtml = `<!DOCTYPE html>
    <html>
      <head>
        <title>${pageTitle}</title>
        <!-- Include necessary stylesheets or scripts -->
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap" rel="stylesheet">            
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous">
        <style>
        ${inlineStyle}
        </style>
      </head>
      <body>
        <div id="root">${htmlContent}</div>
        <!-- Include necessary scripts -->
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe" crossorigin="anonymous"></script>
      </body>
    </html>`;
  
    // Write the HTML content to a file
    //fs.writeFileSync('path/to/website/index.html', finalHtml);
  /*
    // Deploy the website to Netlify
    const client = new API(process.env.NETLIFY_AUTH_TOKEN);
    const deploy = await client.createSiteDeployment(process.env.NETLIFY_SITE_ID, {
      dir: 'path/to/website',
      message: 'Deploying website',
    });
  
    // Assign domains to the website
    await client.createSiteDomain(process.env.NETLIFY_SITE_ID, {
      hostname: 'www.yourdomain.com',
      ssl: true,
    });
    await client.createSiteDomain(process.env.NETLIFY_SITE_ID, {
      hostname: 'yourdomain.com',
      ssl: true,
    });
    */
   return finalHtml;
}

export const createWebsiteContents = (formData) => {
  // create website pages,
  // define color pallettes,
  // define fonts,
  // define navbar etc,
  // got pages and page blocks
  // build homepage
  // build other pages.
  if(!formData){
    return null;
  }
}

export const createPages = () => {
  // creates separate pages from navigation
}



export const getHeaderBlock = async (formData) => {
  const header = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051aa",
        "description": "Website header with logo and links",
        "type": "header",
        "active": true,
        "name": "Header"
    },
    "siteName": formData.companyName,
    "showLogo": false,
    "source": "branding",
    "customLogo": null,
    "logoDesktop": {
        "height": 80
    },
    "logoMobile": {
        "height": 40
    },
    "showSocial": true,
    "style": "left",
    "fullWidth": true,
    "sticky": false,
    "background": "#3D9970",
    "button": {
        "label": "Call Us",
        "link": "#",
        "type": "url"
    }
  };
  let theWebsiteSettings = {
    "websiteName":"okni",
    "pages": [
      "new",
      "effective"
    ],
    "backgroundColor":"#e10e0e",
    "navigationColor":"#67d58e",
    "headingColor":"#ae4242",
    "bodyTextColor":"",
    "googleFont":"Roboto"
  };
  /*
  try{
     // addMessage('Understanding what colors would suit ..')
      let theColorsTaken = await getWebsiteColors(formData.companyName,formData.companyIndustry);
      const theColors = JSON.parse(theColorsTaken);
      theWebsiteSettings['navigationColor'] = theColors['navigationBarColor'];
      header['websiteSettings'] = theWebsiteSettings;
      console.log(header)
      console.log(theColorsTaken)
  } catch (error) {
      console.log('error in website Settings')
      console.log(error.message)
  }
  */
  const theMenus = await getMenus(formData.companyName,formData.companyIndustry);
  console.log('Getting called again. Header');
  //const newMenus = [...theMenus];
  const menus = theMenus.map((menu) => {
    if(Array.isArray(menu)) {
    }
    menu['label'] = menu.menu;
    menu['link'] = (menu && menu.menu) ? generateSlug(menu.menu) : '';
    menu['type'] = 'section';
    menu['section'] = '1234';
    return menu;
  }).filter((element) => element !== undefined);

  header['menus'] = menus;

  // addMessage('Generating Logo ..')
  const logo = await generateLogo(formData.companyName,formData.companyIndustry);
  header['customLogo'] = (logo && logo.data && logo.data.length>0) ? logo.data[0] : null;
  console.log('res Header',header);
  return header;
}
export const getFooterBlock = async (formData) => {
    const footer = {
      "WebsiteBlock": {
          "_id": "629f98a1eb0b4972268051ab",
          "description": "Website footer with logos and links",
          "type": "footer",
          "active": true,
          "name": "Footer"
      },
      "businessName": formData.companyName,
      "showLogo": true,
      "style": "simple-right",
      "additionalDetails": "",
      "showSocial": true,
      "madeWithContentSimi": true,
      "background": "#83dfb6",
      "button": {
          "label": "Home",
          "link": "/",
          "type": "page",
          "page": "644759ffb29fcec827b43354"
      }
  };
  // add menus lastly, save menus separately
  return footer;
}

export const initializeBlockData = (formData) => {
  // this outputs the navigation, theme, colors, fonts etc.
  let pageData = {
    "_id": "644759ffb29fcec827b43354",
    "Website": "644759ffb29fcec827b43350",
    "slug": null,
    "label": "Home",
    "showOnHeader": true,
    "showOnFooter": true,
    "default": true,
    "blocks": [],
    "order": 0,
    "createdAt": "2023-04-25T04:41:35.809Z",
    "seo": {
        "title": "Home Page",
        "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
        "keywords": "keyword",
        "headCode": "",
        "footerCode": "",
        "Image": null
    },
    "__v": 3
  };
  return pageData;
}

export const getHomePageDesign = (formData,websiteDesign) => {
  // outputs the blocks to choose for each type of home pages and their designs
  // order too
  const designs = [
    [
      {
        type: 'banner',
        design: {}
      },
      {
        type: 'service',
        design: {}
      },
      {
        type: 'testimonial',
        design: {}
      },
      {
        type: 'about',
        design: {}
      },
    ],
    [
      {
        type: 'banner',
        design: {}
      },
      {
        type: 'testimonial',
        design: {}
      },
      {
        type: 'service',
        design: {}
      },
      {
        type: 'about',
        design: {}
      },
    ],
  ];
  return pickRandomArray(designs[0],designs[1]);
}

export const createBlock = async (formData,blockType,theBlockDesign) => {
  // should understand block design, block content and repeat if went wrong
  // outputs the block.

  const blockDesignType = {
    service: 'list',
    about: 'hero',
    testimonial: 'quote',
    banner: 'banner',
  }

  let blockDesign = (theBlockDesign) ? theBlockDesign : pickRandomValue(getAllComponents(blockDesignType[blockType]));
  console.log('block design for '+blockType+': ', blockDesign)
  if(blockType==='service'){
    // min number of services, also if additional things included like testimonial etc.
    //if(blockDesign.i)
    let count = 3;
    if(blockDesign && blockDesign.suggestWhenItems && blockDesign.suggestWhenItems.min>0){
      count = blockDesign.suggestWhenItems.min;
    }
    let service = await getServicesBlock(formData.companyName,formData.companyIndustry,count);
    // if testimonials attached, add stuffs. loop the services to create number of testimonials, 
    service['componentDesign'] = {
      identifier: blockDesign['identifier'],
      name: blockDesign['name'],
      text: blockDesign['text'],
    }
    return service;
  } else if(blockType==='banner'){
    // min number of services, also if additional things included like testimonial etc.
    //if(blockDesign.i)
    let count = 3;
    if(blockDesign && blockDesign.suggestWhenItems && blockDesign.suggestWhenItems.min>0){
      count = blockDesign.suggestWhenItems.min;
    }
    let banner = await getBannerBlock(formData.companyName,formData.companyIndustry,formData.websiteType,120);
    // if testimonials attached, add stuffs. loop the services to create number of testimonials, 
    banner['componentDesign'] = {
      identifier: blockDesign['identifier'],
      name: blockDesign['name'],
      text: blockDesign['text'],
    }
    return banner;
  } else if(blockType==='testimonial'){
    let count = 3;
    if(blockDesign && blockDesign.suggestWhenItems && blockDesign.suggestWhenItems.min>0){
      count = blockDesign.suggestWhenItems.min;
    }
    let testimonial = await getTestimonialsBlock(formData.companyName,formData.companyIndustry,count);
    testimonial['componentDesign'] = {
      identifier: blockDesign['identifier'],
      name: blockDesign['name'],
      text: blockDesign['text'],
    }
    return testimonial;
  } else if(blockType==='about'){
    let count = 3;
    if(blockDesign && blockDesign.suggestWhenItems && blockDesign.suggestWhenItems.min>0){
      count = blockDesign.suggestWhenItems.min;
    }
    let about = await getAboutBlock(formData.companyName,formData.companyIndustry,count);
    about['componentDesign'] = {
      identifier: blockDesign['identifier'],
      name: blockDesign['name'],
      text: blockDesign['text'],
    }
    return about;
  } else{
    return null;
  }
}
/*
export const createHomePageBlocks = (formData,getHomePageDesign) => {
  // will return only blocks
  //const { addMessage } = useContext(MessageContext);

  const theHomePageBlocks = fetchHomePageData(formData.companyName,formData.companyIndustry);

  // first choose blocks
  // foreah blocks call stuffs
  const blokStyles = {'banner': [],services: [],};
  // if one call fails call again;

  const fetchHomePageData = async (name = 'Somistry', industry='Florist', websiteType='business') => {
      let pageData = {
          "_id": "644759ffb29fcec827b43354",
          "Website": "644759ffb29fcec827b43350",
          "slug": null,
          "label": "Home",
          "showOnHeader": true,
          "showOnFooter": true,
          "default": true,
          "blocks": [],
          "order": 0,
          "createdAt": "2023-04-25T04:41:35.809Z",
          "seo": {
              "title": "Home Page",
              "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
              "keywords": "keyword",
              "headCode": "",
              "footerCode": "",
              "Image": null
          },
          "__v": 3
      }
      try {
          const header = {
              "WebsiteBlock": {
                  "_id": "629f98a1eb0b4972268051aa",
                  "description": "Website header with logo and links",
                  "type": "header",
                  "active": true,
                  "name": "Header"
              },
              "siteName": name,
              "showLogo": false,
              "source": "branding",
              "customLogo": null,
              "logoDesktop": {
                  "height": 80
              },
              "logoMobile": {
                  "height": 40
              },
              "showSocial": true,
              "style": "left",
              "fullWidth": true,
              "sticky": false,
              "background": "#3D9970",
              "button": {
                  "label": "Call Us",
                  "link": "#",
                  "type": "url"
              }
          };

          const footer = {
              "WebsiteBlock": {
                  "_id": "629f98a1eb0b4972268051ab",
                  "description": "Website footer with logos and links",
                  "type": "footer",
                  "active": true,
                  "name": "Footer"
              },
              "businessName": name,
              "showLogo": true,
              "style": "simple-right",
              "additionalDetails": "",
              "showSocial": true,
              "madeWithContentSimi": true,
              "background": "#83dfb6",
              "button": {
                  "label": "Home",
                  "link": "/",
                  "type": "page",
                  "page": "644759ffb29fcec827b43354"
              }
          };
          let testimonial = [];
          let theWebsiteSettings = {
            "websiteName":"okni",
            "pages": [
              "new",
              "effective"
            ],
            "backgroundColor":"#e10e0e",
            "navigationColor":"#67d58e",
            "headingColor":"#ae4242",
            "bodyTextColor":"",
            "googleFont":"Roboto"
          };
          try{
             // addMessage('Understanding what colors would suit ..')
              let theColorsTaken = await getWebsiteColors(name,industry);
              const theColors = JSON.parse(theColorsTaken);
              theWebsiteSettings['navigationColor'] = theColors['navigationBarColor'];
              header['websiteSettings'] = theWebsiteSettings;
              console.log(header)
              console.log(theColorsTaken)
          } catch (error) {
              console.log('error in website Settings')
              console.log(error.message)
          }
          
         // addMessage('Creating Menus ..')
          const theMenus = await getMenus(name,industry);
          const menus = theMenus.map((menu) => {
            if(Array.isArray(menu)) {
            }
            menu['label'] = menu.menu;
            menu['link'] = "#"+menu.menu.replace(' ','+');
            menu['type'] = 'section';
            menu['section'] = '1234';
            return menu;
          }).filter((element) => element !== undefined);

          header['menus'] = menus;

         // addMessage('Generating Logo ..')
          const logo = await generateLogo(name,industry);
          header['customLogo'] = (logo && logo.data && logo.data.length>0) ? logo.data[0] : null;
         // addMessage('Generating Blocks ..')
      try{
         // addMessage('Generating Testimonials ..')
          testimonial = await getTestimonialsBlock(name,industry)
      } catch (error) {
          console.log('error in testimonial')
          console.log(error.message)
      }
      
      const homePageBlocks = pickRandomArray([header,banner,service,testimonial,about,footer],[header,banner,testimonial,service,about,footer]);

          console.log(homePageBlocks)
          let newPageData = pageData;
          newPageData['blocks'] = homePageBlocks;
          savePage('Home',newPageData);
         // addMessage('Preparing View ..')
          return newPageData;

      } catch (error) {
          console.log('error in fetchHomePageData')
          console.log(error.message)
      }
  }
}
*/