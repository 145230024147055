import { useState, useEffect, useRef } from 'react';
import { Block } from '../components/Block';
import DOMPurify from 'dompurify';

const Home  = (props) => {
    const sanitizedHtml = DOMPurify.sanitize(props.texts);
    const [editableContent, setEditableContent] = useState(sanitizedHtml);
    const contentRef = useRef(null);

    const handleEditableContentChange = (event) => {
      setEditableContent(event.target.innerHTML);
    };
    /*
    useEffect(() => {
      const saveCursorPosition = () => {
        const selection = window.getSelection();
  
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(contentRef.current);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          const caretOffset = preCaretRange.toString().length;
  
          contentRef.current.focus();
  
          const updatedRange = document.createRange();
          updatedRange.setStart(contentRef.current, caretOffset);
          updatedRange.setEnd(contentRef.current, caretOffset);
  
          selection.removeAllRanges();
          selection.addRange(updatedRange);
        }
      };
  
      contentRef.current.addEventListener('input', saveCursorPosition);
  
      return () => {
        contentRef.current.removeEventListener('input', saveCursorPosition);
      };
    }, []);
    */
    // 
    return (
      <>
        <div className='App' contentEditable={true} onInput={handleEditableContentChange} ref={contentRef} dangerouslySetInnerHTML={{ __html: editableContent }} />
        <Block children={editableContent}></Block>
      </>
    );
};
    
export default Home;
