import React, { useEffect, useRef, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { TextComponent } from './Parts/TextComponent';
import { ImageComponent } from './Parts/ImageComponent';
import { CraftifiedComponent } from './Parts/CraftifiedComponent';
import { BoxComponent } from './Parts/BoxComponent';
import { fetchImage } from '../../../helpers/prompts';
import { getBlankBlockDataWithAIContent } from '../../../helpers/sections';
import { resolvePath } from 'react-router-dom';
import { PlaceholderComponent } from './PlaceholderComponent';

export const ToolPalette = () => {
  const { connectors: { create } } = useEditor();
  const [apiData, setApiData] = useState(null);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const textRef = useRef(null);
  const imageRef = useRef(null);
  const imageRef2 = useRef(null);
  const craftifiedRef = useRef(null);
  const awardsRef = useRef(null);
  const statsRef = useRef(null);
  const statsUpdateRef = useRef(null);
  const heroRef = useRef(null);
  const listRef = useRef(null);
  const boxRef = useRef(null);


  const [tiktok, setTiktok] = useState(0);

  // Re-bind the connector when `tiktok` changes.
  useEffect(() => {
    if (craftifiedRef.current) {
      create(craftifiedRef.current, 
        <CraftifiedComponent block={{
          'headline': 'Awards ' + tiktok,
          'content': 'Our Awards goes here',
          items: [{
            title: 'Design Award',
            content: 'Amazing Award contribution',
            year: 2003
          }]
        }} />
      );
    }
  }, [tiktok, create]);

  // This is to bind the connector once to the ref.
  useEffect(() => {
    if (boxRef.current) {
      create(boxRef.current, <BoxComponent style={{}} />);
    }
    if (textRef.current) {
      create(textRef.current, <TextComponent text={'We are amazing'} />);
    }
    if (imageRef.current) {
      create(imageRef.current, <ImageComponent />);
    }
    if (imageRef2.current) {
      create(imageRef2.current, <ImageComponent />);
    }
    if (awardsRef.current) {
      create(awardsRef.current, 
        <CraftifiedComponent block={{
          WebsiteBlock: {
            type: 'awards',
          },
          'headline': 'Awards ' + tiktok,
          'content': 'Our Awards goes here',
          items: [{
            title: 'Design Award',
            content: 'Amazing Award contribution',
            year: 2003
          }]
        }} />        
      );
    }
    if (statsRef.current) {
      create(statsRef.current, 
        <CraftifiedComponent block={{
          WebsiteBlock: {
            type: 'stats',
          },
          'headline': 'Stats ',
          'content': 'Our Stats goes here',
          items: [{
            title: 2003,
            content: 'Customers served',
            type: 'number'
          }]
        }} />        
      );
    }
    if (heroRef.current) {
      create(heroRef.current, 
        <CraftifiedComponent block={{
          WebsiteBlock: {
            type: 'hero',
          },
          'headline': 'Stats ',
          'content': 'Our Stats goes here',
          items: [{
            title: 2003,
            content: 'Customers served',
            type: 'number'
          }]
        }} />        
      );
    }
    if (listRef.current) {
      create(listRef.current, 
        <CraftifiedComponent block={{
          WebsiteBlock: {
            type: 'list',
          },
          componentDesign:{
            identifier: 'bottomRightImage',
          },
          'headline': 'Services ',
          'content': 'Our Services',
          items: [{
            title: 2003,
            content: 'Customers served',
            button:{
              label: 'number go',
            },
          }]
        }} />        
      );
    }
    if(statsUpdateRef.current){
      fetchData('stats').then((resData) => {
        if (resData) {
              create(statsUpdateRef.current, <CraftifiedComponent block={resData} />);
          }

      });
    }
  }, [create]);

    // Fetch data from API
    /*
    const fetchData = async () => {
      try {
        await delay(5000);
        const theImage = await fetchImage('office');
        console.log(theImage);
        setApiData(theImage);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Set default data or handle error
      }
    };
    */
    const fetchData = async (componentType='image') => {
      try {
        await delay(5000);
        if(componentType==='image'){
          const theImage = await fetchImage('office');
          console.log(theImage);
          setApiData(prevState => {
            // Check if the new image URL is different from the previous state
            if (prevState?.url !== theImage?.url) {
              return theImage;
            }
            return prevState;
          });
          return theImage;
        } else{
          const blockData = await getBlankBlockDataWithAIContent(componentType);
          console.log(blockData);
          setApiData(blockData);
          return blockData;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Set default data or handle error
      }
    };
    
    /*
    // This can be triggered by a button or any other event
    const handleCreateComponent = (ref, componentType) => {
      fetchData(componentType).then((res) => {
        if (ref.current) {
          switch (componentType) {
            //case 'text':
            //  create(ref.current, <TextComponent text={apiData.text} />);
            //  break;
            case 'image':
              create(ref.current, <ImageComponent src={apiData?.url} />);
              break;
            case 'stats':
              create(ref.current, <CraftifiedComponent block={apiData} />);
              break;
            // Add cases for other component types as needed
            default:
              break;
          }
        }
      });
    };
    */

    /*
    const handleCreateComponent = async(ref, componentType) => {
      const resData = await fetchData(componentType);
        if (ref.current && resData) {
          console.log('in pp',apiData,resData)
          switch (componentType) {
            case 'image':
              create(ref.current, <ImageComponent src={apiData.url} />);
              break;
            case 'stats':
              return create(ref.current, <CraftifiedComponent block={resData} />);
              break;
            // ... other cases
            default:
              break;
          }
        }
    };
    */
    const handleCreateComponent = async (ref, componentType) => {
      const resData = await fetchData(componentType);
      if (ref.current && resData) {
        console.log('Fetched data:', resData);
        switch (componentType) {
          case 'image':
            create(ref.current, <ImageComponent src={resData.url} />);
            break;
          case 'stats':
            create(ref.current, <CraftifiedComponent block={resData} />);
            break;
          // ... other cases
          default:
            break;
        }
      }
    };
    

const handleCreateComponentNewRi = async (ref, componentType) => {
  // First, insert a placeholder component
  if (ref.current) {
    create(ref.current, <PlaceholderComponent />);
  }

  // Then fetch the data
  const resData = await fetchData(componentType);
  if (ref.current && resData) {
    console.log('Fetched data:', resData);
    switch (componentType) {
      case 'image':
        create(ref.current, <ImageComponent src={resData.url} />);
        break;
      case 'stats':
        create(ref.current, <CraftifiedComponent block={resData} />);
        break;
      // ... other cases
      default:
        break;
    }
  }
};

    

  return (
    <div className="tool-palette" style={{position: 'fixed', width: '200px'}}>
      <div ref={boxRef} style={{ cursor: 'grab' }}>Box Block</div>
      <div ref={textRef} style={{ cursor: 'grab' }}>Text Block</div>
      <div ref={imageRef2} style={{ cursor: 'grab' }}>Image Block</div>
      <div ref={awardsRef} style={{ cursor: 'grab' }}>Awards Block</div>
      <div ref={statsRef} style={{ cursor: 'grab' }}>Stats Block</div>
      <div ref={heroRef} style={{ cursor: 'grab' }}>hero Block</div>
      <div ref={listRef} style={{ cursor: 'grab' }}>List Block</div>
      <div ref={imageRef} onMouseDown={() => handleCreateComponent(imageRef, 'image')} style={{ cursor: 'grab' }}>Image Block with Data auto update</div>
      <div ref={statsUpdateRef} style={{ cursor: 'grab' }}>Stats Block with Data auto update</div>

      <button onClick={() => setTiktok(tiktok+1)}>+</button>
    </div>
  );
}
