import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { createOne, deleteOne, findAll, updateOne } from "../../data/BaseDbOps";

//create action
export const createWebsite = createAsyncThunk(
    "createWebsite",
    async (data, { rejectWithValue }) => {
      console.log("create website data", data);
      try {
        const response = await createOne('website',data);  
        return response;
      } catch (error) {
        return rejectWithValue({errorMessage: error});
      }
    }
);
//edit action
export const updateWebsite = createAsyncThunk(
    "updateWebsite",
    async ({id, data}) => {
      console.log("update website data",id, data);
      try {
        const response = await updateOne('website',data,id);
        return response;
      } catch (error) {
        return {errorMessage: error};
      }
    }
);

export const getAllWebsites = createAsyncThunk(
    "getAllWebsites",
    async (args, { rejectWithValue }) => {
      console.log("args", args);
      let query = [];
      if(args?.startFromId){
        query = [['id','=',args.startFromId]];
        //
      }
      try {
        const response = await findAll('website','*,webpages:page(*)',query);
        return response;
      } catch (error) {
        return rejectWithValue({errorMessage: error});
      }
    }
);

export const getAllWebsitePages = createAsyncThunk(
    "getAllWebsitePages",
    async ({websiteId}) => {
      console.log("create website data",websiteId);
      try {
        const response = await findAll('page','*',[['website_id','=',websiteId]]);  
        return response;
      } catch (error) {
        return {errorMessage: error};
      }
    }
);
export const createWebsitePage = createAsyncThunk(
    "createWebsitePage",
    async ({websiteId, data}) => {
      console.log("create website data",websiteId, data);
      data['website_id'] = websiteId;
      try {
        const response = await createOne('page',data);
        return response;
      } catch (error) {
        return {errorMessage: error};
      }
    }
);
export const updateWebsitePage = createAsyncThunk(
    "updateWebsitePage",
    async ({id, websiteId, data}) => {
      console.log("create website data",id, websiteId, data);
      // we should first check if that belongs here
      try {
        const response = await updateOne('page',data,id);
        return response;
      } catch (error) {
        return {errorMessage: error};
      }
    }
);
export const createWebsitePageDefault = createAsyncThunk(
    "createWebsitePageDefault",
    async ({websiteId, data, postDo}) => {
      console.log("create website data",websiteId, data);
      data['website_id'] = websiteId;
      try {
        const response = await createOne('page',data);
        return {...response,postDo};
      } catch (error) {
        return {errorMessage: error};
      }
    }
);
export const updateWebsitePageDefault = createAsyncThunk(
    "updateWebsitePageDefault",
    async ({id, websiteId, data, postDo}) => {
      console.log("update website data",id, websiteId, data);
      // we should first check if that belongs here
      try {
        const response = await updateOne('page',data,id);
        console.log(response)
        return {...response,postDo};
      } catch (error) {
        return {errorMessage: error};
      }
    }
);
export const deleteWebsitePageDefault = createAsyncThunk(
    "deleteWebsitePageDefault",
    async ({id, websiteId, postDo}) => {
      console.log("delete website data",id, websiteId);
      // we should first check if that belongs here
      try {
        const response = await deleteOne('page',id);
        if(response===true){
          return {id: parseInt(id), website_id: parseInt(websiteId), postDo}
        }
        console.log(response)
        return response;
      } catch (error) {
        return {errorMessage: error};
      }
    }
);

const websiteSlice = createSlice({
    name: "websites",
    initialState: {
        websites: [],
        websitePages: [],
        isLoading: false,
        error: null,
        searchData: [],
        currentEditedWebsiteId: null,
        currentEditedWebsite: {},
        lastCreatedWebsiteId: null,
        currentEditedWebsitePageId: null,
        currentEditedWebsitePageSectionIndex: false,
        currentEditedWebsitePage: {},
        lastCreatedWebsitePageId: null,
    },
    reducers: {
        addWebsite: (state, action) => {
            state.websites.push(action.payload);
        },
        setCurrentEditedWebsitePageId: (state, action) => {
            console.log('inside set page',action);
            state.currentEditedWebsitePageId = action.payload;
        },
        setCurrentEditedWebsitePageSectionIndex: (state, action) => {
            console.log('inside set page section index',action);
            state.currentEditedWebsitePageSectionIndex = action.payload;
        },
    },
    extraReducers: {
        [getAllWebsites.pending]: (state) => {
          state.isLoading = true;
        },
        [getAllWebsites.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websites = action.payload;
        },
        [getAllWebsites.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [createWebsite.pending]: (state) => {
          state.isLoading = true;
        },
        [createWebsite.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websites.push(action.payload);
          state.lastCreatedWebsiteId = action.payload.id;
        },
        [createWebsite.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [updateWebsite.pending]: (state) => {
          state.isLoading = true;
        },
        [updateWebsite.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websites = state.websites.map((ele) =>
            ele.id === action.payload.id ? action.payload : ele
          );
        },
        [updateWebsite.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        // pages
        [getAllWebsitePages.pending]: (state) => {
          state.isLoading = true;
        },
        [getAllWebsitePages.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websitePages = action.payload;
        },
        [getAllWebsitePages.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [createWebsitePage.pending]: (state) => {
          state.isLoading = true;
        },
        [createWebsitePage.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websitePages.push(action.payload);
          state.lastCreatedWebsitePageId = action.payload.id;
        },
        [createWebsitePage.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [updateWebsitePage.pending]: (state) => {
          state.isLoading = true;
        },
        [updateWebsitePage.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websitePages = state.websitePages.map((ele) =>
            ele.id === action.payload.id ? action.payload : ele
          );
        },
        [updateWebsitePage.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [createWebsitePageDefault.pending]: (state) => {
          state.isLoading = true;
        },
        [createWebsitePageDefault.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.websites = state.websites.map(website => {

            if (website.id === action.payload.website_id) {
              return {
                ...website,
                webpages: [...website.webpages, action.payload]
              };
            }
            return website;
          })
          state.lastCreatedWebsitePageId = action.payload.id;
          if(action.payload.postDo==='SETEDIT'){
            state.currentEditedWebsiteId = action.payload.id;
          }
        },
        [createWebsitePageDefault.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [updateWebsitePageDefault.pending]: (state) => {
          state.isLoading = true;
        },
        [updateWebsitePageDefault.fulfilled]: (state, action) => {
          state.isLoading = false; // now here open the website and then
          state.websites = state.websites.map(website => {
            console.log(action)
            if (website.id === action.payload.website_id) {
              return {
                ...website,
                webpages: website.webpages.map(page => 
                  page.id === action.payload.id ? action.payload : page
                )
              };
            }
            return website;
          });
        },
        [updateWebsitePageDefault.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [deleteWebsitePageDefault.pending]: (state) => {
          state.isLoading = true;
        },
        [deleteWebsitePageDefault.fulfilled]: (state, action) => {
          console.log(action);
          state.isLoading = false; // now here open the website and then
          state.websites = state.websites.map(website => {
            if (website.id !== action.payload.website_id) return website;          
            return {
              ...website,
              webpages: website.webpages.filter(page => page.id !== action.payload.id)
            };
          });
          state.currentEditedWebsitePageId = (state.currentEditedWebsiteId===action.payload.id) ? null : state.currentEditedWebsiteId;
        },
        [deleteWebsitePageDefault.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
    },
});

export const getWebsitesSelector = createSelector(
    (state) => state.websites,
    (state) => state
)

export const {addWebsite} = websiteSlice.actions;
export const {setCurrentEditedWebsitePageId} = websiteSlice.actions;
export const {setCurrentEditedWebsitePageSectionIndex} = websiteSlice.actions;

export default websiteSlice.reducer;