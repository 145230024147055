import PublicTemplate from "../../templates/Public";

// Pricing Page
  const PricingPage = () => {
    const headerContent = <h1>Our Pricing Options</h1>;
    const footerContent = <p>Contact us for custom plans</p>;
  
    return (
      <PublicTemplate headerContent={headerContent} footerContentTwo={footerContent}>
        <h2>Pricing Page</h2>
        <p>View our available pricing options.</p>
      </PublicTemplate>
      
    );
  };

  export default PricingPage;