import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  width: 100%;
`;

const NavLink = styled(Link)`
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
`;

const Navigation = () => {
  
  const [NavigationBarColor, setNavigationBarColor] = useState('#676890');

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('websiteSettings'));
    console.log('update');
    if(settings && settings.navigationColor){
      console.log('update in');
      setNavigationBarColor(settings.navigationColor);
    }
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleStorageChange = event => {
    console.log('strorage change')
    if (event.key === 'websiteSettings') {
      console.log('strorage change in')
      const newSettings = JSON.parse(event.newValue);
      setNavigationBarColor(newSettings.navigationColor);
    }
  };

  return (
    <NavContainer backgroundColor={NavigationBarColor}>
      <NavLinks>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <NavLink to="/editor">Editor</NavLink>
        <NavLink to="/builder">Builder</NavLink>
        <NavLink to="/blocks">Blocks</NavLink>
      </NavLinks>
    </NavContainer>
  );
};

export default Navigation;
