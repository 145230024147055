import { Button, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';
import { GriderLayout } from './GriderLayout';

const FooterForm = ({ data, onUpdate }) => {
  const [footerData, setFooterData] = useState(data || {});

  const handleSave = () => {
    onUpdate(footerData);
  };

  return (
    <div>
      <h2>Footer Form</h2>
      <TextField 
          variant="outlined"
          label={'Business Name'}
          fullWidth 
          style={{ marginTop: 11 }}
        value={footerData.businessName}
        onChange={(e) => setFooterData({ ...footerData, businessName: e.target.value })}
      />
      <GriderLayout 
        left={'Show Logo:'}
        right={<input
          type="checkbox"
          checked={footerData.showLogo}
          onChange={(e) => setFooterData({ ...footerData, showLogo: e.target.checked })}
        />}
      />
      <GriderLayout left={'Style:'}
        right={<select
          value={footerData.style}
          onChange={(e) => setFooterData({ ...footerData, style: e.target.value })}
          >
            <option value="simple-left">Simple Left</option>
            <option value="simple-right">Simple Right</option>
            <option value="complex">Complex</option>
          </select>}
      />
      <label></label>
      <TextareaAutosize
        variant="outlined"
        label={'Additional Details:'}
        name="content"
        minRows={4}
        aria-label="Additional Details:"
        placeholder="Additional Details:"
        value={footerData.additionalDetails}
        onChange={(e) => setFooterData({ ...footerData, additionalDetails: e.target.value })}
        style={{width: '100%'}}
      />
      <GriderLayout
        left={'Show Social Links:'}
        right={<input
          type="checkbox"
          checked={footerData.showSocial}
          onChange={(e) => setFooterData({ ...footerData, showSocial: e.target.checked })}
          />}
      />
      <GriderLayout
        left={'Made with ContentSimi:'}
        right={<input
          type="checkbox"
          checked={footerData.madeWithContentSimi}
          onChange={(e) => setFooterData({ ...footerData, madeWithContentSimi: e.target.checked })}
          />}
      />
      
      <TextField 
          variant="outlined"
          label={'Background Color:'}
          fullWidth 
          style={{ marginTop: 11 }}
        value={footerData.background}
        onChange={(e) => setFooterData({ ...footerData, background: e.target.value })}
      />
      
      <TextField 
          variant="outlined"
          label={'Button Label:'}
          fullWidth 
          style={{ marginTop: 11 }}
        value={footerData.button.label}
        onChange={(e) =>
          setFooterData({ ...footerData, button: { ...footerData.button, label: e.target.value } })
        }
      />
      
      <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Button Link:'}
          value={footerData.button.link}
          onChange={(e) =>
            setFooterData({ ...footerData, button: { ...footerData.button, link: e.target.value } })
          }
      />
      <FormControl style={{ marginTop: 11 }} fullWidth>
        <InputLabel>Button Type:</InputLabel>
        <Select
            value={footerData.button.type}
            onChange={(e) =>
            setFooterData({ ...footerData, button: { ...footerData.button, type: e.target.value } })
            }
        >
          <MenuItem value={'page'}>Page</MenuItem>
          <MenuItem value={'external'}>External</MenuItem>
        </Select>
      </FormControl>
      <TextField 
          variant="outlined"
          label={'Button Page:'}
          fullWidth 
          style={{ marginTop: 11 }}
        value={footerData.button.page}
        onChange={(e) =>
          setFooterData({ ...footerData, button: { ...footerData.button, page: e.target.value } })
        }
      />
      <GriderLayout left={'Hide'} 
        right={<input
          type="checkbox"
          value={true}
          checked={footerData.isHidden}
          onChange={(e) => setFooterData({ ...footerData, isHidden: e.target.checked})}
        />} 
      />
      <Button onClick={handleSave} fullWidth variant='outlined' color='primary' className='my-3'>Save</Button>
    </div>
  );
};

export default FooterForm;
