import React from 'react';
import styled from 'styled-components';
import HeaderNavigationBlock from './HeaderNavigationBlock';
import { hexToRgba } from '../../helpers/colors';

let HeaderContainer = {
  //backgroundColor: '#f2f2f2',
  padding: '20px',
  textAlign: 'center',
  margin: 0,
  color: 'aliceblue',
};

const HeaderData = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051aa",
        "description": "Website header with logo and links",
        "type": "header",
        "active": true,
        "name": "Header"
    },
    "siteName": "ZenoCorp",
    "showLogo": false,
    "source": "branding",
    "customLogo": null,
    "logoDesktop": {
        "height": 80
    },
    "logoMobile": {
        "height": 40
    },
    "showSocial": true,
    "style": "left",
    "fullWidth": true,
    "sticky": false,
    "background": "#3D9970",
    "button": {
        "label": "Call Us",
        "link": "#",
        "type": "url"
    }
};

const HeaderBlock = ({block, blockId}) => {
  console.log(block,'in header rendering');
  const some = Math.random();

  const getRandomValueFromArray = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const getRenderHeaderType = () => {
    const headerDesigns = ['fullblown','classic'];
    const randomValue = getRandomValueFromArray(headerDesigns);
    return randomValue;
  }

  const designType = 'fullblown' // getRenderHeaderType();

  if(designType=='fullblown'){

    HeaderContainer = {
      ...HeaderContainer,
      position: 'fixed',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      zIndex: 2000,
      //backgroundColor: (block && block.websiteSettings && block.websiteSettings.navigationColor) ? hexToRgba(block.websiteSettings.navigationColor, 0.5) : 'transparent',
    };
  }
  
  if(block.background){
    HeaderContainer = {
      ...HeaderContainer,
      background: block.background,
    }
  }

  //if(block.alignment)

  console.log(HeaderContainer)

  return (
    <>
    {(designType==='classic') ? 
    <>
      <div style={HeaderContainer}>
        {(block.customLogo && block.customLogo.html) ? <span className='h2' dangerouslySetInnerHTML={{ __html: block.customLogo.html }} /> : <h1>{block.siteName}</h1>}
      </div>
      <HeaderNavigationBlock websiteSettings={block && block.websiteSettings && block.websiteSettings} menus={block && block.menus && block.menus} />
    </> : ''
    }
    {(designType==='fullblown') ? 
    <>
      <div style={HeaderContainer} className='row headertransp1arentbg mb-5'>
        <div className='col-3 d-flex align-items-center'>
          {(block.customLogo && block.customLogo.html) ? <span className='h2' dangerouslySetInnerHTML={{ __html: block.customLogo.html }} /> : <h1>{block.siteName}</h1>}
        </div>
        <div className='col-9'>
          <HeaderNavigationBlock websiteSettings={block && block.websiteSettings && block.websiteSettings} menus={block && block.menus && block.menus} designType={designType} />
        </div>
      </div>
    </> : ''
    }    
    {block.globalCSS && <style>{block.globalCSS.replace("\n","")}</style>}
    </>
  );
};

export default HeaderBlock;
