import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { generateRandomPath, getRandomIpsumLogo, getRandomRadius } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const ProcessBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className='row py-5'>
            <div className='col-md-4'>
              <div className="row">
                <div className='fw-bold col display-3'>{theblock.headline}</div>
                <div className='col-md-12 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
              </div>
            </div>
            <div className='col-md-8'>
                {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
                <div className="row px-5 d-flex justify-content-center">
                    <div className='col-md-3 text-end'>
                        <h3 className='h1'>{index+1}</h3>
                    </div>
                    <div key={index} className={`col-md-9 col-sm-12 mb-3`}>
                        {column.image &&  
                            <div className="random-path-image my-3" style={{ clipPathi: generateRandomPath() }}>
                                <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{borderRadiusi: getRandomRadius()}} />
                            </div>
                        }
                        <h4>{column.title}</h4>
                        <p><MarkdownRenderer markdownString={column.content} /></p>
                    </div>
                </div>
                ))}
            </div>
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default ProcessBlock;