import { Button, TextField, TextareaAutosize } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { cleanDomain, generateSlug, isValidDomain } from '../../../helpers/randoms';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsite } from '../../redux/slices/websiteSlice';
import axios from 'axios';
import { MessageContext } from '../../contexts/MessageContext';

const AnalyticsForm = ({ data = {}, websiteId, onUpdate }) => {
  console.log('in page form: new',data);
  //const [slugEditedManually, setSlugEditedManually] = useState(false);
  const [websiteData, setWebsiteData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [attachingDomain, setAttachingDomain] = useState(false);

  const [settings, setSettings] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { addMessage } = useContext(MessageContext);
  
  const currentEditedWebsitePageId = useSelector(state => state.websites.currentEditedWebsitePageId);
  const theWbsiteData = useSelector(state => state.websites.websites.find(website => website.id === parseInt(websiteId)));

  const handleSave = async() => {   
    if(!websiteId){
      addMessage('Some issue occured, report us.');
      return;
    } 
    if(!settings){
      addMessage('Some issue occured, report us.');
      return;
    }
      setIsLoading(true);
      addMessage('');
      dispatch(updateWebsite({id: websiteId, data: {settings: settings}}))
      setIsLoading(false);
      addMessage(`Your analytics settings is updated`)
  }

  useEffect(() => {
    setWebsiteData(theWbsiteData)
    setSettings(theWbsiteData?.settings)
  }, [theWbsiteData]);


  return (
    <div>
        <>
          <h2>Google analytics</h2>
          <label>
            <TextField 
              variant="outlined"
              fullWidth 
              style={{ marginTop: 11 }}
              label={'Measurement ID'}
              value={settings && settings.analytics ? settings.analytics.google : null}
              onChange={(e) => setSettings({
                ...settings,
                analytics: {
                    ...settings.analytics,
                    google: e.target.value
                }
            })}
            />
          </label>
          <div>
            <div>Get your Google analytics measurement ID. Watch Tutorial</div>
          </div>
          <br />
          
          <h2>Facebook analytics/ads</h2>
          <label>
            <TextField 
              variant="outlined"
              fullWidth 
              style={{ marginTop: 11 }}
              label={'Pixel ID'}
              value={settings && settings.analytics && settings.analytics.facebook ? settings.analytics.facebook : null}
              onChange={(e) => setSettings({
                ...settings,
                analytics: {
                    ...settings.analytics,
                    facebook: e.target.value
                }
            })}
            />
          </label>
          <div>
            <div>Get your Facebook pixel ID. Watch Tutorial</div>
          </div>
          <br />
          <Button onClick={handleSave} fullWidth className='my-3' variant='outlined' color='primary' disabledi={(isLoading) ? true: false}>Save</Button>
        </>
    </div>
  );
};

export default AnalyticsForm;
