export const generateUniqueClassName = (base, index) => `${base}-${index}`;

export const generateCSS = (styles, hoverStyles, className) => {
  const cssRules = Object.entries(styles).map(([key, value]) => `${key}: ${value};`).join(' ');
  
  let hoverCss = '';
  if (hoverStyles) {
    const hoverRules = Object.entries(hoverStyles).map(([key, value]) => `${key}: ${value};`).join(' ');
    hoverCss = `.${className}:hover { ${hoverRules} }`;
  }

  return `
    .${className} { ${cssRules} }
    ${hoverCss}
  `;
};

export const parseLayoutJSON = (layoutJson) => {
  const layout = JSON.parse(layoutJson);
  let html = '';
  let css = '';

  layout.section.content.forEach((item, index) => {
    const className = generateUniqueClassName(item.type, index);
    html += generateHTML(item, className);
    css += generateCSS(item.styles, item.hoverStyles, className);
  });

  return { html, css };
};

export const generateHTML = (item, className) => {
  switch (item.type) {
    case 'text':
      return `<${item.tag} class="${className}">${item.text}</${item.tag}>`;
    case 'image':
      return `<img src="${item.src}" alt="${item.alt}" class="${className}" />`;
    case 'button':
      return `<button class="${className}">${item.text}</button>`;
    default:
      return '';
  }
};

// Example usage
//const { html, css } = parseLayoutJSON(yourJsonString);
