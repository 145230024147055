// new form, on save 

import { useDispatch, useSelector } from "react-redux";
import { addWebsite, createWebsite, getAllWebsites, getWebsitesSelector, updateWebsite } from "../../redux/slices/websiteSlice";
import { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Col } from "react-bootstrap";
import { AppBar, Button, Checkbox, FormControlLabel, InputBase, Paper, Radio, TextField, Toolbar, Typography } from "@mui/material";
import { generateText } from "../../../helpers/prompts";
import { supabase } from "../../../supabase";
import { createBlock, getFooterBlock, getHeaderBlock, getHomePageDesign, initializeBlockData } from "../../../helpers/websiteCreate";
import { MessageContext } from "../../contexts/MessageContext";
import SingleWebsitePreview from "./SingleWebsitePreview";
import PlaceholderList from "../../components/PlaceholderList";

import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import { ArrowCircleDownOutlined, ArrowDropDownCircleSharp } from "@mui/icons-material";
import { adjectives, animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import { SUBDOMAIN_TAIL } from "../../../lib/config";
import axios from "axios";
import { getAllTemplates } from "../../../helpers/templateChoices";
import LottieAnimations from "../../components/LottieAnimations";
import { getAllComponents } from "../../../helpers/templateComponents";
//import namor from "namor"

// form can have the savings. form might not too.
// id paased to form, form pulls data from db
// form update stuffs, if saved, it'll save and push id data to parent too.
// if id data not 

const CreateWebsiteForm = ({onStepChange}) => {
    const { addMessage } = useContext(MessageContext);
    const [step,setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
    const [isSubmitProgress, setIsSubmitProgress] = useState(false);
    const [formData, setFormData] = useState({});
    const [nextQuestions, setNextQuestions] = useState(null);
    const [services, setServices] = useState([]);
    const [generateBlocksActive, setGenerateBlocksActive] = useState(false); //false
    const [isSubmit, setIsSubmit] = useState(false); //false
    const {websites, lastCreatedWebsiteId, currentEditedWebsite, currentEditedWebsiteId} = useSelector(state => state.websites);
    const [blocks, setBlocks] = useState([]);
    // useEffect setFormData if id is given and provided
    // 
    const [siteId, setSiteId] = useState((lastCreatedWebsiteId && lastCreatedWebsiteId>0) ? lastCreatedWebsiteId : null);

    useEffect(() => {
        setSiteId(lastCreatedWebsiteId);
      }, [lastCreatedWebsiteId]);

    const internalChatPrompt = () => {
        // We are a AI website builder
        // You are a supper-helpful and efficient chat assistant
        // Customer can be a business, a person or NGOs etc.
        // We can't offer e-commerce store for now or any app, just simple websites.
        // For business, We need to understand the customerName, their industry, what services they offer, who are their target audience, why they are better, who are the founders, what do they do in the business etc.
        // For a person they might be building portfolio website, or creating something for resume etc, so their name etc.
        // 
    }


    const askQuestions = async (toStep) => {
        let theQuestion = null;
        const donotAsk = 'Don\'t ask questions related to their budget, timeline or too much details on design aspect.';
        const prePrep = `We are a AI website builder
        You are a supper-helpful and efficient chat assistant
        Customer can be a business, a person or NGOs etc.
        We can't offer e-commerce store for now or any app, just simple websites.
        We don't offer hosting/domain on competitor platforms.
        For business, We need to understand the customerName, their industry, what services/products they offer, who are their target audience, why they are better, who are the founders, what do they do in the business etc.
        For an event website the theme, event details, host, speakers, pannel etc. etc.
        For a person they might be building portfolio website, or creating something for resume etc, so their name etc.
        Also try to understand the target country, so we can use tones understandable.
        Also understand if the site is of any of following category:
            An online store or eCommerce website to sell your products

            A portfolio website to showcase your art
            
            A business website to manage and grow your business  
            
            A resume website to grab the attention of potential employers
            
            A blog to share your knowledge with the world
            
            An event website for weddings, parties and company events
            
            A photography website to display and sell prints
            
            A fitness website to book new clients
            
            A restaurant website to help with online orders, delivery and payment
        
        ",
        `
        // when you collect enough information about the business as mentioned above, provide a command only output: "createWebsite
        //if(toStep>1 && toStep<=3){
            setIsQuestionsLoading(true);
            theQuestion = await generateText(
                "Follow instructions strictly. Here is a form filled by a customer for website design: ```"+JSON.stringify(formData)+"``` ask 3-5 followup question to better understand their requirement. Give in a correct JSON array. format [{question: \"Your question\", example: \"Give an example of answer\",type: \"text/checkbox/radio\",choices:[{choiceText: \"Option1\",meaning:\"little bit explaination\"},{choiceText: \"choice2\",meaning: \"\"}]}]. In case of services, give checkbox choices to choose from. Give blank array if no further question needed. "+donotAsk+". 3 max steps needed. Already in step number: "+step,
                false,
                false,
                prePrep,
                );
            setIsQuestionsLoading(false);
            /*if(!theQuestion || JSON.parse(theQuestion).length===0){
                setStep(4)
                askQuestions(4);
            }*/
        //} else if(toStep>=4){
            /*
            let extraPrompt = `Suggest the following based on your observation {pages : ['page1','page2'], homePageBlocks: [{type: 'hero',sectionHeader: 'New age of computing'], colorPallette: [], fontPair: [{name: 'Google Font 1',weight: '700'},{name: 'Google font 2',weight: '100,300'}]}. Give in a correct JSON.`;
            setIsQuestionsLoading(true);
            theQuestion = await generateText(
                "Follow instructions strictly. Here is a form filled by a customer for website design: ```"+JSON.stringify(formData)+"```. If it's a service based businesses, suggest 10 services, if products suggest 10 products to choose from, else give 10 subsections we can write. Don't output the input form. "+extraPrompt,
                false,
                false,
                prePrep
            );
            */
            setStep(step+1);
            onStepChange(step+1);
            if(theQuestion==='createWebsite' || theQuestion==='[]'){
                alert('Create website is pinged');
                setIsQuestionsLoading(false);
                //setNextQuestions(null)
                setGenerateBlocksActive(true)
                setIsSubmit(true)
                return null;
            } else if(toStep===5){
                setIsQuestionsLoading(false);
                setNextQuestions(null)
                //setServices(theQuestion)
                setGenerateBlocksActive(true)
                setIsSubmit(true)
                return null;
            }
        //} else{
        //    return null;
        //}
        /*
        switch(step){
            case (step>1) : 
            theQuestion = await generateText("Here is a form filled by a customer for website design: ```"+JSON.stringify(formData)+"``` ask some followup question to better understand their requirement. Give in an JSON array. format [{question: \"Your question\",type: \"text\"}]");
            break;
            default: 
            return null;
        }
        */
        console.log(theQuestion);
        setNextQuestions(JSON.parse(theQuestion))
    }
    const newData = (e) => {
        //setFormData({...formData, [e.target.name] : e.target.value});  
        /*   
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        }));
        */
        /*
            const [groupName, choice] = event.target.name.split('.');
        setSelectedChoices({
        ...selectedChoices,
        [groupName]: {
            ...selectedChoices[groupName],
            [choice]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        },
        });
        */ 

        const { name, type, checked, value } = e.target;
        const [groupName, choice] = name.split('.');

        if(type==='text'){
            setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            }));
        } else if(type==='checkbox' || type==='radio'){
            setFormData((prevFormData) => ({
                ...prevFormData,
                [groupName]: {
                ...prevFormData[groupName],
                [choice]: type === 'checkbox' ? checked : value,
                },
            }));
        }
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async () => {
        setIsSubmitProgress(true)
        let formDataJsonObject = {};
        formDataJsonObject.questions = {};
        Object.entries(formData).map(([key,value]) => {
            console.log('key', key, 'value',value);
            if (key.startsWith("questions['") && key.endsWith("']")) {
                // Extract the question name between the square brackets
                const questionKey = key.slice("questions['".length, -"']".length);
                //formDataJsonObject['questions'][questionKey] = value;
                if (typeof value === 'object' && value !== null) {
                    // Assuming the value is a simple object (not nested)
                    let keysWithTrueValue = Object.entries(value)
                      .filter(([key, val]) => val === true) // keep only entries with true value
                      .map(([key, val]) => key); // keep only the keys
                  
                    formDataJsonObject['questions'][questionKey] = keysWithTrueValue.join(', ');
                  } else {
                    formDataJsonObject['questions'][questionKey] = value;
                  }
            } else{
                formDataJsonObject[key] = value;
            }
        });
        console.log('Jsonform to submit', formDataJsonObject);

        // we will summerize the stuff too for company.
        const theSummary = await generateText(
            "The company has filled the following form and answered details. Summerize, so we can later create wesite and all pages. The form: ```"+JSON.stringify(formDataJsonObject)+"```. ",
            false,
            false,
            "You are a supper-helpful website designer"
        );

        
        //const newQuestions = JSON.parse(JSON.stringify(formData));
        //console.log(newQuestions.questions);
        //console.log('formData:',JSON.parse(JSON.stringify(formData)))
        const { data: { user } } = await supabase.auth.getUser()
        setIsSubmit(false)
        const newSubdomain = uniqueNamesGenerator({
            dictionaries: [adjectives, colors, animals],
            separator: '-',
            length: 2
        });

        try {
            const response = await axios.post('https://bvorqhdsibaukalonivs.supabase.co/functions/v1/add-domain-to-website/add-domain', { domain: `${newSubdomain}.${SUBDOMAIN_TAIL}` }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
              },
            });      
            //console.log(response.data.message);
         } catch (error) {
            //console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
        } finally {
            //setIsLoading(false);
            addMessage(`Preview URL created`)
        }
        //namor.generate({ words: 2, numbers: 1 });
        const submitDone = await dispatch(createWebsite({name: formData.companyName,formData: formDataJsonObject,owner: user.id, companySummary: theSummary, staging_domain: `${newSubdomain}.${SUBDOMAIN_TAIL}`}))
        setIsSubmitProgress(false)
        setIsSubmit(false)
        //setGenerateBlocksActive(true);
        //console.log('website created', lastCreatedWebsiteId);
        //setSiteId(lastCreatedWebsiteId);
    }

    console.log(siteId);

    const generateWebsite = async () => {
        // get the site Id and start creating.
        console.log('getting called again generateWebsite');
        //const theFormData = {companyName: 'San Inc', companyIndustry: 'Management Consulting', websiteType: 'business'};
        //const theFormData = {companyName: 'Interio', companyIndustry: 'Interior Decoration', websiteType: 'business'};
        const theFormData = formData;
        const theBlocks = getHomePageDesign();
        console.log('block designs:',theBlocks)
        setBlocks(theBlocks);
        /*
        const getBlockDatas = [];
        theBlocks.map(async (theBlock, index) => {
            addMessage('Creating a '+ theBlock.type+' block ...')
            const res = await createBlock(theFormData,theBlock.type);
            if(res && res !==null){
                console.log(theBlock.type,res);
                getBlockDatas.push(res);
                addMessage('Created a '+ theBlock.type+' block ...')
            }
        });
        console.log(getBlockDatas);
        addMessage('Completed')
        */


        let homePageData = initializeBlockData();
        const getBlockDatas = [];
        let allBlocksData = [];
        // get Header blocks
        const failProofGetHeader = async(theFormData,allBlocksData) => {
            try{
                addMessage('Thinking about Design, Fonts, Color & Menus ...');
                const headerBlockData = await getHeaderBlock(theFormData);
                console.log('getting called again failproof',headerBlockData);
                allBlocksData = addBlocksPeriodically(false,allBlocksData,1,headerBlockData);
                getBlockDatas.push(headerBlockData);
            } catch(error){
                addMessage('Encountered some error in design, fonts etc. retrying ...',error);
                console.error('error occurred',error)
                return await failProofGetHeader(theFormData,allBlocksData);
            }
        }
        await failProofGetHeader(theFormData,allBlocksData);
        // get Footer blocks
        addMessage('Thinking about Footer Block ...');
        const footerBlockData = await getFooterBlock(theFormData);
        allBlocksData = addBlocksPeriodically(false,allBlocksData,(theBlocks.length+2),footerBlockData);

        const promises = theBlocks.map(async (theBlock, index) => {
            addMessage('Creating a ' + theBlock.type + ' block ...');
            const fetchBlock = async (theFormData, theBlock) => {
                const res = await createBlock(theFormData, theBlock.type);
                if (res && res !== null) {
                    console.log(theBlock.type, res);
                    //getBlockDatas.push({index: index+1, data: res});
                    getBlockDatas.push(res);
                    allBlocksData = addBlocksPeriodically(false,allBlocksData,(index+2),res);
                    addMessage('Created a ' + theBlock.type + ' block ...');
                } else{
                    fetchBlock(theFormData,theBlock);
                }
            }
            await fetchBlock(theFormData,theBlock);
        });

        console.log('last website Id',lastCreatedWebsiteId);

        let theSiteId =  (lastCreatedWebsiteId && lastCreatedWebsiteId!==112) ? lastCreatedWebsiteId : 112;
        
        // Wait for all the asynchronous tasks to complete
        Promise.all(promises)
            .then( async() => {
                // This block will be executed after all the promises have been resolved
                console.log('working out: ',allBlocksData);
                console.log(getBlockDatas);
                addMessage('Completed');
                getBlockDatas.push(footerBlockData);
                homePageData['blocks'] = allBlocksData;
                console.log(homePageData);
                if(theSiteId){
                    console.log('pinging')
                    const { data: { user } } = await supabase.auth.getUser()
                    //updateWebsite(theSiteId, {data: JSON.stringify(homePageData)});
                    const resultAction = await dispatch(updateWebsite({id: theSiteId, data: {data: homePageData}}));
                
                    // Assuming resultAction has the website's id, either as a direct return or within a payload
                    //const websiteId = resultAction.payload.id; // adjust this line based on your action's return structure
                    navigate(`/dashboard/websites/preview/${theSiteId}`);
                }
            })
            .catch((error) => {
                // Handle any errors that occurred during the asynchronous tasks
                console.error('Error:', error);
            });
        
    }
    let theSiteId =  (lastCreatedWebsiteId && lastCreatedWebsiteId!==112) ? lastCreatedWebsiteId : 112;

    const addBlocksPeriodically = (pageId,data,blockLocation,blockData) => {
        // gets the page and bring the blocks
        // add the reorder and add the respective blocks in the order and save
        let newData = (data) ? data : [];
        let positionToInsert = blockLocation-1;
        let newArrayToAdd = blockData;
        //const newBlocksArray = [...blocks.slice(0, positionToInsert), ...newArrayToAdd, ...blocks.slice(positionToInsert)];
        /*
        if (Array.isArray(newArrayToAdd)) {
            // If newArrayToAdd is an array, use splice() to insert its elements into the blocks array
            newData.splice(positionToInsert, 0, ...newArrayToAdd);
          } else {
            // If newArrayToAdd is not an array, insert it as a single element at the specified position
            newData.splice(positionToInsert, 0, newArrayToAdd);
          }
        */
        newData[positionToInsert] = newArrayToAdd;
        return newData;
    }

    console.log('step',step)
    console.log('formData',formData)
    console.log('nextQuestions',nextQuestions)
    return (
        <div className="">
            {(isLoading) ? 'Loading ...' :
            <>
                <div>
                    <div className="chat-window-container" style={{ textAlign: 'center'}}>
                        <Typography variant="h5" style={{lineHeight: '80px',}}>
                            ContentSimi Website Builder
                        </Typography>
                        <Paper component="main" className="chat-window-content mx-md-auto" style={{textAlign: 'left'}}> 
                            {websites && websites.filter((mapwebsite) => mapwebsite.id === theSiteId).map((website,index) => (
                                <>
                                    {(website && website.formData && website.formData.questions) && Object.entries(website.formData.questions).map(([question, answer]) => (
                                        <span key={question}>
                                        <div className="d-divlock mb-2 text-start text-light bg-dark border p-3 me-3 d-none">
                                            <div  className="d-inline-block" ><img src="/logo.png" height={20} /></div>
                                            <div className="d-inline-block">{question}</div>
                                        </div>
                                        <div className="text-end mb-2 p-3 ms-3 d-none mt-3 mb-4">{answer}</div>
                                        </span>
                                    ))}
                                </>
                            ))}                
                            {(isSubmitProgress) ?                             
                            <div styles={{position: "absolute", bottom:0}} className="pb-3 d-block" >
                                Submitting your details ...
                                <LottieAnimations />
                            </div>
                            :  ''}             
                            {(isQuestionsLoading) ?                             
                            <div styles={{position: "absolute", bottom:0}} className="pb-3 d-block" >
                                Thanks, we are trying to see if we might need further information from you ...
                                <LottieAnimations />
                            </div>
                            :  
                            <>                      
                            <div styles={{position: "absolute", bottom:0}} >
                            {nextQuestions && nextQuestions.map((nextQuestion,index) => (
                                <div className="pb-3" key={index}>
                                    <b>{nextQuestion.question}</b>
                                    <span className="d-block small mt-3 mb-2">
                                        Eg: {nextQuestion.example}
                                        {(nextQuestion.type==='text' || nextQuestion.type==='' || !nextQuestion.type) ? 
                                        <span className="ms-3 text-info" onClick={() => 
                                            setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            [`questions['${nextQuestion.question}']`]: nextQuestion.example,
                                            }))}><ArrowCircleDownOutlined /></span>
                                         : <></>}
                                    </span>
                                    {(nextQuestion.type==='text' || nextQuestion.type==='' || !nextQuestion.type) ? 
                                        <TextField 
                                        label={nextQuestion.question} 
                                        fullWidth
                                        placeholder={nextQuestion.question}
                                        //name={`${group.name}.value`}
                                        //value={formData[group.name]?.value || ''}

                                        name={`questions['${nextQuestion.question}']`}
                                        value={formData[`questions['${nextQuestion.question}']`] || ''}
                                        onChange={(e) => newData(e)}
                                        />
                                    : ''}
                                    {nextQuestion.type === 'checkbox' && nextQuestion.choices.map((choice) => (
                                        <FormControlLabel
                                        key={choice.choiceText}
                                        control={
                                            <Checkbox
                                            checked={formData[`questions['${nextQuestion.question}']`]?.[choice.choiceText] || false}
                                            onChange={(e) => newData(e)}
                                            name={`questions['${nextQuestion.question}'].${choice.choiceText}`}
                                            />
                                        }
                                        label={choice.choiceText}
                                        />
                                    ))}                                    
                                    {nextQuestion.type === 'radio' && nextQuestion.choices.map((choice) => (
                                        <FormControlLabel
                                        key={choice.choiceText}
                                        control={
                                            <Radio
                                            checked={formData[`questions['${nextQuestion.question}']`]?.value === choice.choiceText}
                                            onChange={(e) => newData(e)}
                                            name={`questions['${nextQuestion.question}'].value`}
                                            value={choice.choiceText}
                                            />
                                        }
                                        label={choice.choiceText}
                                        />
                                    ))}


                                </div>
                            ))}
                            </div>                      
                            {services}
                            {blocks && blocks.map((block,index) => (
                                <div key={index}>
                                    Created : {block.type.toUpperCase()}
                                </div>
                            ))}
                            {(step<=1) ? 
                                <>
                                <TextField className="mb-2" fullWidth name="companyName" label="Your Company name" placeholder="Your Company name" value={formData.companyName} onChange={(e) => newData(e)} />
                                <TextField className="mb-2" fullWidth name="companyIndustry" label="What your business do?" placeholder="What your business do" value={formData.companyIndustry} onChange={(e) => newData(e)} />
                                </>
                                : ''
                            }
                            </>
                            }
                            {((step<=6) && !generateBlocksActive && !isSubmit) ? <Button className="btn-lg" variant="contained" color="primary" onClick={() => [setStep(step+1), askQuestions(step+1)]}>Next</Button> : <Button variant="contained" color="primary" onClick={handleSubmit}>Submit and Create</Button>}
                            {generateBlocksActive && !isSubmit && <Button className={'ms-2'} variant="contained" color="primary" onClick={generateWebsite}>Start Generating website</Button>}    
                            {(!generateBlocksActive && isSubmit) && <Button className={'ms-2'} variant="contained" color="primary" onClick={handleSubmit}>Submit and Create</Button>}
                            <Button className={'ms-2'} variant="contained" color="primary" onClick={generateWebsite}>Start Generating website</Button>
                        </Paper>
                        <div className="input-container col-md-2">
                            <InputBase
                            fullWidth
                            placeholder="Type your message..."
                            inputProps={{ 'aria-label': 'type your message' }}
                            />
                            <SendIcon className="send-icon mt-2" color="primary" />
                        </div>
                    </div>
                </div>
            
            </>}
        </div>
    )
}

// create website
    // form
    // on getting everything save
    // get the created ID
    // then take that ID and generate and store.
const templates = getAllTemplates();
const RandomizeTemplate = ({itemsArray}) => {
  const templateTypes = ['banner', 'list', 'quote', 'list', 'formblock'];
  const items = itemsArray || [1, 2, 3, 4, 5, 6];
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleItems((v) => {
        const nextIndex = v.length;
        if (nextIndex < items.length) {
          return [...v, items[nextIndex]];
        }
        clearInterval(interval);
        return v;
      });
    }, 10000);
    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <div className="row">
      {visibleItems.map((item, iIndex) => (
        <div className="col-md-3 p-2" key={iIndex}>
          <div className="border">
            {templateTypes.map((templateType, index) => {
              const templateDesigns = getAllComponents(templateType);
              const randomIndex = Math.floor(Math.random() * templateDesigns.length);
              return (
                <div key={index} className="border-bottom" role="button">
                  {templateDesigns[randomIndex].design}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const CreateWebsite = () => {
    const dispatch = useDispatch();
    const [stepNumber,setStepNumber] = useState(1);
    const [stepArray,setStepArray] = useState([]);
    //const websites = useSelector(getWebsitesSelector)
    const {websites,isLoading, searchData,lastCreatedWebsiteId} = useSelector(state => state.websites); //getAllWebsites);
    useEffect(() => {
      dispatch(getAllWebsites());
      console.log('you')
    }, []);
    console.log('websites',websites);
    console.log('loading',isLoading)
    const random = Math.random();    
    let theSiteId =  (lastCreatedWebsiteId && lastCreatedWebsiteId!==null) ? lastCreatedWebsiteId : null;
    const onStepChange = (stepNumberGiven) => {
        setStepNumber(stepNumberGiven);
        setStepArray(Array.from({ length: stepNumberGiven }, (_, i) => i + 1));
    }
    return <>    
        <div className='row'>
            <Col className="col-md-6">
                <CreateWebsiteForm onStepChange={onStepChange} />
            </Col>
            <Col className="col-md-6 p-5"> 
                <RandomizeTemplate itemsArray={stepArray} />
            </Col>
            {/*<Col>
                {isLoading ? 
                    <PlaceholderList type="newWebsiteRightOnly" />
                    :
                    <>
                    {websites && websites.filter((mapwebsite) => mapwebsite.id === theSiteId).map((website,index) => (
                        <div key={index}>
                            {!isLoading && <SingleWebsitePreview theSiteId={website.id} toRefresh={random}></SingleWebsitePreview>}
                        </div>
                    ))}
                    </>
                }
            </Col>*/}
        </div>
    </>
}

export default CreateWebsite;