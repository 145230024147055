import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { supabase } from '../supabase';

const AwsPublish = ({theContent, theSiteId, onDataUpdate}) => {
  const [siteUrl, setSiteUrl] = useState('');
  const [theBucketName, setTheBucketName] = useState('');
  
// create a bucket
// add upload the files
// assign our subdomain :: via our googledomains api
// set where it's published

const CreateBucket = (bucketName) => {
    console.log('in create bucket')
    return axios.post('http://localhost:3089/create-bucket', {
      bucketName,
    });
};

const UploadFile = (bucketName,folderName,fileName,fileContent) => {
  return axios.post('http://localhost:3089/upload-file', {
      bucketName,
      folderName,
      fileName,
      fileContent,
    });
};

const EnableHosting = (bucketName) => {
  return axios.post('http://localhost:3089/enable-hosting', {
      bucketName,
    });
};

const AddDomain = (bucketName,domainName) => {
    return axios.post('http://localhost:3089/add-domain', {
        bucketName,
        domainName,
      });
};

const BuyDomain = (bucketName,domainName) => {
    return axios.post('http://localhost:3089/buy-domain', {
        bucketName,
        domainName,
      });
};

  const publishSite = (newBucketName=false) => {
    console.log('hey',newBucketName)
    const id = Date.now();
    const bucketName = (newBucketName) ? newBucketName : `website${id}.contentsimiwebsite.com`;
    const domainName = (newBucketName) ? newBucketName : `website${id}.contentsimiwebsite.com`;
    const bucketRegion = 'us-west-2';
    const fileName = 'index.html';
    let fileContent = "";
    if(theContent) {
      fileContent = theContent;
    } else if (localStorage.getItem("htmlContent")){
      fileContent = localStorage.getItem("htmlContent");
    } else{
      fileContent = "";
    }
    CreateBucket(bucketName)
    .then((response) => {
      console.log('in enable hosting')
      EnableHosting(bucketName)
    })
    .then((response) => {
      console.log('in upload files')
      UploadFile(bucketName,false,fileName,fileContent)
    })
    .then((response) => {      
      console.log('in site url update ui')
      setSiteUrl(`http://${bucketName}.s3-website-${bucketRegion}.amazonaws.com/`);
      setTheBucketName(bucketName);
    })
    .then((response) => {      
      BuyDomain(bucketName,domainName.replace('www.',''));
      setSiteUrl(`http://${domainName}`)
    })
    .then((response) => {    
      const upDateSiteStagingURL = async (siteId,url) => {
        const { data: { user } } = await supabase.auth.getUser()
        supabase
        .from('website')
        .update({ staging_domain: url })
        .eq('id', siteId)
        .then(response => {
          if (response.error) {
            console.error(response.error);
            return;
          }
          console.log('Update successful');
          console.log(url, siteId)
        })
      }
      upDateSiteStagingURL(theSiteId,`http://${domainName}`);
      onDataUpdate()
    })
    .catch(error => {
      console.error(error);
    });
  }
  const publishDomain = () => {
    const theDomainName = 'partrakar2.contentsimi.com';
    AddDomain(theBucketName,theDomainName)
    .then((response) => {
      setSiteUrl(`http://${theDomainName}`)
    })
    .catch(error => {
      console.error(error);
    });
  }

  const publishSiteAndDomain = (domainName) => {
    const id = Date.now();
    const bucketName = (domainName) ? domainName : `${id}.contentsimiwebsite.com`;
    const bucketRegion = 'us-west-2';
    const fileName = 'index.html';
    const fileContent = localStorage.getItem("htmlContent") || "";
    CreateBucket(bucketName)
    .then((response) => {
      console.log('in enable hosting')
      EnableHosting(bucketName)
    })
    .then((response) => {
      console.log('in upload files')
      UploadFile(bucketName,false,fileName,fileContent)
    })
    .then((response) => {      
      console.log('in site url update ui')
      setSiteUrl(`http://${bucketName}.s3-website-${bucketRegion}.amazonaws.com/`);
      setTheBucketName(bucketName);
    })
    .then((response) => {
      console.log('in enable before buy domain etc')
      if(domainName){
        console.log('in buy domain etc')
        BuyDomain(domainName,domainName.replace('www.',''));
        setSiteUrl(domainName);
      }
    })
    .catch(error => {
      console.log('else')
      console.error(error);
    });
  }
  const publishSiteAndDomainMain = () => {
    publishSiteAndDomain('www.contentsimiwebsite.com');
  }
  
  const setDomainCName = () => {
    BuyDomain('www.contentsimiwebsite.com','contentsimiwebsite.com');
  }

  const jstCheck = false;
  return (
    <>
      <Button variant="outlined" onClick={() => publishSite()}>Publish</Button>
      {siteUrl && <Button variant="outlined" onClick={publishDomain}>AddDomain</Button>}
      {siteUrl && <a href={siteUrl} target='_blank'>{siteUrl}</a>}
      {jstCheck && <Button variant="outlined" onClick={publishSiteAndDomainMain}>Domain</Button>}
      {jstCheck && <Button variant="outlined" onClick={setDomainCName}>Domain Cname</Button>}
    </>
  );
};

export default AwsPublish;
