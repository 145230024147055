/* ALL KINDS OF FORMS WILL BE LISTED HERE */

import { Skeleton } from "@mui/material"

export const getAllForms = () => {
    const forms = [
        {
            name: 'Contact Form',
            design: <Skeleton />,
        },
        {
            name: 'Job application Form',
            design: <Skeleton />,
        },
        {
            name: 'Industry specific forms',
            design: <Skeleton />,
        },
        {
            name: 'Lead generation Form',
            design: <Skeleton />,
        },
    ]
    return forms;
}