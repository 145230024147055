import React, { useState } from 'react';

const SpacesUploadForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please select a file');
      return;
    }

    try {
      const reader = new FileReader();

      reader.onload = async () => {
        const fileContent = reader.result;

        /*
        // Step 1: Create a DigitalOcean Spaces bucket
        const response = await fetch('https://api.digitalocean.com/v2/spaces', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${REACT_APP_DOP_KEY}`, //YOUR_DIGITALOCEAN_API_TOKEN',
          },
          body: JSON.stringify({
            name: 'my-space-vjyufyyyyy6666655yuihohobiohi8875ssryolpjoufyugig'+Math.random(),
            region: 'nyc3',
            access: 'public',
          }),
        });
        console.log(response)
        if (!response.ok) {
          throw new Error('Failed to create Spaces bucket');
        }

        const { data: { name: spaceName } } = await response.json();

        */
        // Step 2: Send HTML file content to the Spaces bucket
        const spaceName = 'webbuilder23'
        const uploadResponse = await fetch(`https://${spaceName}.nyc3.digitaloceanspaces.com/my-file.html`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'text/html',
            Authorization: `Bearer ${process.env.REACT_APP_DOP_KEY}`, //YOUR_DIGITALOCEAN_SPACES_UPLOAD_TOKEN',
          },
          body: fileContent,
        });
        console.log(uploadResponse)
        if (!uploadResponse.ok) {
          throw new Error('Failed to send HTML file content');
        }

        alert('HTML file content sent successfully');
      };

      reader.readAsText(file);
    } catch (error) {
      console.error(error);
      alert('An error occurred while sending the HTML file content');
    }
  };

  return (
    <div>
      <h2>Send HTML File Content to DigitalOcean Spaces</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default SpacesUploadForm;
