import React from 'react';
import { useEditor } from '@craftjs/core';

export const ContentSidebar = () => {
  const {
    selected,
    actions: { setProp }
  } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').first();
    return {
      selected: state.nodes[currentNodeId]?.data
    };
  });

  return (
    <div className="sidebar" style={{width: '400px', position: 'fixed', right: 0}}>
      {selected && (
        <>
          {selected.name === 'TextComponent' && (
            <input
              type="text"
              value={selected.props.text}
              onChange={(e) => setProp(selected.id, props => { props.text = e.target.value })}
            />
          )}
          {selected.name === 'ImageComponent' && (
            <input
              type="text"
              value={selected.props.src}
              onChange={(e) => setProp(selected.id, props => { props.src = e.target.value })}
            />
          )}
          {JSON.stringify(selected)}
        </>
      )}
    </div>
  );
};
