import React from "react"
import { usePlatformTheme } from "../contexts/PlatformThemeContext";

// Template for Homepage Etc
const InternalTemplate = ({ headerContent, footerContent, children, leftSideBarContent, rightSideBarContent }) => {
  const {platformTheme} = usePlatformTheme();
  const LeftSideBar = React.memo(({children}) => {
    return(
      <div className="col-md-2" style={{backgroundColoru: '#111927'}}>
        <div className="h4 text-lightu py-2 mt-3">
        {(platformTheme && platformTheme.logo_url) ?
            <div className="">
              <img src={platformTheme.logo_url} height="40" /> 
              <br />
              + 
              <br />
              <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" style={{width: 60}} className='p-2 ' />
              <span className=''>
                ContentSimi Websites
              </span>
            </div>
            : 
            <div className="">
              <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" alt="logo" style={{width: 60}} className='p-2 ' />
              <span className=''>
                ContentSimi Websites
              </span>
            </div>
          }
        </div>
        <div className="vh-100" style={{position: 'sticky'}}>
          {children}
        </div>
      </div>
    )
  })
  const RightSideBar = React.memo(({children}) => {
    return(
      <div className="col-md-2 bg-light py-5">{children}</div>
    )
  })
  const CenterContent = React.memo(({children}) => {
    return(
      <div className="col py-3">{children}</div>
    )
  })
    return (
      <div>
        <main className="row">
          {leftSideBarContent && <LeftSideBar>{leftSideBarContent}</LeftSideBar>}
          <CenterContent>
            <header>{headerContent}</header>
            {children}
            <footer>{footerContent}</footer>
          </CenterContent>
          {rightSideBarContent && <RightSideBar>{rightSideBarContent}</RightSideBar>}
        </main>
      </div>
    );
  };

  export default React.memo(InternalTemplate);