import React from 'react';
import styled from 'styled-components';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';

let FooterContainer = {
  backgroundColor: '#f2f2f2',
  padding: '20px',
  textAlign: 'center',
  minHeight: 300,
}

const FooterBlock = ({block, blockId}) => {
  if(block && block.backgroundColor){
    FooterContainer = {
      ...FooterContainer,
      backgroundColor: block.backgroundColor,
    }
  }
  const currentYear = new Date().getFullYear();
  return (
    <>
    <div className='footer footerbg py-5' style={FooterContainer}>
      <p>&copy; {currentYear} {block && block.businessName && block.businessName}. All rights reserved.</p>
      {block.additionalDetails && <div>
        <MarkdownRenderer markdownString={block.additionalDetails} />
      </div>}
      {block.madeWithContentSimi &&
        <a href='http://www.contentsimiwebsite.com/' target='_blank'>
          <div className='stick-bottom stick-right bg-light text-dark p-3' style={{position: 'fixed', right: 0, bottom: 0, zIndex: 2000}}>
            Designed by ContentSimi
          </div>
        </a>
      }
      {block && block.businessName && block.businessName.length<=18 && 
      <div className='parent fw-bold d-block mt-5 pt-5'>
        <span 
          className='stretch-company-name' 
          id='stretch-company-name'
          style={{
            fontSize: `calc(100vw/0.66/${block?.businessName?.length})`,
            lineHeight: `calc(100vw/0.66/${block?.businessName?.length})`,
          }}
        >
          {block && block.businessName && block.businessName}
        </span>
      </div>
      }
    </div>
    </>
  );
};

export default FooterBlock;
