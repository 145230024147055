import { useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { InlineWidget } from "react-calendly";
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const CalendlyBlock = ({ block,blockId }) => {

    const meetingLink = block?.meetingLink ? block.meetingLink : 'https://calendly.com/vijay345mishra/30min';
    /*
    const theblock = {
        "WebsiteBlock": {
            "_id": "63dd53ac234b2b25d10ca28e",
            "description": "Allow customers to book meetings",
            "type": "calendly-scheduling",
            "active": true,
            "name": "Calendly",
            "category": "app"
        },
        "idx": 1,
        "headline": block?.headline,
        "background": "#FFFFFF",
        "align": "center",
        "accountApp": meetingLink,
        "backgroundApp": "#FFFFFF",
        "textColorApp": "#1A1A1A",
        "primaryColorApp": "#0069FF",
        "logging": []
    };
    */

    const theblock = block;
    
    let alignClass = 'text-center';

    if(theblock.align==='left'){
      alignClass = 'text-start'
    } else if(theblock.align==='right'){
      alignClass = 'text-end'
    }

    return (
      <div className={`section ${(alignClass)}`} style={(theblock && theblock.background && theblock.background.image) ? {backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage : `url(${theblock?.background?.image?.url})` } : { background: theblock.background }}>
      <div className={`p-md-5 py-5 ${(!theblock?.background?.image) ? '' : 'brandtransparentbg'}`} style={(!theblock?.background?.image) ? {} : {background: (theblock && theblock.background && theblock.background.opacity) ? `rgba(0,0,0,${theblock.background.opacity/100})` : theblock.background, color: 'white', width: '100%', margin:0 }} >
      <Container style={{opacity: 1}} className='p-md-5 py-5'>
        <div className="row p-md-5 py-5 py-sm-5">
          <Col md={12} xs={12}>
            <h1 className='highlight-this fw-bold display-1'><MarkdownRenderer markdownString={theblock.headline} /></h1>
            <MarkdownRenderer markdownString={theblock.content} />
            {(theblock && theblock.button && theblock.button.link) && <a href={theblock.button.link} data-click={theblock.button.clickEvent} className='btn btn-primary my-3'>{theblock.button.label}</a>}
            <div class="embed-responsive embed-responsive-16by9">
              <InlineWidget url={meetingLink} />
            </div>
          </Col>
        </div>
      </Container>
      </div>
    </div>
    );
  };

export default CalendlyBlock;