import { Button, Container, LinearProgress, Skeleton, Typography } from "@mui/material";
import InternalFeatureTemplate from "../../templates/InternalFeature";
import DashboardSideBarMenu from "./SidebarMenu";
import BannerBlock from "../../../components/Blocks/BannerBlock";
import { useEffect, useState } from "react";
import { supabase } from "../../../supabase";
import InternalTemplate from "../../templates/Internal";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import DelayedButton from "../../components/DelayedButton";
import DelayedLink from "../../components/DelayedLink";
import { usePlatformTheme } from "../../contexts/PlatformThemeContext";

  // Dashboard Page
  const DashboardPage = ({displayBlocks}) => {
    const {platformTheme, platformContent} = usePlatformTheme();
    const headerContent = <>
    {(platformTheme && platformTheme.logo_url) ?
      <div className="pt-5">
        <img src={platformTheme.logo_url} height="40" /> 
        + 
        <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" height="40" /> Contentsimi Websites
      </div>
      : 
      <div className="pt-5">
        <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/0.983417169349498-write--ai-.svg" height="40" /> Contentsimi Websites
      </div>
    }
    </>;
    const footerContent = <p>Logged in as user@example.com</p>;
    const leftSideBarContent = <DashboardSideBarMenu />
    const BlankSpaceDesign = ({children}) => {
        const bannerBlockData = {
            headline: 'We designed 500+ websites this month alone',
            //content: '<button class="btn btn-primary">Get Started Here</button>'
            background : {
                image: {
                    url: 'https://www.lifecoachmagazine.com/wp-content/uploads/2020/07/life-coach-websites-melissa-ambrosini.jpg',
                }
            }
        }
        return(
            <BannerBlock block={bannerBlockData}><Button component={Link} variant="contained" color="primary" to="/dashboard/websites/create" onClickNew={() => {redirect('/dashboard/websites/create')}}>Get started here</Button></BannerBlock>
        )
    }

    const redirectButton = () => {
        
    }
    
    const [payments, setPayments] = useState([]);
    
    /*
    useEffect(() => {
        const getPayments = async () => {
            let { data: payment, error } = await supabase
            .from('payment')
            .select(`amount:SUM(amount)`)
            setPayments(payment.amount)
            console.log(payment)
        }
        getPayments();
    },[]);
    */

    const history = useNavigate();
    const delayAndGo = (e, path) => {
        //e.preventDefault();
        // Do something..
        setTimeout((e) => history(path), 3000);
    }
    /*
    const DelayedButton = ({ to, delay, children, size="large", variant="contained", color="primary" }) => {
        const [isDelayed, setIsDelayed] = useState(false);
      
        const navigate = useNavigate();
        const handleClick = (event) => {
          event.preventDefault();
          setIsDelayed(true);
      
          setTimeout(() => {
            navigate(to);
            //window.location.href = to;
          }, delay);
        };
      
        return (
            <Button onClick={handleClick} disabled={isDelayed} size={size} variant={variant} color={color}>
                {children}
            </Button>
        );
    };
    */
   

    return (
        <InternalTemplate footerContent={(displayBlocks && !displayBlocks.includes('footer') ? undefined : footerContent)} leftSideBarContent={(displayBlocks && !displayBlocks.includes('leftSideBar') ? undefined : leftSideBarContent)}>
            <InternalFeatureTemplate>
                <Row>
                    <Col className="col-md-7 py-5 text-start">
                        {headerContent}
                        <h1 style={{fontFamily: "Open Sans", fontWeight: "700 !important"}}>{platformContent.primaryHeadline}</h1>
                        <h2 style={{color: '#ff0088'}} >{platformContent.headline}</h2>
                        <Typography variant="h6" component="p" className="my-5">
                            {platformContent.subHeadline}
                        </Typography>
                        {/* Help me buy a house: <LinearProgress variant="determinate" value={50} /> */}
                        {/* <Skeleton animation="wave" /> */}
                        <DelayedButton to="/dashboard/websites/create" delay={5000} size="large" variant="contained" color="primary">Get started here</DelayedButton>
                        <DelayedLink delay={100} to="/dashboard/websites" size="large" variant="contained" color="primary" style={{marginLeft: '20px'}}>All Websites</DelayedLink>
                    </Col>
                    <Col className="col-md-5">
                        <img src="https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/6349846ab7be8f473f2a210e_home-hero-1-sm.png" width={"100%"} />
                    </Col>
                </Row>
                <Row className="py-5 text-start">
                    {platformContent && platformContent.sections && platformContent.sections.map((platformContentSection,platformContentSectionIndex) => (
                        <Col className="col-md-4" key={platformContentSectionIndex}>
                            <h3>
                                {platformContentSection.headline}
                            </h3>
                            <Typography component="p" className="my-5">
                                {platformContentSection.content}
                            </Typography>
                        </Col>
                    ))}
                </Row>
                <div className="alert alert-info my-5">
                    We will auto login/signup based on url email. And everything else will be magic links only.
                </div>
            </InternalFeatureTemplate>
        </InternalTemplate>      
    );
  };
  
  export default DashboardPage;