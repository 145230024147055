export const SEOSettings = () => {    
    return {
        "title": "Page Title",
        "description": "Page Description",
        "keywords": ["keyword1", "keyword2", "keyword3"],
        "author": "John Doe",
        "robots": "index, follow",
        "canonical": "https://www.example.com/page",
        "og": {
        "title": "Open Graph Title",
        "description": "Open Graph Description",
        "image": "https://www.example.com/images/og-image.jpg",
        "url": "https://www.example.com/page",
        "site_name": "Example Website"
        },
        "twitter": {
        "card": "summary_large_image",
        "site": "@example"
        }
    }
}
  