import React, { useEffect, useRef, useState } from 'react';
import PlaceholderList from '../NEW/components/PlaceholderList';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function HtmlViewer({theHtml,freeHeight=false,isSmall=false,isBlank=false,isExpanded=false,bigViewiewPort=false}) {
  let newHtml = '';
  if(theHtml){
    newHtml = theHtml;
  } else if(localStorage.getItem("htmlContent")){
    newHtml = localStorage.getItem("htmlContent")
  }
  const [html, setHtml] = useState(newHtml);
  const [load, setLoad] = useState(false);

  const handleHtmlChange = (event) => {
    setHtml(event.target.value);
  };

  /*
  useEffect(() => {    
    const formData = localStorage.getItem('htmlContent');
    if (formData) {
        setHtml(formData);
    }
  },[])
  */

  useEffect(() => {
    if(!theHtml){
      const interval = setInterval(() => {
          setHtml(localStorage.getItem("htmlContent") || "");
        }, 5000);
    
        return () => clearInterval(interval);
    }
    /*
    const handleStorageChange = () => {
        setHtml(localStorage.getItem("htmlContent") || "");
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    */
  }, []);

  useEffect(() =>{
    //setLoad(load)
    localStorage.setItem("load",load)
  },[load])

  const updatePage = () => {
    if(!theHtml){  
      setHtml(localStorage.getItem("htmlContent") || "");
    }
  }

  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleLinkClick = (event) => {
      event.preventDefault(); // Prevent the default link behavior
    };

    if (iframe) {
      const iframeWindow = iframe.contentWindow;
      iframeWindow.addEventListener('click', handleLinkClick);
    }

    return () => {
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.removeEventListener('click', handleLinkClick);
        }
      }
    };
  }, []);

  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  
  //const [iframeSize, setIframeSize] = useState({ width: 0, height: 0 });

  const wrapperRef = useRef(null);
  const [iframeStyle, setIframeStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const wrapper = wrapperRef.current;

      if (wrapper) {
        // Maintain a virtual width of 1200px for the iframe
        const newWidth = 1200;

        // Calculate the iframe's virtual height while maintaining a specific aspect ratio (e.g., 16:9)
        const aspectRatio = 16 / 9;
        const newHeight = 865; //newWidth / aspectRatio;

        // Calculate the scale factor
        const scaleFactor = Math.min(
          wrapper.offsetWidth / newWidth,
          wrapper.offsetHeight / newHeight
        );

        setIframeStyle({
          width: newWidth + 'px',
          height: newHeight + 'px',
          transform: `scale(${scaleFactor})`,
          transformOrigin: 'top left',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();  // Call the function once to set the iframe size initially

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      {/*<button onClick={updatePage}>Show Preview</button>*/}
      <div style={
        (isSmall) ? 
        {
          backgroundImagei: 'url(\'../../browser.svg\')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          paddingLefti:'0.3rem',
          paddingRighti: '0.3rem',
          minHeight: '400px',
          paddingTopi: '80px',
          overflow: 'hidden',
          position: 'relative',
          overflow: 'hidden',
        } 
        : 
        {
          overflow: 'hidden'
        }
        } className={`${(isSmall) ? 'pt1-5 px1-3 iframe-container border': ''} ${(isExpanded) ? '100-vh': ''}`}
        ref={wrapperRef}
        >
        {/*loading && null */}
        {/*<div className={`placeholder${loading ? ' show' : ' hide'} d-none`}><RestartAltIcon /></div>*/}
          {/* <div><PlaceholderList type="iframe" iframeHeight={(freeHeight) ? null : '100vh'} /></div> */}
          <div className='iframe-new-container' style={{
            //position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}>
          {!isBlank&& 
            <iframe ref={iframeRef} 
                title="HTML Viewer" 
                id='htmlViewer' 
                srcDoc={html} 
                style={
                  (bigViewiewPort) ?
                  iframeStyle
                  :
                  {
                    width: '100%',
                    minHeight: (freeHeight)? '400px' : '100vh',
                    display: loading ? 'none' : 'block',
                    background: '#eee'
                  }
                }
                sandbox="allow-same-origin allow-scripts"     
                onLoad={handleIframeLoad}
                className={`iframe${loading ? ' hide' : ' show'}`}
            />
          }
          </div>
      </div>
    </div>
  );
}
export default HtmlViewer;