
import React, { useEffect, useState } from 'react';
import { generateImage, generateLogo, removeImageBackground } from '../../../helpers/prompts';
import { supabase } from '../../../supabase';
import { Button, TextField } from '@mui/material';
import { convertBase64ToDataUrl, extractUrlFromImgString } from '../../../helpers/image';
import { sanitizeHtml } from '../../../helpers/randoms';
import ImageUploaderNew from './ImageUploaderNew';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWebsites, updateWebsite } from '../../redux/slices/websiteSlice';
import CssEditor from '../stylesInteractionParts/CssEditor';

const LogoForm = ({websiteId,onUpdate}) => {
    const [promptImages, setPromptImages] = useState([]);
    const [logos, setLogos] = useState([]);
    const [theLogo, setTheLogo] = useState(null);
    const [aiPrompt, setAiPrompt] = useState('');
    const [theSDLogo, setTheSDLogo] = useState(null);
    const [theSDLogoLoading, setTheSDLogoLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [data, setData] = useState(null);
    const [isAiLoading, setIsAiLoading] = useState(false);
    const [isCustomLoading, setIsCustomAiLoading] = useState(false);  
    const dispatch = useDispatch();
    useEffect(() => {
        const getPageData = async(websiteId) => {
            let { data: thewebsite, error } = await supabase
            .from('website')
            .select('formData->companyName, formData->companyIndustry, data')
            .eq('id',websiteId)
            if(error){
                //console.error(error);
                return null;
            }
            setCompanyName(thewebsite[0].companyName)
            setCompanyIndustry(thewebsite[0].companyIndustry)
            setData(thewebsite[0].data ? thewebsite[0].data : null)
            setTheLogo(thewebsite[0].data ? thewebsite[0].data.blocks[0].customLogo : null)
            setAiPrompt(`Create a logo for one with company description : ${thewebsite[0].companyIndustry}`)
        }
        if(websiteId && websiteId>0 ){
            //getPageData(websiteId);
            dispatch(getAllWebsites({startFromId: websiteId}));
        }        
    },[])
    
    const websiteData = useSelector(state => state.websites.websites.find(website => website.id === parseInt(websiteId)));
    useEffect(() => {
        if (websiteData) {
            const gotPageData = websiteData.data;
            console.log('got page data',websiteData);
            setCompanyName(websiteData.formData['companyName'])
            setCompanyIndustry(websiteData.formData['companyIndustry'])
            setData(websiteData.data ? websiteData.data : null)
            setTheLogo(websiteData.data ? websiteData.data.blocks[0].customLogo : null)
            setAiPrompt(`Create a logo for one with company description : ${websiteData.formData['companyIndustry']}`)
        }
    }, [websiteData]);

    const getGeneratedImage = async () => {
        setIsAiLoading(true);
        // call to prompt & then map
        //  Please write name clearly
        const thePrompt = '[no prose] Create a prompt for an art logo with Dalle-E for an "'+companyIndustry+'" business called "'+companyName+'", use vibrant colors. Give in transparent background and give in png format, symmetric, mordern, no text, geometric';
        const images = await generateImage(thePrompt,true);
        console.log(images);
        if(images && images.data && images.data.length>0){
            let newImages = images.data;
            images.data.map(async (image,index) => {
                const newImage = {...image, url: await convertBase64ToDataUrl(image.b64_json)};
                newImages[index] = newImage;
            })
            setPromptImages(newImages)
            setIsAiLoading(false);
        };
    }
    const getGeneratedLogosSanmaya = async () => {
        setIsCustomAiLoading(true)
        console.log('logos going',companyName,companyIndustry)
        const theLogosGenerated = await generateLogo(companyName, companyIndustry)
        console.log('logo generated',theLogosGenerated)
        setLogos(theLogosGenerated.data.slice(0,10))
        setIsCustomAiLoading(false)
    }
    const saveHtmlLogo = async(providedLogo) => {
        //let newPageData = (data && data.blocks[0] && data.blocks[0].customLogo) ? data : {};
        //newPageData['blocks'][0]['customLogo'] = providedLogo;

        let newPageData = { ...data }; // Create a shallow copy of data

        // Check if blocks exist and have the first element with customLogo property
        if (newPageData.blocks && newPageData.blocks[0] && newPageData.blocks[0].customLogo) {
            // Create a copy of blocks array
            newPageData.blocks = [...newPageData.blocks];
            
            // Create a copy of the first block
            newPageData.blocks[0] = { ...newPageData.blocks[0], customLogo: providedLogo };
        }
        dispatch(updateWebsite({id: websiteId, data: {data: newPageData}}))
        //console.log('provided logo',providedLogo,'page Data',newPageData)
        /*
        const { websiteData, error } = await supabase
        .from('website')
        .update({ data:  JSON.stringify(newPageData)})
        .eq('id', websiteId)
        .select()
        onUpdate()
        */
    }
    const saveLogo = (type='html',providedLogo) => {
        if(type==='upload' && providedLogo && providedLogo.url){        
            let customLogo = {
                url: providedLogo.url,
                html: `<img className='brand' class='brand' src='${providedLogo.url}' height='40' />`,
            };
            saveHtmlLogo(customLogo);
        }
        if(type==='ai' && providedLogo && providedLogo.url){            
            let customLogo = {
                url: providedLogo.url,
                html: `<img className='brand' class="brand" src='${providedLogo.url}' height='40' />`,
            };
            saveHtmlLogo(customLogo);
        }
        if(type==='html' && providedLogo && providedLogo.html){
            if(providedLogo.icon){
                let customLogo = {
                    ...providedLogo,
                    url: extractUrlFromImgString(providedLogo.icon),
                };
                saveHtmlLogo(customLogo);
            }
            else{
                saveHtmlLogo(providedLogo);
            }
        }
    }
    const convertImageURLToDataURL = async(url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
    }      
    const convertBlobToDataURL = async(blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
    }
    const removeBackground = async(type='ai',index,url) => {
        convertImageURLToDataURL(url)
        .then((dataUrl) => {
            console.log(dataUrl);
            const removedData = removeImageBackground(dataUrl);
            console.log('image removed bg url or data',removedData);
        })
        .catch((error) => {
            console.error('Error converting image URL to Data URL:', error);
        });
    }
    console.log(logos)
    const stabilityaiQuery = async (data) => {
        const response = await fetch(
            "https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-xl-base-1.0",
            {
                headers: { Authorization: `Bearer ${process.env.REACT_APP_HUGGING_FACE_KEY}` },
                method: "POST",
                body: JSON.stringify(data),
            }
        );
        const result = await response.blob();
        const imageResult = convertBlobToDataURL(result);
        return imageResult;
    }

    return(
        <>
            <h5 className='fw-bold'>{companyName}</h5>
            <h7 className='fw-bold'></h7>
            <TextField 
                fullWidth
                placeholder='Write prompt' 
                onBlur={(e) => {                       
                    setTheSDLogoLoading(true);
                    stabilityaiQuery({"inputs": e.target.value})
                        .then((sdimg) => {
                            setTheSDLogo(sdimg);
                            setTheSDLogoLoading(false);
                        })
                }}
                value={aiPrompt}
                onChange={(e) => setAiPrompt(e.target.value)}
            />
            {theSDLogo!==null && 
                <>
                {theSDLogoLoading ? 
                    'Generating logo, please wait ...'
                    :                
                    <img src={theSDLogo} className='img-fluid pointer' onClick={() => saveLogo('ai',{url: theSDLogo})} />
                }
                </>
            }
            {(theLogo && theLogo.html) && <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(theLogo.html) }} />}
            
            <hr />

            {theLogo && <ImageUploaderNew image={theLogo} onUpdateImage={(e) => saveLogo('upload', e)} features={['uploader']} />}

            <hr />
            {isAiLoading && 'AI is generating, please wait ...'}
            <div className='btn btn-sm btn-secondary' onClick={getGeneratedImage}>Generate Logo/Image</div>
            <div>
                {(promptImages && promptImages.length>0) && promptImages.map((promptImage,promptImageIndex) => (

                    <div 
                        key={promptImageIndex}
                    >
                        <Button onClick={() => removeBackground('ai',promptImageIndex,promptImage.url)}>Remove Background</Button>
                        <div
                            className='border text-center bg-light my-3 display-4 py-5 pointer'
                            onClick={() => saveLogo('ai',promptImage)}
                        >
                            <img src={promptImage.url} width={'48%'} key={promptImageIndex} />
                        </div>
                    </div>
                ))}
            </div>
            <hr />
            {isCustomLoading && 'Generating logo, please wait ...'}
            <div className='btn btn-sm btn-secondary' onClick={getGeneratedLogosSanmaya}>Generate Logo/Image by Sanmaya</div>
            {logos && logos.map((logo,logoIndex) => (
                <div 
                    key={logoIndex} 
                    className='border text-center bg-light my-3 display-4 py-5 pointer' 
                    style={{fontFamily: logo.font, fontWeight: logo.fontWeight, minHeight: 150}}
                    onClick={() => saveLogo('html',logo)}
                >
                    <div dangerouslySetInnerHTML={{ __html: logo.html }} />
                </div>
            ))}
            <CssEditor />
        </>
    )
}

export default React.memo(LogoForm);