import React from 'react';
import { Block } from '../components/Block';

const Contact = () => {
  return (
    <div>
      <Block title="Contact">
        <p>This is the contact page</p>
      </Block>
    </div>
  );
};

export default Contact;
