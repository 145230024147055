import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { createOne, deleteOne, findAll, updateOne } from "../../data/BaseDbOps";


// all templates (CRUD)
// all section templates (R)
// all page templates (R)

//create action
export const createTemplate = createAsyncThunk(
  "createTemplate",
  async (data, { rejectWithValue }) => {
    console.log("create template data", data);
    try {
      const response = await createOne('templated',data);  
      return response;
    } catch (error) {
      return rejectWithValue({errorMessage: error});
    }
  }
);
//edit action
export const updateTemplate = createAsyncThunk(
  "updateTemplate",
  async ({id, data}) => {
    console.log("update template data",id, data);
    try {
      const response = await updateOne('templated',data,id);
      return response;
    } catch (error) {
      return {errorMessage: error};
    }
  }
);

export const getAllTemplates = createAsyncThunk(
  "getAllTemplates",
  async (args, { rejectWithValue }) => {
    console.log("args", args);
    try {
      const response = await findAll('templated');
      return response;
    } catch (error) {
      return rejectWithValue({errorMessage: error});
    }
  }
);

export const getAlltemplatesForWebsite = createAsyncThunk(
  "getAlltemplatesForWebsite",
  async ({websiteId}) => {
    console.log("create website data",websiteId);
    try {
      const response = await findAll('templated','*',[['website_id','=',websiteId]]);  
      return response;
    } catch (error) {
      return {errorMessage: error};
    }
  }
);

export const deleteTemplateNew = createAsyncThunk(
  "deleteTemplateNew",
  async ({id, websiteId, postDo}) => {
    console.log("delete website data",id, websiteId);
    // we should first check if that belongs here
    try {
      const response = await deleteOne('page',id);
      if(response===true){
        return {id: parseInt(id), website_id: parseInt(websiteId), postDo}
      }
      console.log(response)
      return response;
    } catch (error) {
      return {errorMessage: error};
    }
  }
);

const templateSlice = createSlice({
    name: "templates",
    initialState: {
        templates: [],
        sectionTemplates: [],
        pageTemplates: [],
        isLoading: false,
        error: null,
        searchData: [],
    },
    reducers: {
        addTemplate: (state, action) => {
            console.log(action.payload)
            state.templates.push(action.payload);
            if(action.payload.type=='section'){
              console.log(action.payload)
              state.sectionTemplates.push(action.payload);
            } else if(action.payload.type=='page'){         
              state.pageTemplates.push(action.payload);
            }
        },
        deleteTemplate: (state, action) => {
            state.templates = state.templates.map(template => {
              if (template.id !== action.payload.template_id) return template;
            });
            state.sectionTemplates = state.sectionTemplates.map(template => {
              if (template.id !== action.payload.template_id) return template;
            });
            state.pageTemplates = state.pageTemplates.map(template => {
              if (template.id !== action.payload.template_id) return template;
            });
        },
    },
    extraReducers: {
        [getAllTemplates.pending]: (state) => {
          state.isLoading = true;
        },
        [getAllTemplates.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.templates = action.payload;
        },
        [getAllTemplates.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [createTemplate.pending]: (state) => {
          state.isLoading = true;
        },
        [createTemplate.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.templates.push(action.payload);
        },
        [createTemplate.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        [updateTemplate.pending]: (state) => {
          state.isLoading = true;
        },
        [updateTemplate.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.templates = state.templates.map((ele) =>
            ele.id === action.payload.id ? action.payload : ele
          );
        },
        [updateTemplate.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
        // pages
        [deleteTemplateNew.pending]: (state) => {
          state.isLoading = true;
        },
        [deleteTemplateNew.fulfilled]: (state, action) => {
          console.log(action);
          state.isLoading = false; // now here open the website and then
          state.templates = state.templates.map(website => {
            if (website.id !== action.payload.website_id) return website;          
            return {
              ...website,
              webpages: website.webpages.filter(page => page.id !== action.payload.id)
            };
          });
          state.currentEditedWebsitePageId = (state.currentEditedWebsiteId===action.payload.id) ? null : state.currentEditedWebsiteId;
        },
        [deleteTemplateNew.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.errorMessage;
        },
    },
});

export const getTemplatesSelector = createSelector(
    (state) => state.templates,
    (state) => state
)

export const {addTemplate} = templateSlice.actions;
export const {deleteTemplate} = templateSlice.actions;

export default templateSlice.reducer;