import { Button, Grid, TextField, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { getEditedContent, getParagraphForHeadline } from '../../../helpers/prompts';
import AIWritingGenerator from './AIWritingGenerator';

const theBlockData = {
    "WebsiteBlock": {
        "_id": "629f98a1eb0b4972268051a1",
        "description": "Add a full-width banner",
        "type": "banner",
        "active": true,
        "name": "Banner"
    },
    "idx": 1,
    "headline": "Accounting Experts in Bhubaneswar",
    "content": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
    "align": "center",
    "background": {
        "type": "image",
        "overlay": {
            "color": "#3D9970",
            "amount": 70
        },
        "image": {
            "id": "FHnnjk1Yj7Y",
            "description": "Laptop and notepad",
            "alt_description": "MacBook Pro near white open book",
            "url": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ&ixlib=rb-4.0.3&q=80&w=1080",
            "author": "Nick",
            "authorLink": "https://unsplash.com/@nickmorrison?utm_source=Durable.co&utm_medium=referral",
            "type": "unsplash",
            "download": "https://api.unsplash.com/photos/FHnnjk1Yj7Y/download?ixid=MnwyNjI5NjF8MHwxfHNlYXJjaHwxOXx8QWNjb3VudGluZ3xlbnwwfHx8fDE2ODIzNTYwNDQ"
        }
    },
    "button": {
        "label": "Contact",
        "type": "url",
        "link": "#"
    }
  };

  const onUpdate = (data) => {
    alert(JSON.stringify(data))
  }


  const BannerForm = ({ blockData = theBlockData, onUpdate }) => {
    const [bannerData, setBannerData] = useState(blockData || {})
    const [headline, setHeadline] = useState(blockData.headline);
    const [sectionBuilderTitle, setSectionBuilderTitle] = useState(blockData.sectionBuilderTitle || '');
    const [sectionBuilderDescription, setSectionBuilderDescription] = useState(blockData.sectionBuilderDescription || '');
    const [content, setContent] = useState(blockData.content);
    const [align, setAlign] = useState(blockData.align);
    const [background, setBackground] = useState(blockData.background);
    const [button, setButton] = useState(blockData.button);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

  
    const handleSubmit = (e) => {
      e.preventDefault();
      const updatedBlockData = {
        ...bannerData,
        sectionBuilderTitle,
        sectionBuilderDescription,
        headline,
        content,
        align,
        background,
        button,
      };
      onUpdate(updatedBlockData);
    };

    const addHeadline = (value) => {
        onUpdate({...bannerData,headline: value});
    }
  
    const handleImageSelect = (selectedImage) => {
      setBackground({
        ...background,
        image: {
          id: selectedImage.id,
          description: selectedImage.description,
          alt_description: selectedImage.alt_description,
          url: selectedImage.urls.regular,
          author: (selectedImage.user && selectedImage.user.name) ? selectedImage.user.name : '',
          authorLink: (selectedImage.user && selectedImage.user.links && selectedImage.user.links.html) ? selectedImage.user.links.html : '',
          type: 'unsplash',
          download: (selectedImage.links && selectedImage.links.download) ? selectedImage.links.download : '',
        },
      });
    };
  
    return (
      <form onSubmit={handleSubmit}>
        
        {/*<TextField
            variant="outlined"
            label={'AI Title'} 
            value={sectionBuilderTitle}
            onChange={(e) => setSectionBuilderTitle(e.target.value)}
            style={{width: '100%'}}
    />*/}
            
        <label>
          We want to accomplish with this section:
        <TextareaAutosize
            minRows={4}
            aria-label="maximum height"
            placeholder="Maximum 4 rows"
            value={sectionBuilderDescription}
            onChange={(e) => setSectionBuilderDescription(e.target.value)}
            style={{width: '100%'}}
            />
        </label>
        <AIWritingGenerator props={{blockType: 'banner', data: blockData, toUpdate: 'headline'}} onUpdate={(e) => setHeadline(e)} />
              <label>
        <TextField 
          variant="outlined"
          label={'Headline'} 
          value={headline}
          fullWidth 
          style={{ marginTop: 11 }}
          onChange={(e) => setHeadline(e.target.value)}
          />
      </label>
      <br />
      <label>
        Content:
        <AIWritingGenerator props={{blockType: 'banner', data: blockData, toUpdate: 'content'}} onUpdate={(e) => setContent(e)} />
        <TextareaAutosize
            minRows={4}
            aria-label="maximum height"
            placeholder="Maximum 4 rows"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{width: '100%'}}
            />
      </label>
      <br />
      {/*
      <Grid container spacing={2} className='border-bottom mb-2'>
        <Grid item style={{ flex: '1 0 auto' }}>
          Alignment:
        </Grid>
        <Grid item style={{ minWidth: 0 }} className='text-end'>
          <select value={align} onChange={(e) => setAlign(e.target.value)}>
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </Grid>
      </Grid>
      */}
      <GriderLayout 
        left={'Alignment:'}
        right={<select value={align} 
          onChange={(e) => setAlign(e.target.value)} 
          style={{border: 'none', background: 'transparent'}}>
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>}
      />
      <br />
      <GriderLayout 
        left={'Background Color:'}
        right={<input
          type="color"
          value={background.color}
          onChange={(e) =>
            setBackground({ ...background, color: e.target.value })
          }
        />}
      />
      <h5 className='fw-bold'>Button</h5>
      <br />
      {button &&
      <>
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Button Label'}
          value={button.label}
          onChange={(e) =>
            setButton({ ...button, label: e.target.value })
          }
        />
      </label>
      <br />
      <GriderLayout
        left={'Button Type:'}
        right={<select
          value={button.type}
          onChange={(e) => setButton({ ...button, type: e.target.value })}
        >
          <option value="url">URL</option>
          <option value="email">Email</option>
          <option value="phone">Phone</option>
        </select>}
      />
      <br />
      <GriderLayout left={'External Link'} 
        right={<input
          type="checkbox"
          value={true}
          checked={button?.external}
          onChange={(e) =>
              setButton({ ...button, external: e.target.checked })
          }
        />}
      />
      <br />
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Button Link'}
          value={button.link}
          onChange={(e) =>
            setButton({ ...button, link: e.target.value })
          }
        />
      </label>
      <br />
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Click Event'}
          value={button.clickEvent}
          onChange={(e) =>
            setButton({ ...button, clickEvent: e.target.value })
          }
        />
      </label>
      </>}
      <br />

      {(background && background.image) && <ImageUploaderNew image={background.image} onUpdateImage={(e) => handleImageSelect(e)} />}
      {/*
      <label>
        <TextField 
          variant="outlined"
          fullWidth 
          style={{ marginTop: 11 }}
          label={'Search Images'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button variant='outlined' color='secondary' onClick={handleImageSearch}>search</Button>
      </label>
      <br />
      <div>
        {searchResults.map((image) => (
          <label key={image.id}>
            <input
              type="radio"
              name="selectedImage"
              value={image.id}
              checked={background.image && background.image.id === image.id}
              onChange={() => handleImageSelect(image)}
              style={{border: 'none'}}
            />
            <img
              src={image.urls.thumb}
              alt={image.alt_description}
              style={{ width: '100%', height: 'auto', marginRight: '10px', border: (background.image && background.image.id === image.id) ? '2px solid blue' : 'none' }}
            />
          </label>
        ))}
      </div>
      */}          
        <GriderLayout left={'Use Block Version'} 
          right={<input
            type="checkbox"
            value={'block'}
            checked={(bannerData.version==='block')}
            onChange={(e) => setBannerData({ ...bannerData, version: e.target.checked ? 'block' : 'old' })}
          />} 
        />
        <GriderLayout left={'Hide'} 
          right={<input
            type="checkbox"
            value={true}
            checked={bannerData.isHidden}
            onChange={(e) => setBannerData({ ...bannerData, isHidden: e.target.checked})}
          />} 
        />
        <Button variant='outlined' color='primary' type='submit' fullWidth className='my-3'>Save</Button>
      </form>
    );
  };
  
  export default BannerForm;
  