import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { generateRandomPath, getRandomIpsumLogo, getRandomRadius } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const TeamBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className="row text-center">
            <div className='pt-5 col display-3'>{theblock.headline}</div>
            <div className='col-md-12 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
          </div>
          <div className="row text-center px-5 d-flex justify-content-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className={`col-md-${(theblock.items.length>4) ? '4' : 12/theblock.items.length} col-sm-12`}>
                  {column.image &&  
                    <div className="random-path-image my-3" style={{ clipPathi: generateRandomPath() }}>
                        <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} sizes="100vw" style={{borderRadiusi: getRandomRadius()}} />
                    </div>
                  }
                <h5 className='badge bg-light'>{column.designation}</h5>
                <h3>{column.name}</h3>
                <p>{column.about}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default TeamBlock;