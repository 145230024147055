import React, { useContext, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
//import fs from 'fs';
//import { API } from 'netlify';
import { generateLogo, getAboutBlock, getBannerBlock, getMenus, getServicesBlock, getTestimonialsBlock, getWebsiteColors } from '../helpers/prompts';
import WebsitePreview from './WebsitePreview';
import SitePreview from '../pages/SiteCreated';
import TemplateStylesOne from './StylesOne';
//import styles from '../App.css';
import { Button, CircularProgress } from '@mui/material';
import { MessageContext } from '../NEW/contexts/MessageContext';
import { getFontCombos } from '../helpers/fontChoices';

const WebsiteGenerator = ({ website }) => {
  
  const { addMessage } = useContext(MessageContext);
  // Generate the HTML content
  //const htmlContent = ReactDOMServer.renderToString(<WebsitePreview website={website} />);

    const fetchHomePageData = async (name = 'Somistry', industry='Florist', websiteType='business') => {
        let pageData = {
            "_id": "644759ffb29fcec827b43354",
            "Website": "644759ffb29fcec827b43350",
            "slug": null,
            "label": "Home",
            "showOnHeader": true,
            "showOnFooter": true,
            "default": true,
            "blocks": [],
            "order": 0,
            "createdAt": "2023-04-25T04:41:35.809Z",
            "seo": {
                "title": "Home Page",
                "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
                "keywords": "keyword",
                "headCode": "",
                "footerCode": "",
                "Image": null
            },
            "__v": 3
        }
        try {
            const header = {
                "WebsiteBlock": {
                    "_id": "629f98a1eb0b4972268051aa",
                    "description": "Website header with logo and links",
                    "type": "header",
                    "active": true,
                    "name": "Header"
                },
                "siteName": name,
                "showLogo": false,
                "source": "branding",
                "customLogo": null,
                "logoDesktop": {
                    "height": 80
                },
                "logoMobile": {
                    "height": 40
                },
                "showSocial": true,
                "style": "left",
                "fullWidth": true,
                "sticky": false,
                "background": "#3D9970",
                "button": {
                    "label": "Call Us",
                    "link": "#",
                    "type": "url"
                }
            };

            const footer = {
                "WebsiteBlock": {
                    "_id": "629f98a1eb0b4972268051ab",
                    "description": "Website footer with logos and links",
                    "type": "footer",
                    "active": true,
                    "name": "Footer"
                },
                "businessName": name,
                "showLogo": true,
                "style": "simple-right",
                "additionalDetails": "",
                "showSocial": true,
                "madeWithContentSimi": true,
                "background": "#83dfb6",
                "button": {
                    "label": "Home",
                    "link": "/",
                    "type": "page",
                    "page": "644759ffb29fcec827b43354"
                }
            };
            //let banner = [];
            let testimonial = [];
            //let service = [];
            //let about = [];
            let theWebsiteSettings = {"websiteName":"okni","pages":["new","effective"],"backgroundColor":"#e10e0e","navigationColor":"#67d58e","headingColor":"#ae4242","bodyTextColor":"","googleFont":"Roboto"};
            
            try{
                setGeneratedProgress(5);
                setGeneratedProgressLabel('Understanding what colors would suit ..')
                addMessage('Understanding what colors would suit ..')
                let theColorsTaken = await getWebsiteColors(name,industry);
                const theColors = JSON.parse(theColorsTaken);
                theWebsiteSettings['navigationColor'] = theColors['navigationBarColor'];
                header['websiteSettings'] = theWebsiteSettings;
                console.log(header)
                console.log(theColorsTaken)
            } catch (error) {
                // Handle errors
                console.log('error in website Settings')
                console.log(error.message)
            }

            
            setGeneratedProgressLabel('Creating Menus ..')
            addMessage('Creating Menus ..')
            const theMenus = await getMenus(name,industry);
            const menus = theMenus.map((menu) => {
              if(Array.isArray(menu)) {
                //return undefined;
              }
              menu['label'] = menu.menu;
              menu['link'] = "#"+menu.menu.replace(' ','+');
              menu['type'] = 'section';
              menu['section'] = '1234';
              return menu;
            }).filter((element) => element !== undefined);

            header['menus'] = menus;


            setGeneratedProgress(10);
            setGeneratedProgressLabel('Generating Logo ..')
            addMessage('Generating Logo ..')
            const logo = await generateLogo(name,industry);
            //console.log('error in logo')
            header['customLogo'] = (logo && logo.data && logo.data.length>0) ? logo.data[0] : null;
            setGeneratedProgress(20);
        //try{
            setGeneratedProgressLabel('Generating Blocks ..')
            addMessage('Generating Blocks ..')
            const banner = await getBannerBlock(name,industry,websiteType,120);
            setGeneratedProgress(30)
        //} catch (error) {
            // Handle errors
        //    console.log('error in banner')
        //}
        //try{
            const about = await getAboutBlock(name,industry,websiteType);
            setGeneratedProgress(40)
        //} catch (error) {
        //    // Handle errors
        //    console.log('error in about')
       // }
        //try{
            setGeneratedProgressLabel('Generating Content for services ..')
            addMessage('Generating Content for services ..')
            const service = await getServicesBlock(name,industry);
            setGeneratedProgress(60)
        //} catch (error) {
            // Handle errors
        //    console.log('error in service')
        //}
        try{
            setGeneratedProgressLabel('Generating Testimonials ..')
            addMessage('Generating Testimonials ..')
            testimonial = await getTestimonialsBlock(name,industry)
            setGeneratedProgress(70)
        } catch (error) {
            // Handle errors
            console.log('error in testimonial')
            console.log(error.message)
        }
        const pickRandomArray = (...arrays) => {
          const randomIndex = Math.floor(Math.random() * arrays.length);
          console.log(randomIndex)
          return arrays[randomIndex];
        }
        
        const homePageBlocks = pickRandomArray([header,banner,service,testimonial,about,footer],[header,banner,testimonial,service,about,footer]);

            console.log(homePageBlocks)
            let newPageData = pageData;
            newPageData['blocks'] = homePageBlocks;
            savePage('Home',newPageData);
            setGeneratedProgressLabel('')
            addMessage('Preparing View ..')
            setGeneratedProgress(100)
            return newPageData;

        } catch (error) {
            // Handle errors
            console.log('error in fetchHomePageData')
            console.log(error.message)
        }
    }
    const savePage = (pageName, {blocks}) => {
        let thePage = {
            "_id": "644759ffb29fcec827b43354",
            "Website": "644759ffb29fcec827b43350",
            "slug": null,
            "label": "Home",
            "showOnHeader": true,
            "showOnFooter": true,
            "default": true,
            "blocks": [],
            "order": 0,
            "createdAt": "2023-04-25T04:41:35.809Z",
            "seo": {
                "title": "Home Page",
                "description": "ZenoCorp, an Accounting business in Bhubaneswar, OR, provides quality financial services to its clients. We specialize in tax preparation, bookkeeping, financial planning, and business consulting. Our team of experts is committed to helping you get the best results for your financial needs.",
                "keywords": "keyword",
                "headCode": "",
                "footerCode": "",
                "Image": null
            },
            "__v": 3
        }
        if(blocks && blocks.length>0){
            thePage['blocks'] = blocks;
        }
        if(pageName && pageName!==''){
            thePage['label'] = pageName;
        }
        localStorage.setItem('homePageData', JSON.stringify(thePage));
    }



    const generateWebsite = async ({thePageData=false}) => {
        const pageData = (thePageData) ? thePageData : await fetchHomePageData(website.name,website.industry,website.websiteType);
        const htmlContent = ReactDOMServer.renderToString(<WebsitePreview pageData={pageData} />);
        //const inlineStyle = require('../App.css');
        //console.log(inlineStyle);

        const inlineStyle = TemplateStylesOne();

        const fontCombo = getFontCombos();

        // Generate the final HTML file
        const finalHtml = `<!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>${website.name}</title>
            <!-- Include necessary stylesheets or scripts -->
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap" rel="stylesheet">            
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/js/all.min.js"></script>
            ${fontCombo.url}
            <style>
            ${inlineStyle.replace("\n",' ')}
            ${fontCombo.style.replace("\n",' ')}
            </style>
          </head>
          <body>
            <div id="root">${htmlContent}</div>
            <!-- Include necessary scripts -->
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>
            </body>
        </html>`;
      
        // Write the HTML content to a file
        //fs.writeFileSync('path/to/website/index.html', finalHtml);
      /*
        // Deploy the website to Netlify
        const client = new API(process.env.NETLIFY_AUTH_TOKEN);
        const deploy = await client.createSiteDeployment(process.env.NETLIFY_SITE_ID, {
          dir: 'path/to/website',
          message: 'Deploying website',
        });
      
        // Assign domains to the website
        await client.createSiteDomain(process.env.NETLIFY_SITE_ID, {
          hostname: 'www.yourdomain.com',
          ssl: true,
        });
        await client.createSiteDomain(process.env.NETLIFY_SITE_ID, {
          hostname: 'yourdomain.com',
          ssl: true,
        });
        */
       return finalHtml;
    }

  const downloadHTMLFile = async () => {
    setGenerated(false);
    setGeneratedProgress(0)
    const htmlContent = await generateWebsite({}); 
    localStorage.setItem('htmlContent', htmlContent);
    setGenerated(true);
    addMessage('Preview Created. Next please Save & Publish ..')
    setGeneratedProgress(100)

    /*
    const fileName = website.name+'-downloaded-file.html';
  
    // Create a blob from the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });
  
    // Create a data URL from the blob
    const dataUrl = URL.createObjectURL(blob);
  
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = fileName;
  
    // Programmatically click the link to trigger the download
    link.click();
  
    // Clean up the temporary URL object
    URL.revokeObjectURL(dataUrl);
    */
  }
  /*
  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  */

  const [generated, setGenerated] = useState(true);
  const [generatedProgress, setGeneratedProgress] = useState(0);
  const [generatedProgressLabel, setGeneratedProgressLabel] = useState('');

  // Return a success message or redirect the user to the generated website
  return (
    <div>
      <h1>Website Deployed</h1>
      <p>
        Your website has been deployed successfully! 
        <Button  color="secondary"  variant="outlined" onClick={downloadHTMLFile}>
            Generate Preview {/*& Download HTML*/} 
            {!generated && 
                <>
                {/* <CircularProgressWithLabel value={generatedProgress} /> */}
                <CircularProgress variant="determinate" value={generatedProgress} color="secondary" />
                </>
            }
        </Button>
        {generatedProgressLabel}
      </p>
    </div>
  );
};

export default WebsiteGenerator;
