import { Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DelayedLink = ({ delay, replace, state, to, ...props }) => {
  const [isNavigating,setIsNavigating] = useState(false);
  const navigate = useNavigate();
  const timerRef = useRef();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = (e) => {
    //e.preventDefault();
    setIsNavigating(true)
    timerRef.current = setTimeout(navigate, delay, to, { replace, state });
  };

  //return <Link to={to} {...props} onClick={clickHandler} />;

  return <Button disabled={isNavigating} component={Link} to={to} {...props} onClick={clickHandler} />;
};

export default DelayedLink;