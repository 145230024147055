import React from 'react';
import { styled } from '@mui/system';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';

// Create a custom styled component
const StyledCardMedia = styled(CardMedia)`
  padding-top: 56.25%; /* 16:9 ratio */
`;

// Create a functional component for the SaaS UI
const SaaSUI = () => {
  return (
    <div>
      <AppBar position="static" sx={{ marginBottom: 3 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Your SaaS App
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <StyledCardMedia
                component="img"
                image="https://via.placeholder.com/300"
                alt="Image title"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Feature 1
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac
                  interdum justo.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <StyledCardMedia
                component="img"
                image="https://via.placeholder.com/300"
                alt="Image title"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Feature 2
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac
                  interdum justo.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <StyledCardMedia
                component="img"
                image="https://via.placeholder.com/300"
                alt="Image title"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Feature 3
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac
                  interdum justo.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SaaSUI;
