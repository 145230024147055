import React, { useContext, useState } from "react";
import { parseLayoutJSON } from "../../../helpers/futureGenerator";

const SingleWebsiteFuture = () => {
  const yourJsonString = {
    "section": {
      "style": {
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "justifyContent": "space-around",
        "height": "100vh"
      },
      "content": [
        {
          "type": "text",
          "tag": "h1",
          "text": "Main Headline",
          "styles": {
            "fontWeight": "bold",
            "fontSize": "24px",
            "color": "#333"
          }
        },
        {
          "type": "image",
          "src": "url-to-image.jpg",
          "alt": "Descriptive alt text",
          "styles": {
            "width": "300px",
            "height": "auto",
            "borderRadius": "8px"
          }
        },
        {
          "type": "text",
          "tag": "p",
          "text": "This is a descriptive paragraph that will explain more details.",
          "styles": {
            "fontSize": "16px",
            "color": "#666"
          }
        },
        {
          "type": "button",
          "text": "Click Me",
          "styles": {
            "padding": "10px 20px",
            "fontSize": "16px",
            "backgroundColor": "#007BFF",
            "color": "white",
            "border": "none",
            "borderRadius": "5px",
            "cursor": "pointer",
            "outline": "none"
          },
          "hoverStyles": {
            "backgroundColor": "#0056b3"
          }
        }
      ]
    }
  }
  
  const { html, css } = parseLayoutJSON(JSON.stringify(yourJsonString));
  return(
    <>
      <>
      {css}
      </>    
      <>
      {html}
      </>
    </>
  )
}

export default SingleWebsiteFuture