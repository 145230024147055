import React from 'react';
import { useNode } from '@craftjs/core';
import { FreeFormComponent } from './FreeFormComponent';

export const ImageComponent = ({ src }) => {
  const { connectors: { connect, drag } } = useNode();

  return (
    <FreeFormComponent>
    <div ref={ref => connect(drag(ref))} style={{ border: '1px dashed black' }}>
      <img src={src} alt="Craft" style={{ width: '20%' }} />
    </div>
    </FreeFormComponent>
  );
};

ImageComponent.craft = {
  props: { src: 'https://images.unsplash.com/photo-1694109075176-bd79e3f4edac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80' }
};
