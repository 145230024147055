
export const getFontCombos = () => {
    const fontsi = [''];
    const fonts = {
      'Crimson Text + Work Sans' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:400,700" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Work Sans', sans-serif;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Crimson Text', serif;
         }`,
      },
      'Oswald + Cardo' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Cardo:400,700|Oswald" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Cardo', serif;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Oswald', sans-serif;
          text-transform: uppercase;
         }
         :root {
            font-size: 22px;
          }
         `,
      },
      'Quicksand (Medium + Light)' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Quicksand:300,500" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Quicksand', sans-serif;
          font-weight: 300;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-weight: 500;
         }`,
      },
      'Abril Fatface + Roboto' : {
        url: '<link href="https://fonts.googleapis.com/css?family=Abril+Fatface|Roboto:300,700" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Abril Fatface', serif;
         }`,
      },           
    };
    const randomFont = () => {
      const fontKeys = Object.keys(fonts);
      const randomIndex = Math.floor(Math.random() * fontKeys.length);
      const randomFontKey = fontKeys[randomIndex];
      return fonts[randomFontKey];
    };
    
    const selectedFont = randomFont();
    return selectedFont;
  }

  export const getAllFontCombos = () => {
    const fonts = [
      {
        name: 'Crimson Text + Work Sans',
        url: '<link href="https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:400,700" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Work Sans', sans-serif;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Crimson Text', serif;
         }`,
      },
      {
        name: 'Oswald + Cardo',
        url: '<link href="https://fonts.googleapis.com/css?family=Cardo:400,700|Oswald" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Cardo', serif;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Oswald', sans-serif;
          text-transform: uppercase;
         }`,
      },
      {
        name: 'Quicksand (Medium + Light)',
        url: '<link href="https://fonts.googleapis.com/css?family=Quicksand:300,500" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Quicksand', sans-serif;
          font-weight: 300;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-weight: 500;
         }`,
      },
      {
        name: 'Abril Fatface + Roboto',
        url: '<link href="https://fonts.googleapis.com/css?family=Abril+Fatface|Roboto:300,700" rel="stylesheet">',
        style: `body, .body-font {
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
         }
         .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
          font-family: 'Abril Fatface', serif;
         }`,
      },
      {
        name: 'Archivo + Open Sans',
        url: '<link href="https://fonts.googleapis.com/css?family=Archivo:500|Open+Sans:300,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Archivo', sans-serif;
            font-weight: 500;
           }`,
      },
      {
        name: 'Montserrat + Work Sans',
        url: '<link href="https://fonts.googleapis.com/css?family=Montserrat:900|Work+Sans:300" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Work Sans', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Montserrat', sans-serif;
            font-weight: 900;
           }`,
      },
      {
        name: 'Lato + Prata',
        url: '<link href="https://fonts.googleapis.com/css?family=Prata:300,700|Lato" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Prata', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Lato', serif;
            text-transform: uppercase;
           }`,
      },
      {
        name: 'Prata + Lato',
        url: '<link href="https://fonts.googleapis.com/css?family=Lato:300,700|Prata" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Lato', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Prata', serif;
           }`,
      },
      {
        name: 'Montserrat + Lora',
        url: '<link href="https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Lora', serif;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            text-transform: uppercase;
           }`,
      },
      {
        name: 'Playfair Display + Chivo',
        url: '<link href="https://fonts.googleapis.com/css?family=Chivo:300,700|Playfair+Display:700i" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Chivo', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6, .singlesaasshotoneslide blockquote {
            font-family: 'Playfair Display', serif;
            font-weight: 700;
            font-style: italic;
           }`,
      },
      {
        name: 'Roboto Slab + Roboto',
        url: '<link href="https://fonts.googleapis.com/css?family=Roboto:300,700|Roboto+Slab:700,500" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Roboto Slab', serif;
            font-weight: 700;
           }
           .singlesaasshotoneslide blockquote {
            font-family: 'Roboto Slab', serif;
            font-weight: 500;
           }`,
      },
      {
        name: 'Sintony + Poppins',
        url: '<link href="https://fonts.googleapis.com/css?family=Poppins:300,700|Sintony:700,500" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Sintony', serif;
            font-weight: 700;
           }
           .singlesaasshotoneslide blockquote {
            font-family: 'Sintony', serif;
            font-weight: 500;
           }`,
      },
      {
        name: 'Open Sans + PT Sans',
        url: '<link href="https://fonts.googleapis.com/css?family=Open+Sans:500,700|PT+Sans:400,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Open Sans', serif;
            font-weight: 700;
           }
           .singlesaasshotoneslide blockquote {
            font-family: 'Open Sans', serif;
            font-weight: 500;
           }`,
      },
      {
        name: 'Bungee Shade + ABeeZee',
        url: '<link href="https://fonts.googleapis.com/css?family=Bungee+Shade:400|ABeeZee:400,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'ABeeZee', sans-serif;
            font-weight: 400;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Bungee Shade', serif;
            font-weight: 400;
            text-spacing: 1em;
           }`,
      },
      {
        name: 'Roboto + Lora',
        url: '<link href="https://fonts.googleapis.com/css?family=Roboto:300,700|Lora:400,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Lora', serif;
            font-weight: 400;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
           }
           blockquote {
            font-family: 'Lora', serif;
            font-weight: 400;
           }`,
      },
      {
        name: 'Montserrat + Merriweather',
        url: '<link href="https://fonts.googleapis.com/css?family=Montserrat:300,700|Merriweather:400,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Merriweather', serif;
            font-weight: 400;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
           }
           blockquote {
            font-family: 'Merriweather', serif;
            font-weight: 400;
           }`,
      },
      {
        name: 'Open Sans + Playfair Display',
        url: '<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,700|Playfair+Display:700,400" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Playfair Display', serif;
            font-weight: 700;
           }
           blockquote {
            font-family: 'Playfair Display', serif;
            font-weight: 400;
           }`,
      },
      {
        name: 'Source Sans Pro + Source Serif Pro',
        url: '<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,700|Source+Serif+Pro:400,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Source Serif Pro', serif;
            font-weight: 400;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 700;
           }
           blockquote {
            font-family: 'Source Serif Pro', serif;
            font-weight: 400;
           }`,
      },
      {
        name: 'Yeseva One + Roboto',
        url: '<link href="https://fonts.googleapis.com/css?family=Yeseva+One|Roboto:300,700" rel="stylesheet">',
        style: `body, .body-font {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
           }
           .header-font, h1, h2, h3, h4, h5, h6 {
            font-family: 'Yeseva One', serif;
            font-weight: 400;
           }
           blockquote {
            font-family: 'Yeseva One', serif;
            font-weight: 400;
           }`,
      },                              
    ];
    return fonts;
  }