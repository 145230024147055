import DynamicElement from "../Forms/parts/DynamicElement";

const FreeFormBlock = ({ block, blockId }) => {
    let theblock = {...block};
    console.log("the block",theblock)
    return (
      <>
        {theblock && theblock?.elements?.map(element => (
            <div key={element.id}>
                <DynamicElement key={element.id} element={element} editMode={false} />
            </div>
        ))}
      </>
    );
  };

export default FreeFormBlock;