import { Box, Container, Skeleton } from "@mui/material";
import { Col, Row } from "react-bootstrap";

  export const getAllComponents = (type) => {
    const components = {
      'banner' : [
        {
          name: 'Heroic',
          identifier: 'heroic',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col">
            <Skeleton animation={false} variant="string" height={10} width={'50%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={10} width={'30%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'80%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'60%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'50%'}  style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Heroic 2',
          identifier: 'heroic2',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <Box sx={{ py: 5, borderBottom: 1, textAlign: 'center', backgroundColor: 'rgba(80, 227, 194, 1)' }}>
          <Container sx={{ my: 5 }}>
            {/* Simulating the Title */}
            <Skeleton variant="text" height={60} width="50%" />
            
            {/* Simulating the Text */}
            {[...Array(4)].map((_, index) => (
              <Skeleton key={index} variant="text" height={20} width={`${80 - index * 10}%`} />
            ))}
            
            {/* Simulating the Button */}
            <Skeleton variant="rectangular" height={40} width={100} />
          </Container>
    
          {/* Additional Skeletons if needed for the '.cs-items' */}
          <Skeleton variant="rectangular" height={100} />
        </Box>,
        },
        {
          name: 'Left Content + Right Image',
          identifier: 'leftContentRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Left Content & Testimonial + Right Image',
          identifier: 'leftContentPlusTestimonialRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={'80%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={10} width={30}  style={{marginBottom: '0.3rem'}} className="bg-secondary" />
                <div className="row">
                  <Col md={2} className="me-0">
                    <Skeleton animation={false} variant="circular" height={15} width={15} />
                  </Col>
                  <Col className="ms-0">
                    <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                    <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  </Col>
                </div>
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Left Content & Brands + Right Image',
          identifier: 'leftContentPlusBrandRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={'80%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={10} width={30}  style={{marginBottom: '0.3rem'}} className="bg-secondary" />
                <div className="row px-2">
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                </div>
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Right Content + Left Image',
          identifier: 'rightContentLeftImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="string" height={80} width={'100%'} className="text-center" />
          </div>
          <div className="col ms-0 py-2">
            <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Video background hero',
          identifier: 'videoBackgroundHero',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'50%'}  style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          </div>,
        },
        {
          name: 'Video background hero 2',
          identifier: 'videoDownBackgroundHero',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'50%'}  style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
          </div>
          </div>,
        },
        {
          name: 'Video sidebar + Content',
          identifier: 'rightContentLeftVideo',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col me-0 pe-0">
            <Skeleton animation="pulse" variant="string" height={80} width={'100%'} className="text-center" />
          </div>
          <div className="col ms-0 py-2">
            <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        /*
        {
          name: 'With side wide floating features',
          identifier: 'sideWideFloating',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
          </div>
          </div>,
        },
        */
      ],
      'quote' : [            
        {
          name: 'Quote 3 column static',
          identifier: 'threeColumnStatic', //'singlesaasshot',
          customerTypes: ['b2b','b2c'], // suitable for which types of customer
          suggestWhenItems: {
            min: 2,
            max: 5,
          },
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col mx-1 px-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col ms-0 ps-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Slides 3 set',
          identifier: 'slide3Set', //'singlesaasshottwo',
          suggestWhenItems: {
            min: 3,
            max: 5,
          },
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col ms-0 ps-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Slides 2 sets',
          identifier: 'slide2Set',
          suggestWhenItems: {
            min: 2,
            max: 5,
          },
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Slides 1 set',
          identifier: 'slide1Set',
          suggestWhenItems: {
            min: 1,
            max: 5,
          },
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col-10 mx-auto">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },      
        {
          name: 'Heroic',
          suggestWhenItems: {
            min: 1,
            max: 1,
          },
          identifier: 'singlesaasshotoneslide',
          design: <div className="row border mx-0 py-3 text-center">
          <div className="border-top border-bottom bg-light py-3">
          <div className="col-10 mx-auto">
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          </div>
        </div>,
        },      
        {
          name: 'Testimonial with respective services link, highlights',
          design: <Skeleton />,
        },
      ],
      // services
      'list' : [
        {
          name: 'Services + Testimonials',
          type: 'collection',
          identifier: 'serviceCumTestimonial',
          suggestWhenItems: {
            min: 1,
            max: 5,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={'80%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={10} width={30}  style={{marginBottom: '0.3rem'}} className="bg-secondary" />
                <div className="row">
                  <Col md={2} className="me-0">
                    <Skeleton animation={false} variant="circular" height={15} width={15} />
                  </Col>
                  <Col className="ms-0">
                    <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                    <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  </Col>
                </div>
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
          subcomponents: [
            {
              name: 'testimonial snippet',
            },
            {
              name: 'Feature video snippet',
            },
          ]
        },
        {
          name: '4blockpacked',
          type: 'collection',
          identifier: '4blockpacked',
          suggestWhenItems: {
            min: 4,
            max: 8,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className="row border py-3 mx-0">
            <div className="col ms-0 ps-0">
              <Skeleton animation={false} variant="string" height={80} />
              <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
              <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
              <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
              <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
            </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
              </div>
            </div>,
          subcomponents: [
            {
              name: 'testimonial snippet',
            },
            {
              name: 'Feature video snippet',
            },
          ]
        },
        {
          name: 'bottomRightImage',
          type: 'collection',
          identifier: 'bottomRightImage',
          suggestWhenItems: {
            min: 4,
            max: 8,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className="row border py-3 px-3 mx-0">
              <div className="col border mx-1 px-0 pt-3">
                <div className="mx-1 mb-2">
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                </div>
                <div>
                  <Skeleton animation={false} variant="string" height={40} style={{marginLeft: '1rem'}} />
                </div>
              </div>
              <div className="col border mx-1 px-0 pt-3">
                <div className="mx-1 mb-2">
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                </div>
                <div>
                  <Skeleton animation={false} variant="string" height={40} style={{marginLeft: '1rem'}} />
                </div>
              </div>
              <div className="col border mx-1 px-0 pt-3">
                <div className="mx-1 mb-2">
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                </div>
                <div>
                  <Skeleton animation={false} variant="string" height={40} style={{marginLeft: '1rem'}} />
                </div>
              </div>
              <div className="col border mx-1 px-0 pt-3">
                <div className="mx-1 mb-2">
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                  <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                </div>
                <div>
                  <Skeleton animation={false} variant="string" height={40} style={{marginLeft: '1rem'}} />
                </div>
              </div>
          </div>,
          subcomponents: [
            {
              name: 'testimonial snippet',
            },
            {
              name: 'Feature video snippet',
            },
          ]
        },
        {
          name: 'accordion',
          type: 'collection',
          identifier: 'accordion',
          suggestWhenItems: {
            min: 2,
            max: 8,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className="row border py-3 mx-0">
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
          subcomponents: [
            {
              name: 'testimonial snippet',
            },
            {
              name: 'Feature video snippet',
            },
          ]
        },
        {
          name: 'A primary service hero, others minor',
          identifier: 'oneFeaturedServiceOthersMinor',
          type: 'collection',
          suggestWhenItems: {
            min: 3,
            max: 4,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className=" border mx-0 p-3"><div className="row">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="string" height={80} width={'100%'} className="text-center" />
          </div>
          <div className="col ms-0 py-2">
            <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
          </div>
          <div className="row px-2">
            <Col md={3} className="p-1">
              <Skeleton animation={false} variant="rectangular" height={50} />
            </Col>
            <Col md={3} className="p-1">
              <Skeleton animation={false} variant="rectangular" height={50} />
            </Col>
            <Col md={3} className="p-1">
              <Skeleton animation={false} variant="rectangular" height={50} />
            </Col>
            <Col md={3} className="p-1">
              <Skeleton animation={false} variant="rectangular" height={50} />
            </Col>
          </div>
          </div>,
        },
        {
          name: 'SaaS feature builder',
          identifier: 'saas2X2square',
          type: 'collection',
          suggestWhenItems: {
            min: 2,
            max: 4,
          },
          primaryItems: {
            min: 1,
            max: 1,
          },
          secondaryItems: {
            min: 2,
            max: 4,
          },
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
            items: {           
              header: {
                limit: 4,
                biggerLimit: 12,
              },
              content: {
                limit: 50,
                biggerLimit: 100,
              },
              amlet: {
                limit: 2,
                biggerLimit: 4,
              },
            }
          },
          design: <div className=" border mx-0 p-3">
          <div className="row">
          <div className="col-6 me-0 pe-1 mb-2">
            <div className="row border-primary">
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={8} width={'30%'} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
              </div>
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={50} width={'100%'} className="text-center" />
              </div>
            </div>
          </div>
          <div className="col-6 ms-0 ps-1 mb-2">
            <div className="row border-primary">
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={8} width={'30%'} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
              </div>
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={50} width={'100%'} className="text-center" />
              </div>
            </div>
          </div>
          <div className="col-6 me-0 pe-1 mb-2">
            <div className="row border-primary">
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={8} width={'30%'} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
              </div>
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={50} width={'100%'} className="text-center" />
              </div>
            </div>
          </div>
          <div className="col-6 ms-0 ps-1 mb-2">
            <div className="row border-primary">
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={8} width={'30%'} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.1rem'}} />
              </div>
              <div className="col-12">
                <Skeleton animation={false} variant="string" height={50} width={'100%'} className="text-center" />
              </div>
            </div>
          </div>
          </div>
          </div>,
        },
      ],
      'hero': [
        {
          name: 'Slides 3 set',
          identifier: 'slide3Set', //'singlesaasshottwo',
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col ms-0 ps-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: '1/3rd Image right',
          identifier: '1/3rdImgRight', //'singlesaasshottwo',
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col ms-0 ps-0">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col-8 ms-0 ps-0">
            <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: '1/3rd Image left',
          identifier: '1/3rdImgLeft', //'singlesaasshottwo',
          design: <div className="row border mx-0 py-3 text-center">
          <div className="col-8 ms-0 ps-0 order-last">
            <Skeleton animation={false} variant="circular" height={15} width={15} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} className="mx-auto" />
            - <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          <div className="col ms-0 ps-0">
            <Skeleton animation={false} variant="text" height={5} width={'30%'}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
      ],      
      'formblock' : [
        {
          name: 'Heroic',
          identifier: 'heroic',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col">
            <Skeleton animation={false} variant="string" height={10} width={'50%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={10} width={'30%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'80%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'60%'} style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={'50%'}  style={{marginBottom: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Left Content + Right Image',
          identifier: 'leftContentRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Left Content & Testimonial + Right Image',
          identifier: 'leftContentPlusTestimonialRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={'80%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={10} width={30}  style={{marginBottom: '0.3rem'}} className="bg-secondary" />
                <div className="row">
                  <Col md={2} className="me-0">
                    <Skeleton animation={false} variant="circular" height={15} width={15} />
                  </Col>
                  <Col className="ms-0">
                    <Skeleton animation={false} height={5}  style={{marginBottom: '0.2rem'}} />
                    <Skeleton animation={false} height={5} width={'60%'}  style={{marginBottom: '0.2rem'}} />
                  </Col>
                </div>
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Left Content & Brands + Right Image',
          identifier: 'leftContentPlusBrandRightImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border py-3 mx-0">
              <div className="col me-0 py-2">
                <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} variant="string" height={5} width={'80%'}  style={{marginBottom: '0.2rem'}} />
                <Skeleton animation={false} height={10} width={30}  style={{marginBottom: '0.3rem'}} className="bg-secondary" />
                <div className="row px-2">
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                      <Col md={3} className="p-1">
                        <Skeleton animation={false} variant="rectangular" height={10} />
                      </Col>
                </div>
              </div>
              <div className="col ms-0 ps-0">
                <Skeleton animation={false} variant="string" height={80} />
              </div>
            </div>,
        },
        {
          name: 'Right Content + Left Image',
          identifier: 'rightContentLeftImage',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col me-0 pe-0">
            <Skeleton animation={false} variant="string" height={80} width={'100%'} className="text-center" />
          </div>
          <div className="col ms-0 py-2">
            <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'Video background hero',
          identifier: 'videoBackgroundHero',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'50%'}  style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
          </div>
          </div>,
        },
        {
          name: 'Video background hero 2',
          identifier: 'videoDownBackgroundHero',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'80%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'60%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={'50%'}  style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
          </div>
          </div>,
        },
        {
          name: 'Video sidebar + Content',
          identifier: 'rightContentLeftVideo',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-3">
          <div className="col me-0 pe-0">
            <Skeleton animation="pulse" variant="string" height={80} width={'100%'} className="text-center" />
          </div>
          <div className="col ms-0 py-2">
            <Skeleton animation={false} variant="string" height={10} style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} />
            <Skeleton animation={false} variant="string" height={5} width={30}  style={{marginBottom: '0.2rem'}} className="mx-auto bg-secondary" />
          </div>
        </div>,
        },
        {
          name: 'With side wide floating features',
          identifier: 'sideWideFloating',
          text: {
            header: {
              limit: 4,
              biggerLimit: 12,
            },
            content: {
              limit: 50,
              biggerLimit: 100,
            },
            amlet: {
              limit: 2,
              biggerLimit: 4,
            },
          },
          design: <div className="row border mx-0 py-1">
          <div className="col">
            <Skeleton animation={false} variant="text" height={10} width={'50%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={8} width={'30%'} style={{marginBottom: '0.1rem'}} className="mx-auto" />
            <Skeleton animation={false} variant="text" height={5} width={30}  style={{marginBottom: '0.1rem'}} className="mx-auto bg-secondary" />
            <Skeleton height={80} width={100} style={{margin: '0.2rem'}} className="mx-auto" />
          </div>
          </div>,
        },
      ],
    }
    return components[type];
  }

  // if number of services are greater than x amount, do suggest one way, else suggest otherway.

  const primaryComponents = {
    // these components will be used as the variables and dynamically replace the stuff, especially contents
    'human': '', // design component of human here
    'header': '', // header skeleton
    'paragraph': '', // same components can be used to generate the final 
  }