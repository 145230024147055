import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { getRandomIpsumLogo } from '../../helpers/randoms';
const LogosBlock = ({ block, blockId }) => {
    let theblock = {...block};
    theblock['items'] = (theblock['items']) ? theblock['items'] : [
      {
          "image": {
            "url": getRandomIpsumLogo(),
            "urlSmall": getRandomIpsumLogo(),
          },
          "idx": 1
      },
      {
          "image": {
            "url": getRandomIpsumLogo(),
            "urlSmall": getRandomIpsumLogo(),
          },
          "idx": 2
      },
      {
          "image": {
            "url": getRandomIpsumLogo(),
            "urlSmall": getRandomIpsumLogo(),
          },
          "idx": 3
      },
      {
          "image": {
            "url": getRandomIpsumLogo(),
            "urlSmall": getRandomIpsumLogo(),
          },
          "idx": 4
      },
      {
          "image": {
            "url": getRandomIpsumLogo(),
            "urlSmall": getRandomIpsumLogo(),
          },
          "idx": 5
      },
    ];

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`} style={{backgroundColor: theblock.background+' !important'}}>
        <Container>
          <div className="row text-center">
            <div className='h3 pt-5 col'>{theblock.headline}</div>
            <div className='col-md-12 pb-3'>{theblock.content}</div>
          </div>
          <div className="row text-center px-5 d-flex justify-content-center">
            {(theblock.items && theblock.items.length>0) && theblock.items.map((column, index) => (
              <div key={index} className='col-md-2 col-sm-12'>
                {column.image && 
                        <Card.Img loading='lazy' variant="top" src={column.image.url} alt={column.description} height="80" />
                }
              </div>
            ))}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default LogosBlock;