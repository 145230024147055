import React, { useState } from 'react';
import axios from 'axios';

const CreateBucketForm = () => {
  const [bucketName, setBucketName] = useState('');

  const handleCreateBucket = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:3089/create-bucket', {
        bucketName,
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error creating bucket:', error);
    }
  };

  return (
    <div>
      <h1>Create Bucket</h1>
      <form onSubmit={handleCreateBucket}>
        <label>
          Bucket Name:
          <input type="text" value={bucketName} onChange={(e) => setBucketName(e.target.value)} />
        </label>
        <button type="submit">Create Bucket</button>
      </form>
    </div>
  );
};

const UploadFileForm = () => {
  const [bucketName, setBucketName] = useState('');
  const [folderName, setFolderName] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');

  const handleUpload = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:3089/upload-file', {
        bucketName,
        folderName,
        fileName,
        fileContent,
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <h1>Upload File to S3</h1>
      <form onSubmit={handleUpload}>
        <label>
          Bucket Name:
          <input type="text" value={bucketName} onChange={(e) => setBucketName(e.target.value)} />
        </label>
        <label>
          Folder Name:
          <input type="text" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
        </label>
        <label>
          File Name:
          <input type="text" value={fileName} onChange={(e) => setFileName(e.target.value)} />
        </label>
        <label>
          File Content:
          <textarea value={fileContent} onChange={(e) => setFileContent(e.target.value)} />
        </label>
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

const AddDomainForm = () => {
    const [bucketName, setBucketName] = useState('');
    const [domainName, setDomainName] = useState('');
  
    const handleCreateBucket = async (e) => {
      e.preventDefault();
  
      try {
        const response = await axios.post('http://localhost:3089/enable-hosting', {
          bucketName,
        });
        /*
        const domainResponse = await axios.post('http://localhost:3089/add-domain', {
          bucketName,
          domainName,
        });
        */
        console.log(response.data);
        //console.log(domainResponse.data);
      } catch (error) {
        console.error('Error enabling hosting or assigning domain:', error);
      }
    };
  
    return (
      <div>
        <h1>Create Bucket</h1>
        <form onSubmit={handleCreateBucket}>
          <label>
            Bucket Name:
            <input type="text" value={bucketName} onChange={(e) => setBucketName(e.target.value)} />
          </label>
            <label>
                Domain Name:
                <input type="text" value={domainName} onChange={(e) => setDomainName(e.target.value)} />
            </label>
          <button type="submit">Assign Domain</button>
        </form>
      </div>
    );
  };

const AwsFileFour = () => {
  return (
    <div>
      <CreateBucketForm />
      <UploadFileForm />
      <AddDomainForm />
    </div>
  );
};

export default AwsFileFour;
