import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, IconButton, Switch, TextField, TextareaAutosize, Typography } from '@mui/material';
import React, { useState } from 'react';
//import ImageUploader from './ImageUploader';
import ImageUploaderNew from './ImageUploaderNew';
import { GriderLayout } from './GriderLayout';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

/*
const newForm = {
  "title": "Registration Form",
  "fields": [
      {
          "label": "First Name",
          "type": "text",
          "required": true,
          "placeholder": "Enter your first name"
      },
      {
          "label": "Last Name",
          "type": "text",
          "required": true,
          "placeholder": "Enter your last name"
      },
      {
          "label": "Email",
          "type": "email",
          "required": true,
          "placeholder": "Enter your email"
      },
      {
          "label": "Age",
          "type": "number",
          "required": true,
          "placeholder": "Enter your age"
      },
      {
          "label": "Date of Birth",
          "type": "date",
          "required": true
      },
      {
          "label": "Gender",
          "type": "select",
          "required": true,
          "options": [
              {
                  "value": "male",
                  "display": "Male"
              },
              {
                  "value": "female",
                  "display": "Female"
              },
              {
                  "value": "other",
                  "display": "Other"
              }
          ]
      }
  ]
};
*/

const FormForm = ({ data, onUpdate }) => {
  const staticUUID = Date.now() + Math.random(); //uuidv4();
  const [formData, setFormData] = useState({...data, uuid: (data.uuid)? data.uuid : staticUUID}  || {});
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const StyledAccordionSummary = styled(AccordionSummary)({
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const StyledIconButton = styled(IconButton)({
    //marginRight: 'auto',
    cursor: 'grab !important',
  });
  
  const handleItemChange = (index, field, value) => {
    console.log('index',index,'field',field,'value',value);
    const updatedfields = [...formData.fields];
    updatedfields[index] = { ...updatedfields[index], [field]: value };
    setFormData({ ...formData, fields: updatedfields });
  };
  const handleItemDelete = (index,event) => {
    event.stopPropagation();
    const newFields = [...formData.fields];
    newFields.splice(index, 1);
    setFormData({ ...formData, fields: newFields });
  };

  const handleSave = () => {
    //alert(JSON.stringify({...formData, ...formData, fields: formData.fields,  title: formData.title, header: formData.header}))
    onUpdate({...formData, fields: formData.fields,  title: formData.title, header: formData.header});
  };  
    
  const handleDragStart = (event, index) => {
    event.stopPropagation();
    event.dataTransfer.setData('text/plain', index);
    setIsDragging(true);
  };

  const handleDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
  };
  const handleDrop = async (event, targetIndex) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData('text/plain');
    const draggedItem = formData.fields[draggedIndex];
    const targetItem = formData.fields[targetIndex];

    const newFields = [...formData.fields];
    newFields[draggedIndex] = targetItem;
    newFields[targetIndex] = draggedItem;

    let newFormData = {...formData, fields: newFields};
    setFormData(newFormData);
    setIsDragging(false);
    onUpdate(newFormData); 
  };
  
  const handleAddItem = () => {
    const newItem = {
      "label": "",
      "type": "text",
      "required": true,
      "placeholder": "Some text",
      idx: formData.fields.length,
    };
    setFormData({ ...formData, fields: [...formData.fields, newItem] });
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  
  // Function to handle the change of select options
const handleOptionChangeOld = (fieldIndex, optionIndex, value) => {
  const updatedFields = [...formData.fields];
  const options = [...updatedFields[fieldIndex].options];
  options[optionIndex].value = value;
  updatedFields[fieldIndex].options = options;
  setFormData({ ...formData, fields: updatedFields });
};
const handleChangeOption = (fieldIndex, optionIndex, type, newValue) => {
  const updatedFields = [...formData.fields];
  const option = updatedFields[fieldIndex].options[optionIndex];

  if (type === 'value') {
    option.value = newValue;
  } else if (type === 'display') {
    option.display = newValue;
  }

  setFormData({
    ...formData,
    fields: updatedFields,
  });
};


// Function to handle adding a new option
const handleAddOptionOld = (fieldIndex) => {
  const updatedFields = [...formData.fields];
  updatedFields[fieldIndex].options.push({ value: '' });
  setFormData({ ...formData, fields: updatedFields });
};

const handleAddOption = (fieldIndex) => {
  const updatedFields = [...formData.fields];
  const field = updatedFields[fieldIndex];
  
  if (!field.options) {
    field.options = [];
  }

  field.options.push({
    value: "",
    display: "",
  });
  
  setFormData({
    ...formData,
    fields: updatedFields,
  });
};


// Function to handle removing an option
const handleRemoveOption = (fieldIndex, optionIndex) => {
  const updatedFields = [...formData.fields];
  updatedFields[fieldIndex].options.splice(optionIndex, 1);
  setFormData({ ...formData, fields: updatedFields });
};

  return (
    <div>   
      <label>
        <TextField 
        variant="outlined"
        label={'UUID'}
        value={formData.uuid}
        readOnly
        fullWidth 
        style={{ marginTop: 11 }}
        />
      </label>
      <FormControlLabel
        control={
          <Switch
            checked={formData.isModal}
            onChange={handleCheckBoxChange}
            name="isModal"
            inputProps={{ 'aria-label': 'Is Modal?' }}
          />
        }
        label="Is Modal?"
      />

      <label>
        <TextField 
        variant="outlined"
        label={'Form Title'} 
        value={formData.title}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, title: e.target.value})}
        />
      </label>
      <label>
        <TextField 
        variant="outlined"
        label={'Form Display header'} 
        value={formData.headline}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, headline: e.target.value})}
        />
      </label>
      <label>Content:</label>
        <TextareaAutosize
            minRows={4}
            aria-label="Display short text below header"
            placeholder="Content"
            style={{width: '100%'}}
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
      />
      {formData.background && <ImageUploaderNew image={formData.background.image}
      onUpdateImage={(e) => {
        setFormData({ ...formData, background: { ...formData.background, image: { ...formData.background.image, url: e.urls.regular, id: e.id } } });
      }}
      />}
      <GriderLayout
        left={'Text Alignment:'}
        right={<select
          value={formData.align}
          onChange={(e) => setFormData({ ...formData, align: e.target.value })}
        >
          <option value="top">Top</option>
          <option value="middle">Middle</option>
          <option value="bottom">Bottom</option>
        </select>}
      />
      {formData.image && formData.image.media && <ImageUploaderNew image={formData.image.media} 
      onUpdateImage={(e) => {
        setFormData({ ...formData, image: { ...formData.image, media: { ...formData.image.media, url: e.urls.regular, id: e.id } } });
      }}
      />}
      <br />

      <GriderLayout
        left={'Image Alignment:'}
        right={<select
          value={formData.image.align}
          onChange={(e) =>
            setFormData({ ...formData, image: { ...formData.image, align: e.target.value } })
          }
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>}
      />
      <GriderLayout
        left={'Image Corner Radius:'}
        right={<select
          value={formData.image.cornerRadius}
          onChange={(e) =>
            setFormData({ ...formData, image: { ...formData.image, cornerRadius: e.target.value } })
          }
        >
          <option value="none">None</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>}
      />
      <GriderLayout
        left={'Background Color:'}
        right={<input
          type="color"
          value={formData.background}
          onChange={(e) => setFormData({ ...formData, background: e.target.value })}
        />}
      />
      <hr />
      <h2>Fields</h2>
      {formData && formData.fields && formData.fields.length>0 && formData.fields.map((field,index) => (        
        <Accordion
          key={index}                               
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
          onTouchStart={(event) => handleDragStart(event, index)}
          onTouchMove={handleDragOver}
          onTouchEnd={(event) => handleDrop(event, index)}
          //ref={drop} 
          style={{ opacity: isDragging ? 0.5 : 1 }}
          >
            
        <StyledAccordionSummary
          //expandIcon={}
        >
          <StyledIconButton>
            <DragIndicatorIcon />
          </StyledIconButton>
          <Typography variant="h6">{field.label}</Typography>
          <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleItemDelete(index,event)}><DeleteIcon /></IconButton>
        </StyledAccordionSummary>
        <AccordionDetails>
          <div key={index} >
            <TextField 
              variant="outlined"
              label={'Label'}
              value={field.label}
              fullWidth 
              style={{ marginTop: 11 }}
              onChange={(e) => handleItemChange(index, 'label', e.target.value)}
              />
              <select
                value={field.type} 
                style={{border: 'none', background: 'transparent'}}
                onChange={(e) => handleItemChange(index, 'type', e.target.value)}
                >
                  <option value="text">Text</option>
                  <option value="textarea">Text Area</option>
                  <option value="date">Date</option>
                  <option value="email">Email</option>
                  <option value="number">Number</option>
                  <option value="select">Select</option>
                  <option value="checkbox">Checkbox</option>
              </select>
              {(['email','text','number'].includes(field.type)) &&
                <TextField 
                  variant="outlined"
                  label={'Placeholder'}
                  value={field.placeholder}
                  fullWidth 
                  style={{ marginTop: 11 }}
                  onChange={(e) => handleItemChange(index, 'placeholder', e.target.value)}
                />
              }
              {(['select','checkbox','radio'].includes(field.type)) &&
                <div className='ps-2'>
                  <h4>Options:</h4>
                  {field.options && field.options.length>0 && field.options.map((option, optionIndex) => (
                    <div className='ps-2' key={optionIndex}>
                      <TextField 
                        variant="outlined"
                        label={'Value'}
                        value={option.value}
                        fullWidth 
                        style={{ marginTop: 11 }}
                        onChange={(e) => handleChangeOption(index, optionIndex, 'value', e.target.value)}
                        />
                      <TextField 
                        variant="outlined"
                        label={'Label'}
                        value={option.display}
                        fullWidth 
                        style={{ marginTop: 11 }}
                        onChange={(e) => handleChangeOption(index, optionIndex, 'display', e.target.value)}
                      />
                      <button onClick={() => handleRemoveOption(index, optionIndex)}>Remove</button>
                    </div>
                  ))}                  
                  <button onClick={() => handleAddOption(index)}>Add Option</button>
                </div>
              }              
              <GriderLayout
                left={'Required?'}
                right={<input
                  type="checkbox"
                  checked={field.required}
                  onChange={(e) => handleItemChange(index, 'required', e.target.checked)}
                />}
              />
          </div>
        </AccordionDetails>
        </Accordion>
      ))}
      <button onClick={handleAddItem}>Add Item</button>
      <h4 className='mt-3'>How do you want to submit the form?</h4>      
      <label>
        <TextField 
        variant="outlined"
        label={'Submit To URL'} 
        value={formData.submitTo}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, submitTo: e.target.value})}
        />
      </label>
      <select
        value={formData.submitMethod} 
        style={{border: 'none', background: 'transparent'}}
        onChange={(e) => setFormData({...formData, submitMethod: e.target.value})}
        >
          <option value="POST">POST</option>
          <option value="GET">GET</option>
      </select>
      <label>
        <TextField 
        variant="outlined"
        label={'Message to show if form submitted successfully'} 
        value={(formData.messages) ? formData.messages.success : 'Thanks for submitting'}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, messages: {...formData.messages, success: e.target.value}})}
        />
      </label>
      <label>
        <TextField 
        variant="outlined"
        label={'Message to show if failure occured'} 
        value={(formData.messages) ? formData.messages.failure : 'Failure occurred, please try later'}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, messages: {...formData.messages, failure: e.target.value}})}
        />
      </label>  
      <label>
        <TextField 
        variant="outlined"
        label={'Submit Button Text'} 
        value={formData.submitText}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, submitText: e.target.value})}
        />
      </label>
      <label>
        <TextField 
        variant="outlined"
        label={'On Submit Events'} 
        value={formData.submitEvent}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, submitEvent: e.target.value})}
        />
      </label>
      <hint>On submit button click, you can add this</hint>
      <label>
        <TextField 
        variant="outlined"
        label={'On Success Events'} 
        value={formData.successEvent}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, successEvent: e.target.value})}
        />
      </label>
      <label>
        <TextField 
        variant="outlined"
        label={'On Failure Events'}
        value={formData.failureEvent}
        fullWidth 
        style={{ marginTop: 11 }}
        onChange={(e) => setFormData({...formData, failureEvent: e.target.value})}
        />
      </label>

      <hr />
        <GriderLayout left={'Hide'} 
          right={<input
            type="checkbox"
            value={true}
            checked={formData.isHidden}
            onChange={(e) => setFormData({ ...formData, isHidden: e.target.checked})}
          />} 
        />
      <br />
      <Button onClick={handleSave} fullWidth className='my-3' variant='outlined' color='primary'>Save</Button>
    </div>
  );
};

export default FormForm;
