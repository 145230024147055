import React, { useState } from 'react';
import AWS from 'aws-sdk';

const AWSUploadFileForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file');
      return;
    }

    try {
      const s3 = new AWS.S3({
        accessKeyId: 'AKIAQ6NJMFA26PQLZW2F',
        secretAccessKey: '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',
        region: 'us-east-1',
      });

      const params = {
        Bucket: 'websitebuilder-3599',
        Key: file.name,
        Body: file,
      };

      await s3.upload(params).promise();

      alert('File uploaded successfully');
    } catch (error) {
      console.error(error);
      alert('An error occurred while uploading the file');
    }
  };

  return (
    <div>
      <h2>Upload to Amazon S3</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default AWSUploadFileForm;
