import React, { useContext, useState } from "react";
import EditorContext from "../../contexts/EditorContext";
import WebsitePreviewerLive from "../../../components/WebsitePreviwerLive";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Button } from "@mui/material";
import html2canvas from "html2canvas";
import { supabase } from "../../../supabase";
import domtoimage from 'dom-to-image';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useDispatch, useSelector } from "react-redux";
import { updateWebsite, updateWebsitePageDefault } from "../../redux/slices/websiteSlice";
import axios from "axios";

const SingleWebsitePreview = ({theSiteId,iframeRef,children,toRefresh=1}) => {
    const { font, colorPallette } = useContext(EditorContext);
    const [viewportWidth, setViewportWidth] = useState((localStorage.getItem('EditorViewportWidth')) ? localStorage.getItem('EditorViewportWidth') : '100%');
    const [viewportZIndex, setViewportZIndex] = useState(0);
    const [websiteHtml, setWebsiteHtml] = useState((localStorage.getItem('htmlContent-'+theSiteId)) ? localStorage.getItem('htmlContent-'+theSiteId) : null);
    const [expand, setExpand] = useState(false);

    const dispatch = useDispatch();
    
    const currentEditedWebsitePageId = useSelector(state => state.websites.currentEditedWebsitePageId);
    const websiteData = useSelector(state => state.websites?.websites?.find(website => website.id === parseInt(theSiteId)));


    const htmlChanged = (f) => {
      setWebsiteHtml(f)
    }

    //const iframeRef = useRef(null);
    
    const captureScreenshotOld = async () => {
            
        const iframeElement = iframeRef.current;
    
        if (iframeElement && iframeElement.contentWindow) {
          const iframeDocument = iframeElement.contentWindow.document;
          const iframeBody = iframeDocument.body;

            //html2canvas(iframeBody).then((canvas) => {
            // Convert canvas to image data URL
            //const screenshot = canvas.toDataURL();
            // Do something with the screenshot data, such as save it or display it
            //console.log(screenshot);
          const screenshot = await html2canvas(iframeBody);
          const screenshotBlob = await new Promise((resolve) => {
            screenshot.toBlob((blob) => {
              resolve(blob);
            });
          });
      
          await supabase.storage.from('images').upload('screenshot-'+Math.random()+'.png', screenshotBlob);
        }
    };

    const captureScreenshot = async () => {
      const iframe = iframeRef.current;
  
      try {
        // Access iframe content
        const iframeContent = iframeRef.current.contentWindow.document; //iframe.contentWindow.document;
  
        // Capture the screenshot using dom-to-image
        const dataUrl = await domtoimage.toPng(iframeContent.body);
        
        // Upload the screenshot to Supabase storage
        const { data, error } = await supabase.storage
          .from('images')
          .upload('screenshot-'+Math.random()+'.png', dataUrl);
  
        if (error) {
          console.error('Error uploading screenshot to Supabase:', error);
        } else {
          console.log('Screenshot uploaded to Supabase:', data);
        }
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      }
    };
    function downloadHTMLFile(htmlContent) {
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.html';
      link.click();
      URL.revokeObjectURL(url);
    }

    const saveWebsite = async() => {
      // send to update the website / page html
      const { user, session } = supabase.auth;
      const jwt = session?.access_token;

      console.log('Save executing',theSiteId,currentEditedWebsitePageId,websiteHtml)

      const websiteId = theSiteId;
      const projectName = `websitedeploy${theSiteId}`;
      const fileContent = websiteHtml;

      if(currentEditedWebsitePageId===null || currentEditedWebsitePageId==="null"){
        console.log('Save executing homepage dispatch',theSiteId,currentEditedWebsitePageId,websiteHtml)

        dispatch(updateWebsite({id: theSiteId,data: {html: websiteHtml}})).then( async (res) => {
          /*
          try {
            const response = await axios.post('https://bvorqhdsibaukalonivs.supabase.co/functions/v1/websitedeploy', {
              projectName,
              websiteId,
              fileContent,
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
              },
            });
      
            console.log(response.data.message);
          } catch (error) {
            console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
          } finally {
            //setIsLoading(false);
          }
          */
        })
      } else{
        console.log('Save executing page dispath',theSiteId,currentEditedWebsitePageId,websiteHtml)
        dispatch(updateWebsitePageDefault({id: currentEditedWebsitePageId,data: {html: websiteHtml}}))
      }
    }
    const publishWebsite = async() => {
      await saveWebsite();
    }

    const changeViewPortWidth = (width) => {
        setViewportWidth(width)
        localStorage.setItem('EditorViewportWidth', width)
    }

    let theStyle = {
        width: viewportWidth,
    }    
    let expandStyle = {
    }
    if(expand){
        theStyle['zIndex'] = '2000';
        theStyle['position'] = 'fixed';
        theStyle['left'] = 0;
        theStyle['top'] = 0;

        expandStyle['zIndex'] = '4000';
        expandStyle['position'] = 'fixed';
        expandStyle['right'] = 0;
        expandStyle['top'] = 0;
    }

    const switchExpand = () => {
        setExpand(!expand);
        setViewportWidth('100%')
    }

    return(        
        <div className="bg-light mx-auto" style={theStyle} id={toRefresh}>
            <WebsitePreviewerLive 
              websiteId={theSiteId} 
              theFontCombo={font} 
              theColorPallete={colorPallette} 
              iframeRef={iframeRef} 
              onHTMLChange={(e) => htmlChanged(e)} 
              toRefresh={toRefresh}
              >
                <div className="row" style={expandStyle}>
                    <div className="col">
                        {!expand && theSiteId && websiteHtml && <>
                        <Button className="fw-bold ms-5" onClick={captureScreenshot}>Screenshot</Button>
                        <Button className="fw-bold" onClick={() => downloadHTMLFile(websiteHtml)}>Download</Button>
                        {/*<Button className="fw-bold" onClick={saveWebsite}>Save</Button>*/}
                        <Button className="fw-bold" onClick={publishWebsite}>Publish</Button>
                        <Button className="btn" target='_blank' href={`https://${websiteData?.staging_domain}`}>
                          Preview
                        </Button>
                        {websiteData?.domain && 
                          <Button className="btn" target='_blank' href={`https://${websiteData?.domain}`}>
                            Website
                          </Button>
                        }
                        </>}
                    </div>
                    <div className="col border-left d-inline-block text-end">
                        {!expand && <>
                        <Button className="px-1 ms-5 text-lg" onClick={() => changeViewPortWidth('100%')}><WebAssetIcon /></Button>
                        <Button className="px-1" onClick={() => changeViewPortWidth('428px')}><SmartphoneIcon /></Button>
                        </>}
                        <Button className="px-1" onClick={switchExpand}>{(!expand) ? <OpenInFullIcon /> : <CloseFullscreenIcon /> }</Button>
                    </div>
                </div>
            </WebsitePreviewerLive>
        </div>
    )
}

export default SingleWebsitePreview