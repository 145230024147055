import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {Col, Row, Card, Button, Container} from 'react-bootstrap';
import { generateRandomPath, getRandomIpsumLogo, getRandomRadius } from '../../helpers/randoms';
import MarkdownRenderer from '../../NEW/components/MarkdownRenderer';
const HtmlBlock = ({ block, blockId }) => {
    let theblock = {...block};

    const designType = (theblock.componentDesign) ? theblock.componentDesign.identifier : 'parallel';
    const arrowHtmlEntity = '&rarr;';
    return (
      <>
      {(designType==='parallel') ?
      <div className={`py-5 ${((blockId%2)===0) ? 'lightbg' : 'lightaccentbg'}`}>
        <Container>
          <div className="row text-center">
            <div className='pt-5 col'><h2 className='display-3'>{theblock.headline}</h2></div>
            <div className='col-md-12 pb-3'><MarkdownRenderer markdownString={theblock.content} /></div>
            {theblock.button && <span className='btn btn-primary btn-lg'>{theblock.button.label}</span>}
          </div>
        </Container>
      </div>
      : ''}
      </>
    );
  };

export default HtmlBlock;