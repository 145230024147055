const TemplateStylesOne = () => {
    const styles = `.App {
        /*text-align: center;*/
        font-family: 'DM Sans', sans-serif;
      }
      
      .App-logo {
        height: 40vmin;
        pointer-events: none;
      }
      
      @media (prefers-reduced-motion: no-preference) {
        .App-logo {
          animation: App-logo-spin infinite 20s linear;
        }
      }
      
      .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
      }
      
      .App-link {
        color: #61dafb;
      }
      
      @keyframes App-logo-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
      /* will write a css code for popup */
      .thumbnail {
        width: 100px; /* Adjust the width and height as per your requirements */
        height: 100px;
        border-radius: 50%; /* Make the image circular */
        object-fit: cover; /* Ensure the image fills the thumbnail without distortion */
      }
      .bannerWithBg::before {   
            content: "";
            /* background-image: url('https://placekitten.com/1200/800'); */
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.75;
      }
      .nav-link{
        color: aliceblue;
      }
      .nav-link:hover{
        color: aliceblue;
      }

      .form-control::placeholder {
        opacity: 0.5!important;
      }

      .py-sm-0{
        padding-top: 0rem!important;
        padding-bottom: 0rem!important;
      }
      .py-sm-1{
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important;
      }
      .py-sm-2{
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important;
      }
      .py-sm-3{
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
      }
      .py-sm-4{
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important;
      }
      .py-sm-5{
        padding-top: 3rem!important;
        padding-bottom: 3rem!important;
      }
      .px-sm-0{
        padding-left: 0rem!important;
        padding-right: 0rem!important;
      }
      .px-sm-1{
        padding-left: 0.25rem!important;
        padding-right: 0.25rem!important;
      }
      .px-sm-2{
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
      }
      .px-sm-3{
        padding-left: 1rem!important;
        padding-right: 1rem!important;
      }
      .px-sm-4{
        padding-left: 1.5rem!important;
        padding-right: 1.5rem!important;
      }
      .px-sm-5{
        padding-left: 3rem!important;
        padding-right: 3rem!important;
      }

      
      .py-md-0{
        padding-top: 0rem!important;
        padding-bottom: 0rem!important;
      }
      .py-md-1{
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important;
      }
      .py-md-2{
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important;
      }
      .py-md-3{
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
      }
      .py-md-4{
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important;
      }
      .py-md-5{
        padding-top: 3rem!important;
        padding-bottom: 3rem!important;
      }
      .px-md-0{
        padding-left: 0rem!important;
        padding-right: 0rem!important;
      }
      .px-md-1{
        padding-left: 0.25rem!important;
        padding-right: 0.25rem!important;
      }
      .px-md-2{
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
      }
      .px-md-3{
        padding-left: 1rem!important;
        padding-right: 1rem!important;
      }
      .px-md-4{
        padding-left: 1.5rem!important;
        padding-right: 1.5rem!important;
      }
      .px-md-5{
        padding-left: 3rem!important;
        padding-right: 3rem!important;
      }
      `;
    return styles;
}

export default TemplateStylesOne;