import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardMedia, CardContent, Button, TextField, Grid, Select, MenuItem } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import { styled } from '@mui/system';
import { supabase } from '../../../supabase';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import { getRandomPic } from '../../../helpers/sections';


//const supabase = createClient('YOUR_SUPABASE_URL', 'YOUR_SUPABASE_API_KEY');

const ImageCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  maxWidth: 400,
  margin: 'auto',
  marginBottom: theme.spacing(2),
  transition: '0.3s',
  '&:hover': {
    //boxShadow: theme.shadows[6],
  },
}));

const ImageContent = styled(CardContent)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: (theme) => theme.spacing(2),
});

const Image = styled(CardMedia)({
  height: 200,
  width: '100%',
  objectFit: 'cover',
});

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ImageUploaderNew = ({ blockData, image, onUpdateImage, features=['uploader','unsplash','gallerry','previewBox'] }) => {
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  if(!image){
    image = {
      "_id": "64536415bbca55b72afa69f9",
      "author": "Stephanie",
      "authorLink": "https://unsplash.com/@steph?utm_source=Durable.co&utm_medium=referral",
      "type": "unsplash",
      "url": getRandomPic('personheadshot'),
      "urlSmall": getRandomPic('personheadshot'),
      "Business": "644759ffb29fcec827b43346",
      "createdAt": "2023-05-04T07:51:49.971Z",
      "__v": 0,
      "id": "64536415bbca55b72afa69f9"
    };
  }

  const handleFileChange = (event) => {
    handleUpload()
  };

  const handleSearch = async () => {
    setIsSearching(true)
    try {

      const response = await axios.get(`https://api.unsplash.com/search/photos?query=${searchQuery}&per_page=10&client_id=hY3ez-K60a2GYlTfb7UjdWEw9P8h9vQvb0VqRKtGZVc`);

      console.log('response images', response)
      const results = response.data.results.map((item) => {
        const { id, urls } = item;
        return {
          id,
          url: urls.regular,
          smallUrl: urls.small,
          urls: urls,
        };
      });
      setSearchResults(results);
      setIsSearching(false);
    } catch (error) {
      console.log('Error searching for image:', error);
      setIsSearching(false);
    }
  };

  const uploadToSupabase = async (fileName) => {
    const { data, error } = await supabase.storage.from('images').upload(`public/${fileName}`, file, {
      cacheControl: '3600',
      upsert: false,
    }).on('upload', (response) => {
      const { data, error } = response;
      if (error) {
        console.log('Error uploading image:', error);
        return null;
      };

    return data;
    })
  };


  const handleUpload = async (event) => { 
    
    setIsUploading(true)
    const selectedFile = event.target.files[0];
    setFile(selectedFile);    
    const random = Math.random(); //uuidv4()
    const fileName = `${random}-${selectedFile.name}`;
    try {
      //const fileName = `${uuidv4()}-${file.name}`; 
      //const data = await uploadToSupabase(fileName);

        const { data: signedUrlData, error: signedUrlError } = await supabase.storage
        .from('images')
        .createSignedUploadUrl('public/'+fileName, 600); // Set the expiration time for the signed URL (in seconds)

        if (signedUrlError) {
            console.log('Error creating signed URL:', signedUrlError);
            return;
        }
        //console.log(signedUrlData);
        //return null;
        /*
        const { signedURL, error: uploadError } = await axios.put(signedUrlData.signedURL, file, {
        headers: {
            'Content-Type': file.type,
        },
        });
        */
        const { data: uploadData, error: uploadError } = await supabase
        .storage
        .from('images')
        .uploadToSignedUrl('public/'+fileName, signedUrlData.token, selectedFile, {
          cacheControl: 'public, max-age=31536000',
        })

        if (uploadError) {
            console.log('Error uploading image:', uploadError);
            return;
        }

        //console.log(uploadData);
        //return null;

        
        const { data: publicURLData, error: publicURLError } = supabase
        .storage
        .from('images')
        .getPublicUrl('public/'+fileName)

        //console.log(publicURLData);

        //return null;

        if (publicURLError) {
        console.log('Error retrieving image URL:', publicURLError);
        return;
        }

      console.log('file uploaded',publicURLData.publicUrl);

      if (publicURLData.publicUrl) {
        /*
        const { publicURL } = supabase.storage
          .from('images')
          .getPublicUrl(`public/${fileName}`);
        */

        const publicURL = publicURLData.publicUrl; //'https://bvorqhdsibaukalonivs.supabase.co/storage/v1/object/public/images/public/'+encodeURIComponent(fileName)
        console.log('file uploaded URL',publicURL);
        const newImage = {
          url: publicURL,
          smallUrl: publicURL,
          urls: {
            regular: publicURL,
            thumb: publicURL,
          }
        };

        onUpdateImage(newImage);
        console.log(newImage)
      }
      setIsUploading(false)
    } catch (error) {
      console.log('Error uploading image:', error);
      setIsUploading(false)
    }
  };

  /*
  const handleUpload = async () => {
    const random = Math.random(); //uuidv4()
    const fileName = `${random}-${file.name}`;

    try {
      const { data, error } = await supabase.storage.from('images').upload(`public/${fileName}`, file, {
        cacheControl: '3600',
        upsert: false,
      });
      if (error) {
        console.log('Error uploading image:', error);
        return;
      }
      const url = URL.createObjectURL(file);
      const newImage = {
        url,
        smallUrl: url,
        urls: {
            regular: url,
        }
      };
      onUpdateImage(newImage);
    } catch (error) {
      console.log('Error uploading image:', error);
    }
  };
  */
  const handleUpdate = (selectedImage) => {
    onUpdateImage(selectedImage);
    setSearchResults([]);
  };

  // State to hold the selected value
  const [selectedValue, setSelectedValue] = useState('');

  // Handle change event for the select
  const handleChange = (event) => {
      setSelectedValue(event.target.value);
  };

  // Options for the select
  const options = ['TriangeUp', 'Heart', 'Option 3'];

  return (
    <>
      <ImageCard style={{ minHeight: '250px', backgroundSize: 'contain', ...(image.url ? { background: `url(${image.url}) no-repeat center center`} : {}) }}>
        {isUploading && <>....</>}
        {/* <Image image={image.url} title="Uploaded Image" style={{position: 'absolute', height: "100vh", aspectRatio: '1/1'}} /> */}
        {(!isUploading && features && features.includes('unsplash')) && 
          <>
          <Grid container spacing={2} style={{zIndex: 1000, position: 'absolute', bottom: 0}}>
            <Grid item xs={10}>
                <TextField
                label="Search for images"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{width: '100%'}}
                />
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={handleSearch}>
                    <SearchIcon />
                </Button>
            </Grid>
          </Grid>
          {blockData && blockData.title &&
          <Grid container spacing={2}>
            <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={(e) => { setSearchQuery(blockData.title); handleSearch()}}>
                    Search for {blockData.title}
                </Button>
            </Grid>
          </Grid>
          }
          </>
        }
        <ImageContent>
          {isSearching && <>....</>}
          {(features && features.includes('unsplash') && searchResults.length > 0) && (
            <Grid container spacing={2}>
              {searchResults.map((result) => (
                <Grid item key={result.id} xs={4}>
                  <ImageCard>
                    <Image image={result.url} title="Searched Image" />
                    <ImageContent>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdate(result)}
                      >
                        Select
                      </Button>
                    </ImageContent>
                  </ImageCard>
                </Grid>
              ))}
            </Grid>
          )}
          {(!isUploading && features && features.includes('uploader')) && 
            <Button variant="contained" color="primary" component="label">
              Upload
              <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleUpload} />
            </Button>
          }
        </ImageContent>
      </ImageCard>
      <Select
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
        >
            <MenuItem value="" disabled>
                Choose an option
            </MenuItem>
            {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    </>
  );
};

export default ImageUploaderNew;
