import React, { useRef, useEffect } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

function AceCodeEditor({ data, ...props }) {
  const editorRef = useRef(null);

  const extractKeys = (obj, parent = '') => {
    const keys = [];
    for (let key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        keys.push(...extractKeys(obj[key], `${parent}${key}.`));
      } else {
        keys.push(`${parent}${key}`);
      }
    }
    return keys;
  };

  useEffect(() => {
    const editor = editorRef.current.editor;
    const langTools = window.ace.require("ace/ext/language_tools");

    const customCompleter = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        if (prefix.length === 0) {
          callback(null, []);
          return;
        }

        const templateHints = [
          { caption: "{{name}}", value: "{{name}}", meta: "variable" },
        ];

        let dataHints = extractKeys(data).map(key => ({
          caption: `{{${key}}}`,
          value: `{{${key}}}`,
          meta: "variable"
        }));

        const contentBeforeCursor = editor.session.getTextRange({start: {row: 0, column: 0}, end: pos});
        const loopMatch = contentBeforeCursor.match(/{% loop (\w+) as (\w+) %}/);

        if (loopMatch && !contentBeforeCursor.includes("{% endloop %}")) {
          const loopVar = loopMatch[1];
          const iteratorName = loopMatch[2];
          if (data[loopVar] && Array.isArray(data[loopVar]) && data[loopVar].length > 0) {
            const loopItemKeys = extractKeys(data[loopVar][0]);
            dataHints = dataHints.concat(loopItemKeys.map(key => ({
              caption: `{{${iteratorName}.${key}}}`,
              value: `{{${iteratorName}.${key}}}`,
              meta: "loop variable"
            })));
          }
        }

        const combinedHints = [...templateHints, ...dataHints];
        callback(null, combinedHints.filter(hint => hint.caption.startsWith(prefix)));
      }
    };

    langTools.setCompleters([customCompleter]);
    editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true
    });
  }, [data]);

  return (
    <AceEditor
      mode="html"
      theme="monokai"
      ref={editorRef}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true
      }}
      {...props}
    />
  );
}

export default AceCodeEditor;



/*

import React, { useRef, useEffect } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

function AceCodeEditor({ data, ...props }) {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = editorRef.current.editor;
    const langTools = window.ace.require("ace/ext/language_tools");

    const customCompleter = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        if (prefix.length === 0) {
          callback(null, []);
          return;
        }

        const customCompletions = Object.keys(data).map(key => ({
          caption: key,
          value: key,
          meta: "variable"
        }));

        callback(null, customCompletions.filter(item => item.caption.startsWith(prefix)));
      }
    };

    langTools.setCompleters([customCompleter]);
    editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true
    });
  }, [data]);

  return (
    <AceEditor
      mode="html"
      theme="monokai"
      ref={editorRef}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true
      }}
      {...props}
    />
  );
}

export default AceCodeEditor;

*/