import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import CampaignIcon from '@mui/icons-material/Campaign';

const DelayedButton = ({ to, delay=5000, children, size="large", variant="contained", color="primary" }) => {

  
    const [isNavigating, setIsNavigating] = useState(false);
    const [isDelayed, setIsDelayed] = useState(false);
    const [isTalking, setIsTalking] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const location = useLocation();
    //const history = useHistory();
    
    const handleButtonClickNavigate = () => {
        setIsTalking(true);
        setIsNavigating(true);
    };
    
    useEffect(() => {
      let timeoutId;

      if (isNavigating) {
        timeoutId = setTimeout(() => {
          // Perform any additional tasks if needed

          // Redirect to the destination after the delay
          //window.location.href = to;
          setIsDelayed(true);
          setIsTalking(false);
          setIsNavigating(false);
          //history.push(to);
        }, delay);
      }

      return () => clearTimeout(timeoutId);
    }, [isNavigating, delay, children]);

    const mat = <CampaignIcon />
    
    if (redirect) {
      //return <Redirect to={destination} />;
    }
  
    return (
      <>
        <Button
          component={Link}
          to={to}
          disabled={isNavigating}
          className={isNavigating ? 'navigating' : ''}
          onClick={handleButtonClickNavigate}
          size={size} 
          variant={variant} 
          color={color}
        >
          {isTalking ?  (mat) : ('')}
          {children}
        </Button>
    </>
    );
};

export default DelayedButton;