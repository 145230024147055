import { Col, Button } from "react-bootstrap";
import HtmlViewer from "../../../components/HtmlViewer";
import NewWebsite from "../../../pages/NewWebsite";
import InternalTemplate from "../../templates/Internal";
import DashboardSideBarMenu from "./SidebarMenu";
import Header from "../../../components/Header";
import { supabase } from "../../../supabase";
import React, { useEffect, useState } from "react";
import MultiStepForm from "../../components/MultiStepForm";
import SnapshotFromHtml from "../../components/SnapshotFromHtml";
import { generateWebsite } from "../../../helpers/websiteCreate";
import { useParams } from "react-router-dom";
import axios from "axios";
import PlaceholderList from "../../components/PlaceholderList";
import DelayedLink from "../../components/DelayedLink";
import MenuIcon from '@mui/icons-material/Menu';

  const NewWebsitePage = ({match}) => {
    const { theSiteId } = useParams();
    //if(theSiteId){
      console.log(theSiteId)
    //}
    const [siteId, setSiteId] = useState(null);
    const [siteData, setSiteData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const headerContent = <h1>Creating a New Website <DelayedLink to={'/dashboard/websites'}><MenuIcon /></DelayedLink></h1>;
    const footerContent = <p>Logged in as user@example.com</p>;
    const leftSideBarContent = <DashboardSideBarMenu />
    const insertWebsite = async() => {
        let htmlContent = localStorage.getItem('htmlContent');
        const homePageData = localStorage.getItem('homePageData');
        const formDataRaw = localStorage.getItem('formData');
        const formData = (formDataRaw) ? JSON.parse(formDataRaw) : {};
        const { data: { user } } = await supabase.auth.getUser()
        if(siteId){
          const { data, error } = await supabase
              .from('website')
              .update([
                  { name: (formData && formData.companyName)? formData.companyName : Math.random(), data: homePageData, html: htmlContent, owner:  user.id},
              ])
              .eq('id',siteId)
              .select()
          if(error){
              alert(JSON.stringify(error))
              return null;
          }
          console.log(data);
          setSiteId(data[0].id)
          setSiteData(data[0].data)
          return data[0];
        } else{
          const { data, error } = await supabase
              .from('website')
              .insert([
                  { name: (formData && formData.companyName)? formData.companyName : Math.random(), data: homePageData, html: htmlContent, owner:  user.id},
              ])
              .select()
          if(error){
              alert(JSON.stringify(error))
              return null;
          }
          console.log(data);
          setSiteId(data[0].id)
          setSiteData(data[0].data)
          return data[0];
        }
    }

    const getHtmlContent = () => {
      return localStorage.getItem('htmlContent')
    }
    const reGenerateWebsite = async() => {
      //let website={{name: state.companyName, industry: state.companyIndustry}}
      let homePageData = (siteData && siteData.length>0) ? siteData : localStorage.getItem('homePageData2');
      let htmlContent = await generateWebsite({thePageData: (homePageData) ? JSON.parse(homePageData) : false});
      localStorage.setItem('htmlContent', htmlContent);
    }
    const pullAndSetWebsite = async(websiteId) => {
      let { data: website, error } = await supabase
      .from('website')
      .select('*')
      .eq('id',websiteId);
      if(error){
        console.error(error);
      }
      website = website[0]
      console.log(website)
      setSiteId(websiteId);
      setSiteData(website.data);
      //if(website.formData){
        localStorage.setItem('formData', JSON.stringify(website.formData))
        localStorage.setItem('homePageData', JSON.stringify(website.data))
      //}
      localStorage.setItem('htmlContent', website.html);
    }

    useEffect(() => {
      if(theSiteId){
        // pull the data from DB
        // push the HTML in
        // set the formData
        pullAndSetWebsite(theSiteId);
        setIsLoading(false);
      } else{
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    },[]);
      

    return (
      <InternalTemplate headerContent={headerContent} footerContent={footerContent} leftSideBarContent={leftSideBarContent}>
        {isLoading ? 
          <PlaceholderList type="newWebsite" />
          :
          <div className='row'>
            <Col className="col-md-2">
                <NewWebsite onSaved={(e) => setSiteId(e)} />
                <MultiStepForm />
            </Col>
            <Col>
              <Header nameAny='ContentSimi Website Creator'>
                <Button onClick={insertWebsite}>Save</Button> {' '} 
                {/* <SnapshotFromHtml htmlContent={getHtmlContent()} /> */}
                <Button onClick={reGenerateWebsite}>Regenerate</Button>
              </Header>              
              <HtmlViewer />
            </Col>
          </div>
          }
      </InternalTemplate>
      
    );
  };
  
  export default React.memo(NewWebsitePage);