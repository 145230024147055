//import microsoftLogoSvg from './brands/microsoft.svg';
//import spotifyLogoSvg from './brands/spotify-1.svg';
//import { ReactComponent as microsoftLogoSvg } from '../public/brands/microsoft.svg';
//import { ReactComponent as spotifyLogoSvg } from '../public/brands/spotify-1.svg';

const PUBLIC_URL = 'http://localhost:3059';

export const getAllBrandLogos = () => {
    const brands = [
        {
            name: 'Microsoft',
            logoUrl: PUBLIC_URL + '/brands/microsoft.svg',
            //logoSvg: <div dangerouslySetInnerHTML={{ __html: microsoftLogoSvg }} />,
        },
        {
            name: 'Spotify',
            logoUrl: PUBLIC_URL + '/brands/spotify-1.svg',
            //logoSvg: <div dangerouslySetInnerHTML={{ __html: spotifyLogoSvg }} />,
        },
    ];
    return brands;
}