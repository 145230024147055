import {Col, Row} from 'react-bootstrap';
const ThreeColumnBlock = ({ block, blockId }) => {
    return (
      <div className="row">
        <h3>3-Column Block</h3>
        {block.columns.map((column, index) => (
          <Col key={index} className='col-md-3'>
            {column}
          </Col>
        ))}
      </div>
    );
  };

export default ThreeColumnBlock;