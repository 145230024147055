import { supabase } from "../supabase";

export const convertBase64ToDataUrl = async (base64json,isPng=true) => {
    if(!isPng){
        const b64Json = base64json;
        //const b64Json = base64json.replace(/-/g, '+').replace(/_/g, '/');
        const jsonString = window.atob(b64Json);
        const jsonObj = JSON.parse(jsonString);
        const newDataUrl = `data:${jsonObj.imageType};${jsonObj.imageData}`;
        return newDataUrl;
    } else{
        const newDataUrl = `data:image/png;base64,${base64json}`;
        return newDataUrl;
        
    }
}

export const extractUrlFromImgString = (imgString) => {
    if(imgString && imgString.length>0){
        const htmlString = imgString;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const imageUrl = doc.querySelector('img').src;
        console.log(imageUrl);  // Outputs: https://example.com/image.jpg
        return imageUrl;
    } else{
        return null;
    }
}

export const convertImageURLToDataURL = async(url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
}
export const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export const resizeImage = (dataURL, maxWidth, maxHeight, outputFormat = 'image/png', quality = 0.8) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = dataURL;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const newDataURL = canvas.toDataURL(outputFormat, quality);
            resolve(newDataURL);
        };

        img.onerror = (err) => {
            reject(err);
        };
    });
}

export const uploadToSupabaseFromDataURL = async(dataURL,extension='png') => {
    const blob = dataURLtoBlob(dataURL);
    const random = Math.random();
    const path = random+'.'+extension;
    /*
    const { error } = await supabase.storage.from('images').upload('public/'+path, blob);
    if (error) {
        console.error('Error uploading:', error);
    } else {
        console.log('Uploaded successfully');
    }
    */   
    const { data: signedUrlData, error: signedUrlError } = await supabase.storage
        .from('images')
        .createSignedUploadUrl('public/'+path, 600);
    if (signedUrlError) {
        console.log('Error creating signed URL:', signedUrlError);
        return;
    }
    const { data: uploadData, error: uploadError } = await supabase
        .storage
        .from('images')
        .uploadToSignedUrl('public/'+path, signedUrlData.token, blob
        /*,{
            extraHeaders: {
              'Content-Type': 'image/jpeg',
              'Cache-Control': 'public, max-age=31536000'
            }
            */)

    if (uploadError) {
        console.log('Error uploading image:', uploadError);
        return;
    }
    const { data: publicURLData, error: publicURLError } = supabase
        .storage
        .from('images')
        .getPublicUrl('public/'+path)
    if (publicURLError) {
        console.log('Error retrieving image URL:', publicURLError);
        return;
    }
    console.log('file uploaded',publicURLData.publicUrl);
    if (publicURLData.publicUrl) {
        return publicURLData.publicUrl;
    } else{
        console.log('some issue with public url');
    }
}

export const openAiDataUrlToSupabaseUpload = async (dataurl, w=512, h=512) => {
    try {
        // Resize the image
        const resizedDataURL = await resizeImage(dataurl, w, h);
        
        // Convert the data URL to a blob and upload to Supabase or another service
        const uploadedURL = await uploadToSupabaseFromDataURL(resizedDataURL);
        
        return uploadedURL;
    } catch (error) {
        console.error("Error processing the image:", error);
        return null;
    }
}


