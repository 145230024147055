import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const MultiStepForm = () => {
  const steps = [
    {
      label: 'Step 1',
      fields: [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'email', label: 'Email', type: 'email' },
      ],
    },
    {
      label: 'Step 2',
      fields: [
        { name: 'password', label: 'Password', type: 'password' },
        { name: 'confirmPassword', label: 'Confirm Password', type: 'password' },
      ],
    },
    // Add more steps as needed
  ];

  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      // Handle form submission
      console.log('Form submitted:', values);
      setSubmitting(false);
    }, 500);
  };

  return (
    <div>
      <h2>Multi-Step Form</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            {steps.map((step, index) => (
              <div key={index} style={{ display: values.step === index ? 'block' : 'none' }}>
                <h3>{step.label}</h3>
                {step.fields.map((field) => (
                  <div key={field.name}>
                    <label htmlFor={field.name}>{field.label}</label>
                    <Field type={field.type} id={field.name} name={field.name} />
                    <ErrorMessage name={field.name} component="div" />
                  </div>
                ))}
                {index > 0 && (
                  <button type="button" onClick={() => setFieldValue('step', index - 1)}>
                    Previous
                  </button>
                )}
                {index < steps.length - 1 && (
                  <button type="button" onClick={() => setFieldValue('step', index + 1)}>
                    Next
                  </button>
                )}
                {index === steps.length - 1 && (
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                )}
              </div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepForm;
