// Template for Homepage Etc
const PublicTemplate = ({ headerContent, footerContent, children }) => {
    return (
      <div>
        <header>{headerContent}</header>
        <main>{children}</main>
        <footer>{footerContent}</footer>
      </div>
    );
  };

  export default PublicTemplate;