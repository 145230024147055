import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, TextField, TextareaAutosize, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import { GriderLayout } from './GriderLayout';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { generateSlug } from '../../../helpers/randoms';

const HeaderForm = ({ data, onUpdate }) => {
  const [headerData, setHeaderData] = useState(data || {});

  const handleSave = () => {
    onUpdate(headerData);
  };

  const Submenu = () => {
    
  }

  const handleAddMenuItem = () => {
    const menuItemNew = {
      label: "",
      link: "#",
      type: "section",
      section: "1234",
      labelManuallyEdited: false,
      linkManuallyEdited: false,
    }
    setHeaderData({ ...headerData, menus: [...headerData.menus, menuItemNew] });
  }
  
  const handleAddSubMenuItemNext = (menuIndex) => {
    const subMenuItemNew = {
      menu: "",
      label: "",
      link: "#",
      type: "section",
      section: "1234",
      labelManuallyEdited: false,
      linkManuallyEdited: false,
    }
    let theNewMenus = headerData.menus;
    console.log(theNewMenus)
    theNewMenus[menuIndex]['submenus'] = (theNewMenus[menuIndex]['submenus']) ? theNewMenus[menuIndex]['submenus'] : [];
    theNewMenus[menuIndex]['submenus'].push(subMenuItemNew);
    setHeaderData({ ...headerData, menus: theNewMenus });
  }
  const handleAddSubMenuItemOld = (menuIndex) => {
    const subMenuItemNew = {
        menu: "",
        label: "",
        link: "#",
        type: "section",
        section: "1234",
        labelManuallyEdited: false,
        linkManuallyEdited: false,
    };

    // Clone the existing menus
    let updatedMenus = [...headerData.menus];

    // Clone the specific menu item you want to modify
    let updatedMenuItem = {...updatedMenus[menuIndex]};

    // Initialize 'submenus' if it doesn't exist
    if (!updatedMenuItem.submenus) {
        updatedMenuItem.submenus = [];
    }

    // Add the new submenu item
    updatedMenuItem.submenus.push(subMenuItemNew);

    // Replace the original menu item with the updated one
    updatedMenus[menuIndex] = updatedMenuItem;

    // Update the state
    setHeaderData({ ...headerData, menus: updatedMenus });
};

const handleAddSubMenuItem = (menuPath) => {  
  const newSubMenuItem = {
    menu: "",
    label: "",
    link: "#",
    type: "section",
    section: "1234",
    labelManuallyEdited: false,
    linkManuallyEdited: false,
  };
  // Recursive function to add a submenu item at the specified path
  const addSubMenuItem = (menuItems, path) => {
      if (path.length === 0) {
          // Base case: if the path is exhausted, add the new submenu item here
          return [...menuItems, newSubMenuItem];
      }

      // Recursive case: navigate through the nested structure
      return menuItems.map((item, index) => {
          if (index === path[0]) {
              return {
                  ...item,
                  submenus: item.submenus ? 
                      addSubMenuItem(item.submenus, path.slice(1)) : 
                      (path.length === 1 ? [newSubMenuItem] : [])
              };
          }
          return item;
      });
  };

  // Update the state with the new menus structure
  setHeaderData(prevState => ({
      ...prevState,
      menus: addSubMenuItem(prevState.menus, menuPath)
  }));
};
  
  const handleMenuDelete = (index,event) => {
    event.stopPropagation();
    const newItems = [...headerData.menus];
    newItems.splice(index, 1);
    setHeaderData({ ...headerData, menus: newItems });
  };
  const handleSubMenuDeleteOld = (subMenuindex,menuIndex,event) => {
    event.stopPropagation();
    const newItems = [...headerData.menus];
    const newMenu = newItems[menuIndex];
    newItems[menuIndex]['submenus'].splice(subMenuindex, 1);
    if(newItems[menuIndex]['submenus'].length===0){
      delete newItems[menuIndex]['submenus'];
    }
    setHeaderData({ ...headerData, menus: newItems });
  };
  const handleSubMenuDelete = (subMenuindex, menuIndex, event) => {
    event.stopPropagation();
    // Create a new copy of the menus array
    const newItems = [...headerData.menus];
    // Create a new copy of the specific menu you want to modify
    const newMenu = { ...newItems[menuIndex] };
    // Modify the submenu array within the newly created menu object
    newMenu.submenus = [...newMenu.submenus];
    newMenu.submenus.splice(subMenuindex, 1);
    if (newMenu.submenus.length === 0) {
      delete newMenu['submenus'];
    }
    // Reassign the modified menu back into the new items array
    newItems[menuIndex] = newMenu;
    // Update the state with the new array
    setHeaderData({ ...headerData, menus: newItems });
  };
  
  
  const StyledAccordionSummary = styled(AccordionSummary)({
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const StyledIconButton = styled(IconButton)({
    //marginRight: 'auto',
    cursor: 'grab !important',
  });
  
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (event) => {
      event.preventDefault();
  };

  const handleDropMenu = async (event, targetIndex) => {
      event.preventDefault();
      const draggedIndex = event.dataTransfer.getData('text/plain');
      const draggedItem = headerData.menus[draggedIndex];

      const newItems = [...headerData.menus];
      newItems.splice(draggedIndex, 1);
      newItems.splice(targetIndex, 0, draggedItem);

      let newHeaderData = {...headerData, menus: newItems};
      setHeaderData(newHeaderData);
      onUpdate(newHeaderData); 
  };

  
  const handleDragStartSubmenu = (event, subIndex) => {
    event.dataTransfer.setData('text/plain', subIndex);
  };

  const handleDragOverSubmenu = (event) => {
      event.preventDefault();
  };
  /*
  const handleDropSubMenu = async (event, menuIndex, targetSubIndex) => {
    event.preventDefault();
    //const draggedSubIndex = event.dataTransfer.getData('text/plain');
    //const draggedSubItem = headerData.menus[menuIndex][draggedSubIndex];

    //const newItems = [...headerData.menus[menuIndex]];
    //newItems.splice(draggedSubIndex, 1);
    //newItems.splice(targetSubIndex, 0, draggedSubItem);
    const draggedSubIndex = parseInt(event.dataTransfer.getData('text/plain'), 10);
    const draggedSubItem = headerData.menus[menuIndex].submenus[draggedSubIndex];
    console.log('menu index',headerData.menus[menuIndex]);

    const newItems = [...headerData.menus[menuIndex].submenus];
    newItems.splice(draggedSubIndex, 1);
    newItems.splice(targetSubIndex, 0, draggedSubItem);
    console.log('menu index 2',newItems);

    //let newHeaderData = headerData;
    //newHeaderData['menus'][menuIndex] = newItems;
    let newHeaderData = { ...headerData, menus: [...headerData.menus.slice(0, menuIndex), newItems, ...headerData.menus.slice(menuIndex + 1)] };
    setHeaderData(newHeaderData);
    console.log('Submenu reorder',newHeaderData['menus'][menuIndex],headerData)
    //onUpdate(newHeaderData); 
};
*/
const handleDropSubMenu = async (event, menuIndex, targetSubIndex) => {
  event.preventDefault();
  const draggedSubIndex = parseInt(event.dataTransfer.getData('text/plain'), 10);
  const draggedSubItem = headerData.menus[menuIndex].submenus[draggedSubIndex];

  const newSubmenus = [...headerData.menus[menuIndex].submenus];
  newSubmenus.splice(draggedSubIndex, 1);
  newSubmenus.splice(targetSubIndex, 0, draggedSubItem);

  const newMenu = { ...headerData.menus[menuIndex], submenus: newSubmenus };
  const newMenus = [...headerData.menus.slice(0, menuIndex), newMenu, ...headerData.menus.slice(menuIndex + 1)];

  const newHeaderData = { ...headerData, menus: newMenus };
  setHeaderData(newHeaderData);
  //console.log('Submenu reorder', 'menuIndex',menuIndex,'submenuIndex', draggedSubIndex, targetSubIndex, newHeaderData.menus[menuIndex], newHeaderData);
  onUpdate(newHeaderData);
};


  return (
    <div>
        <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Site Name'}
        value={headerData.siteName}
        onChange={(e) => setHeaderData({ ...headerData, siteName: e.target.value })}
      />
      <br />
      <GriderLayout
        left={'Show Logo:'}
        right={<input
          type="checkbox"
          checked={headerData.showLogo}
          onChange={(e) => setHeaderData({ ...headerData, showLogo: e.target.checked })}
        />}
      />
      <br />
      <label>Background Color:</label>
      <input
        type="text"
        value={headerData.background}
        onChange={(e) => setHeaderData({ ...headerData, background: e.target.value })}
      />
      {/*
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Logo Source'}
        value={headerData.source}
        onChange={(e) => setHeaderData({ ...headerData, source: e.target.value })}
      />
      *}
      {/*
      <hr />
      <h5>Custom Logo</h5>
      <TextField
        variant="outlined"
        fullWidth 
        style={{ marginTop: 11 }}
        label={'Icon'}
        value={(headerData.customLogo && headerData.customLogo.icon) ? headerData.customLogo.icon : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, icon: e.target.value } })
        }
      />
      <label>Background Color:</label>
      <input
        type="color"
        value={(headerData.customLogo && headerData.customLogo.backgroundColor) ? headerData.customLogo.backgroundColor : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, backgroundColor: e.target.value } })
        }
      />
      <label>Color:</label>
      <input
        type="color"
        value={(headerData.customLogo && headerData.customLogo.color) ? headerData.customLogo.color : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, color: e.target.value } })
        }
      />
      <label>Font:</label>
      <input
        type="text"
        value={(headerData.customLogo && headerData.customLogo.font) ? headerData.customLogo.font : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, font: e.target.value } })
        }
      />
      <label>Font Weight:</label>
      <input
        type="text"
        value={(headerData.customLogo && headerData.customLogo.fontWeight) ? headerData.customLogo.fontWeight : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, fontWeight: e.target.value } })
        }
      />
      <label>HTML:</label>
      <textarea
        value={(headerData.customLogo && headerData.customLogo.html) ? headerData.customLogo.html : ''}
        onChange={(e) => setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, html: e.target.value } })}
      ></textarea>
      <label>Business Card HTML:</label>
      <textarea
        value={(headerData.customLogo && headerData.customLogo.bizcardHtml) ? headerData.customLogo.bizcardHtml : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, customLogo: { ...headerData.customLogo, bizcardHtml: e.target.value } })
        }
      ></textarea>
      <hr />
      <label>Show Social Links:</label>
      <input
        type="checkbox"
        checked={headerData.showSocial}
        onChange={(e) => setHeaderData({ ...headerData, showSocial: e.target.checked })}
      />
      <label>Style:</label>
      <input
        type="text"
        value={headerData.style}
        onChange={(e) => setHeaderData({ ...headerData, style: e.target.value })}
      />
      <label>Full Width:</label>
      <input
        type="checkbox"
        checked={headerData.fullWidth}
        onChange={(e) => setHeaderData({ ...headerData, fullWidth: e.target.checked })}
      />
      <label>Sticky:</label>
      <input
        type="checkbox"
        checked={headerData.sticky}
        onChange={(e) => setHeaderData({ ...headerData, sticky: e.target.checked })}
      />
      <label>Background Color:</label>
      <input
        type="color"
        value={headerData.background}
        onChange={(e) => setHeaderData({ ...headerData, background: e.target.value })}
      />
      <label>Button Label:</label>
      <input
        type="text"
        value={(headerData.button && headerData.button.label) ? headerData.button.label : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, button: { ...headerData.button, label: e.target.value } })
        }
      />
      <label>Button Link:</label>
      <input
        type="text"
        value={(headerData.button && headerData.button.link) ? headerData.button.link : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, button: { ...headerData.button, link: e.target.value } })
        }
      />
      <label>Button Type:</label>
      <select
        value={(headerData.button && headerData.button.link) ? headerData.button.type : ''}
        onChange={(e) =>
          setHeaderData({ ...headerData, button: { ...headerData.button, type: e.target.value } })
        }
      >
        <option value="page">Page</option>
        <option value="external">External</option>
      </select>
      *}
      {/*}
      <label>Website Settings - Website Name:</label>
      <input
        type="text"
        value={headerData.websiteSettings.websiteName}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, websiteName: e.target.value } })
        }
      />
      <label>Website Settings - Pages:</label>
      <textarea
        value={headerData.websiteSettings.pages.join('\n')}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, pages: e.target.value.split('\n') } })
        }
      ></textarea>
      <label>Website Settings - Background Color:</label>
      <input
        type="text"
        value={headerData.websiteSettings.backgroundColor}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, backgroundColor: e.target.value } })
        }
      />
      <label>Website Settings - Navigation Color:</label>
      <input
        type="text"
        value={headerData.websiteSettings.navigationColor}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, navigationColor: e.target.value } })
        }
      />
      <label>Website Settings - Heading Color:</label>
      <input
        type="text"
        value={headerData.websiteSettings.headingColor}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, headingColor: e.target.value } })
        }
      />
      <label>Website Settings - Body Text Color:</label>
      <input
        type="text"
        value={headerData.websiteSettings.bodyTextColor}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, bodyTextColor: e.target.value } })
        }
      />
      <label>Website Settings - Google Font:</label>
      <input
        type="text"
        value={headerData.websiteSettings.googleFont}
        onChange={(e) =>
          setHeaderData({ ...headerData, websiteSettings: { ...headerData.websiteSettings, googleFont: e.target.value } })
        }
    />*/}
      <hr />
      <h5>Menus:</h5>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      {headerData.menus && headerData.menus.map((menu, index) => (
        <Accordion
          key={index}                                     
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDropMenu(event, index)}
          onTouchStart={(event) => handleDragStart(event, index)}
          onTouchMove={handleDragOver}
          onTouchEnd={(event) => handleDropMenu(event, index)}
          >     
        <StyledAccordionSummary>
          <StyledIconButton>
            <DragIndicatorIcon />
          </StyledIconButton>
        <Typography variant="h6">{menu.menu}</Typography>
          <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleMenuDelete(index,event)}><DeleteIcon /></IconButton>
        </StyledAccordionSummary>
        <AccordionDetails>
        <div key={index}>  
          <TextField
            variant="outlined"
            fullWidth 
            style={{ marginTop: 11 }}
            label={'Menu'}
            value={menu.menu}
            onChange={(e) =>
              setHeaderData({
                ...headerData,
                menus: [
                  ...headerData.menus.slice(0, index),
                  { ...menu, menu: e.target.value },
                  ...headerData.menus.slice(index + 1)
                ]
              })
            }
          />
          <TextField
            variant="outlined"
            fullWidth 
            style={{ marginTop: 11 }}
            label={'Label'}
            value={menu.label}
            onChange={(e) =>
              setHeaderData({
                ...headerData,
                menus: [
                  ...headerData.menus.slice(0, index),
                  { ...menu, label: e.target.value },
                  ...headerData.menus.slice(index + 1)
                ]
              })
            }
          />
          <TextField
            variant="outlined"
            fullWidth 
            style={{ marginTop: 11 }}
            label={'Link'}
            value={menu.link}
            onChange={(e) =>
              setHeaderData({
                ...headerData,
                menus: [
                  ...headerData.menus.slice(0, index),
                  { ...menu, link: e.target.value },
                  ...headerData.menus.slice(index + 1)
                ]
              })
            }
          />
          {menu.submenus && <label>Submenus:</label>}     
          <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          {menu.submenus &&
            menu.submenus.map((submenu, subIndex) => (
              <div 
                key={subIndex} 
                style={{paddingLeft: 20}}                           
                draggable
                onDragStart={(event) => handleDragStartSubmenu(event, subIndex)}
                onDragOver={handleDragOverSubmenu}
                onDrop={(event) => handleDropSubMenu(event, index, subIndex)}
                onTouchStart={(event) => handleDragStartSubmenu(event, subIndex)}
                onTouchMove={handleDragOverSubmenu}
                onTouchEnd={(event) => handleDropSubMenu(event, index, subIndex)}
                >
                <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'Submenu'}
                  value={submenu.menu}
                  onChange={(e) =>
                    setHeaderData({
                      ...headerData,
                      menus: [
                        ...headerData.menus.slice(0, index),
                        {
                          ...menu,
                          submenus: [
                            ...menu.submenus.slice(0, subIndex),
                            { 
                              ...submenu, 
                              menu: e.target.value,
                              label: (submenu.labelManuallyEdited) ? submenu.label : (submenu.label) ? submenu.label : submenu.menu,
                              link: (submenu.linkManuallyEdited) ? submenu.link : (submenu.link) ? submenu.link : (submenu.label) ? generateSlug(submenu.label) : generateSlug(submenu.menu),
                            },
                            ...menu.submenus.slice(subIndex + 1)
                          ]
                        },
                        ...headerData.menus.slice(index + 1)
                      ]
                    })
                  }
                />
                <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'Submenu Label'}
                  value={submenu.label}
                  onChange={(e) =>
                    setHeaderData({
                      ...headerData,
                      menus: [
                        ...headerData.menus.slice(0, index),
                        {
                          ...menu,
                          submenus: [
                            ...menu.submenus.slice(0, subIndex),
                            { 
                              ...submenu, 
                              label: e.target.value,
                              link: (submenu.linkManuallyEdited) ? submenu.link : (submenu.link) ? submenu.link : (submenu.label) ? generateSlug(submenu.label) : generateSlug(submenu.menu),
                              labelManuallyEdited: true,
                            },
                            ...menu.submenus.slice(subIndex + 1)
                          ]
                        },
                        ...headerData.menus.slice(index + 1)
                      ]
                    })
                  }
                />
                <TextField
                  variant="outlined"
                  fullWidth 
                  style={{ marginTop: 11 }}
                  label={'Submenu Link'}
                  value={submenu.link}
                  onChange={(e) =>
                    setHeaderData({
                      ...headerData,
                      menus: [
                        ...headerData.menus.slice(0, index),
                        {
                          ...menu,
                          submenus: [
                            ...menu.submenus.slice(0, subIndex),
                            { 
                              ...submenu, 
                              link: e.target.value,
                              linkManuallyEdited: true,
                            },
                            ...menu.submenus.slice(subIndex + 1)
                          ]
                        },
                        ...headerData.menus.slice(index + 1)
                      ]
                    })
                  }
                />
                <IconButton style={{marginLeft: 'auto'}} onClick={(event) => handleSubMenuDelete(subIndex,index,event)}><DeleteIcon /></IconButton>
                <button onClick={() => handleAddSubMenuItem([index,submenu])}>Add Submenu</button>
              </div>
            ))}
          </DndProvider>
          <button onClick={() => handleAddSubMenuItem([index])}>Add Submenu</button>
          {/*
          <label>Type:</label>
          <select
            value={menu.type}
            onChange={(e) =>
              setHeaderData({
                ...headerData,
                menus: [
                  ...headerData.menus.slice(0, index),
                  { ...menu, type: e.target.value },
                  ...headerData.menus.slice(index + 1)
                ]
              })
            }
          >
            <option value="section">Section</option>
            <option value="external">External</option>
          </select>
          {menu.section && (
            <div>
              <TextField
                variant="outlined"
                fullWidth 
                style={{ marginTop: 11 }}
                label={'Section'}
                value={menu.section}
                onChange={(e) =>
                  setHeaderData({
                    ...headerData,
                    menus: [
                      ...headerData.menus.slice(0, index),
                      { ...menu, section: e.target.value },
                      ...headerData.menus.slice(index + 1)
                    ]
                  })
                }
              />
            </div>
          )}
          */}
        </div>
        </AccordionDetails>
      </Accordion>
      ))}
      </DndProvider>
      <button onClick={handleAddMenuItem}>Add Menu</button>

      <TextareaAutosize
                name="globalCSS"
                minRows={4}
                style={{width: '100%'}}
                aria-label="global CSS"
                placeholder="global CSS"
                value={headerData.globalCSS}
                onChange={(e) => setHeaderData({ ...headerData, globalCSS: e.target.value })}
            />

      <Button variant='outlined' color='primary' className='my-3' fullWidth onClick={handleSave}>Save</Button>
    </div>
  );
};

export default HeaderForm;
